import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import PaidInOutService from "../../services/paid-in-out-service";
import {
  GET_ALL_PAIDINOUT_FUND,
  GET_CAPITAL_CALL_CREDIT_FUND,
  GET_CAPITAL_CALL_DEBIT_FUND,
  ADD_PAIDINOUT_FUND,
  GET_PAIDINOUT,
  EDIT_PAIDINOUT_FUND,
  DELETE_PAIDINOUT_FUND,
  EXPORT_PAIDINOUT_FUND,
  UPDATE_CAPITAL_CALL,
} from "../actions";
import {
  getAllPaidInOutFund,
  getAllPaidInOutFundSuccess,
  getAllPaidInOutFundError,
  getCapitalCallDebitFundSuccess,
  getCapitalCallCreditFundSuccess,
  getCapitalCallCreditFundError,
  getCapitalCallDebitFundError,
  addPaidInOutFundSuccess,
  addPaidInOutFundError,
  getPaidInOutSuccess,
  getPaidInOutError,
  editPaidInOutFundSuccess,
  editPaidInOutFundError,
  deletePaidInOutFundSuccess,
  deletePaidInOutFundError,
  exportPaidInOutFundSuccess,
  exportPaidInOutFundError,
  updatePaidInOutSuccess,
  updatePaidInOutError,
} from "./action";
import {
  getCapitalCallCreditFund as callCredit,
  getCapitalCallDebitFund as callDebit,
  getAllPaidInFund,
  getAllPaidOutFund,
} from "src/reduxs/actions";
import { toast } from "react-toastify";
import { handleMyErrorMessage } from "../../helpers/util";
import ToastElement from "../../components/toast";
import saveAs from "file-saver";
import moment from "moment";
// const getItems = (state) => state.paidInOut?.paidInOutList;

export function* watchGetAllPaidInOutFund() {
  yield takeEvery(GET_ALL_PAIDINOUT_FUND, getAllPaidInOutAc);
}

const getAllPaidInOutFundAsync = async (param) => {
  return PaidInOutService.getAllPaidInOutFund(param);
};

function* getAllPaidInOutAc({ payload }) {
  try {
    const response = yield call(getAllPaidInOutFundAsync, payload.param);
    if (response.data.success) {
      yield put(
        getAllPaidInOutFundSuccess(response.data.success, response.data.data)
      );
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getAllPaidInOutFundError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getAllPaidInOutFundError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getAllPaidInOutFundError(errorMessage));
    }
  }
}

export function* watchGetCapitalCallCreditFund() {
  yield takeEvery(GET_CAPITAL_CALL_CREDIT_FUND, getCapitalCallCreditFund);
}

const getCapitalCallAsync = async (param) => {
  return PaidInOutService.getCapitalCall(param);
};

function* getCapitalCallCreditFund({ payload }) {
  try {
    const response = yield call(getCapitalCallAsync, payload.capitalParam);
    if (response.data.success) {
      yield put(getCapitalCallCreditFundSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getCapitalCallCreditFundError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getCapitalCallCreditFundError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getCapitalCallCreditFundError(errorMessage));
    }
  }
}
export function* watchGetCapitalCallDebitFund() {
  yield takeEvery(GET_CAPITAL_CALL_DEBIT_FUND, getCapitalCallDebitFund);
}

function* getCapitalCallDebitFund({ payload }) {
  try {
    const response = yield call(getCapitalCallAsync, payload.capitalParam);
    if (response.data.success) {
      yield put(getCapitalCallDebitFundSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getCapitalCallDebitFundError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getCapitalCallDebitFundError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getCapitalCallDebitFundError(errorMessage));
    }
  }
}

export function* watchAddPaidInOutFund() {
  yield takeEvery(ADD_PAIDINOUT_FUND, addPaidInOutFund);
}

const addPaidInOutAsync = async (data) => {
  return PaidInOutService.addPaidInOutFund(data.paidInOutData);
};

function* addPaidInOutFund({ payload }) {
  try {
    const response = yield call(addPaidInOutAsync, payload);

    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        addPaidInOutFundSuccess(response.data.success, response.data.message)
      );
      // Fetch updated list

      yield put(
        getAllPaidInOutFund({
          projectId: payload.projectId,
        })
      );

      if (response.data.data?.transactionType === "credit") {
        yield put(callCredit({ projectId: payload.projectId, type: "credit" }));
        yield put(
          getAllPaidInFund({ projectId: payload.projectId, type: "credit" })
        );
      } else {
        yield put(callDebit({ projectId: payload.projectId, type: "debit" }));
        yield put(
          getAllPaidOutFund({
            projectId: payload.projectId,
          })
        );
      }
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(addPaidInOutFundError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type="error"
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   { containerId: "default" }
    // );
    // yield put(addPaidInOutFundError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(addPaidInOutFundError(errorMessage));
    }
  }
}

export function* watchGetPaidInOut() {
  yield takeEvery(GET_PAIDINOUT, getPaidInOutAc);
}

const getPaidInOutAsync = async (id) => {
  return PaidInOutService.getPaidInOut(id);
};

function* getPaidInOutAc({ payload }) {
  try {
    const response = yield call(getPaidInOutAsync, payload.paidInOutId);
    if (response.data.success) {
      yield put(getPaidInOutSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getPaidInOutError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getPaidInOutError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getPaidInOutError(errorMessage));
    }
  }
}

export function* watchEditPaidInOutFund() {
  yield takeEvery(EDIT_PAIDINOUT_FUND, editPaidInOutFund);
}

const editPaidInOutAsync = async (data, id) => {
  return PaidInOutService.editPaidInOutFund(data, id);
};

function* editPaidInOutFund({ payload }) {
  try {
    const response = yield call(
      editPaidInOutAsync,
      payload.paidInOutData,
      payload.paidInOutId
    );
    if (response.data.success) {
      yield put(
        editPaidInOutFundSuccess(response.data.success, response.data.message)
      );
      yield put(
        getAllPaidInOutFund({
          projectId: payload.projectId,
        })
      );
      yield put(
        getAllPaidInFund({
          projectId: payload.projectId,
        })
      );
      yield put(
        getAllPaidOutFund({
          projectId: payload.projectId,
        })
      );
      yield put(callCredit({ projectId: payload.projectId, type: "credit" }));
      yield put(callDebit({ projectId: payload.projectId, type: "debit" }));

      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(editPaidInOutFundError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type="error"
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   { containerId: "default" }
    // );
    // yield put(editPaidInOutFundError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(editPaidInOutFundError(errorMessage));
    }
  }
}

export function* watchDeletePaidInOutFund() {
  yield takeEvery(DELETE_PAIDINOUT_FUND, deletePaidInOutFund);
}

const deletePaidInOutAsync = async (id) => {
  return PaidInOutService.deletePaidInOutFund(id);
};

function* deletePaidInOutFund({ payload }) {
  try {
    const response = yield call(deletePaidInOutAsync, payload.paidInOutId);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        deletePaidInOutFundSuccess(response.data.success, response.data.message)
      );
      // Fetch updated list
      yield put(
        getAllPaidInOutFund({
          projectId: payload.projectId,
        })
      );
      yield put(
        getAllPaidInFund({
          projectId: payload.projectId,
        })
      );
      yield put(
        getAllPaidOutFund({
          projectId: payload.projectId,
        })
      );
      yield put(callCredit({ projectId: payload.projectId, type: "credit" }));
      yield put(callDebit({ projectId: payload.projectId, type: "debit" }));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(deletePaidInOutFundError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(deletePaidInOutFundError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(deletePaidInOutFundError(errorMessage));
    }
  }
}

export function* watchExportPaidInOutFund() {
  yield takeEvery(EXPORT_PAIDINOUT_FUND, exportPaidInOutFund);
}

const exportPaidInOutAsync = async (projectId) => {
  return PaidInOutService.exportPaidInOutFund(projectId);
};

function* exportPaidInOutFund({ payload }) {
  try {
    const response = yield call(exportPaidInOutAsync, payload.projectId);
    if (response && response.data) {
      yield put(exportPaidInOutFundSuccess(true, ""));
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const fileName =
        "PaidInOutFund-" + moment().format("YYYY-MMM-DD-HH-mm") + ".xlsx";
      saveAs(blob, fileName);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(exportPaidInOutFundError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(exportPaidInOutFundError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(exportPaidInOutFundError(errorMessage));
    }
  }
}

export function* watchUpdatePaidInOut() {
  yield takeEvery(UPDATE_CAPITAL_CALL, updatePaidInOut);
}

const updatePaidInOutAsync = async (data, projectId) => {
  return PaidInOutService.updatePaidInOutFund(data, projectId);
};

function* updatePaidInOut({ payload }) {
  try {
    const response = yield call(
      updatePaidInOutAsync,
      payload.data,
      payload.projectId
    );
    if (response.data.success) {
      yield put(
        updatePaidInOutSuccess(response.data.success, response.data.message)
      );

      yield put(
        getAllPaidInOutFund({
          projectId: payload.projectId,
        })
      );

      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(updatePaidInOutError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type="error"
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   { containerId: "default" }
    // );
    // yield put(updatePaidInOutError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(updatePaidInOutError(errorMessage));
    }
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAllPaidInOutFund),
    fork(watchGetCapitalCallCreditFund),
    fork(watchGetCapitalCallDebitFund),
    fork(watchAddPaidInOutFund),
    fork(watchGetPaidInOut),
    fork(watchEditPaidInOutFund),
    fork(watchDeletePaidInOutFund),
    fork(watchExportPaidInOutFund),

    fork(watchUpdatePaidInOut),
  ]);
}
