const Card = ({ children, classes }) => {
  return (
    <div
      className={`w-full px-5 py-6 bg-white rounded-lg shadow-card ${
        classes || ""
      }`}
    >
      {children}
    </div>
  );
};
export default Card;
