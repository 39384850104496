import React from "react";

const IconFileGears = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 134.026 134.026">
      <g>
        <g>
          <path
            d="M77.941,126.248H21.824c-8.592,0-15.588-6.996-15.588-15.588V42.072c0-8.592,6.997-15.588,15.588-15.588h56.118
			c7.521,0,13.822,5.355,15.271,12.453c2.021-0.566,4.056-1.017,6.102-1.267c-2.046-9.925-10.851-17.421-21.373-17.421H44.856
			c1.449-7.109,7.746-12.47,15.271-12.47h30.279c8.592,0,15.588,6.997,15.588,15.588V37.45c2.101,0.095,4.184,0.362,6.235,0.779
			V23.366c0-12.035-9.785-21.824-21.823-21.824H60.127c-10.96,0-19.979,8.163-21.507,18.706H21.824C9.792,20.248,0,30.037,0,42.072
			v68.588c0,12.032,9.792,21.823,21.824,21.823h56.118c9.463,0,17.445-6.089,20.472-14.51c-2.064-0.299-4.086-0.768-6.077-1.377
			C89.998,122.254,84.433,126.248,77.941,126.248z"
          />
          <rect x="18.706" y="42.852" width="41.504" height="4.676" />
          <rect x="18.706" y="63.634" width="31.956" height="4.676" />
          <rect x="18.706" y="84.422" width="31.956" height="4.677" />
          <rect x="18.706" y="105.204" width="41.504" height="4.677" />
          <path
            d="M127.312,76.195l5.718-2.106c0.785-0.286,1.188-1.17,0.907-1.961l-4.762-12.879c-0.293-0.785-1.182-1.196-1.961-0.904
			l-5.705,2.104c-0.798,0.292-1.748,0.161-2.126-0.283c-0.39-0.454-1.181-2.183-0.828-2.936l2.54-5.52
			c0.358-0.761,0.018-1.678-0.749-2.024l-12.459-5.751c-0.767-0.347-1.681-0.012-2.033,0.749l-2.546,5.52
			c-0.341,0.764-1.108,1.357-1.698,1.315c-0.579-0.04-2.375-0.686-2.667-1.474l-2.102-5.718c-0.292-0.786-1.169-1.19-1.96-0.904
			l-12.885,4.752c-0.792,0.292-1.2,1.172-0.907,1.963l2.101,5.712c0.292,0.786,0.17,1.748-0.28,2.125
			c-0.438,0.387-2.162,1.182-2.929,0.831l-5.514-2.545c-0.759-0.356-1.669-0.012-2.024,0.749l-5.755,12.474
			c-0.356,0.755-0.015,1.668,0.746,2.021l5.514,2.545c0.768,0.354,1.358,1.121,1.315,1.693c-0.03,0.584-0.686,2.381-1.474,2.673
			l-5.708,2.101c-0.789,0.293-1.194,1.17-0.904,1.961l4.75,12.891c0.286,0.785,1.172,1.193,1.958,0.907l5.714-2.106
			c0.78-0.293,1.742-0.164,2.126,0.279c0.383,0.439,1.175,2.168,0.828,2.936l-2.546,5.505c-0.353,0.761-0.012,1.681,0.743,2.027
			l12.477,5.755c0.768,0.353,1.681,0.012,2.027-0.75l2.539-5.51c0.354-0.768,1.121-1.353,1.699-1.322
			c0.578,0.037,2.369,0.688,2.661,1.48l2.106,5.699c0.293,0.791,1.176,1.199,1.961,0.913l12.885-4.749
			c0.792-0.293,1.193-1.176,0.907-1.967l-2.101-5.712c-0.292-0.786-0.158-1.747,0.28-2.125c0.45-0.39,2.18-1.182,2.935-0.828
			l5.517,2.545c0.768,0.347,1.675,0.013,2.034-0.749l5.736-12.471c0.358-0.761,0.012-1.68-0.743-2.027l-5.517-2.545
			c-0.756-0.354-1.279-2.192-1.279-2.765C125.875,77.206,126.521,76.487,127.312,76.195z M105.33,91.632
			c-7.903,2.91-16.684-1.139-19.601-9.043c-2.911-7.903,1.139-16.684,9.042-19.604c7.904-2.92,16.685,1.129,19.607,9.033
			C117.289,79.935,113.24,88.715,105.33,91.632z"
          />
        </g>
      </g>
    </svg>
  );
};

export default IconFileGears;
