import {
  GET_ALL_USER,
  GET_ALL_USER_SUCCESS,
  GET_ALL_USER_ERROR,
  GET_USER_LIST,
  GET_USER_LIST_SUCCESS,
  GET_USER_LIST_ERROR,
  RESEND_VERIFICATION_CODE,
  RESEND_VERIFICATION_CODE_SUCCESS,
  RESEND_VERIFICATION_CODE_ERROR,
  ADD_USER,
  ADD_USER_SUCCESS,
  ADD_USER_ERROR,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  EDIT_USER,
  EDIT_USER_SUCCESS,
  EDIT_USER_ERROR,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  DELETE_MULTIPLE_USER,
  DELETE_MULTIPLE_USER_SUCCESS,
  DELETE_MULTIPLE_USER_ERROR,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  UPDATE_EMAIL_SETTING,
  UPDATE_EMAIL_SETTING_SUCCESS,
  UPDATE_EMAIL_SETTING_ERROR,
  RESET_USER,
  EXPORT_USER,
  EXPORT_USER_SUCCESS,
  EXPORT_USER_FAIL,
  GET_INVESTORS_BY_USER,
  GET_INVESTORS_BY_USER_SUCCESS,
  GET_INVESTORS_BY_USER_FAIL,
  SET_SELECTED_INVESTOR,
  CLEAR_SELECTED_INVESTOR,
} from "../actions";

const INIT_STATE = {
  dbParam: null,
  users: null,
  userList: null,
  metaData: null,
  userData: null,
  changePasswordData: null,
  emailSettingData: null,
  investors: [],
  userId: null,
  userIds: null,
  success: false,
  message: null,
  loading: false,
  loading1: false,
  loading2: false,
  loading3: false,
  loading4: false, //for fetching investors by user
  error: null,
  selectedInvestor: JSON.parse(localStorage.getItem("selectedInvestor")),
};

const userReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_USER:
      return {
        ...state,
        error: null,
      };
    case GET_ALL_USER_SUCCESS:
      return {
        ...state,
        users: action.payload,
        error: null,
      };
    case GET_ALL_USER_ERROR:
      return {
        ...state,
        users: null,
        error: action.payload,
      };
    case GET_USER_LIST:
      return {
        ...state,
        userData: null,
        loading: true,
        userId: null,
        userIds: null,
        error: null,
      };
    case GET_USER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        userList: action.payload.userList,
        metaData: action.payload.metadata,
        error: null,
      };
    case GET_USER_LIST_ERROR:
      return {
        ...state,
        loading: false,
        userList: null,
        error: action.payload,
      };
    case RESEND_VERIFICATION_CODE:
      return { ...state, loading1: true, error: null };
    case RESEND_VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case RESEND_VERIFICATION_CODE_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case ADD_USER:
      return { ...state, loading: true, error: null };
    case ADD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: false,
      };

    case ADD_USER_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case GET_USER:
      return { ...state, loading2: true, error: null };
    case GET_USER_SUCCESS:
      return {
        ...state,
        userData: action.payload,
        error: null,
        loading2: false,
      };
    case GET_USER_ERROR:
      return {
        ...state,
        userData: null,
        error: action.payload,
        loading2: false,
      };
    case EDIT_USER:
      return { ...state, loading: true, error: null };
    case EDIT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case EDIT_USER_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DELETE_USER:
      return { ...state, loading1: true, error: null };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_USER_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DELETE_MULTIPLE_USER:
      return { ...state, loading1: true, error: null };
    case DELETE_MULTIPLE_USER_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_MULTIPLE_USER_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case CHANGE_PASSWORD:
      return { ...state, loading: true, error: null };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case UPDATE_EMAIL_SETTING:
      return { ...state, loading: true, error: null };
    case UPDATE_EMAIL_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case UPDATE_EMAIL_SETTING_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case RESET_USER:
      return {
        ...state,
        loading: false,
        loading1: false,
        success: false,
        message: null,
        userData: null,
        error: null,
      };

    case EXPORT_USER:
      return {
        ...state,
        loading3: true,
        error: null,
      };
    case EXPORT_USER_SUCCESS:
      return {
        ...state,
        loading3: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case EXPORT_USER_FAIL:
      return {
        ...state,
        loading3: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case GET_INVESTORS_BY_USER:
      return {
        ...state,
        loading4: true,
        error: null,
      };
    case GET_INVESTORS_BY_USER_SUCCESS:
      return {
        ...state,
        investors: action.payload,
        loading4: false,
        error: null,
      };
    case GET_INVESTORS_BY_USER_FAIL:
      return {
        ...state,
        investors: [],
        loading4: false,
        error: action.payload,
      };
    case SET_SELECTED_INVESTOR:
      return {
        ...state,
        selectedInvestor: action.payload,
      };
    case CLEAR_SELECTED_INVESTOR:
      return {
        ...state,
        selectedInvestor: null,
      };
    default:
      return { ...state };
  }
};
export default userReducer;
