import {
  SET_PAIDIN,
  GET_ALL_PAIDIN,
  GET_ALL_PAIDIN_SUCCESS,
  GET_ALL_PAIDIN_ERROR,
  ADD_PAIDIN,
  ADD_PAIDIN_SUCCESS,
  ADD_PAIDIN_ERROR,
  GET_PAIDIN,
  GET_PAIDIN_SUCCESS,
  GET_PAIDIN_ERROR,
  EDIT_PAIDIN,
  EDIT_PAIDIN_SUCCESS,
  EDIT_PAIDIN_ERROR,
  DELETE_PAIDIN,
  DELETE_PAIDIN_SUCCESS,
  DELETE_PAIDIN_ERROR,
  EXPORT_PAIDIN,
  EXPORT_PAIDIN_SUCCESS,
  EXPORT_PAIDIN_ERROR,
  RESET_PAIDIN,
} from "../actions";

const INIT_STATE = {
  rowIndex: null,
  columnId: null,
  value: null,
  param: null,
  paidInList: null,
  paidInData: null,
  paidInId: null,
  projectId: null,
  success: false,
  success1: false,
  message: null,
  loading: false,
  loading1: false,
  loading2: false,
  loading3: false,
  error: null,
  totalPaidInAmount: null,
};

function _setPaidInList(paidInList, rowIndex, columnId, value) {
  paidInList?.data?.forEach(function (item, index) {
    if (index === rowIndex) this[index][columnId] = value;
  }, paidInList?.data);
  return paidInList;
}

const paidInReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_PAIDIN:
      return {
        ...state,
        paidInList: _setPaidInList(
          state.paidInList,
          action.payload.rowIndex,
          action.payload.columnId,
          action.payload.value
        ),
      };
    case GET_ALL_PAIDIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ALL_PAIDIN_SUCCESS:
      return {
        ...state,
        loading: false,
        paidInList: action.payload.data,
        success: action.payload.success,
        totalPaidInAmount: action.payload.totalPaidInAmount,
        error: null,
      };
    case GET_ALL_PAIDIN_ERROR:
      return {
        ...state,
        loading: false,
        paidInList: null,
        error: action.payload,
      };
    case ADD_PAIDIN:
      return { ...state, loading1: true, error: null };
    case ADD_PAIDIN_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case ADD_PAIDIN_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case GET_PAIDIN:
      return { ...state, error: null };
    case GET_PAIDIN_SUCCESS:
      return {
        ...state,
        paidInData: action.payload,
        error: null,
      };
    case GET_PAIDIN_ERROR:
      return {
        ...state,
        paidInData: null,
        error: action.payload,
      };
    case EDIT_PAIDIN:
      return { ...state, loading1: true, error: null };
    case EDIT_PAIDIN_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case EDIT_PAIDIN_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DELETE_PAIDIN:
      return { ...state, loading2: true, error: null };
    case DELETE_PAIDIN_SUCCESS:
      return {
        ...state,
        loading2: false,
        success1: action.payload.success1,
        message: action.payload.message,
        error: null,
      };
    case DELETE_PAIDIN_ERROR:
      return {
        ...state,
        loading2: false,
        success1: false,
        message: null,
        error: action.payload,
      };
    case EXPORT_PAIDIN:
      return { ...state, loading3: true, error: null };
    case EXPORT_PAIDIN_SUCCESS:
      return {
        ...state,
        loading3: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case EXPORT_PAIDIN_ERROR:
      return {
        ...state,
        loading3: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case RESET_PAIDIN:
      return {
        ...state,
        loading: false,
        loading1: false,
        loading2: false,
        loading3: false,
        success: false,
        success1: false,
        message: null,
        error: null,
      };

    default:
      return { ...state };
  }
};
export default paidInReducer;
