export const SET_PAIDOUT = "SET_PAIDOUT";
export const GET_ALL_PAIDOUT = "GET_ALL_PAIDOUT";
export const GET_ALL_PAIDOUT_SUCCESS = "GET_ALL_PAIDOUT_SUCCESS";
export const GET_ALL_PAIDOUT_ERROR = "GET_ALL_PAIDOUT_ERROR";
export const ADD_PAIDOUT = "ADD_PAIDOUT";
export const ADD_PAIDOUT_SUCCESS = "ADD_PAIDOUT_SUCCESS";
export const ADD_PAIDOUT_ERROR = "ADD_PAIDOUT_ERROR";
export const GET_PAIDOUT = "GET_PAIDOUT";
export const GET_PAIDOUT_SUCCESS = "GET_PAIDOUT_SUCCESS";
export const GET_PAIDOUT_ERROR = "GET_PAIDOUT_ERROR";
export const EDIT_PAIDOUT = "EDIT_PAIDOUT";
export const EDIT_PAIDOUT_SUCCESS = "EDIT_PAIDOUT_SUCCESS";
export const EDIT_PAIDOUT_ERROR = "EDIT_PAIDOUT_ERROR";
export const DELETE_PAIDOUT = "DELETE_PAIDOUT";
export const DELETE_PAIDOUT_SUCCESS = "DELETE_PAIDOUT_SUCCESS";
export const DELETE_PAIDOUT_ERROR = "DELETE_PAIDOUT_ERROR";
export const EXPORT_PAIDOUT = "EXPORT_PAIDOUT";
export const EXPORT_PAIDOUT_SUCCESS = "EXPORT_PAIDOUT_SUCCESS";
export const EXPORT_PAIDOUT_ERROR = "EXPORT_PAIDOUT_ERROR";
export const RESET_PAIDOUT = "RESET_PAIDOUT";
export const GET_ALL_PAIDOUTINVESTOR = "GET_ALL_PAIDOUTINVESTOR";
export const GET_ALL_PAIDOUTINVESTOR_SUCCESS =
  "GET_ALL_PAIDOUTINVESTOR_SUCCESS";
export const GET_ALL_PAIDOUTINVESTOR_FAIL = "GET_ALL_PAIDOUTINVESTOR_FAIL";

export const setPaidOut = (rowIndex, columnId, value) => ({
  type: SET_PAIDOUT,
  payload: { rowIndex, columnId, value },
});

export const getAllPaidOut = (param) => ({
  type: GET_ALL_PAIDOUT,
  payload: { param },
});

export const getAllPaidOutSuccess = (
  success,
  paidOutList,
  totalPaidOutAmount
) => ({
  type: GET_ALL_PAIDOUT_SUCCESS,
  payload: { success, paidOutList, totalPaidOutAmount },
});

export const getAllPaidOutError = (error) => ({
  type: GET_ALL_PAIDOUT_ERROR,
  payload: error,
});

export const addPaidOut = (paidOutData, projectId, eachPropertyFund) => ({
  type: ADD_PAIDOUT,
  payload: { paidOutData, projectId, eachPropertyFund },
});

export const addPaidOutSuccess = (success, message) => ({
  type: ADD_PAIDOUT_SUCCESS,
  payload: { success, message },
});

export const addPaidOutError = (error) => ({
  type: ADD_PAIDOUT_ERROR,
  payload: error,
});

export const getPaidOut = (paidOutId) => ({
  type: GET_PAIDOUT,
  payload: { paidOutId },
});

export const getPaidOutSuccess = (paidOutData) => ({
  type: GET_PAIDOUT_SUCCESS,
  payload: paidOutData,
});

export const getPaidOutError = (error) => ({
  type: GET_PAIDOUT_ERROR,
  payload: error,
});

export const editPaidOut = (
  paidOutId,
  paidOutData,
  projectId,
  eachPropertyFund
) => ({
  type: EDIT_PAIDOUT,
  payload: { paidOutId, paidOutData, projectId, eachPropertyFund },
});

export const editPaidOutSuccess = (success, message) => ({
  type: EDIT_PAIDOUT_SUCCESS,
  payload: { success, message },
});

export const editPaidOutError = (error) => ({
  type: EDIT_PAIDOUT_ERROR,
  payload: error,
});

export const deletePaidOut = (paidOutId, projectId, eachPropertyFund) => ({
  type: DELETE_PAIDOUT,
  payload: { paidOutId, projectId, eachPropertyFund },
});

export const deletePaidOutSuccess = (success1, message) => ({
  type: DELETE_PAIDOUT_SUCCESS,
  payload: { success1, message },
});

export const deletePaidOutError = (error) => ({
  type: DELETE_PAIDOUT_ERROR,
  payload: error,
});

export const exportPaidOut = (projectId, eachPropertyFund) => ({
  type: EXPORT_PAIDOUT,
  payload: { projectId, eachPropertyFund },
});

export const exportPaidOutSuccess = (success, message) => ({
  type: EXPORT_PAIDOUT_SUCCESS,
  payload: { success, message },
});

export const exportPaidOutError = (error) => ({
  type: EXPORT_PAIDOUT_ERROR,
  payload: error,
});

export const resetPaidOut = () => ({
  type: RESET_PAIDOUT,
  payload: {},
});
export const getpaidOutInvestorList = (projectId) => ({
  type: GET_ALL_PAIDOUTINVESTOR,
  payload: { projectId },
});
export const paidOutInvestorListSuccess = (success, data) => ({
  type: GET_ALL_PAIDOUTINVESTOR_SUCCESS,
  payload: { success, data },
});
export const paidOutInvestorListFail = (error) => ({
  type: GET_ALL_PAIDOUTINVESTOR_FAIL,
  payload: { error },
});
