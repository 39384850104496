import React, { useEffect } from "react";
import reactDom from "react-dom";
import { hideScroll } from "src/helpers/util";

const BackDrop = ({ classes, ...props }) => {
  useEffect(() => {
    hideScroll(true);

    return () => {
      // remove body class element while unmount
      hideScroll(false);
    };
  });

  return reactDom.createPortal(
    <div className={`backdrop ${classes || ""}`} {...props}></div>,
    document.getElementById("root")
  );
};

export default BackDrop;
