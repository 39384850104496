// import PrimaryLogo from "../../assets/images/logo.svg";
import PrimaryLogo from "../../assets/images/Malling-logo-hv.svg";
import SecondaryLogo from "../../assets/images/logo-small.svg";
// import PrimaryLogoLight from "../../assets/images/logo-light.svg";
// import SecondaryLogoLight from "../../assets/images/logo-small-light.svg";

export const toolBarConfig = {
	primaryLogo: PrimaryLogo,
	secondaryLogo: SecondaryLogo,
	style: "sticky", //option: static, sticky, fixed
};

export const sideNavConfig = {
	primaryLogo: PrimaryLogo,
	secondaryLogo: SecondaryLogo,
	hoverSubMenu: false,
	collapseState: {
		xl: true,
		xxl: false,
	}, //sidemenu default state in medium devices and large devices
	expandOnHover: true, //Expand side nav on hover if side nav is collapsed & hoversubmenu is false
	navigateOnParentClick: false, // Enable this if you want to navigate to first child on parent click
};

export const Breakpoints = {
	sm: 640,
	md: 768,
	lg: 1024,
	xl: 1280,
	xxl: 1536,
};
