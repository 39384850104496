import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { connect } from "react-redux";
import { IconUserO, IconLogout } from "src/components/svg";
import { getUnreadInboxCount } from "src/reduxs/actions";
import { useTranslation } from "react-i18next";

const listItemsStyle =
  "group flex rounded-md items-center w-full px-3 py-2 text-sm text-gray-900 hover:bg-primary-500 hover:text-white focus:outline-none svg-color-grey hover:svg-color-white";

const UserInfoDropdown = (props) => {
  const { t } = useTranslation();
  useEffect(() => {}, [props?.currentUserProfileData]);

  return (
    <>
      <Menu as="div" className="relative inline-block">
        <Menu.Button className="inline-flex items-center justify-center w-full py-2 rounded-xs focus:outline-none">
          <span className="hidden xl:block text-topNav text-sm">
            {props.currentUserProfileData?.firstName}{" "}
            {props.currentUserProfileData?.lastName}
            <small className="status-bg !flex !h-auto !py-0.5 !px-1 rounded-md !text-xs success">
              {props.inboxMessageCount > 0 ? props.inboxMessageCount : 0}{" "}
              {t("MESSAGE.FORM.MESSAGE")}
            </small>
          </span>

          {props.loading ? (
            <span className="w-8 h-8 sm:ml-4 rounded-full bg-gray-200 animate-pulse"></span>
          ) : (
            <>
              {Array.isArray(props.currentUserProfileData?.photo) &&
              props.currentUserProfileData?.photo.length ? (
                <img
                  src={props.currentUserProfileData?.photo[0]?.url}
                  alt=""
                  className="w-8 h-8 sm:ml-4 bg-gray-100 rounded-full object-cover"
                />
              ) : (
                <span className="w-8 h-8 sm:ml-4 flex justify-center items-center rounded-full !bg-[#CBC1AB] overflow-hidden">
                  <img
                    src={
                      require("../../../assets/images/logo-small.svg").default
                    }
                    alt=""
                    className="w-4 h-4"
                  />
                </span>
              )}
            </>
          )}
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            as="ul"
            className="absolute right-0 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg min-w-[11rem] ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <Menu.Item
              as="li"
              className={`group p-1 flex rounded-md items-center w-full text-sm`}
            >
              {({ close }) => (
                <Link className={listItemsStyle} to={"/profile-message"} onClick={close}>
                  <span className="mr-2 svg-size-xs">
                    <IconUserO />
                  </span>
                  Profile
                </Link>
              )}
            </Menu.Item>

            <Menu.Item
              as="li"
              className={`group p-1 flex rounded-md items-center w-full text-sm focus:outline-none`}
            >
              <Link to={"/logout"} className={listItemsStyle}>
                <span className="mr-2 svg-size-xs">
                  <IconLogout />
                </span>
                Logout
              </Link>
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

const mapStateToProps = ({ shared, auth, message }) => {
  const { user } = auth;
  const { currentUserProfileData } = shared;
  const { inboxMessageCount } = message;
  return {
    user,
    currentUserProfileData,
    inboxMessageCount,
  };
};
export default connect(mapStateToProps, { getUnreadInboxCount })(
  UserInfoDropdown
);
