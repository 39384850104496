import React from "react";

const IconUserO = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 14 14"
    >
      <g id="Group_2077" dataname="Group 2077" transform="translate(-3 -2.5)">
        <g id="Group_2078" dataname="Group 2078" transform="translate(3.5 3)">
          <circle
            id="Ellipse_295"
            dataname="Ellipse 295"
            cx="2.321"
            cy="2.321"
            r="2.321"
            transform="translate(3.987 2.066)"
            fill="rgba(255,255,255,0)"
            stroke="#000"
            strokeLinecap="round"
            strokeWidth="1"
          />
          <circle
            id="Ellipse_296"
            dataname="Ellipse 296"
            cx="6.5"
            cy="6.5"
            r="6.5"
            fill="rgba(255,255,255,0)"
            stroke="#000"
            strokeWidth="1"
          />
          <path
            id="Path_9291"
            dataname="Path 9291"
            d="M14.9,17.75a3.777,3.777,0,0,0-1.646-1.983,5.518,5.518,0,0,0-5.611,0A3.777,3.777,0,0,0,6,17.75"
            transform="translate(-4.143 -6.816)"
            fill="rgba(255,255,255,0)"
            stroke="#000"
            strokeLinecap="round"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  );
};

export default IconUserO;
