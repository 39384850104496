import React, { Component } from 'react'
import { connect } from "react-redux";
import qs from 'query-string'
import { withTranslation } from "react-i18next";
import { verifyNewUser, login } from "../../../reduxs/actions";
import { LayoutSplashScreen } from "../../../configs/core";
import { toast } from "react-toastify";
import ToastElement from "../../../components/toast";

class BankIdResponse extends Component {
     constructor(props) {
          super(props)
          const { state, access_token, error, error_description} = qs.parse(this.props.location.hash ? this.props.location.hash : this.props.location.search);

          if (state === 'login' ) {
               if(error){
                    error_description && toast.error(
                         <ToastElement type="error" message={error_description} />,
                         { containerId: "default", position: "top-right" }
                              );
                    this.props.history.push('/auth/login')
               }else{
                    const loginData = {
                         type: "investorNorwegian",
                         token: access_token
                    }
                    this.props.login(loginData, this.props.history);
               }
          }else if(state === 'verify'){
               if(error){
                    error_description && toast.error(
                         <ToastElement type="error" message={error_description} />,
                         { containerId: "default", position: "top-right" }
                    );
                    this.props.history.push('/auth/login')
               }else{
                    const data = {
                         token: access_token
                    }
                    this.props.verifyNewUser(data, this.props.history);
               }
               

          }
     }

     render() {
          return <LayoutSplashScreen />;
     }
}

const mapStateToProps = () => {
     return {};
};

export default connect(mapStateToProps, {
     verifyNewUser, login
})(withTranslation()(BankIdResponse));