import MaskedInput from "react-text-mask";
import { useField } from "formik";

const MaskInputField = ({
  label,
  classes,
  icon,
  iconPosition,
  mask,
  placeholder,
  isRequired,
  ...props
}) => {
  const [field, meta] = useField(props);
  return (
    <div
      className={`field-group ${
        meta.touched && meta.error ? "field-group-error" : ""
      } ${classes?.root || ""}`}
    >
      {label && (
        <label htmlFor="" className="field-label">
          {label ? label : null}
          {isRequired && <strong className="text-red-700">*</strong>}
        </label>
      )}

      <div
        className={`field-wrap 
       ${iconPosition === "end" ? "icon-end" : ""} 
       ${iconPosition === "start" ? "icon-start" : ""} `}
      >
        <MaskedInput
          className={`field ${classes?.input || ""}`}
          placeholder={placeholder}
          mask={mask}
          guide={true}
          {...field} // Show/hide dashes
        />
        {icon ? (
          <span
            className={`field-icon focus:outline-none ${
              iconPosition === "end" ? "right-5" : "left-5"
            }`}
          >
            {icon}
          </span>
        ) : null}
      </div>

      {props.fieldArray && meta.error ? (
        <span
          className={`block mt-2 text-xs text-right text-red-700 ${
            classes?.error || ""
          }`}
        >
          {meta.error}
        </span>
      ) : meta.touched && meta.error ? (
        <span
          className={`block mt-2 text-xs text-right text-red-700 ${
            classes?.error || ""
          }`}
        >
          {meta.error}
        </span>
      ) : null}
    </div>
  );
};
export default MaskInputField;
