import React from "react";
import { IconExpandedMenu } from "../../components/svg";

const SideNavTriggerMobile = (props) => {
  return (
    <button
      className="mr-3 mobile-menu-toggler svg-color-toolbar-icon"
      onClick={() => props.toggleMobileSideNav()}
      size="small"
    >
      <IconExpandedMenu />
    </button>
  );
};

export default SideNavTriggerMobile;
