import Req from "../interceptors/token-interceptor";
import qs from 'query-string'
import { cleanObj } from 'src/helpers/util'
const ExportDataService = (function () {
     function _getAllData(endpoint, params) {
          const sentParams = { ...cleanObj(params) };
          return Req.get(
               `/api/${endpoint}?${qs.stringify(sentParams, { arrayFormat: 'bracket' })}`, {
               responseType: "arraybuffer",
          });
     }
     return { getAllData: _getAllData };
})();
export default ExportDataService;
