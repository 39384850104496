import {
  GET_CURRENT_USER,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_ERROR,
  GET_PERMISSION,
  GET_PERMISSION_SUCCESS,
  GET_PERMISSION_ERROR,
  GET_COUNTRY_LIST,
  GET_COUNTRY_LIST_SUCCESS,
  GET_COUNTRY_LIST_ERROR,
  GET_CONFIG_CHOICE,
  GET_CONFIG_CHOICE_SUCCESS,
  GET_CONFIG_CHOICE_ERROR,
  GET_MULTIPLE_CONFIG_CHOICE,
  GET_MULTIPLE_CONFIG_CHOICE_SUCCESS,
  GET_MULTIPLE_CONFIG_CHOICE_ERROR,
  GET_INVESTOR_BY_PROJECT,
  GET_INVESTOR_BY_PROJECT_SUCCESS,
  GET_INVESTOR_BY_PROJECT_ERROR,
  GET_CURRENT_USER_PROFILE,
  GET_CURRENT_USER_PROFILE_SUCCESS,
  GET_CURRENT_USER_PROFILE_FAIL,
  SET_GLOBAL_FILTER,
  CLEAR_GLOBAL_FILTER,
  RESET_PROJECT_INVESTOR,
  SET_TOAST_STATUS,
} from "../actions";

const INIT_STATE = {
  currentUserData: null,
  permission: null,
  countryList: "",
  category: null,
  categories: null,
  choiceList: null,
  multipleChoiceList: null,
  success: false,
  message: null,
  loading: false,
  error: null,
  pInvestors: null,
  noOfFilter: 0,
  loading1: false,
  isToastActive: false,
};

const setFilterValue = (state, payload) => {
  let keys = payload.module;
  let value = payload.value;
  let map =
    state?.length > 0
      ? new Map(state.map((i) => [i.module, i.value]))
      : new Map();
  map.set(keys, value);
  return Array.from(map, ([module, value]) => ({ module, value }));
};

const sharedReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CURRENT_USER:
      return { ...state, error: null };
    case GET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        currentUserData: action.payload,
        error: null,
      };
    case GET_CURRENT_USER_ERROR:
      return {
        ...state,
        currentUserData: null,
        error: action.payload,
      };
    case GET_PERMISSION:
      return { ...state, loading: true, error: null };
    case GET_PERMISSION_SUCCESS:
      return {
        ...state,
        permission: action.payload,
        loading: false,
        error: null,
      };
    case GET_PERMISSION_ERROR:
      return {
        ...state,
        permission: null,
        error: action.payload,
      };
    case GET_COUNTRY_LIST:
      return { ...state, error: null };
    case GET_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        countryList: action.payload,
        error: null,
      };
    case GET_COUNTRY_LIST_ERROR:
      return {
        ...state,
        countryList: null,
        error: action.payload,
      };
    case GET_CONFIG_CHOICE:
      return {
        ...state,
        error: null,
      };
    case GET_CONFIG_CHOICE_SUCCESS:
      return {
        ...state,
        choiceList: action.payload,
        error: null,
      };
    case GET_CONFIG_CHOICE_ERROR:
      return {
        ...state,
        choiceList: null,
        error: action.payload,
      };
    case GET_MULTIPLE_CONFIG_CHOICE:
      return {
        ...state,
        error: null,
      };
    case GET_MULTIPLE_CONFIG_CHOICE_SUCCESS:
      return {
        ...state,
        multipleChoiceList: action.payload,
        error: null,
      };
    case GET_MULTIPLE_CONFIG_CHOICE_ERROR:
      return {
        ...state,
        multipleChoiceList: null,
        error: action.payload,
      };
    case GET_INVESTOR_BY_PROJECT:
      return {
        ...state,
        loading1: true,
        error: null,
      };
    case GET_INVESTOR_BY_PROJECT_SUCCESS:
      return {
        ...state,
        loading1: false,
        pInvestors: action.payload,
        error: null,
      };
    case GET_INVESTOR_BY_PROJECT_ERROR:
      return {
        ...state,
        loading1: false,
        pInvestors: null,
        error: action.payload,
      };

    case GET_CURRENT_USER_PROFILE:
      return { ...state, error: null };

    case GET_CURRENT_USER_PROFILE_SUCCESS:
      return {
        ...state,
        currentUserProfileData: action.payload,
        error: null,
      };
    case GET_CURRENT_USER_PROFILE_FAIL:
      return {
        ...state,
        currentUserProfileData: null,
        error: action.payload,
      };
    case SET_GLOBAL_FILTER: {
      return {
        ...state,
        noOfFilter: setFilterValue(state.noOfFilter, action.payload),
      };
    }

    case CLEAR_GLOBAL_FILTER: {
      return {
        ...state,
        noOfFilter: 0,
      };
    }
    case RESET_PROJECT_INVESTOR: {
      return {
        ...state,
        pInvestors: null,
        loading1: false,
      };
    }

    case SET_TOAST_STATUS: {
      return {
        ...state,
        isToastActive: action.payload,
      };
    }

    default:
      return { ...state };
  }
};
export default sharedReducer;
