export const SET_PAIDINOUT = "SET_PAIDINOUT";
export const GET_ALL_PAIDINOUT = "GET_ALL_PAIDINOUT";
export const GET_ALL_PAIDINOUT_SUCCESS = "GET_ALL_PAIDINOUT_SUCCESS";
export const GET_ALL_PAIDINOUT_ERROR = "GET_ALL_PAIDINOUT_ERROR";
export const GET_CAPITAL_CALL_CREDIT = "GET_CAPITAL_CREDIT";
export const GET_CAPITAL_CALL_CREDIT_SUCCESS =
  "GET_CAPITAL_CALL_CREDIT_SUCCESS";
export const GET_CAPITAL_CALL_CREDIT_ERROR = "GET_CAPITAL_CALL_CREIDT_ERROR";
export const GET_CAPITAL_CALL_DEBIT = "GET_CAPITAL_CALL_DEBIT";
export const GET_CAPITAL_CALL_DEBIT_SUCCESS = "GET_CAPIAL_CALL_DEBIT_SUCCESS";
export const GET_CAPITAL_CALL_DEBIT_ERROR = "GET_CAPITAL_CALL_DEBIT_ERROR";
export const ADD_PAIDINOUT = "ADD_PAIDINOUT";
export const ADD_PAIDINOUT_SUCCESS = "ADD_PAIDINOUT_SUCCESS";
export const ADD_PAIDINOUT_ERROR = "ADD_PAIDINOUT_ERROR";
export const GET_PAIDINOUT = "GET_PAIDINOUT";
export const GET_PAIDINOUT_SUCCESS = "GET_PAIDINOUT_SUCCESS";
export const GET_PAIDINOUT_ERROR = "GET_PAIDINOUT_ERROR";
export const EDIT_PAIDINOUT = "EDIT_PAIDINOUT";
export const EDIT_PAIDINOUT_SUCCESS = "EDIT_PAIDINOUT_SUCCESS";
export const EDIT_PAIDINOUT_ERROR = "EDIT_PAIDINOUT_ERROR";
export const DELETE_PAIDINOUT = "DELETE_PAIDINOUT";
export const DELETE_PAIDINOUT_SUCCESS = "DELETE_PAIDINOUT_SUCCESS";
export const DELETE_PAIDINOUT_ERROR = "DELETE_PAIDINOUT_ERROR";
export const EXPORT_PAIDINOUT = "EXPORT_PAIDINOUT";
export const EXPORT_PAIDINOUT_SUCCESS = "EXPORT_PAIDINOUT_SUCCESS";
export const EXPORT_PAIDINOUT_ERROR = "EXPORT_PAIDINOUT_ERROR";
export const RESET_PAIDINOUT = "RESET_PAIDINOUT";
export const SET_CHECKED = "SET_CHECKED";
export const SET_CHECKED_SUCCESS = "SET_CHECKED_SUCCESS";
export const SET_CHECKED_ERROR = "SET_CHECKED_ERROR";
export const SET_EACH_PROPERTYFUND = "SET_EACH_PROPERTYFUND";
export const setChecked = (param) => ({
  type: SET_CHECKED,
  payload: { param },
});

export const setIsCheckedSuccess = (success) => ({
  type: SET_CHECKED_SUCCESS,
  payload: { success },
});
export const setIsCheckedError = (error) => ({
  type: SET_CHECKED_ERROR,
  payload: { error },
});
export const setPaidInOut = (rowIndex, columnId, value) => ({
  type: SET_PAIDINOUT,
  payload: { rowIndex, columnId, value },
});
export const setEachPropertyFund = (eachPropertyFund) => ({
  type: SET_EACH_PROPERTYFUND,
  payload: eachPropertyFund,
});

export const getAllPaidInOut = (param) => ({
  type: GET_ALL_PAIDINOUT,
  payload: { param },
});

export const getAllPaidInOutSuccess = (success, paidInOutList) => ({
  type: GET_ALL_PAIDINOUT_SUCCESS,
  payload: { success, paidInOutList },
});

export const getAllPaidInOutError = (error) => ({
  type: GET_ALL_PAIDINOUT_ERROR,
  payload: error,
});

export const getCapitalCallCredit = (capitalParam) => ({
  type: GET_CAPITAL_CALL_CREDIT,
  payload: { capitalParam },
});

export const getCapitalCallCreditSuccess = (capitalCall) => ({
  type: GET_CAPITAL_CALL_CREDIT_SUCCESS,
  payload: capitalCall,
});

export const getCapitalCallCreditError = (error) => ({
  type: GET_CAPITAL_CALL_CREDIT_ERROR,
  payload: error,
});
export const getCapitalCallDebit = (capitalParam) => ({
  type: GET_CAPITAL_CALL_DEBIT,
  payload: { capitalParam },
});

export const getCapitalCallDebitSuccess = (capitalCall) => ({
  type: GET_CAPITAL_CALL_DEBIT_SUCCESS,
  payload: capitalCall,
});

export const getCapitalCallDebitError = (error) => ({
  type: GET_CAPITAL_CALL_DEBIT_ERROR,
  payload: error,
});

export const addPaidInOut = (paidInOutData, projectId, eachPropertyFund) => ({
  type: ADD_PAIDINOUT,
  payload: { paidInOutData, projectId, eachPropertyFund },
});

export const addPaidInOutSuccess = (success, message) => ({
  type: ADD_PAIDINOUT_SUCCESS,
  payload: { success, message },
});

export const addPaidInOutError = (error) => ({
  type: ADD_PAIDINOUT_ERROR,
  payload: error,
});

export const getPaidInOut = (paidInOutId) => ({
  type: GET_PAIDINOUT,
  payload: { paidInOutId },
});

export const getPaidInOutSuccess = (paidInOutData) => ({
  type: GET_PAIDINOUT_SUCCESS,
  payload: paidInOutData,
});

export const getPaidInOutError = (error) => ({
  type: GET_PAIDINOUT_ERROR,
  payload: error,
});

export const editPaidInOut = (
  paidInOutId,
  paidInOutData,
  projectId,
  eachPropertyFund
) => ({
  type: EDIT_PAIDINOUT,
  payload: { paidInOutId, paidInOutData, projectId, eachPropertyFund },
});

export const editPaidInOutSuccess = (success, message) => ({
  type: EDIT_PAIDINOUT_SUCCESS,
  payload: { success, message },
});

export const editPaidInOutError = (error) => ({
  type: EDIT_PAIDINOUT_ERROR,
  payload: error,
});

export const deletePaidInOut = (paidInOutId, projectId, eachPropertyFund) => ({
  type: DELETE_PAIDINOUT,
  payload: { paidInOutId, projectId, eachPropertyFund },
});

export const deletePaidInOutSuccess = (success1, message) => ({
  type: DELETE_PAIDINOUT_SUCCESS,
  payload: { success1, message },
});

export const deletePaidInOutError = (error) => ({
  type: DELETE_PAIDINOUT_ERROR,
  payload: error,
});

export const exportPaidInOut = (projectId, eachPropertyFund) => ({
  type: EXPORT_PAIDINOUT,
  payload: { projectId, eachPropertyFund },
});

export const exportPaidInOutSuccess = (success, message) => ({
  type: EXPORT_PAIDINOUT_SUCCESS,
  payload: { success, message },
});

export const exportPaidInOutError = (error) => ({
  type: EXPORT_PAIDINOUT_ERROR,
  payload: error,
});

export const resetPaidInOut = () => ({
  type: RESET_PAIDINOUT,
  payload: {},
});
