export const defaultCurrency = {
  name: "Norwegian Krone",
  code: "NOK",
  symbol: "NOK",
  symbolPosition: "after",
};

export const defaultCountry = {
  id: 160,
  name: "Norway",
  code: "NO",
  isdCode: "+47",
};

export const validImageExtensions = ['png', 'jpg', 'jpeg', 'svg']

export const maxFileSize = 30
