import React, { useEffect, useMemo } from "react";
import Brand from "../brand";
import { Breakpoints, toolBarConfig } from "../LayoutConfig";
import SideNavTriggerMobile from "../side-nav-trigger-mobile";
import LanguageDropdown from "./language-dropdown";
import NotificationDropdown from "./notification-dropdown";
import InvestorDropdown from "src/layouts/components/topnav/investor-dropdown";
import { isAdminOrEmployee } from "src/helpers/util";
// import ToolbarSearch from "./toolbar-search";
import RoleDropdown from "./role-dropdown";
import UserInfoDropdown from "./user-info-dropdown";
import { withResizeDetector } from "react-resize-detector";
import { connect } from "react-redux";
import {
  getRecentNotification,
  resetNotification,
  clearSelectedInvestor,
} from "src/reduxs/actions";

const Topnav = (props) => {
  const { toggleMobileSideNav, drawerCollapse, notification } = props;
  useEffect(() => {
    if (props.currentRole?.id) {
      props.getRecentNotification(props.currentRole?.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification?.readSuccess, props.currentRole?.id]);

  useEffect(() => {
    if (notification?.readSuccess) {
      props.resetNotification();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification?.readSuccess]);

  const recentNotifications = useMemo(() => {
    return notification?.recentNotifications;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification?.recentNotifications]);

  return (
    <header className={`cms-header ${toolBarConfig.style}`}>
      <div className="container flex items-center max-w-full mx-auto px-3 xl:px-10">
        {toolBarConfig.style === "fixed" ? (
          <Brand logo={toolBarConfig.primaryLogo} />
        ) : null}

        {window.innerWidth < Breakpoints.xl && (
          <SideNavTriggerMobile
            toggleMobileSideNav={toggleMobileSideNav}
            drawerCollapse={drawerCollapse}
          />
        )}

        {/* <ToolbarSearch /> */}

        <div className="flex items-center ml-auto space-x-3 sm:space-x-7">
          <LanguageDropdown />

          <NotificationDropdown notifications={recentNotifications} />

          <RoleDropdown />
          {!isAdminOrEmployee(props.currentRole) && <InvestorDropdown />}

          <UserInfoDropdown />
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = (state) => ({
  notification: state.notification,
  currentRole: state.auth.currentRole,
  currentUserData: state.shared.currentUserData,
});

export default connect(mapStateToProps, {
  getRecentNotification,
  resetNotification,
  clearSelectedInvestor,
})(withResizeDetector(Topnav));
