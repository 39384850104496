import React from 'react'
import { Switch } from '@headlessui/react'

const SideNavTriggerCollapse = (props) => {
  return (
    <div
      className={`flex pt-3 pb-6 px-4 ${
        props.drawerCollapse ? 'justify-center' : 'justify-end'
      }`}
    >
      <Switch
        checked={props.switchState}
        onChange={() => props.toggleSideNav()}
        className={`${
          props.switchState ? 'bg-subMenuActive' : 'bg-primary-400'
        } w-8 h-5 relative inline-flex items-center border border-solid border-primary-500 rounded-full focus:outline-none`}
      >
        <span
          className={`${
            props.switchState ? 'translate-x-4' : 'translate-x-1'
          } inline-block w-3 h-3 transform bg-white rounded-full`}
        />
      </Switch>
    </div>
  )
}

export default SideNavTriggerCollapse
