import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import RoleService from "../../services/role-service";
import TableDataService from "../../services/table-data-service1";
import {
  GET_ALL_ROLE,
  GET_MODULE_LIST,
  GET_ROLE_LIST,
  ADD_ROLE,
  GET_ROLE,
  EDIT_ROLE,
  DELETE_ROLE,
  DELETE_MULTIPLE_ROLE,
  GET_PERMISSION_ROLETYPE,
} from "../actions";
import {
  getAllRoleSuccess,
  getAllRoleError,
  getModuleListSuccess,
  getModuleListError,
  getRoleList,
  getRoleListSuccess,
  getRoleListError,
  addRoleSuccess,
  addRoleError,
  getRoleSuccess,
  getRoleError,
  editRoleSuccess,
  editRoleError,
  deleteRoleSuccess,
  deleteRoleError,
  deleteMultipleRoleSuccess,
  deleteMultipleRoleError,
  getPermissionRoleTypeError,
  getPermissionRoleTypeSuccess,
} from "./action";
import { toast } from "react-toastify";
import { handleMyErrorMessage } from "../../helpers/util";
import ToastElement from "../../components/toast";
const getMetaData = (state) => state.role.metaData;
export function* watchGetAllRole() {
  yield takeEvery(GET_ALL_ROLE, getAllRole);
}

const getAllRoleAsync = async () => {
  return RoleService.getAllRole();
};

function* getAllRole() {
  try {
    const response = yield call(getAllRoleAsync);
    if (response.data.success) {
      yield put(getAllRoleSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getAllRoleError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getAllRoleError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      toast.error(
        <ToastElement type='error' message={errorMessage} />,
        { containerId: 'default' }
      )
      yield put(getAllRoleError(errorMessage))
    }
  }
}

export function* watchGetModuleList() {
  yield takeEvery(GET_MODULE_LIST, getModuleList);
}

const getModuleListAsync = async () => {
  return RoleService.getModuleList();
};

function* getModuleList() {
  try {
    const response = yield call(getModuleListAsync);
    if (response.data.success) {
      yield put(getModuleListSuccess(response.data.data));
    } else {
      yield put(getModuleListError(response.data.message));
    }
  } catch (error) {
    // yield put(getModuleListError(error.response.data.message));
    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      yield put(getModuleListError(errorMessage))
    }
  }
}

export function* watchGetRoleList() {
  yield takeEvery(GET_ROLE_LIST, getRoleListAc);
}

const getRoleListAsync = async (dbParam) => {
  return TableDataService.getAllData("roles", dbParam);
};

function* getRoleListAc({ payload }) {
  try {
    const response = yield call(getRoleListAsync, payload.dbParam);
    if (response.data.success) {
      yield put(getRoleListSuccess(response.data, response.data.metadata));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getRoleListError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getRoleListError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      toast.error(
        <ToastElement type='error' message={errorMessage} />,
        { containerId: 'default' }
      )
      yield put(getRoleListError(errorMessage))
    }
  }
}

export function* watchAddRole() {
  yield takeEvery(ADD_ROLE, addRole);
}

const addRoleAsync = async (data) => {
  return RoleService.addRole(data);
};

function* addRole({ payload }) {
  try {
    const response = yield call(addRoleAsync, payload.roleData);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(addRoleSuccess(response.data.success, response.data.message));
      yield put(getRoleList({}));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(addRoleError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type="error"
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   {
    //     containerId: "default",
    //   }
    // );
    // yield put(addRoleError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      toast.error(
        <ToastElement type='error' message={errorMessage} />,
        { containerId: 'default' }
      )
      yield put(addRoleError(errorMessage))
    }
  }
}

export function* watchGetRole() {
  yield takeEvery(GET_ROLE, getRole);
}

const getRoleAsync = async (id) => {
  return RoleService.getRole(id);
};

function* getRole({ payload }) {
  try {
    const response = yield call(getRoleAsync, payload.roleId);
    if (response.data.success) {
      yield put(getRoleSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getRoleError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getRoleError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      toast.error(
        <ToastElement type='error' message={errorMessage} />,
        { containerId: 'default' }
      )
      yield put(getRoleError(errorMessage))
    }
  }
}

export function* watchEditRole() {
  yield takeEvery(EDIT_ROLE, editRole);
}

const editRoleAsync = async (data, id) => {
  return RoleService.editRole(data, id);
};

function* editRole({ payload }) {
  const metadata = yield select(getMetaData);
  try {
    const response = yield call(
      editRoleAsync,
      payload.roleData,
      payload.roleId
    );
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(editRoleSuccess(response.data.success, response.data.message));
      yield put(getRoleList({ page: metadata?.page || 1 }));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(editRoleError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type="error"
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   { containerId: "default" }
    // );
    // yield put(editRoleError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      toast.error(
        <ToastElement type='error' message={errorMessage} />,
        { containerId: 'default' }
      )
      yield put(editRoleError(errorMessage))
    }
  }
}

export function* watchDeleteRole() {
  yield takeEvery(DELETE_ROLE, deleteRole);
}

const deleteRoleAsync = async (id) => {
  return RoleService.deleteRole(id);
};

function* deleteRole({ payload }) {
  const metadata = yield select(getMetaData);
  try {
    const response = yield call(deleteRoleAsync, payload.roleId);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        deleteRoleSuccess(response.data.success, response.data.message)
      );
      // Fetch updated role list
      yield put(getRoleList({ pageSize: 5, page: metadata?.page || 1 }));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(deleteRoleError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(deleteRoleError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      toast.error(
        <ToastElement type='error' message={errorMessage} />,
        { containerId: 'default' }
      )
      yield put(deleteRoleError(errorMessage))
    }
  }
}

export function* watchDeleteMultipleRole() {
  yield takeEvery(DELETE_MULTIPLE_ROLE, deleteMultipleRole);
}

const deleteMultipleRoleAsync = async (ids) => {
  return RoleService.deleteMultipleRole(ids);
};

function* deleteMultipleRole({ payload }) {
  const metadata = yield select(getMetaData);
  try {
    const response = yield call(deleteMultipleRoleAsync, payload.roleIds);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        deleteMultipleRoleSuccess(response.data.success, response.data.message)
      );
      // Fetch updated role list
      yield put(getRoleList({ page: metadata?.page }));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(deleteMultipleRoleError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(deleteMultipleRoleError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      toast.error(
        <ToastElement type='error' message={errorMessage} />,
        { containerId: 'default' }
      )
      yield put(deleteMultipleRoleError(errorMessage))
    }
  }
}

export function* watchModuleListById() {
  yield takeEvery(GET_PERMISSION_ROLETYPE, getModuleListById);
}

const getModuleListByIdAsync = async (id) => {
  return RoleService.getModuleById(id);
};

function* getModuleListById({ payload }) {
  try {
    const response = yield call(getModuleListByIdAsync, payload);
    if (response.data.success) {
      yield put(getPermissionRoleTypeSuccess(response.data.data));
    } else {
      yield put(getPermissionRoleTypeError(response.data.message));
    }
  } catch (error) {
    // yield put(getPermissionRoleTypeError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      yield put(getPermissionRoleTypeError(errorMessage))
    }
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAllRole),
    fork(watchGetModuleList),
    fork(watchGetRoleList),
    fork(watchAddRole),
    fork(watchGetRole),
    fork(watchEditRole),
    fork(watchDeleteRole),
    fork(watchDeleteMultipleRole),
    fork(watchModuleListById),
  ]);
}
