import React from "react";

const IconMailDisabled = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 19.383 19.383">
      <g
        id="Group_6010"
        dataname="Group 6010"
        transform="translate(53.354 -345.293)"
      >
        <g id="Group_6009" dataname="Group 6009">
          <rect
            id="Rectangle_6893"
            dataname="Rectangle 6893"
            width="11.287"
            height="8.247"
            rx="1.436"
            transform="translate(-49.306 350.861)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_10759"
            dataname="Path 10759"
            d="M-48.183,352.119"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
        <circle
          id="Ellipse_370"
          dataname="Ellipse 370"
          cx="9.191"
          cy="9.191"
          r="9.191"
          transform="translate(-52.854 345.793)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_180"
          dataname="Line 180"
          x1="14.596"
          y1="9.29"
          transform="translate(-52.056 352.148)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_10760"
          dataname="Path 10760"
          d="M-49.24,351.557"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_181"
          dataname="Line 181"
          x1="2.815"
          y1="1.791"
          transform="translate(-46.798 353.111)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_182"
          dataname="Line 182"
          y1="1.791"
          x2="2.815"
          transform="translate(-43.667 353.111)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export default IconMailDisabled;
