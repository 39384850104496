import Req from "../interceptors/token-interceptor";
import qs from "query-string";
import { cleanObj, pagination_limit } from "src/helpers/util";
const TableDataService = (function () {
  function _getAllData(endpoint, params) {
    if (
      !params.page ||
      !params.pageSize ||
      !params.sortedBy ||
      !params.orderBy
    ) {
      params.page = params.page ? params.page : 1;
      params.pageSize = params.pageSize ? params.pageSize : pagination_limit;
      params.sortedBy = params.sortedBy ? params.sortedBy : "desc";
      params.orderBy = params.orderBy ? params.orderBy : "id";
    }
    const sentParams = { ...cleanObj(params) };
    return Req.get(
      `/api/${endpoint}?${qs.stringify(sentParams, { arrayFormat: "bracket" })}`
    );
  }
  return { getAllData: _getAllData };
})();
export default TableDataService;
