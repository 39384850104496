import React, { useEffect, useState } from "react";
import qs from "query-string";
import { LayoutSplashScreen } from "src/configs/core";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { getDocumentFiles, markDocumentSeen } from "src/reduxs/actions";
import DocumentViewer from "src/components/doc-viewer";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { IconChevronRight } from "src/components/svg";
import Folder from "src/components/folder";
import moment from "moment";
import { useTranslation } from "react-i18next";
const LoadDocuments = (props) => {
  const [document, setDocument] = useState(null);
  const [docLoading, setDocLoading] = useState(true);
  const [active, setActive] = useState(0);
  const search = useLocation().search;
  const params = qs.parse(search);
  const { documentFiles } = props;
  const { t } = useTranslation();
  const thumbSlide = {
    type: "slide",
    rewind: true,
    fixedWidth: 234,
    fixedHeight: 166,
    gap: "1rem",
    pagination: false,
    cover: true,
    arrows: true,
  };
  useEffect(() => {
    initialLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.messageId]);

  const initialLoad = () => {
    if (Object.keys(params).length > 0) {
      props.getDocumentFiles(
        params.messageId,
        params.investorId,
        props.history
      );
      const documentReadAt = moment().format("YYYY-MM-DD H:m");
      props.markDocumentSeen(params.investorId, {
        documentReadAt,
        messageId: params.messageId,
      });
    }
    // else {
    //   history.push("/dashboard");
    // }
  };

  useEffect(() => {
    if (!props.loading) {
      setTimeout(() => {
        setDocument(documentFiles?.[0] || null);
        setDocLoading(false);
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentFiles]);

  const handleDocumentView = (data, index) => {
    setActive(index);
    setDocLoading(true);
    setTimeout(() => {
      setDocument(data);
      setDocLoading(false);
    }, 5000);
  };

  return (
    <>
      {props.loading ? (
        <LayoutSplashScreen />
      ) : (
        <div className="block container mx-auto py-9">
          <h2 className="mb-5 text-grey-700 text-2xl xl:text-3xl">
            {t("DOCUMENT.LIST_OF_ATTACHMENT")}{" "}
            <small className="block text-sm">
              {t("DOCUMENT.CLICK_DOCUMENT_TO_VIEW")}
            </small>
          </h2>

          <Splide
            options={thumbSlide}
            renderControls={() => (
              <div className="splide__arrows flex absolute -top-12 right-2 space-x-3">
                <button
                  type="button"
                  className="splide__arrow splide__arrow--prev static rounded bg-primary-100 opacity-100 translate-y-0 svg-color-primary-dark hover:bg-primary-600"
                >
                  <IconChevronRight />
                </button>
                <button
                  type="button"
                  className="splide__arrow splide__arrow--next static rounded bg-primary-100 opacity-100 translate-y-0 svg-color-primary-dark hover:bg-primary-600"
                >
                  <IconChevronRight />
                </button>
              </div>
            )}
            className="mb-8"
          >
            {documentFiles?.map((item, index) => {
              return (
                <SplideSlide key={index} className="p-1">
                  <Folder
                    classes={{
                      root: `cursor-pointer 
                      ${active === index ? "selected" : ""}`,
                    }}
                    onFolderClick={() => handleDocumentView(item, index)}
                  >
                    <h4 className="-ml-3 pl-3 border-l-4 border-solid border-primary-600 text-topNav text-sm">
                      <span className="block mb-2 text-xs text-grey-600">
                        {t("DOCUMENT.DOCUMENT_SECTION")}
                      </span>
                      <span className="text-ellipsis line-3">{item.name}</span>
                    </h4>
                  </Folder>
                </SplideSlide>
              );
            })}
          </Splide>
          <h6 className="text-lg my-5">{t("DOCUMENT.DOCUMENT_PREVIEW")}</h6>

          <DocumentViewer data={document} loading={docLoading} />
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ message }) => {
  const { loading, success, documentFiles } = message;
  return {
    loading,
    success,
    documentFiles,
  };
};

export default connect(mapStateToProps, { getDocumentFiles, markDocumentSeen })(
  LoadDocuments
);
