export const SET_PAIDIN_FUND = "SET_PAIDIN_FUND";
export const GET_ALL_PAIDIN_FUND = "GET_ALL_PAIDIN_FUND";
export const GET_ALL_PAIDIN_FUND_SUCCESS = "GET_ALL_PAIDIN_FUND_SUCCESS";
export const GET_ALL_PAIDIN_FUND_ERROR = "GET_ALL_PAIDIN_FUND_ERROR";
export const ADD_PAIDIN_FUND = "ADD_PAIDIN_FUND";
export const ADD_PAIDIN_FUND_SUCCESS = "ADD_PAIDIN_FUND_SUCCESS";
export const ADD_PAIDIN_FUND_ERROR = "ADD_PAIDIN_FUND_ERROR";
export const GET_PAIDIN = "GET_PAIDIN";
export const GET_PAIDIN_SUCCESS = "GET_PAIDIN_SUCCESS";
export const GET_PAIDIN_ERROR = "GET_PAIDIN_ERROR";
export const EDIT_PAIDIN_FUND = "EDIT_PAIDIN_FUND";
export const EDIT_PAIDIN_FUND_SUCCESS = "EDIT_PAIDIN_FUND_SUCCESS";
export const EDIT_PAIDIN_FUND_ERROR = "EDIT_PAIDIN_FUND_ERROR";
export const DELETE_PAIDIN_FUND = "DELETE_PAIDIN_FUND";
export const DELETE_PAIDIN_FUND_SUCCESS = "DELETE_PAIDIN_FUND_SUCCESS";
export const DELETE_PAIDIN_FUND_ERROR = "DELETE_PAIDIN_FUND_ERROR";
export const EXPORT_PAIDIN_FUND = "EXPORT_PAIDIN_FUND";
export const EXPORT_PAIDIN_FUND_SUCCESS = "EXPORT_PAIDIN_FUND_SUCCESS";
export const EXPORT_PAIDIN_FUND_ERROR = "EXPORT_PAIDIN_FUND_ERROR";
export const RESET_PAIDIN = "RESET_PAIDIN";
export const UPDATE_PAID_IN = "UPDATE_PAID_IN";
export const UPDATE_PAID_IN_SUCCESS = "UPDATE_PAID_IN_SUCCESS";
export const UPDATE_PAID_IN_ERROR = "UPDATE_PAID_IN_ERROR";

export const setPaidInFund = (rowIndex, columnId, value) => ({
  type: SET_PAIDIN_FUND,
  payload: { rowIndex, columnId, value },
});

export const getAllPaidInFund = (param) => ({
  type: GET_ALL_PAIDIN_FUND,
  payload: { param },
});

export const getAllPaidInFundSuccess = (success, data, totalPaidInAmount) => ({
  type: GET_ALL_PAIDIN_FUND_SUCCESS,
  payload: { success, data, totalPaidInAmount },
});

export const getAllPaidInFundError = (error) => ({
  type: GET_ALL_PAIDIN_FUND_ERROR,
  payload: error,
});

export const addPaidInFund = (paidInData, projectId, eachPropertyFund) => ({
  type: ADD_PAIDIN_FUND,
  payload: { paidInData, projectId, eachPropertyFund },
});

export const addPaidInFundSuccess = (success, message) => ({
  type: ADD_PAIDIN_FUND_SUCCESS,
  payload: { success, message },
});

export const addPaidInFundError = (error) => ({
  type: ADD_PAIDIN_FUND_ERROR,
  payload: error,
});

export const getPaidIn = (paidInId) => ({
  type: GET_PAIDIN,
  payload: { paidInId },
});

export const getPaidInSuccess = (paidInData) => ({
  type: GET_PAIDIN_SUCCESS,
  payload: paidInData,
});

export const getPaidInError = (error) => ({
  type: GET_PAIDIN_ERROR,
  payload: error,
});

export const editPaidInFund = (paidInId, paidInData, projectId) => ({
  type: EDIT_PAIDIN_FUND,
  payload: { paidInId, paidInData, projectId },
});

export const editPaidInFundSuccess = (success, message) => ({
  type: EDIT_PAIDIN_FUND_SUCCESS,
  payload: { success, message },
});

export const editPaidInFundError = (error) => ({
  type: EDIT_PAIDIN_FUND_ERROR,
  payload: error,
});

export const deletePaidInFund = (paidInId, projectId, eachPropertyFund) => ({
  type: DELETE_PAIDIN_FUND,
  payload: { paidInId, projectId, eachPropertyFund },
});

export const deletePaidInFundSuccess = (success1, message) => ({
  type: DELETE_PAIDIN_FUND_SUCCESS,
  payload: { success1, message },
});

export const deletePaidInFundError = (error) => ({
  type: DELETE_PAIDIN_FUND_ERROR,
  payload: error,
});

export const exportPaidInFund = (projectId) => ({
  type: EXPORT_PAIDIN_FUND,
  payload: { projectId },
});

export const exportPaidInFundSuccess = (success, message) => ({
  type: EXPORT_PAIDIN_FUND_SUCCESS,
  payload: { success, message },
});

export const exportPaidInFundError = (error) => ({
  type: EXPORT_PAIDIN_FUND_ERROR,
  payload: error,
});

export const resetPaidIn = () => ({
  type: RESET_PAIDIN,
  payload: {},
});

export const updatePaidIn = (data, projectId, eachPropertyFund) => ({
  type: UPDATE_PAID_IN,
  payload: { data, projectId, eachPropertyFund },
});
export const updatePaidInSuccess = (success, message) => ({
  type: UPDATE_PAID_IN_SUCCESS,
  payload: { success, message },
});

export const updatePaidInError = (error) => ({
  type: UPDATE_PAID_IN_ERROR,
  payload: error,
});
