import React from "react";

const FastInputField = ({
  label,
  classes,
  icon,
  iconPosition,
  isRequired = false,
  loading = false,
  field, meta, form,
  ...props
}) => {

  return loading ? (
    <div className="field-group animate-pulse">
      <label className="w-28 h-6 field-label rounded bg-grey-400"></label>
      <span className="w-full h-[50px] block border-0 rounded bg-grey-400"></span>
    </div>
  ) : (
    <div
      className={`field-group
      ${meta.touched && meta.error ? "field-group-error" : ""} 
      ${classes?.root || ""}`}
    >
      {label && (
        <label htmlFor="" className="field-label">
          {label ? label : null}
          {isRequired && <strong className="text-red-700">*</strong>}
        </label>
      )}

      <div
        className={`field-wrap 
       ${iconPosition === "end" ? "icon-end" : ""} 
       ${iconPosition === "start" ? "icon-start" : ""} `}
      >
        <input
          className={`field
        ${classes?.input || ""}
        `}
          {...field}
          {...props}
        />

        {icon ? (
          <span
            className={`field-icon focus:outline-none ${
              iconPosition === "end" ? "right-5" : "left-5"
            }`}
          >
            {icon}
          </span>
        ) : null}
      </div>

      {props.fieldArray && meta.error ? (
        <span
          className={`block mt-2 text-xs text-right text-red-700 ${
            classes?.error || ""
          }`}
        >
          {meta.error}
        </span>
      ) : meta.touched && meta.error ? (
        <span
          className={`block mt-2 text-xs text-right text-red-700 ${
            classes?.error || ""
          }`}
        >
          {meta.error}
        </span>
      ) : null}
    </div>
  );
};

export default FastInputField;
