import React from "react";

const IconLogout = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22.949 22.951">
      <path
        id="exit"
        d="M7.267,17.572v1.793a3.59,3.59,0,0,0,3.586,3.586h9.01a3.59,3.59,0,0,0,3.586-3.586V3.586A3.59,3.59,0,0,0,19.863,0h-9.01A3.59,3.59,0,0,0,7.267,3.586V5.379a.9.9,0,1,0,1.793,0V3.586a1.8,1.8,0,0,1,1.793-1.793h9.01a1.8,1.8,0,0,1,1.793,1.793V19.365a1.8,1.8,0,0,1-1.793,1.793h-9.01A1.8,1.8,0,0,1,9.06,19.365V17.572a.9.9,0,1,0-1.793,0ZM1.155,9.936,3.163,7.928A.9.9,0,1,1,4.431,9.2L3,10.624H13.767a.9.9,0,0,1,0,1.793H3l1.428,1.428a.9.9,0,1,1-1.268,1.268L1.155,13.105a2.244,2.244,0,0,1,0-3.17Zm0,0"
        transform="translate(-0.5)"
      />
    </svg>
  );
};

export default IconLogout;
