import React from "react";
import { Route, Redirect } from "react-router-dom";
import { checkPermission } from "src/helpers/util";

export function ContentRoute({
  children,
  component,
  render,
  data,
  permission,
  ...props
}) {
  return (
    <Route {...props}>
      {(routeProps) => {
        if (
          data &&
          permission &&
          !checkPermission(
            data.module,
            data.section,
            data.action,
            permission.permission
          )
        ) {
          const currentRole = JSON.parse(localStorage.getItem("currentRole"));
          const currentUser = JSON.parse(localStorage.getItem("currentUser"));
          if (currentUser && currentRole) {
            if (currentRole.id === currentUser.default_role_id) {
              return <Redirect to="/403" />;
            } else {
              return <Redirect to="/dashboard" />;
            }
          } else {
            return <Redirect to="/403" />;
          }
        }

        if (typeof children === "function") {
          return <>{children(routeProps)}</>;
        }

        if (!routeProps.match) {
          return null;
        }

        if (children) {
          return <>{children}</>;
        }

        if (component) {
          return <>{React.createElement(component, routeProps)}</>;
        }

        if (render) {
          return <>{render(routeProps)}</>;
        }

        return null;
      }}
    </Route>
  );
}
