import React from "react";

const IconDownload = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 23.138 25.704"
    >
      <path
        id="Union_35"
        dataname="Union 35"
        d="M11.385,0h3.766V9.288H22.77L11.385,20.673ZM0,9.288H7.619V0h3.766V20.673Z"
        transform="translate(0)"
      />
      <line
        id="Line_179"
        dataname="Line 179"
        x2="20.662"
        transform="translate(1.476 24.704)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default IconDownload;
