import {
  GET_ALL_PROJECT,
  GET_ALL_PROJECT_SUCCESS,
  GET_ALL_PROJECT_ERROR,
  GET_PROJECT_LIST,
  GET_PROJECT_LIST_SUCCESS,
  GET_PROJECT_LIST_ERROR,
  ADD_PROJECT,
  ADD_PROJECT_SUCCESS,
  ADD_PROJECT_ERROR,
  GET_PROJECT,
  GET_PROJECT_SUCCESS,
  GET_PROJECT_ERROR,
  EDIT_PROJECT,
  EDIT_PROJECT_SUCCESS,
  EDIT_PROJECT_ERROR,
  DELETE_PROJECT,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_ERROR,
  DELETE_MULTIPLE_PROJECT,
  DELETE_MULTIPLE_PROJECT_SUCCESS,
  DELETE_MULTIPLE_PROJECT_ERROR,
  ADD_PROPERTY,
  ADD_PROPERTY_SUCCESS,
  ADD_PROPERTY_ERROR,
  GET_PROPERTY,
  GET_PROPERTY_SUCCESS,
  GET_PROPERTY_ERROR,
  EDIT_PROPERTY,
  EDIT_PROPERTY_SUCCESS,
  EDIT_PROPERTY_ERROR,
  DELETE_PROPERTY,
  DELETE_PROPERTY_SUCCESS,
  DELETE_PROPERTY_ERROR,
  RESET_PROPERTY,
  RESET_PROJECT,
  SET_COLUMN_FILTER_PAIDIN,
  SET_COLUMN_FILTER_PAIDOUT,
  EXPORT_PROJECT_SUCCESS,
  EXPORT_PROJECT_FAIL,
  EXPORT_PROJECT,
  GET_PROJECT_BY_INVESTOR,
  GET_PROJECT_BY_INVESTOR_SUCCESS,
  GET_PROJECT_BY_INVESTOR_ERROR,
  SET_TAB_STATE,
} from "../actions";

const INIT_STATE = {
  dbParam: null,
  projects: null,
  projectList: null,
  projectData: null,
  projectId: null,
  projectIds: null,
  propertyData: null,
  propertyId: null,
  success: false,
  success1: false,
  message: null,
  loading: false,
  loading1: false,
  loading2: false,
  error: null,
  colsOptionPaidIn: null,
  colsOptionPaidOut: null,
  tabDisable: false,
};

const projectReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_TAB_STATE:
      return {
        ...state,
        tabDisable: action.payload,
      };
    case GET_ALL_PROJECT:
      return {
        ...state,
        error: null,
      };
    case GET_ALL_PROJECT_SUCCESS:
      return {
        ...state,
        projects: action.payload,
        error: null,
      };
    case GET_ALL_PROJECT_ERROR:
      return {
        ...state,
        projects: null,
        error: action.payload,
      };
    case GET_PROJECT_LIST:
      return {
        ...state,
        loading: true,
        projectId: null,
        projectData: null,
        projectIds: null,
        propertyData: null,
        propertyId: null,
        error: null,
      };
    case GET_PROJECT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        projectList: action.payload,
        error: null,
      };
    case GET_PROJECT_LIST_ERROR:
      return {
        ...state,
        loading: false,
        projectList: null,
        error: action.payload,
      };
    case ADD_PROJECT:
      return { ...state, loading: true, error: null };
    case ADD_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case ADD_PROJECT_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case GET_PROJECT:
      return { ...state, error: null };
    case GET_PROJECT_SUCCESS:
      return {
        ...state,
        projectData: action.payload,
        error: null,
      };
    case GET_PROJECT_ERROR:
      return {
        ...state,
        projectData: null,
        error: action.payload,
      };
    case EDIT_PROJECT:
      return { ...state, loading: true, error: null };
    case EDIT_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case EDIT_PROJECT_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DELETE_PROJECT:
      return { ...state, loading1: true, error: null };
    case DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_PROJECT_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DELETE_MULTIPLE_PROJECT:
      return { ...state, loading1: true, error: null };
    case DELETE_MULTIPLE_PROJECT_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_MULTIPLE_PROJECT_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case ADD_PROPERTY:
      return { ...state, loading: true, error: null };
    case ADD_PROPERTY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case ADD_PROPERTY_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case GET_PROPERTY:
      return { ...state, error: null };
    case GET_PROPERTY_SUCCESS:
      return {
        ...state,
        propertyData: action.payload,
        error: null,
      };
    case GET_PROPERTY_ERROR:
      return {
        ...state,
        propertyData: null,
        error: action.payload,
      };
    case EDIT_PROPERTY:
      return { ...state, loading: true, error: null };
    case EDIT_PROPERTY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case EDIT_PROPERTY_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DELETE_PROPERTY:
      return { ...state, loading1: true, error: null };
    case DELETE_PROPERTY_SUCCESS:
      return {
        ...state,
        loading1: false,
        success1: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_PROPERTY_ERROR:
      return {
        ...state,
        loading1: false,
        success1: false,
        message: null,
        error: action.payload,
      };
    case RESET_PROPERTY:
      return {
        ...state,
        propertyData: null,
      };
    case RESET_PROJECT:
      return {
        ...state,
        loading: false,
        loading1: false,
        success: false,
        success1: false,
        message: null,
        error: null,
      };

    case SET_COLUMN_FILTER_PAIDIN:
      return {
        ...state,
        colsOptionPaidIn: [...action.payload],
      };
    case SET_COLUMN_FILTER_PAIDOUT:
      return {
        ...state,
        colsOptionPaidOut: [...action.payload],
      };
    case EXPORT_PROJECT:
      return {
        ...state,
        loading2: true,
        error: null,
      };

    case EXPORT_PROJECT_SUCCESS:
      return {
        ...state,
        loading2: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case EXPORT_PROJECT_FAIL:
      return {
        ...state,
        loading2: false,
        error: action.payload,
        success: false,
      };
    case GET_PROJECT_BY_INVESTOR:
      return {
        ...state,
        error: null,
      };
    case GET_PROJECT_BY_INVESTOR_SUCCESS:
      return {
        ...state,
        projects: action.payload,
        error: null,
      };
    case GET_PROJECT_BY_INVESTOR_ERROR:
      return {
        ...state,
        projects: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
};
export default projectReducer;
