import React, { Component } from 'react'
import { withResizeDetector } from 'react-resize-detector'
import { Breakpoints, sideNavConfig, toolBarConfig } from '../LayoutConfig'
import Brand from '../brand'
import Menu from './menu'
import SideNavTriggerCollapse from '../side-nav-trigger-collapse'

class SideNav extends Component {
  constructor() {
    super()
    this.state = {
      isHovered: false,
    }
    this.handleHover = this.handleHover.bind(this)
    this.handleLeave = this.handleLeave.bind(this)
  }
  handleHover() {
    this.setState({isHovered: true})
  }

  handleLeave() {
    this.setState({isHovered: false})
  }

  onLogout = () => {
    this.props.logout(this.props.history)
  }

  render() {
    var isMenuCollapse = false
    if (this.props.drawerCollapse && this.state.isHovered === false) {
      isMenuCollapse = true
    }

    return (
      <>
        <aside
          className={`cms-side-nav hidden xl:flex flex-col
          ${isMenuCollapse ? 'collapsed' : 'expanded'} 
          ${this.props.drawerHover ? 'hover-expand' : ''}
          ${
            this.props.openMobileMenu && window.innerWidth < Breakpoints.xl
              ? 'mobile-menu-active'
              : ''
          }
        `}
          onMouseEnter={this.handleHover}
          onMouseLeave={this.handleLeave}
        >
          {toolBarConfig.style === 'fixed' ? (
            <Brand
              logo={sideNavConfig.primaryLogo}
              secondaryLogo={sideNavConfig.secondaryLogo}
              drawerCollapse={isMenuCollapse}
              className='opacity-0'
            />
          ) : (
            <Brand
              logo={sideNavConfig.primaryLogo}
              secondaryLogo={sideNavConfig.secondaryLogo}
              drawerCollapse={isMenuCollapse}
            />
          )}

          {window.innerWidth >= Breakpoints.xl && (
            <SideNavTriggerCollapse
              toggleSideNav={this.props.toggleSideNav}
              drawerCollapse={isMenuCollapse}
              switchState={this.props.drawerCollapse}
            />
          )}

          <nav>
            <ul>
              {this.props.menuList?.map((item, index) => {
                return (
                  <Menu
                    item={item}
                    key={index}
                    location={this.props.location}
                    drawerCollapse={isMenuCollapse}
                    closeDrawer={this.props.closeDrawer}
                  />
                )
              })}
            </ul>
          </nav>

          <footer className='cms-sidenav-foot'>
            <h6>
              © 2021 {document.title}. <br /> All rights reserved.
            </h6>
          </footer>
        </aside>
      </>
    )
  }
}

export default withResizeDetector(SideNav)
