import React, { Component } from "react";
import { connect } from "react-redux";
import { verifyUser } from "../../../reduxs/actions";
import { withTranslation } from "react-i18next";
import { LayoutSplashScreen } from "../../../configs/core";
class Verify extends Component {
  constructor(props) {
    super(props);
    this.props.verifyUser(this.props.match.params.token, this.props.history);
  }

  render() {
    return <LayoutSplashScreen />;
  }
}

const mapStateToProps = () => {
  return {};
};
export default connect(mapStateToProps, {
  verifyUser,
})(withTranslation()(Verify));
