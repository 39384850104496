export const timeZoneData = [
  {
    countryCode: 'CI',
    countryName: "Côte d'Ivoire",
    zoneName: 'Africa/Abidjan',
    gmtOffset: 0,
    timestamp: 1643889206,
    isdCode: '+225',
    id: 52,
  },
  {
    countryCode: 'CI',
    countryName: "Côte d'Ivoire",
    zoneName: 'Africa/Abidjan',
    gmtOffset: 0,
    timestamp: 1643889206,
    isdCode: '+225',
    id: 248,
  },
  {
    countryCode: 'GH',
    countryName: 'Ghana',
    zoneName: 'Africa/Accra',
    gmtOffset: 0,
    timestamp: 1643889206,
    isdCode: '+233',
    id: 82,
  },
  {
    countryCode: 'ET',
    countryName: 'Ethiopia',
    zoneName: 'Africa/Addis_Ababa',
    gmtOffset: 10800,
    timestamp: 1643900006,
    isdCode: '+251',
    id: 68,
  },
  {
    countryCode: 'DZ',
    countryName: 'Algeria',
    zoneName: 'Africa/Algiers',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+213',
    id: 3,
  },
  {
    countryCode: 'ER',
    countryName: 'Eritrea',
    zoneName: 'Africa/Asmara',
    gmtOffset: 10800,
    timestamp: 1643900006,
    isdCode: '+291',
    id: 66,
  },
  {
    countryCode: 'ML',
    countryName: 'Mali',
    zoneName: 'Africa/Bamako',
    gmtOffset: 0,
    timestamp: 1643889206,
    isdCode: '+223',
    id: 131,
  },
  {
    countryCode: 'CF',
    countryName: 'Central African Republic',
    zoneName: 'Africa/Bangui',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+236',
    id: 41,
  },
  {
    countryCode: 'GM',
    countryName: 'Gambia',
    zoneName: 'Africa/Banjul',
    gmtOffset: 0,
    timestamp: 1643889206,
    isdCode: '+220',
    id: 79,
  },
  {
    countryCode: 'GW',
    countryName: 'Guinea-Bissau',
    zoneName: 'Africa/Bissau',
    gmtOffset: 0,
    timestamp: 1643889206,
    isdCode: '+245',
    id: 91,
  },
  {
    countryCode: 'MW',
    countryName: 'Malawi',
    zoneName: 'Africa/Blantyre',
    gmtOffset: 7200,
    timestamp: 1643896406,
    isdCode: '+265',
    id: 128,
  },
  {
    countryCode: 'CG',
    countryName: 'Congo',
    zoneName: 'Africa/Brazzaville',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+242',
    id: 49,
  },
  {
    countryCode: 'BI',
    countryName: 'Burundi',
    zoneName: 'Africa/Bujumbura',
    gmtOffset: 7200,
    timestamp: 1643896406,
    isdCode: '+257',
    id: 35,
  },
  {
    countryCode: 'EG',
    countryName: 'Egypt',
    zoneName: 'Africa/Cairo',
    gmtOffset: 7200,
    timestamp: 1643896406,
    isdCode: '+20',
    id: 63,
  },
  {
    countryCode: 'MA',
    countryName: 'Morocco',
    zoneName: 'Africa/Casablanca',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+212',
    id: 144,
  },
  {
    countryCode: 'ES',
    countryName: 'Spain',
    zoneName: 'Africa/Ceuta',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+34',
    id: 195,
  },
  {
    countryCode: 'GN',
    countryName: 'Guinea',
    zoneName: 'Africa/Conakry',
    gmtOffset: 0,
    timestamp: 1643889206,
    isdCode: '+224',
    id: 90,
  },
  {
    countryCode: 'SN',
    countryName: 'Senegal',
    zoneName: 'Africa/Dakar',
    gmtOffset: 0,
    timestamp: 1643889206,
    isdCode: '+221',
    id: 185,
  },
  {
    countryCode: 'TZ',
    countryName: 'Tanzania, United Republic of',
    zoneName: 'Africa/Dar_es_Salaam',
    gmtOffset: 10800,
    timestamp: 1643900006,
    isdCode: '+255',
    id: 208,
  },
  {
    countryCode: 'DJ',
    countryName: 'Djibouti',
    zoneName: 'Africa/Djibouti',
    gmtOffset: 10800,
    timestamp: 1643900006,
    isdCode: '+253',
    id: 58,
  },
  {
    countryCode: 'CM',
    countryName: 'Cameroon',
    zoneName: 'Africa/Douala',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+237',
    id: 37,
  },
  {
    countryCode: 'EH',
    countryName: 'Western Sahara',
    zoneName: 'Africa/El_Aaiun',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+212',
    id: 234,
  },
  {
    countryCode: 'SL',
    countryName: 'Sierra Leone',
    zoneName: 'Africa/Freetown',
    gmtOffset: 0,
    timestamp: 1643889206,
    isdCode: '+232',
    id: 187,
  },
  {
    countryCode: 'BW',
    countryName: 'Botswana',
    zoneName: 'Africa/Gaborone',
    gmtOffset: 7200,
    timestamp: 1643896406,
    isdCode: '+267',
    id: 28,
  },
  {
    countryCode: 'ZW',
    countryName: 'Zimbabwe',
    zoneName: 'Africa/Harare',
    gmtOffset: 7200,
    timestamp: 1643896406,
    isdCode: '+263',
    id: 239,
  },
  {
    countryCode: 'ZA',
    countryName: 'South Africa',
    zoneName: 'Africa/Johannesburg',
    gmtOffset: 7200,
    timestamp: 1643896406,
    isdCode: '+27',
    id: 193,
  },
  {
    countryCode: 'UG',
    countryName: 'Uganda',
    zoneName: 'Africa/Kampala',
    gmtOffset: 10800,
    timestamp: 1643900006,
    isdCode: '+256',
    id: 219,
  },
  {
    countryCode: 'SD',
    countryName: 'Sudan',
    zoneName: 'Africa/Khartoum',
    gmtOffset: 7200,
    timestamp: 1643896406,
    isdCode: '+249',
    id: 199,
  },
  {
    countryCode: 'RW',
    countryName: 'Rwanda',
    zoneName: 'Africa/Kigali',
    gmtOffset: 7200,
    timestamp: 1643896406,
    isdCode: '+250',
    id: 177,
  },
  {
    countryCode: 'NG',
    countryName: 'Nigeria',
    zoneName: 'Africa/Lagos',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+234',
    id: 156,
  },
  {
    countryCode: 'GA',
    countryName: 'Gabon',
    zoneName: 'Africa/Libreville',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+241',
    id: 78,
  },
  {
    countryCode: 'TG',
    countryName: 'Togo',
    zoneName: 'Africa/Lome',
    gmtOffset: 0,
    timestamp: 1643889206,
    isdCode: '+228',
    id: 210,
  },
  {
    countryCode: 'AO',
    countryName: 'Angola',
    zoneName: 'Africa/Luanda',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+244',
    id: 6,
  },
  {
    countryCode: 'ZM',
    countryName: 'Zambia',
    zoneName: 'Africa/Lusaka',
    gmtOffset: 7200,
    timestamp: 1643896406,
    isdCode: '+260',
    id: 238,
  },
  {
    countryCode: 'GQ',
    countryName: 'Equatorial Guinea',
    zoneName: 'Africa/Malabo',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+240',
    id: 65,
  },
  {
    countryCode: 'MZ',
    countryName: 'Mozambique',
    zoneName: 'Africa/Maputo',
    gmtOffset: 7200,
    timestamp: 1643896406,
    isdCode: '+258',
    id: 145,
  },
  {
    countryCode: 'LS',
    countryName: 'Lesotho',
    zoneName: 'Africa/Maseru',
    gmtOffset: 7200,
    timestamp: 1643896406,
    isdCode: '+266',
    id: 119,
  },
  {
    countryCode: 'SZ',
    countryName: 'Eswatini',
    zoneName: 'Africa/Mbabane',
    gmtOffset: 7200,
    timestamp: 1643896406,
    isdCode: '+268',
    id: 202,
  },
  {
    countryCode: 'SO',
    countryName: 'Somalia',
    zoneName: 'Africa/Mogadishu',
    gmtOffset: 10800,
    timestamp: 1643900006,
    isdCode: '+252',
    id: 192,
  },
  {
    countryCode: 'LR',
    countryName: 'Liberia',
    zoneName: 'Africa/Monrovia',
    gmtOffset: 0,
    timestamp: 1643889206,
    isdCode: '+231',
    id: 120,
  },
  {
    countryCode: 'KE',
    countryName: 'Kenya',
    zoneName: 'Africa/Nairobi',
    gmtOffset: 10800,
    timestamp: 1643900006,
    isdCode: '+254',
    id: 110,
  },
  {
    countryCode: 'TD',
    countryName: 'Chad',
    zoneName: 'Africa/Ndjamena',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+235',
    id: 42,
  },
  {
    countryCode: 'NE',
    countryName: 'Niger',
    zoneName: 'Africa/Niamey',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+227',
    id: 155,
  },
  {
    countryCode: 'MR',
    countryName: 'Mauritania',
    zoneName: 'Africa/Nouakchott',
    gmtOffset: 0,
    timestamp: 1643889206,
    isdCode: '+222',
    id: 135,
  },
  {
    countryCode: 'BF',
    countryName: 'Burkina Faso',
    zoneName: 'Africa/Ouagadougou',
    gmtOffset: 0,
    timestamp: 1643889206,
    isdCode: '+226',
    id: 34,
  },
  {
    countryCode: 'BJ',
    countryName: 'Benin',
    zoneName: 'Africa/Porto-Novo',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+229',
    id: 23,
  },
  {
    countryCode: 'ST',
    countryName: 'Sao Tome and Principe',
    zoneName: 'Africa/Sao_Tome',
    gmtOffset: 0,
    timestamp: 1643889206,
    isdCode: '+239',
    id: 183,
  },
  {
    countryCode: 'LY',
    countryName: 'Libya',
    zoneName: 'Africa/Tripoli',
    gmtOffset: 7200,
    timestamp: 1643896406,
    isdCode: '+218',
    id: 121,
  },
  {
    countryCode: 'TN',
    countryName: 'Tunisia',
    zoneName: 'Africa/Tunis',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+216',
    id: 214,
  },
  {
    countryCode: 'NA',
    countryName: 'Namibia',
    zoneName: 'Africa/Windhoek',
    gmtOffset: 7200,
    timestamp: 1643896406,
    isdCode: '+264',
    id: 147,
  },
  {
    countryCode: 'US',
    countryName: 'United States of America',
    zoneName: 'America/Adak',
    gmtOffset: -36000,
    timestamp: 1643853206,
    isdCode: '+1',
    id: 223,
  },
  {
    countryCode: 'US',
    countryName: 'United States of America',
    zoneName: 'America/Anchorage',
    gmtOffset: -32400,
    timestamp: 1643856806,
    isdCode: '+1',
    id: 223,
  },
  {
    countryCode: 'AI',
    countryName: 'Anguilla',
    zoneName: 'America/Anguilla',
    gmtOffset: -14400,
    timestamp: 1643874806,
    isdCode: '+1-264',
    id: 7,
  },
  {
    countryCode: 'AG',
    countryName: 'Antigua and Barbuda',
    zoneName: 'America/Antigua',
    gmtOffset: -14400,
    timestamp: 1643874806,
    isdCode: '+1-268',
    id: 9,
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    zoneName: 'America/Araguaina',
    gmtOffset: -10800,
    timestamp: 1643878406,
    isdCode: '+55',
    id: 30,
  },
  {
    countryCode: 'AR',
    countryName: 'Argentina',
    zoneName: 'America/Argentina/Buenos_Aires',
    gmtOffset: -10800,
    timestamp: 1643878406,
    isdCode: '+54',
    id: 10,
  },
  {
    countryCode: 'AR',
    countryName: 'Argentina',
    zoneName: 'America/Argentina/Catamarca',
    gmtOffset: -10800,
    timestamp: 1643878406,
    isdCode: '+54',
    id: 10,
  },
  {
    countryCode: 'AR',
    countryName: 'Argentina',
    zoneName: 'America/Argentina/Cordoba',
    gmtOffset: -10800,
    timestamp: 1643878406,
    isdCode: '+54',
    id: 10,
  },
  {
    countryCode: 'AR',
    countryName: 'Argentina',
    zoneName: 'America/Argentina/Jujuy',
    gmtOffset: -10800,
    timestamp: 1643878406,
    isdCode: '+54',
    id: 10,
  },
  {
    countryCode: 'AR',
    countryName: 'Argentina',
    zoneName: 'America/Argentina/La_Rioja',
    gmtOffset: -10800,
    timestamp: 1643878406,
    isdCode: '+54',
    id: 10,
  },
  {
    countryCode: 'AR',
    countryName: 'Argentina',
    zoneName: 'America/Argentina/Mendoza',
    gmtOffset: -10800,
    timestamp: 1643878406,
    isdCode: '+54',
    id: 10,
  },
  {
    countryCode: 'AR',
    countryName: 'Argentina',
    zoneName: 'America/Argentina/Rio_Gallegos',
    gmtOffset: -10800,
    timestamp: 1643878406,
    isdCode: '+54',
    id: 10,
  },
  {
    countryCode: 'AR',
    countryName: 'Argentina',
    zoneName: 'America/Argentina/Salta',
    gmtOffset: -10800,
    timestamp: 1643878406,
    isdCode: '+54',
    id: 10,
  },
  {
    countryCode: 'AR',
    countryName: 'Argentina',
    zoneName: 'America/Argentina/San_Juan',
    gmtOffset: -10800,
    timestamp: 1643878406,
    isdCode: '+54',
    id: 10,
  },
  {
    countryCode: 'AR',
    countryName: 'Argentina',
    zoneName: 'America/Argentina/San_Luis',
    gmtOffset: -10800,
    timestamp: 1643878406,
    isdCode: '+54',
    id: 10,
  },
  {
    countryCode: 'AR',
    countryName: 'Argentina',
    zoneName: 'America/Argentina/Tucuman',
    gmtOffset: -10800,
    timestamp: 1643878406,
    isdCode: '+54',
    id: 10,
  },
  {
    countryCode: 'AR',
    countryName: 'Argentina',
    zoneName: 'America/Argentina/Ushuaia',
    gmtOffset: -10800,
    timestamp: 1643878406,
    isdCode: '+54',
    id: 10,
  },
  {
    countryCode: 'AW',
    countryName: 'Aruba',
    zoneName: 'America/Aruba',
    gmtOffset: -14400,
    timestamp: 1643874806,
    isdCode: '+297',
    id: 12,
  },
  {
    countryCode: 'PY',
    countryName: 'Paraguay',
    zoneName: 'America/Asuncion',
    gmtOffset: -10800,
    timestamp: 1643878406,
    isdCode: '+595',
    id: 166,
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    zoneName: 'America/Atikokan',
    gmtOffset: -18000,
    timestamp: 1643871206,
    isdCode: '+1',
    id: 38,
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    zoneName: 'America/Bahia',
    gmtOffset: -10800,
    timestamp: 1643878406,
    isdCode: '+55',
    id: 30,
  },
  {
    countryCode: 'MX',
    countryName: 'Mexico',
    zoneName: 'America/Bahia_Banderas',
    gmtOffset: -21600,
    timestamp: 1643867606,
    isdCode: '+52',
    id: 138,
  },
  {
    countryCode: 'BB',
    countryName: 'Barbados',
    zoneName: 'America/Barbados',
    gmtOffset: -14400,
    timestamp: 1643874806,
    isdCode: '+1-246',
    id: 19,
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    zoneName: 'America/Belem',
    gmtOffset: -10800,
    timestamp: 1643878406,
    isdCode: '+55',
    id: 30,
  },
  {
    countryCode: 'BZ',
    countryName: 'Belize',
    zoneName: 'America/Belize',
    gmtOffset: -21600,
    timestamp: 1643867606,
    isdCode: '+501',
    id: 22,
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    zoneName: 'America/Blanc-Sablon',
    gmtOffset: -14400,
    timestamp: 1643874806,
    isdCode: '+1',
    id: 38,
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    zoneName: 'America/Boa_Vista',
    gmtOffset: -14400,
    timestamp: 1643874806,
    isdCode: '+55',
    id: 30,
  },
  {
    countryCode: 'CO',
    countryName: 'Colombia',
    zoneName: 'America/Bogota',
    gmtOffset: -18000,
    timestamp: 1643871206,
    isdCode: '+57',
    id: 47,
  },
  {
    countryCode: 'US',
    countryName: 'United States of America',
    zoneName: 'America/Boise',
    gmtOffset: -25200,
    timestamp: 1643864006,
    isdCode: '+1',
    id: 223,
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    zoneName: 'America/Cambridge_Bay',
    gmtOffset: -25200,
    timestamp: 1643864006,
    isdCode: '+1',
    id: 38,
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    zoneName: 'America/Campo_Grande',
    gmtOffset: -14400,
    timestamp: 1643874806,
    isdCode: '+55',
    id: 30,
  },
  {
    countryCode: 'MX',
    countryName: 'Mexico',
    zoneName: 'America/Cancun',
    gmtOffset: -18000,
    timestamp: 1643871206,
    isdCode: '+52',
    id: 138,
  },
  {
    countryCode: 'VE',
    countryName: 'Venezuela (Bolivarian Republic of)',
    zoneName: 'America/Caracas',
    gmtOffset: -14400,
    timestamp: 1643874806,
    isdCode: '+58',
    id: 229,
  },
  {
    countryCode: 'GF',
    countryName: 'French Guiana',
    zoneName: 'America/Cayenne',
    gmtOffset: -10800,
    timestamp: 1643878406,
    isdCode: '+594',
    id: 75,
  },
  {
    countryCode: 'KY',
    countryName: 'Cayman Islands',
    zoneName: 'America/Cayman',
    gmtOffset: -18000,
    timestamp: 1643871206,
    isdCode: '+1-345',
    id: 40,
  },
  {
    countryCode: 'US',
    countryName: 'United States of America',
    zoneName: 'America/Chicago',
    gmtOffset: -21600,
    timestamp: 1643867606,
    isdCode: '+1',
    id: 223,
  },
  {
    countryCode: 'MX',
    countryName: 'Mexico',
    zoneName: 'America/Chihuahua',
    gmtOffset: -25200,
    timestamp: 1643864006,
    isdCode: '+52',
    id: 138,
  },
  {
    countryCode: 'CR',
    countryName: 'Costa Rica',
    zoneName: 'America/Costa_Rica',
    gmtOffset: -21600,
    timestamp: 1643867606,
    isdCode: '+506',
    id: 51,
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    zoneName: 'America/Creston',
    gmtOffset: -25200,
    timestamp: 1643864006,
    isdCode: '+1',
    id: 38,
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    zoneName: 'America/Cuiaba',
    gmtOffset: -14400,
    timestamp: 1643874806,
    isdCode: '+55',
    id: 30,
  },
  {
    countryCode: 'CW',
    countryName: 'Curaçao',
    zoneName: 'America/Curacao',
    gmtOffset: -14400,
    timestamp: 1643874806,
    isdCode: '+599',
    id: 247,
  },
  {
    countryCode: 'GL',
    countryName: 'Greenland',
    zoneName: 'America/Danmarkshavn',
    gmtOffset: 0,
    timestamp: 1643889206,
    isdCode: '+299',
    id: 85,
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    zoneName: 'America/Dawson',
    gmtOffset: -25200,
    timestamp: 1643864006,
    isdCode: '+1',
    id: 38,
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    zoneName: 'America/Dawson_Creek',
    gmtOffset: -25200,
    timestamp: 1643864006,
    isdCode: '+1',
    id: 38,
  },
  {
    countryCode: 'US',
    countryName: 'United States of America',
    zoneName: 'America/Denver',
    gmtOffset: -25200,
    timestamp: 1643864006,
    isdCode: '+1',
    id: 223,
  },
  {
    countryCode: 'US',
    countryName: 'United States of America',
    zoneName: 'America/Detroit',
    gmtOffset: -18000,
    timestamp: 1643871206,
    isdCode: '+1',
    id: 223,
  },
  {
    countryCode: 'DM',
    countryName: 'Dominica',
    zoneName: 'America/Dominica',
    gmtOffset: -14400,
    timestamp: 1643874806,
    isdCode: '+1-767',
    id: 59,
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    zoneName: 'America/Edmonton',
    gmtOffset: -25200,
    timestamp: 1643864006,
    isdCode: '+1',
    id: 38,
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    zoneName: 'America/Eirunepe',
    gmtOffset: -18000,
    timestamp: 1643871206,
    isdCode: '+55',
    id: 30,
  },
  {
    countryCode: 'SV',
    countryName: 'El Salvador',
    zoneName: 'America/El_Salvador',
    gmtOffset: -21600,
    timestamp: 1643867606,
    isdCode: '+503',
    id: 64,
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    zoneName: 'America/Fort_Nelson',
    gmtOffset: -25200,
    timestamp: 1643864006,
    isdCode: '+1',
    id: 38,
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    zoneName: 'America/Fortaleza',
    gmtOffset: -10800,
    timestamp: 1643878406,
    isdCode: '+55',
    id: 30,
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    zoneName: 'America/Glace_Bay',
    gmtOffset: -14400,
    timestamp: 1643874806,
    isdCode: '+1',
    id: 38,
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    zoneName: 'America/Goose_Bay',
    gmtOffset: -14400,
    timestamp: 1643874806,
    isdCode: '+1',
    id: 38,
  },
  {
    countryCode: 'TC',
    countryName: 'Turks and Caicos Islands',
    zoneName: 'America/Grand_Turk',
    gmtOffset: -18000,
    timestamp: 1643871206,
    isdCode: '+1-649',
    id: 217,
  },
  {
    countryCode: 'GD',
    countryName: 'Grenada',
    zoneName: 'America/Grenada',
    gmtOffset: -14400,
    timestamp: 1643874806,
    isdCode: '+1-473',
    id: 86,
  },
  {
    countryCode: 'GP',
    countryName: 'Guadeloupe',
    zoneName: 'America/Guadeloupe',
    gmtOffset: -14400,
    timestamp: 1643874806,
    isdCode: '+590',
    id: 87,
  },
  {
    countryCode: 'GT',
    countryName: 'Guatemala',
    zoneName: 'America/Guatemala',
    gmtOffset: -21600,
    timestamp: 1643867606,
    isdCode: '+502',
    id: 89,
  },
  {
    countryCode: 'EC',
    countryName: 'Ecuador',
    zoneName: 'America/Guayaquil',
    gmtOffset: -18000,
    timestamp: 1643871206,
    isdCode: '+593',
    id: 62,
  },
  {
    countryCode: 'GY',
    countryName: 'Guyana',
    zoneName: 'America/Guyana',
    gmtOffset: -14400,
    timestamp: 1643874806,
    isdCode: '+592',
    id: 92,
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    zoneName: 'America/Halifax',
    gmtOffset: -14400,
    timestamp: 1643874806,
    isdCode: '+1',
    id: 38,
  },
  {
    countryCode: 'CU',
    countryName: 'Cuba',
    zoneName: 'America/Havana',
    gmtOffset: -18000,
    timestamp: 1643871206,
    isdCode: '+53',
    id: 54,
  },
  {
    countryCode: 'MX',
    countryName: 'Mexico',
    zoneName: 'America/Hermosillo',
    gmtOffset: -25200,
    timestamp: 1643864006,
    isdCode: '+52',
    id: 138,
  },
  {
    countryCode: 'US',
    countryName: 'United States of America',
    zoneName: 'America/Indiana/Indianapolis',
    gmtOffset: -18000,
    timestamp: 1643871206,
    isdCode: '+1',
    id: 223,
  },
  {
    countryCode: 'US',
    countryName: 'United States of America',
    zoneName: 'America/Indiana/Knox',
    gmtOffset: -21600,
    timestamp: 1643867606,
    isdCode: '+1',
    id: 223,
  },
  {
    countryCode: 'US',
    countryName: 'United States of America',
    zoneName: 'America/Indiana/Marengo',
    gmtOffset: -18000,
    timestamp: 1643871206,
    isdCode: '+1',
    id: 223,
  },
  {
    countryCode: 'US',
    countryName: 'United States of America',
    zoneName: 'America/Indiana/Petersburg',
    gmtOffset: -18000,
    timestamp: 1643871206,
    isdCode: '+1',
    id: 223,
  },
  {
    countryCode: 'US',
    countryName: 'United States of America',
    zoneName: 'America/Indiana/Tell_City',
    gmtOffset: -21600,
    timestamp: 1643867606,
    isdCode: '+1',
    id: 223,
  },
  {
    countryCode: 'US',
    countryName: 'United States of America',
    zoneName: 'America/Indiana/Vevay',
    gmtOffset: -18000,
    timestamp: 1643871206,
    isdCode: '+1',
    id: 223,
  },
  {
    countryCode: 'US',
    countryName: 'United States of America',
    zoneName: 'America/Indiana/Vincennes',
    gmtOffset: -18000,
    timestamp: 1643871206,
    isdCode: '+1',
    id: 223,
  },
  {
    countryCode: 'US',
    countryName: 'United States of America',
    zoneName: 'America/Indiana/Winamac',
    gmtOffset: -18000,
    timestamp: 1643871206,
    isdCode: '+1',
    id: 223,
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    zoneName: 'America/Inuvik',
    gmtOffset: -25200,
    timestamp: 1643864006,
    isdCode: '+1',
    id: 38,
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    zoneName: 'America/Iqaluit',
    gmtOffset: -18000,
    timestamp: 1643871206,
    isdCode: '+1',
    id: 38,
  },
  {
    countryCode: 'JM',
    countryName: 'Jamaica',
    zoneName: 'America/Jamaica',
    gmtOffset: -18000,
    timestamp: 1643871206,
    isdCode: '+1-876',
    id: 106,
  },
  {
    countryCode: 'US',
    countryName: 'United States of America',
    zoneName: 'America/Juneau',
    gmtOffset: -32400,
    timestamp: 1643856806,
    isdCode: '+1',
    id: 223,
  },
  {
    countryCode: 'US',
    countryName: 'United States of America',
    zoneName: 'America/Kentucky/Louisville',
    gmtOffset: -18000,
    timestamp: 1643871206,
    isdCode: '+1',
    id: 223,
  },
  {
    countryCode: 'US',
    countryName: 'United States of America',
    zoneName: 'America/Kentucky/Monticello',
    gmtOffset: -18000,
    timestamp: 1643871206,
    isdCode: '+1',
    id: 223,
  },
  {
    countryCode: 'BO',
    countryName: 'Bolivia (Plurinational State of)',
    zoneName: 'America/La_Paz',
    gmtOffset: -14400,
    timestamp: 1643874806,
    isdCode: '+591',
    id: 26,
  },
  {
    countryCode: 'PE',
    countryName: 'Peru',
    zoneName: 'America/Lima',
    gmtOffset: -18000,
    timestamp: 1643871206,
    isdCode: '+51',
    id: 167,
  },
  {
    countryCode: 'US',
    countryName: 'United States of America',
    zoneName: 'America/Los_Angeles',
    gmtOffset: -28800,
    timestamp: 1643860406,
    isdCode: '+1',
    id: 223,
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    zoneName: 'America/Maceio',
    gmtOffset: -10800,
    timestamp: 1643878406,
    isdCode: '+55',
    id: 30,
  },
  {
    countryCode: 'NI',
    countryName: 'Nicaragua',
    zoneName: 'America/Managua',
    gmtOffset: -21600,
    timestamp: 1643867606,
    isdCode: '+505',
    id: 154,
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    zoneName: 'America/Manaus',
    gmtOffset: -14400,
    timestamp: 1643874806,
    isdCode: '+55',
    id: 30,
  },
  {
    countryCode: 'MQ',
    countryName: 'Martinique',
    zoneName: 'America/Martinique',
    gmtOffset: -14400,
    timestamp: 1643874806,
    isdCode: '+596',
    id: 134,
  },
  {
    countryCode: 'MX',
    countryName: 'Mexico',
    zoneName: 'America/Matamoros',
    gmtOffset: -21600,
    timestamp: 1643867606,
    isdCode: '+52',
    id: 138,
  },
  {
    countryCode: 'MX',
    countryName: 'Mexico',
    zoneName: 'America/Mazatlan',
    gmtOffset: -25200,
    timestamp: 1643864006,
    isdCode: '+52',
    id: 138,
  },
  {
    countryCode: 'US',
    countryName: 'United States of America',
    zoneName: 'America/Menominee',
    gmtOffset: -21600,
    timestamp: 1643867606,
    isdCode: '+1',
    id: 223,
  },
  {
    countryCode: 'MX',
    countryName: 'Mexico',
    zoneName: 'America/Merida',
    gmtOffset: -21600,
    timestamp: 1643867606,
    isdCode: '+52',
    id: 138,
  },
  {
    countryCode: 'US',
    countryName: 'United States of America',
    zoneName: 'America/Metlakatla',
    gmtOffset: -32400,
    timestamp: 1643856806,
    isdCode: '+1',
    id: 223,
  },
  {
    countryCode: 'MX',
    countryName: 'Mexico',
    zoneName: 'America/Mexico_City',
    gmtOffset: -21600,
    timestamp: 1643867606,
    isdCode: '+52',
    id: 138,
  },
  {
    countryCode: 'PM',
    countryName: 'Saint Pierre and Miquelon',
    zoneName: 'America/Miquelon',
    gmtOffset: -10800,
    timestamp: 1643878406,
    isdCode: '+508',
    id: 198,
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    zoneName: 'America/Moncton',
    gmtOffset: -14400,
    timestamp: 1643874806,
    isdCode: '+1',
    id: 38,
  },
  {
    countryCode: 'MX',
    countryName: 'Mexico',
    zoneName: 'America/Monterrey',
    gmtOffset: -21600,
    timestamp: 1643867606,
    isdCode: '+52',
    id: 138,
  },
  {
    countryCode: 'UY',
    countryName: 'Uruguay',
    zoneName: 'America/Montevideo',
    gmtOffset: -10800,
    timestamp: 1643878406,
    isdCode: '+598',
    id: 225,
  },
  {
    countryCode: 'MS',
    countryName: 'Montserrat',
    zoneName: 'America/Montserrat',
    gmtOffset: -14400,
    timestamp: 1643874806,
    isdCode: '+1-664',
    id: 143,
  },
  {
    countryCode: 'BS',
    countryName: 'Bahamas',
    zoneName: 'America/Nassau',
    gmtOffset: -18000,
    timestamp: 1643871206,
    isdCode: '+1-242',
    id: 16,
  },
  {
    countryCode: 'US',
    countryName: 'United States of America',
    zoneName: 'America/New_York',
    gmtOffset: -18000,
    timestamp: 1643871206,
    isdCode: '+1',
    id: 223,
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    zoneName: 'America/Nipigon',
    gmtOffset: -18000,
    timestamp: 1643871206,
    isdCode: '+1',
    id: 38,
  },
  {
    countryCode: 'US',
    countryName: 'United States of America',
    zoneName: 'America/Nome',
    gmtOffset: -32400,
    timestamp: 1643856806,
    isdCode: '+1',
    id: 223,
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    zoneName: 'America/Noronha',
    gmtOffset: -7200,
    timestamp: 1643882006,
    isdCode: '+55',
    id: 30,
  },
  {
    countryCode: 'US',
    countryName: 'United States of America',
    zoneName: 'America/North_Dakota/Beulah',
    gmtOffset: -21600,
    timestamp: 1643867606,
    isdCode: '+1',
    id: 223,
  },
  {
    countryCode: 'US',
    countryName: 'United States of America',
    zoneName: 'America/North_Dakota/Center',
    gmtOffset: -21600,
    timestamp: 1643867606,
    isdCode: '+1',
    id: 223,
  },
  {
    countryCode: 'US',
    countryName: 'United States of America',
    zoneName: 'America/North_Dakota/New_Salem',
    gmtOffset: -21600,
    timestamp: 1643867606,
    isdCode: '+1',
    id: 223,
  },
  {
    countryCode: 'GL',
    countryName: 'Greenland',
    zoneName: 'America/Nuuk',
    gmtOffset: -10800,
    timestamp: 1643878406,
    isdCode: '+299',
    id: 85,
  },
  {
    countryCode: 'MX',
    countryName: 'Mexico',
    zoneName: 'America/Ojinaga',
    gmtOffset: -25200,
    timestamp: 1643864006,
    isdCode: '+52',
    id: 138,
  },
  {
    countryCode: 'PA',
    countryName: 'Panama',
    zoneName: 'America/Panama',
    gmtOffset: -18000,
    timestamp: 1643871206,
    isdCode: '+507',
    id: 164,
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    zoneName: 'America/Pangnirtung',
    gmtOffset: -18000,
    timestamp: 1643871206,
    isdCode: '+1',
    id: 38,
  },
  {
    countryCode: 'SR',
    countryName: 'Suriname',
    zoneName: 'America/Paramaribo',
    gmtOffset: -10800,
    timestamp: 1643878406,
    isdCode: '+597',
    id: 200,
  },
  {
    countryCode: 'US',
    countryName: 'United States of America',
    zoneName: 'America/Phoenix',
    gmtOffset: -25200,
    timestamp: 1643864006,
    isdCode: '+1',
    id: 223,
  },
  {
    countryCode: 'HT',
    countryName: 'Haiti',
    zoneName: 'America/Port-au-Prince',
    gmtOffset: -18000,
    timestamp: 1643871206,
    isdCode: '+509',
    id: 93,
  },
  {
    countryCode: 'TT',
    countryName: 'Trinidad and Tobago',
    zoneName: 'America/Port_of_Spain',
    gmtOffset: -14400,
    timestamp: 1643874806,
    isdCode: '+1-868',
    id: 213,
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    zoneName: 'America/Porto_Velho',
    gmtOffset: -14400,
    timestamp: 1643874806,
    isdCode: '+55',
    id: 30,
  },
  {
    countryCode: 'PR',
    countryName: 'Puerto Rico',
    zoneName: 'America/Puerto_Rico',
    gmtOffset: -14400,
    timestamp: 1643874806,
    isdCode: '+1-787',
    id: 172,
  },
  {
    countryCode: 'CL',
    countryName: 'Chile',
    zoneName: 'America/Punta_Arenas',
    gmtOffset: -10800,
    timestamp: 1643878406,
    isdCode: '+56',
    id: 43,
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    zoneName: 'America/Rainy_River',
    gmtOffset: -21600,
    timestamp: 1643867606,
    isdCode: '+1',
    id: 38,
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    zoneName: 'America/Rankin_Inlet',
    gmtOffset: -21600,
    timestamp: 1643867606,
    isdCode: '+1',
    id: 38,
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    zoneName: 'America/Recife',
    gmtOffset: -10800,
    timestamp: 1643878406,
    isdCode: '+55',
    id: 30,
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    zoneName: 'America/Regina',
    gmtOffset: -21600,
    timestamp: 1643867606,
    isdCode: '+1',
    id: 38,
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    zoneName: 'America/Resolute',
    gmtOffset: -21600,
    timestamp: 1643867606,
    isdCode: '+1',
    id: 38,
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    zoneName: 'America/Rio_Branco',
    gmtOffset: -18000,
    timestamp: 1643871206,
    isdCode: '+55',
    id: 30,
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    zoneName: 'America/Santarem',
    gmtOffset: -10800,
    timestamp: 1643878406,
    isdCode: '+55',
    id: 30,
  },
  {
    countryCode: 'CL',
    countryName: 'Chile',
    zoneName: 'America/Santiago',
    gmtOffset: -10800,
    timestamp: 1643878406,
    isdCode: '+56',
    id: 43,
  },
  {
    countryCode: 'DO',
    countryName: 'Dominican Republic',
    zoneName: 'America/Santo_Domingo',
    gmtOffset: -14400,
    timestamp: 1643874806,
    isdCode: '+1-809',
    id: 60,
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    zoneName: 'America/Sao_Paulo',
    gmtOffset: -10800,
    timestamp: 1643878406,
    isdCode: '+55',
    id: 30,
  },
  {
    countryCode: 'GL',
    countryName: 'Greenland',
    zoneName: 'America/Scoresbysund',
    gmtOffset: -3600,
    timestamp: 1643885606,
    isdCode: '+299',
    id: 85,
  },
  {
    countryCode: 'US',
    countryName: 'United States of America',
    zoneName: 'America/Sitka',
    gmtOffset: -32400,
    timestamp: 1643856806,
    isdCode: '+1',
    id: 223,
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    zoneName: 'America/St_Johns',
    gmtOffset: -12600,
    timestamp: 1643876606,
    isdCode: '+1',
    id: 38,
  },
  {
    countryCode: 'KN',
    countryName: 'Saint Kitts and Nevis',
    zoneName: 'America/St_Kitts',
    gmtOffset: -14400,
    timestamp: 1643874806,
    isdCode: '+1-869',
    id: 178,
  },
  {
    countryCode: 'LC',
    countryName: 'Saint Lucia',
    zoneName: 'America/St_Lucia',
    gmtOffset: -14400,
    timestamp: 1643874806,
    isdCode: '+1-758',
    id: 179,
  },
  {
    countryCode: 'VI',
    countryName: 'Virgin Islands (U.S.)',
    zoneName: 'America/St_Thomas',
    gmtOffset: -14400,
    timestamp: 1643874806,
    isdCode: '+1-340',
    id: 232,
  },
  {
    countryCode: 'VC',
    countryName: 'Saint Vincent and the Grenadines',
    zoneName: 'America/St_Vincent',
    gmtOffset: -14400,
    timestamp: 1643874806,
    isdCode: '+1-784',
    id: 180,
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    zoneName: 'America/Swift_Current',
    gmtOffset: -21600,
    timestamp: 1643867606,
    isdCode: '+1',
    id: 38,
  },
  {
    countryCode: 'HN',
    countryName: 'Honduras',
    zoneName: 'America/Tegucigalpa',
    gmtOffset: -21600,
    timestamp: 1643867606,
    isdCode: '+504',
    id: 95,
  },
  {
    countryCode: 'GL',
    countryName: 'Greenland',
    zoneName: 'America/Thule',
    gmtOffset: -14400,
    timestamp: 1643874806,
    isdCode: '+299',
    id: 85,
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    zoneName: 'America/Thunder_Bay',
    gmtOffset: -18000,
    timestamp: 1643871206,
    isdCode: '+1',
    id: 38,
  },
  {
    countryCode: 'MX',
    countryName: 'Mexico',
    zoneName: 'America/Tijuana',
    gmtOffset: -28800,
    timestamp: 1643860406,
    isdCode: '+52',
    id: 138,
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    zoneName: 'America/Toronto',
    gmtOffset: -18000,
    timestamp: 1643871206,
    isdCode: '+1',
    id: 38,
  },
  {
    countryCode: 'VG',
    countryName: 'Virgin Islands (British)',
    zoneName: 'America/Tortola',
    gmtOffset: -14400,
    timestamp: 1643874806,
    isdCode: '+1-284',
    id: 231,
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    zoneName: 'America/Vancouver',
    gmtOffset: -28800,
    timestamp: 1643860406,
    isdCode: '+1',
    id: 38,
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    zoneName: 'America/Whitehorse',
    gmtOffset: -25200,
    timestamp: 1643864006,
    isdCode: '+1',
    id: 38,
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    zoneName: 'America/Winnipeg',
    gmtOffset: -21600,
    timestamp: 1643867606,
    isdCode: '+1',
    id: 38,
  },
  {
    countryCode: 'US',
    countryName: 'United States of America',
    zoneName: 'America/Yakutat',
    gmtOffset: -32400,
    timestamp: 1643856806,
    isdCode: '+1',
    id: 223,
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    zoneName: 'America/Yellowknife',
    gmtOffset: -25200,
    timestamp: 1643864006,
    isdCode: '+1',
    id: 38,
  },
  {
    countryCode: 'AQ',
    countryName: 'Antarctica',
    zoneName: 'Antarctica/Casey',
    gmtOffset: 39600,
    timestamp: 1643928806,
    isdCode: '+672',
    id: 8,
  },
  {
    countryCode: 'AQ',
    countryName: 'Antarctica',
    zoneName: 'Antarctica/Davis',
    gmtOffset: 25200,
    timestamp: 1643914406,
    isdCode: '+672',
    id: 8,
  },
  {
    countryCode: 'AQ',
    countryName: 'Antarctica',
    zoneName: 'Antarctica/DumontDUrville',
    gmtOffset: 36000,
    timestamp: 1643925206,
    isdCode: '+672',
    id: 8,
  },
  {
    countryCode: 'AU',
    countryName: 'Australia',
    zoneName: 'Antarctica/Macquarie',
    gmtOffset: 39600,
    timestamp: 1643928806,
    isdCode: '+61',
    id: 13,
  },
  {
    countryCode: 'AQ',
    countryName: 'Antarctica',
    zoneName: 'Antarctica/Mawson',
    gmtOffset: 18000,
    timestamp: 1643907206,
    isdCode: '+672',
    id: 8,
  },
  {
    countryCode: 'AQ',
    countryName: 'Antarctica',
    zoneName: 'Antarctica/McMurdo',
    gmtOffset: 46800,
    timestamp: 1643936006,
    isdCode: '+672',
    id: 8,
  },
  {
    countryCode: 'AQ',
    countryName: 'Antarctica',
    zoneName: 'Antarctica/Palmer',
    gmtOffset: -10800,
    timestamp: 1643878406,
    isdCode: '+672',
    id: 8,
  },
  {
    countryCode: 'AQ',
    countryName: 'Antarctica',
    zoneName: 'Antarctica/Rothera',
    gmtOffset: -10800,
    timestamp: 1643878406,
    isdCode: '+672',
    id: 8,
  },
  {
    countryCode: 'AQ',
    countryName: 'Antarctica',
    zoneName: 'Antarctica/Syowa',
    gmtOffset: 10800,
    timestamp: 1643900006,
    isdCode: '+672',
    id: 8,
  },
  {
    countryCode: 'AQ',
    countryName: 'Antarctica',
    zoneName: 'Antarctica/Troll',
    gmtOffset: 0,
    timestamp: 1643889206,
    isdCode: '+672',
    id: 8,
  },
  {
    countryCode: 'AQ',
    countryName: 'Antarctica',
    zoneName: 'Antarctica/Vostok',
    gmtOffset: 21600,
    timestamp: 1643910806,
    isdCode: '+672',
    id: 8,
  },
  {
    countryCode: 'SJ',
    countryName: 'Svalbard and Jan Mayen',
    zoneName: 'Arctic/Longyearbyen',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+47',
    id: 201,
  },
  {
    countryCode: 'YE',
    countryName: 'Yemen',
    zoneName: 'Asia/Aden',
    gmtOffset: 10800,
    timestamp: 1643900006,
    isdCode: '+967',
    id: 235,
  },
  {
    countryCode: 'KZ',
    countryName: 'Kazakhstan',
    zoneName: 'Asia/Almaty',
    gmtOffset: 21600,
    timestamp: 1643910806,
    isdCode: '+7',
    id: 109,
  },
  {
    countryCode: 'JO',
    countryName: 'Jordan',
    zoneName: 'Asia/Amman',
    gmtOffset: 7200,
    timestamp: 1643896406,
    isdCode: '+962',
    id: 108,
  },
  {
    countryCode: 'RU',
    countryName: 'Russian Federation',
    zoneName: 'Asia/Anadyr',
    gmtOffset: 43200,
    timestamp: 1643932406,
    isdCode: '+7',
    id: 176,
  },
  {
    countryCode: 'KZ',
    countryName: 'Kazakhstan',
    zoneName: 'Asia/Aqtau',
    gmtOffset: 18000,
    timestamp: 1643907206,
    isdCode: '+7',
    id: 109,
  },
  {
    countryCode: 'KZ',
    countryName: 'Kazakhstan',
    zoneName: 'Asia/Aqtobe',
    gmtOffset: 18000,
    timestamp: 1643907206,
    isdCode: '+7',
    id: 109,
  },
  {
    countryCode: 'TM',
    countryName: 'Turkmenistan',
    zoneName: 'Asia/Ashgabat',
    gmtOffset: 18000,
    timestamp: 1643907206,
    isdCode: '+993',
    id: 216,
  },
  {
    countryCode: 'KZ',
    countryName: 'Kazakhstan',
    zoneName: 'Asia/Atyrau',
    gmtOffset: 18000,
    timestamp: 1643907206,
    isdCode: '+7',
    id: 109,
  },
  {
    countryCode: 'IQ',
    countryName: 'Iraq',
    zoneName: 'Asia/Baghdad',
    gmtOffset: 10800,
    timestamp: 1643900006,
    isdCode: '+964',
    id: 102,
  },
  {
    countryCode: 'BH',
    countryName: 'Bahrain',
    zoneName: 'Asia/Bahrain',
    gmtOffset: 10800,
    timestamp: 1643900006,
    isdCode: '+973',
    id: 17,
  },
  {
    countryCode: 'AZ',
    countryName: 'Azerbaijan',
    zoneName: 'Asia/Baku',
    gmtOffset: 14400,
    timestamp: 1643903606,
    isdCode: '+994',
    id: 15,
  },
  {
    countryCode: 'TH',
    countryName: 'Thailand',
    zoneName: 'Asia/Bangkok',
    gmtOffset: 25200,
    timestamp: 1643914406,
    isdCode: '+66',
    id: 209,
  },
  {
    countryCode: 'RU',
    countryName: 'Russian Federation',
    zoneName: 'Asia/Barnaul',
    gmtOffset: 25200,
    timestamp: 1643914406,
    isdCode: '+7',
    id: 176,
  },
  {
    countryCode: 'LB',
    countryName: 'Lebanon',
    zoneName: 'Asia/Beirut',
    gmtOffset: 7200,
    timestamp: 1643896406,
    isdCode: '+961',
    id: 118,
  },
  {
    countryCode: 'KG',
    countryName: 'Kyrgyzstan',
    zoneName: 'Asia/Bishkek',
    gmtOffset: 21600,
    timestamp: 1643910806,
    isdCode: '+996',
    id: 115,
  },
  {
    countryCode: 'BN',
    countryName: 'Brunei Darussalam',
    zoneName: 'Asia/Brunei',
    gmtOffset: 28800,
    timestamp: 1643918006,
    isdCode: '+673',
    id: 32,
  },
  {
    countryCode: 'RU',
    countryName: 'Russian Federation',
    zoneName: 'Asia/Chita',
    gmtOffset: 32400,
    timestamp: 1643921606,
    isdCode: '+7',
    id: 176,
  },
  {
    countryCode: 'MN',
    countryName: 'Mongolia',
    zoneName: 'Asia/Choibalsan',
    gmtOffset: 28800,
    timestamp: 1643918006,
    isdCode: '+976',
    id: 142,
  },
  {
    countryCode: 'LK',
    countryName: 'Sri Lanka',
    zoneName: 'Asia/Colombo',
    gmtOffset: 19800,
    timestamp: 1643909006,
    isdCode: '+94',
    id: 196,
  },
  {
    countryCode: 'SY',
    countryName: 'Syrian Arab Republic',
    zoneName: 'Asia/Damascus',
    gmtOffset: 7200,
    timestamp: 1643896406,
    isdCode: '+963',
    id: 205,
  },
  {
    countryCode: 'BD',
    countryName: 'Bangladesh',
    zoneName: 'Asia/Dhaka',
    gmtOffset: 21600,
    timestamp: 1643910806,
    isdCode: '+880',
    id: 18,
  },
  {
    countryCode: 'TL',
    countryName: 'Timor-Leste',
    zoneName: 'Asia/Dili',
    gmtOffset: 32400,
    timestamp: 1643921606,
    isdCode: '+670',
    id: 61,
  },
  {
    countryCode: 'AE',
    countryName: 'United Arab Emirates',
    zoneName: 'Asia/Dubai',
    gmtOffset: 14400,
    timestamp: 1643903606,
    isdCode: '+971',
    id: 221,
  },
  {
    countryCode: 'TJ',
    countryName: 'Tajikistan',
    zoneName: 'Asia/Dushanbe',
    gmtOffset: 18000,
    timestamp: 1643907206,
    isdCode: '+992',
    id: 207,
  },
  {
    countryCode: 'CY',
    countryName: 'Cyprus',
    zoneName: 'Asia/Famagusta',
    gmtOffset: 7200,
    timestamp: 1643896406,
    isdCode: '+357',
    id: 55,
  },
  {
    countryCode: 'PS',
    countryName: 'Palestine, State of',
    zoneName: 'Asia/Gaza',
    gmtOffset: 7200,
    timestamp: 1643896406,
    isdCode: '+970',
    id: 241,
  },
  {
    countryCode: 'PS',
    countryName: 'Palestine, State of',
    zoneName: 'Asia/Hebron',
    gmtOffset: 7200,
    timestamp: 1643896406,
    isdCode: '+970',
    id: 241,
  },
  {
    countryCode: 'VN',
    countryName: 'Viet Nam',
    zoneName: 'Asia/Ho_Chi_Minh',
    gmtOffset: 25200,
    timestamp: 1643914406,
    isdCode: '+84',
    id: 230,
  },
  {
    countryCode: 'HK',
    countryName: 'Hong Kong',
    zoneName: 'Asia/Hong_Kong',
    gmtOffset: 28800,
    timestamp: 1643918006,
    isdCode: '+852',
    id: 96,
  },
  {
    countryCode: 'MN',
    countryName: 'Mongolia',
    zoneName: 'Asia/Hovd',
    gmtOffset: 25200,
    timestamp: 1643914406,
    isdCode: '+976',
    id: 142,
  },
  {
    countryCode: 'RU',
    countryName: 'Russian Federation',
    zoneName: 'Asia/Irkutsk',
    gmtOffset: 28800,
    timestamp: 1643918006,
    isdCode: '+7',
    id: 176,
  },
  {
    countryCode: 'ID',
    countryName: 'Indonesia',
    zoneName: 'Asia/Jakarta',
    gmtOffset: 25200,
    timestamp: 1643914406,
    isdCode: '+62',
    id: 100,
  },
  {
    countryCode: 'ID',
    countryName: 'Indonesia',
    zoneName: 'Asia/Jayapura',
    gmtOffset: 32400,
    timestamp: 1643921606,
    isdCode: '+62',
    id: 100,
  },
  {
    countryCode: 'IL',
    countryName: 'Israel',
    zoneName: 'Asia/Jerusalem',
    gmtOffset: 7200,
    timestamp: 1643896406,
    isdCode: '+972',
    id: 104,
  },
  {
    countryCode: 'AF',
    countryName: 'Afghanistan',
    zoneName: 'Asia/Kabul',
    gmtOffset: 16200,
    timestamp: 1643905406,
    isdCode: '+93',
    id: 1,
  },
  {
    countryCode: 'RU',
    countryName: 'Russian Federation',
    zoneName: 'Asia/Kamchatka',
    gmtOffset: 43200,
    timestamp: 1643932406,
    isdCode: '+7',
    id: 176,
  },
  {
    countryCode: 'PK',
    countryName: 'Pakistan',
    zoneName: 'Asia/Karachi',
    gmtOffset: 18000,
    timestamp: 1643907206,
    isdCode: '+92',
    id: 162,
  },
  {
    countryCode: 'NP',
    countryName: 'Nepal',
    zoneName: 'Asia/Kathmandu',
    gmtOffset: 20700,
    timestamp: 1643909906,
    isdCode: '+977',
    id: 149,
  },
  {
    countryCode: 'NP',
    countryName: 'Nepal',
    zoneName: 'Asia/Katmandu',
    gmtOffset: 20700,
    timestamp: 1643909906,
    isdCode: '+977',
    id: 149,
  },
  {
    countryCode: 'RU',
    countryName: 'Russian Federation',
    zoneName: 'Asia/Khandyga',
    gmtOffset: 32400,
    timestamp: 1643921606,
    isdCode: '+7',
    id: 176,
  },
  {
    countryCode: 'IN',
    countryName: 'India',
    zoneName: 'Asia/Kolkata',
    gmtOffset: 19800,
    timestamp: 1643909006,
    isdCode: '+91',
    id: 99,
  },
  {
    countryCode: 'RU',
    countryName: 'Russian Federation',
    zoneName: 'Asia/Krasnoyarsk',
    gmtOffset: 25200,
    timestamp: 1643914406,
    isdCode: '+7',
    id: 176,
  },
  {
    countryCode: 'MY',
    countryName: 'Malaysia',
    zoneName: 'Asia/Kuala_Lumpur',
    gmtOffset: 28800,
    timestamp: 1643918006,
    isdCode: '+60',
    id: 129,
  },
  {
    countryCode: 'MY',
    countryName: 'Malaysia',
    zoneName: 'Asia/Kuching',
    gmtOffset: 28800,
    timestamp: 1643918006,
    isdCode: '+60',
    id: 129,
  },
  {
    countryCode: 'KW',
    countryName: 'Kuwait',
    zoneName: 'Asia/Kuwait',
    gmtOffset: 10800,
    timestamp: 1643900006,
    isdCode: '+965',
    id: 114,
  },
  {
    countryCode: 'MO',
    countryName: 'Macao',
    zoneName: 'Asia/Macau',
    gmtOffset: 28800,
    timestamp: 1643918006,
    isdCode: '+853',
    id: 125,
  },
  {
    countryCode: 'RU',
    countryName: 'Russian Federation',
    zoneName: 'Asia/Magadan',
    gmtOffset: 39600,
    timestamp: 1643928806,
    isdCode: '+7',
    id: 176,
  },
  {
    countryCode: 'ID',
    countryName: 'Indonesia',
    zoneName: 'Asia/Makassar',
    gmtOffset: 28800,
    timestamp: 1643918006,
    isdCode: '+62',
    id: 100,
  },
  {
    countryCode: 'PH',
    countryName: 'Philippines',
    zoneName: 'Asia/Manila',
    gmtOffset: 28800,
    timestamp: 1643918006,
    isdCode: '+63',
    id: 168,
  },
  {
    countryCode: 'OM',
    countryName: 'Oman',
    zoneName: 'Asia/Muscat',
    gmtOffset: 14400,
    timestamp: 1643903606,
    isdCode: '+968',
    id: 161,
  },
  {
    countryCode: 'CY',
    countryName: 'Cyprus',
    zoneName: 'Asia/Nicosia',
    gmtOffset: 7200,
    timestamp: 1643896406,
    isdCode: '+357',
    id: 55,
  },
  {
    countryCode: 'RU',
    countryName: 'Russian Federation',
    zoneName: 'Asia/Novokuznetsk',
    gmtOffset: 25200,
    timestamp: 1643914406,
    isdCode: '+7',
    id: 176,
  },
  {
    countryCode: 'RU',
    countryName: 'Russian Federation',
    zoneName: 'Asia/Novosibirsk',
    gmtOffset: 25200,
    timestamp: 1643914406,
    isdCode: '+7',
    id: 176,
  },
  {
    countryCode: 'RU',
    countryName: 'Russian Federation',
    zoneName: 'Asia/Omsk',
    gmtOffset: 21600,
    timestamp: 1643910806,
    isdCode: '+7',
    id: 176,
  },
  {
    countryCode: 'KZ',
    countryName: 'Kazakhstan',
    zoneName: 'Asia/Oral',
    gmtOffset: 18000,
    timestamp: 1643907206,
    isdCode: '+7',
    id: 109,
  },
  {
    countryCode: 'KH',
    countryName: 'Cambodia',
    zoneName: 'Asia/Phnom_Penh',
    gmtOffset: 25200,
    timestamp: 1643914406,
    isdCode: '+855',
    id: 36,
  },
  {
    countryCode: 'ID',
    countryName: 'Indonesia',
    zoneName: 'Asia/Pontianak',
    gmtOffset: 25200,
    timestamp: 1643914406,
    isdCode: '+62',
    id: 100,
  },
  {
    countryCode: 'KP',
    countryName: "Korea (Democratic People's Republic of)",
    zoneName: 'Asia/Pyongyang',
    gmtOffset: 32400,
    timestamp: 1643921606,
    isdCode: '+850',
    id: 112,
  },
  {
    countryCode: 'QA',
    countryName: 'Qatar',
    zoneName: 'Asia/Qatar',
    gmtOffset: 10800,
    timestamp: 1643900006,
    isdCode: '+974',
    id: 173,
  },
  {
    countryCode: 'KZ',
    countryName: 'Kazakhstan',
    zoneName: 'Asia/Qostanay',
    gmtOffset: 21600,
    timestamp: 1643910806,
    isdCode: '+7',
    id: 109,
  },
  {
    countryCode: 'KZ',
    countryName: 'Kazakhstan',
    zoneName: 'Asia/Qyzylorda',
    gmtOffset: 18000,
    timestamp: 1643907206,
    isdCode: '+7',
    id: 109,
  },
  {
    countryCode: 'SA',
    countryName: 'Saudi Arabia',
    zoneName: 'Asia/Riyadh',
    gmtOffset: 10800,
    timestamp: 1643900006,
    isdCode: '+966',
    id: 184,
  },
  {
    countryCode: 'RU',
    countryName: 'Russian Federation',
    zoneName: 'Asia/Sakhalin',
    gmtOffset: 39600,
    timestamp: 1643928806,
    isdCode: '+7',
    id: 176,
  },
  {
    countryCode: 'UZ',
    countryName: 'Uzbekistan',
    zoneName: 'Asia/Samarkand',
    gmtOffset: 18000,
    timestamp: 1643907206,
    isdCode: '+998',
    id: 226,
  },
  {
    countryCode: 'KR',
    countryName: 'Korea, Republic of',
    zoneName: 'Asia/Seoul',
    gmtOffset: 32400,
    timestamp: 1643921606,
    isdCode: '+82',
    id: 113,
  },
  {
    countryCode: 'CN',
    countryName: 'China',
    zoneName: 'Asia/Shanghai',
    gmtOffset: 28800,
    timestamp: 1643918006,
    isdCode: '+86',
    id: 44,
  },
  {
    countryCode: 'SG',
    countryName: 'Singapore',
    zoneName: 'Asia/Singapore',
    gmtOffset: 28800,
    timestamp: 1643918006,
    isdCode: '+65',
    id: 188,
  },
  {
    countryCode: 'RU',
    countryName: 'Russian Federation',
    zoneName: 'Asia/Srednekolymsk',
    gmtOffset: 39600,
    timestamp: 1643928806,
    isdCode: '+7',
    id: 176,
  },
  {
    countryCode: 'TW',
    countryName: 'Taiwan, Province of China',
    zoneName: 'Asia/Taipei',
    gmtOffset: 28800,
    timestamp: 1643918006,
    isdCode: '+886',
    id: 206,
  },
  {
    countryCode: 'UZ',
    countryName: 'Uzbekistan',
    zoneName: 'Asia/Tashkent',
    gmtOffset: 18000,
    timestamp: 1643907206,
    isdCode: '+998',
    id: 226,
  },
  {
    countryCode: 'GE',
    countryName: 'Georgia',
    zoneName: 'Asia/Tbilisi',
    gmtOffset: 14400,
    timestamp: 1643903606,
    isdCode: '+995',
    id: 80,
  },
  {
    countryCode: 'IR',
    countryName: 'Iran (Islamic Republic of)',
    zoneName: 'Asia/Tehran',
    gmtOffset: 12600,
    timestamp: 1643901806,
    isdCode: '+98',
    id: 101,
  },
  {
    countryCode: 'BT',
    countryName: 'Bhutan',
    zoneName: 'Asia/Thimphu',
    gmtOffset: 21600,
    timestamp: 1643910806,
    isdCode: '+975',
    id: 25,
  },
  {
    countryCode: 'JP',
    countryName: 'Japan',
    zoneName: 'Asia/Tokyo',
    gmtOffset: 32400,
    timestamp: 1643921606,
    isdCode: '+81',
    id: 107,
  },
  {
    countryCode: 'RU',
    countryName: 'Russian Federation',
    zoneName: 'Asia/Tomsk',
    gmtOffset: 25200,
    timestamp: 1643914406,
    isdCode: '+7',
    id: 176,
  },
  {
    countryCode: 'MN',
    countryName: 'Mongolia',
    zoneName: 'Asia/Ulaanbaatar',
    gmtOffset: 28800,
    timestamp: 1643918006,
    isdCode: '+976',
    id: 142,
  },
  {
    countryCode: 'CN',
    countryName: 'China',
    zoneName: 'Asia/Urumqi',
    gmtOffset: 21600,
    timestamp: 1643910806,
    isdCode: '+86',
    id: 44,
  },
  {
    countryCode: 'RU',
    countryName: 'Russian Federation',
    zoneName: 'Asia/Ust-Nera',
    gmtOffset: 36000,
    timestamp: 1643925206,
    isdCode: '+7',
    id: 176,
  },
  {
    countryCode: 'LA',
    countryName: "Lao People's Democratic Republic",
    zoneName: 'Asia/Vientiane',
    gmtOffset: 25200,
    timestamp: 1643914406,
    isdCode: '+856',
    id: 116,
  },
  {
    countryCode: 'RU',
    countryName: 'Russian Federation',
    zoneName: 'Asia/Vladivostok',
    gmtOffset: 36000,
    timestamp: 1643925206,
    isdCode: '+7',
    id: 176,
  },
  {
    countryCode: 'RU',
    countryName: 'Russian Federation',
    zoneName: 'Asia/Yakutsk',
    gmtOffset: 32400,
    timestamp: 1643921606,
    isdCode: '+7',
    id: 176,
  },
  {
    countryCode: 'MM',
    countryName: 'Myanmar',
    zoneName: 'Asia/Yangon',
    gmtOffset: 23400,
    timestamp: 1643912606,
    isdCode: '+95',
    id: 146,
  },
  {
    countryCode: 'RU',
    countryName: 'Russian Federation',
    zoneName: 'Asia/Yekaterinburg',
    gmtOffset: 18000,
    timestamp: 1643907206,
    isdCode: '+7',
    id: 176,
  },
  {
    countryCode: 'AM',
    countryName: 'Armenia',
    zoneName: 'Asia/Yerevan',
    gmtOffset: 14400,
    timestamp: 1643903606,
    isdCode: '+374',
    id: 11,
  },
  {
    countryCode: 'PT',
    countryName: 'Portugal',
    zoneName: 'Atlantic/Azores',
    gmtOffset: -3600,
    timestamp: 1643885606,
    isdCode: '+351',
    id: 171,
  },
  {
    countryCode: 'BM',
    countryName: 'Bermuda',
    zoneName: 'Atlantic/Bermuda',
    gmtOffset: -14400,
    timestamp: 1643874806,
    isdCode: '+1-441',
    id: 24,
  },
  {
    countryCode: 'ES',
    countryName: 'Spain',
    zoneName: 'Atlantic/Canary',
    gmtOffset: 0,
    timestamp: 1643889206,
    isdCode: '+34',
    id: 195,
  },
  {
    countryCode: 'CV',
    countryName: 'Cabo Verde',
    zoneName: 'Atlantic/Cape_Verde',
    gmtOffset: -3600,
    timestamp: 1643885606,
    isdCode: '+238',
    id: 39,
  },
  {
    countryCode: 'FO',
    countryName: 'Faroe Islands',
    zoneName: 'Atlantic/Faroe',
    gmtOffset: 0,
    timestamp: 1643889206,
    isdCode: '+298',
    id: 70,
  },
  {
    countryCode: 'PT',
    countryName: 'Portugal',
    zoneName: 'Atlantic/Madeira',
    gmtOffset: 0,
    timestamp: 1643889206,
    isdCode: '+351',
    id: 171,
  },
  {
    countryCode: 'IS',
    countryName: 'Iceland',
    zoneName: 'Atlantic/Reykjavik',
    gmtOffset: 0,
    timestamp: 1643889206,
    isdCode: '+354',
    id: 98,
  },
  {
    countryCode: 'GS',
    countryName: 'South Georgia and the South Sandwich Islands',
    zoneName: 'Atlantic/South_Georgia',
    gmtOffset: -7200,
    timestamp: 1643882006,
    isdCode: '+500',
    id: 194,
  },
  {
    countryCode: 'SH',
    countryName: 'Saint Helena, Ascension and Tristan da Cunha',
    zoneName: 'Atlantic/St_Helena',
    gmtOffset: 0,
    timestamp: 1643889206,
    isdCode: '+290',
    id: 197,
  },
  {
    countryCode: 'FK',
    countryName: 'Falkland Islands (Malvinas)',
    zoneName: 'Atlantic/Stanley',
    gmtOffset: -10800,
    timestamp: 1643878406,
    isdCode: '+500',
    id: 69,
  },
  {
    countryCode: 'AU',
    countryName: 'Australia',
    zoneName: 'Australia/Adelaide',
    gmtOffset: 37800,
    timestamp: 1643927006,
    isdCode: '+61',
    id: 13,
  },
  {
    countryCode: 'AU',
    countryName: 'Australia',
    zoneName: 'Australia/Brisbane',
    gmtOffset: 36000,
    timestamp: 1643925206,
    isdCode: '+61',
    id: 13,
  },
  {
    countryCode: 'AU',
    countryName: 'Australia',
    zoneName: 'Australia/Broken_Hill',
    gmtOffset: 37800,
    timestamp: 1643927006,
    isdCode: '+61',
    id: 13,
  },
  {
    countryCode: 'AU',
    countryName: 'Australia',
    zoneName: 'Australia/Darwin',
    gmtOffset: 34200,
    timestamp: 1643923406,
    isdCode: '+61',
    id: 13,
  },
  {
    countryCode: 'AU',
    countryName: 'Australia',
    zoneName: 'Australia/Eucla',
    gmtOffset: 31500,
    timestamp: 1643920706,
    isdCode: '+61',
    id: 13,
  },
  {
    countryCode: 'AU',
    countryName: 'Australia',
    zoneName: 'Australia/Hobart',
    gmtOffset: 39600,
    timestamp: 1643928806,
    isdCode: '+61',
    id: 13,
  },
  {
    countryCode: 'AU',
    countryName: 'Australia',
    zoneName: 'Australia/Lindeman',
    gmtOffset: 36000,
    timestamp: 1643925206,
    isdCode: '+61',
    id: 13,
  },
  {
    countryCode: 'AU',
    countryName: 'Australia',
    zoneName: 'Australia/Lord_Howe',
    gmtOffset: 39600,
    timestamp: 1643928806,
    isdCode: '+61',
    id: 13,
  },
  {
    countryCode: 'AU',
    countryName: 'Australia',
    zoneName: 'Australia/Melbourne',
    gmtOffset: 39600,
    timestamp: 1643928806,
    isdCode: '+61',
    id: 13,
  },
  {
    countryCode: 'AU',
    countryName: 'Australia',
    zoneName: 'Australia/Perth',
    gmtOffset: 28800,
    timestamp: 1643918006,
    isdCode: '+61',
    id: 13,
  },
  {
    countryCode: 'AU',
    countryName: 'Australia',
    zoneName: 'Australia/Sydney',
    gmtOffset: 39600,
    timestamp: 1643928806,
    isdCode: '+61',
    id: 13,
  },
  {
    countryCode: 'NL',
    countryName: 'Netherlands',
    zoneName: 'Europe/Amsterdam',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+31',
    id: 150,
  },
  {
    countryCode: 'AD',
    countryName: 'Andorra',
    zoneName: 'Europe/Andorra',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+376',
    id: 5,
  },
  {
    countryCode: 'RU',
    countryName: 'Russian Federation',
    zoneName: 'Europe/Astrakhan',
    gmtOffset: 14400,
    timestamp: 1643903606,
    isdCode: '+7',
    id: 176,
  },
  {
    countryCode: 'GR',
    countryName: 'Greece',
    zoneName: 'Europe/Athens',
    gmtOffset: 7200,
    timestamp: 1643896406,
    isdCode: '+30',
    id: 84,
  },
  {
    countryCode: 'RS',
    countryName: 'Serbia',
    zoneName: 'Europe/Belgrade',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+381',
    id: 236,
  },
  {
    countryCode: 'DE',
    countryName: 'Germany',
    zoneName: 'Europe/Berlin',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+49',
    id: 81,
  },
  {
    countryCode: 'SK',
    countryName: 'Slovakia',
    zoneName: 'Europe/Bratislava',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+421',
    id: 189,
  },
  {
    countryCode: 'BE',
    countryName: 'Belgium',
    zoneName: 'Europe/Brussels',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+32',
    id: 21,
  },
  {
    countryCode: 'RO',
    countryName: 'Romania',
    zoneName: 'Europe/Bucharest',
    gmtOffset: 7200,
    timestamp: 1643896406,
    isdCode: '+40',
    id: 175,
  },
  {
    countryCode: 'HU',
    countryName: 'Hungary',
    zoneName: 'Europe/Budapest',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+36',
    id: 97,
  },
  {
    countryCode: 'DE',
    countryName: 'Germany',
    zoneName: 'Europe/Busingen',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+49',
    id: 81,
  },
  {
    countryCode: 'MD',
    countryName: 'Moldova, Republic of',
    zoneName: 'Europe/Chisinau',
    gmtOffset: 7200,
    timestamp: 1643896406,
    isdCode: '+373',
    id: 140,
  },
  {
    countryCode: 'DK',
    countryName: 'Denmark',
    zoneName: 'Europe/Copenhagen',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+45',
    id: 57,
  },
  {
    countryCode: 'IE',
    countryName: 'Ireland',
    zoneName: 'Europe/Dublin',
    gmtOffset: 0,
    timestamp: 1643889206,
    isdCode: '+353',
    id: 103,
  },
  {
    countryCode: 'GI',
    countryName: 'Gibraltar',
    zoneName: 'Europe/Gibraltar',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+350',
    id: 83,
  },
  {
    countryCode: 'GG',
    countryName: 'Guernsey',
    zoneName: 'Europe/Guernsey',
    gmtOffset: 0,
    timestamp: 1643889206,
    isdCode: '+44-1481',
    id: 243,
  },
  {
    countryCode: 'FI',
    countryName: 'Finland',
    zoneName: 'Europe/Helsinki',
    gmtOffset: 7200,
    timestamp: 1643896406,
    isdCode: '+358',
    id: 72,
  },
  {
    countryCode: 'IM',
    countryName: 'Isle of Man',
    zoneName: 'Europe/Isle_of_Man',
    gmtOffset: 0,
    timestamp: 1643889206,
    isdCode: '+44-1624',
    id: 244,
  },
  {
    countryCode: 'TR',
    countryName: 'Turkey',
    zoneName: 'Europe/Istanbul',
    gmtOffset: 10800,
    timestamp: 1643900006,
    isdCode: '+90',
    id: 215,
  },
  {
    countryCode: 'JE',
    countryName: 'Jersey',
    zoneName: 'Europe/Jersey',
    gmtOffset: 0,
    timestamp: 1643889206,
    isdCode: '+44-1534',
    id: 245,
  },
  {
    countryCode: 'RU',
    countryName: 'Russian Federation',
    zoneName: 'Europe/Kaliningrad',
    gmtOffset: 7200,
    timestamp: 1643896406,
    isdCode: '+7',
    id: 176,
  },
  {
    countryCode: 'UA',
    countryName: 'Ukraine',
    zoneName: 'Europe/Kiev',
    gmtOffset: 7200,
    timestamp: 1643896406,
    isdCode: '+380',
    id: 220,
  },
  {
    countryCode: 'RU',
    countryName: 'Russian Federation',
    zoneName: 'Europe/Kirov',
    gmtOffset: 10800,
    timestamp: 1643900006,
    isdCode: '+7',
    id: 176,
  },
  {
    countryCode: 'PT',
    countryName: 'Portugal',
    zoneName: 'Europe/Lisbon',
    gmtOffset: 0,
    timestamp: 1643889206,
    isdCode: '+351',
    id: 171,
  },
  {
    countryCode: 'SI',
    countryName: 'Slovenia',
    zoneName: 'Europe/Ljubljana',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+386',
    id: 190,
  },
  {
    countryCode: 'GB',
    countryName: 'United Kingdom of Great Britain and Northern Ireland',
    zoneName: 'Europe/London',
    gmtOffset: 0,
    timestamp: 1643889206,
    isdCode: '+44',
    id: 222,
  },
  {
    countryCode: 'LU',
    countryName: 'Luxembourg',
    zoneName: 'Europe/Luxembourg',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+352',
    id: 124,
  },
  {
    countryCode: 'ES',
    countryName: 'Spain',
    zoneName: 'Europe/Madrid',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+34',
    id: 195,
  },
  {
    countryCode: 'MT',
    countryName: 'Malta',
    zoneName: 'Europe/Malta',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+356',
    id: 132,
  },
  {
    countryCode: 'AX',
    countryName: 'Åland Islands',
    zoneName: 'Europe/Mariehamn',
    gmtOffset: 7200,
    timestamp: 1643896406,
    isdCode: '+358',
    id: 240,
  },
  {
    countryCode: 'BY',
    countryName: 'Belarus',
    zoneName: 'Europe/Minsk',
    gmtOffset: 10800,
    timestamp: 1643900006,
    isdCode: '+375',
    id: 20,
  },
  {
    countryCode: 'MC',
    countryName: 'Monaco',
    zoneName: 'Europe/Monaco',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+377',
    id: 141,
  },
  {
    countryCode: 'RU',
    countryName: 'Russian Federation',
    zoneName: 'Europe/Moscow',
    gmtOffset: 10800,
    timestamp: 1643900006,
    isdCode: '+7',
    id: 176,
  },
  {
    countryCode: 'NO',
    countryName: 'Norway',
    zoneName: 'Europe/Oslo',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+47',
    id: 160,
  },
  {
    countryCode: 'FR',
    countryName: 'France',
    zoneName: 'Europe/Paris',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+33',
    id: 73,
  },
  {
    countryCode: 'ME',
    countryName: 'Montenegro',
    zoneName: 'Europe/Podgorica',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+382',
    id: 242,
  },
  {
    countryCode: 'CZ',
    countryName: 'Czechia',
    zoneName: 'Europe/Prague',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+420',
    id: 56,
  },
  {
    countryCode: 'LV',
    countryName: 'Latvia',
    zoneName: 'Europe/Riga',
    gmtOffset: 7200,
    timestamp: 1643896406,
    isdCode: '+371',
    id: 117,
  },
  {
    countryCode: 'IT',
    countryName: 'Italy',
    zoneName: 'Europe/Rome',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+39',
    id: 105,
  },
  {
    countryCode: 'RU',
    countryName: 'Russian Federation',
    zoneName: 'Europe/Samara',
    gmtOffset: 14400,
    timestamp: 1643903606,
    isdCode: '+7',
    id: 176,
  },
  {
    countryCode: 'SM',
    countryName: 'San Marino',
    zoneName: 'Europe/San_Marino',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+378',
    id: 182,
  },
  {
    countryCode: 'BA',
    countryName: 'Bosnia and Herzegovina',
    zoneName: 'Europe/Sarajevo',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+387',
    id: 27,
  },
  {
    countryCode: 'RU',
    countryName: 'Russian Federation',
    zoneName: 'Europe/Saratov',
    gmtOffset: 14400,
    timestamp: 1643903606,
    isdCode: '+7',
    id: 176,
  },
  {
    countryCode: 'UA',
    countryName: 'Ukraine',
    zoneName: 'Europe/Simferopol',
    gmtOffset: 10800,
    timestamp: 1643900006,
    isdCode: '+380',
    id: 220,
  },
  {
    countryCode: 'MK',
    countryName: 'North Macedonia',
    zoneName: 'Europe/Skopje',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+389',
    id: 126,
  },
  {
    countryCode: 'BG',
    countryName: 'Bulgaria',
    zoneName: 'Europe/Sofia',
    gmtOffset: 7200,
    timestamp: 1643896406,
    isdCode: '+359',
    id: 33,
  },
  {
    countryCode: 'SE',
    countryName: 'Sweden',
    zoneName: 'Europe/Stockholm',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+46',
    id: 203,
  },
  {
    countryCode: 'EE',
    countryName: 'Estonia',
    zoneName: 'Europe/Tallinn',
    gmtOffset: 7200,
    timestamp: 1643896406,
    isdCode: '+372',
    id: 67,
  },
  {
    countryCode: 'AL',
    countryName: 'Albania',
    zoneName: 'Europe/Tirane',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+355',
    id: 2,
  },
  {
    countryCode: 'RU',
    countryName: 'Russian Federation',
    zoneName: 'Europe/Ulyanovsk',
    gmtOffset: 14400,
    timestamp: 1643903606,
    isdCode: '+7',
    id: 176,
  },
  {
    countryCode: 'UA',
    countryName: 'Ukraine',
    zoneName: 'Europe/Uzhgorod',
    gmtOffset: 7200,
    timestamp: 1643896406,
    isdCode: '+380',
    id: 220,
  },
  {
    countryCode: 'LI',
    countryName: 'Liechtenstein',
    zoneName: 'Europe/Vaduz',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+423',
    id: 122,
  },
  {
    countryCode: 'VA',
    countryName: 'Holy See',
    zoneName: 'Europe/Vatican',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+379',
    id: 228,
  },
  {
    countryCode: 'AT',
    countryName: 'Austria',
    zoneName: 'Europe/Vienna',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+43',
    id: 14,
  },
  {
    countryCode: 'LT',
    countryName: 'Lithuania',
    zoneName: 'Europe/Vilnius',
    gmtOffset: 7200,
    timestamp: 1643896406,
    isdCode: '+370',
    id: 123,
  },
  {
    countryCode: 'RU',
    countryName: 'Russian Federation',
    zoneName: 'Europe/Volgograd',
    gmtOffset: 10800,
    timestamp: 1643900006,
    isdCode: '+7',
    id: 176,
  },
  {
    countryCode: 'PL',
    countryName: 'Poland',
    zoneName: 'Europe/Warsaw',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+48',
    id: 170,
  },
  {
    countryCode: 'HR',
    countryName: 'Croatia',
    zoneName: 'Europe/Zagreb',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+385',
    id: 53,
  },
  {
    countryCode: 'UA',
    countryName: 'Ukraine',
    zoneName: 'Europe/Zaporozhye',
    gmtOffset: 7200,
    timestamp: 1643896406,
    isdCode: '+380',
    id: 220,
  },
  {
    countryCode: 'CH',
    countryName: 'Switzerland',
    zoneName: 'Europe/Zurich',
    gmtOffset: 3600,
    timestamp: 1643892806,
    isdCode: '+41',
    id: 204,
  },
  {
    countryCode: 'MG',
    countryName: 'Madagascar',
    zoneName: 'Indian/Antananarivo',
    gmtOffset: 10800,
    timestamp: 1643900006,
    isdCode: '+261',
    id: 127,
  },
  {
    countryCode: 'IO',
    countryName: 'British Indian Ocean Territory',
    zoneName: 'Indian/Chagos',
    gmtOffset: 21600,
    timestamp: 1643910806,
    isdCode: '+246',
    id: 31,
  },
  {
    countryCode: 'CX',
    countryName: 'Christmas Island',
    zoneName: 'Indian/Christmas',
    gmtOffset: 25200,
    timestamp: 1643914406,
    isdCode: '+61',
    id: 45,
  },
  {
    countryCode: 'CC',
    countryName: 'Cocos (Keeling) Islands',
    zoneName: 'Indian/Cocos',
    gmtOffset: 23400,
    timestamp: 1643912606,
    isdCode: '+61',
    id: 46,
  },
  {
    countryCode: 'KM',
    countryName: 'Comoros',
    zoneName: 'Indian/Comoro',
    gmtOffset: 10800,
    timestamp: 1643900006,
    isdCode: '+269',
    id: 48,
  },
  {
    countryCode: 'TF',
    countryName: 'French Southern Territories',
    zoneName: 'Indian/Kerguelen',
    gmtOffset: 18000,
    timestamp: 1643907206,
    isdCode: null,
    id: 77,
  },
  {
    countryCode: 'SC',
    countryName: 'Seychelles',
    zoneName: 'Indian/Mahe',
    gmtOffset: 14400,
    timestamp: 1643903606,
    isdCode: '+248',
    id: 186,
  },
  {
    countryCode: 'MV',
    countryName: 'Maldives',
    zoneName: 'Indian/Maldives',
    gmtOffset: 18000,
    timestamp: 1643907206,
    isdCode: '+960',
    id: 130,
  },
  {
    countryCode: 'MU',
    countryName: 'Mauritius',
    zoneName: 'Indian/Mauritius',
    gmtOffset: 14400,
    timestamp: 1643903606,
    isdCode: '+230',
    id: 136,
  },
  {
    countryCode: 'YT',
    countryName: 'Mayotte',
    zoneName: 'Indian/Mayotte',
    gmtOffset: 10800,
    timestamp: 1643900006,
    isdCode: '+262',
    id: 137,
  },
  {
    countryCode: 'RE',
    countryName: 'Réunion',
    zoneName: 'Indian/Reunion',
    gmtOffset: 14400,
    timestamp: 1643903606,
    isdCode: '+262',
    id: 174,
  },
  {
    countryCode: 'WS',
    countryName: 'Samoa',
    zoneName: 'Pacific/Apia',
    gmtOffset: 46800,
    timestamp: 1643936006,
    isdCode: '+685',
    id: 181,
  },
  {
    countryCode: 'NZ',
    countryName: 'New Zealand',
    zoneName: 'Pacific/Auckland',
    gmtOffset: 46800,
    timestamp: 1643936006,
    isdCode: '+64',
    id: 153,
  },
  {
    countryCode: 'PG',
    countryName: 'Papua New Guinea',
    zoneName: 'Pacific/Bougainville',
    gmtOffset: 39600,
    timestamp: 1643928806,
    isdCode: '+675',
    id: 165,
  },
  {
    countryCode: 'NZ',
    countryName: 'New Zealand',
    zoneName: 'Pacific/Chatham',
    gmtOffset: 49500,
    timestamp: 1643938706,
    isdCode: '+64',
    id: 153,
  },
  {
    countryCode: 'FM',
    countryName: 'Micronesia (Federated States of)',
    zoneName: 'Pacific/Chuuk',
    gmtOffset: 36000,
    timestamp: 1643925206,
    isdCode: '+691',
    id: 139,
  },
  {
    countryCode: 'CL',
    countryName: 'Chile',
    zoneName: 'Pacific/Easter',
    gmtOffset: -18000,
    timestamp: 1643871206,
    isdCode: '+56',
    id: 43,
  },
  {
    countryCode: 'VU',
    countryName: 'Vanuatu',
    zoneName: 'Pacific/Efate',
    gmtOffset: 39600,
    timestamp: 1643928806,
    isdCode: '+678',
    id: 227,
  },
  {
    countryCode: 'TK',
    countryName: 'Tokelau',
    zoneName: 'Pacific/Fakaofo',
    gmtOffset: 46800,
    timestamp: 1643936006,
    isdCode: '+690',
    id: 211,
  },
  {
    countryCode: 'FJ',
    countryName: 'Fiji',
    zoneName: 'Pacific/Fiji',
    gmtOffset: 43200,
    timestamp: 1643932406,
    isdCode: '+679',
    id: 71,
  },
  {
    countryCode: 'TV',
    countryName: 'Tuvalu',
    zoneName: 'Pacific/Funafuti',
    gmtOffset: 43200,
    timestamp: 1643932406,
    isdCode: '+688',
    id: 218,
  },
  {
    countryCode: 'EC',
    countryName: 'Ecuador',
    zoneName: 'Pacific/Galapagos',
    gmtOffset: -21600,
    timestamp: 1643867606,
    isdCode: '+593',
    id: 62,
  },
  {
    countryCode: 'PF',
    countryName: 'French Polynesia',
    zoneName: 'Pacific/Gambier',
    gmtOffset: -32400,
    timestamp: 1643856806,
    isdCode: '+689',
    id: 76,
  },
  {
    countryCode: 'SB',
    countryName: 'Solomon Islands',
    zoneName: 'Pacific/Guadalcanal',
    gmtOffset: 39600,
    timestamp: 1643928806,
    isdCode: '+677',
    id: 191,
  },
  {
    countryCode: 'GU',
    countryName: 'Guam',
    zoneName: 'Pacific/Guam',
    gmtOffset: 36000,
    timestamp: 1643925206,
    isdCode: '+1-671',
    id: 88,
  },
  {
    countryCode: 'US',
    countryName: 'United States of America',
    zoneName: 'Pacific/Honolulu',
    gmtOffset: -36000,
    timestamp: 1643853206,
    isdCode: '+1',
    id: 223,
  },
  {
    countryCode: 'KI',
    countryName: 'Kiribati',
    zoneName: 'Pacific/Kanton',
    gmtOffset: 46800,
    timestamp: 1643936006,
    isdCode: '+686',
    id: 111,
  },
  {
    countryCode: 'KI',
    countryName: 'Kiribati',
    zoneName: 'Pacific/Kiritimati',
    gmtOffset: 50400,
    timestamp: 1643939606,
    isdCode: '+686',
    id: 111,
  },
  {
    countryCode: 'FM',
    countryName: 'Micronesia (Federated States of)',
    zoneName: 'Pacific/Kosrae',
    gmtOffset: 39600,
    timestamp: 1643928806,
    isdCode: '+691',
    id: 139,
  },
  {
    countryCode: 'MH',
    countryName: 'Marshall Islands',
    zoneName: 'Pacific/Kwajalein',
    gmtOffset: 43200,
    timestamp: 1643932406,
    isdCode: '+692',
    id: 133,
  },
  {
    countryCode: 'MH',
    countryName: 'Marshall Islands',
    zoneName: 'Pacific/Majuro',
    gmtOffset: 43200,
    timestamp: 1643932406,
    isdCode: '+692',
    id: 133,
  },
  {
    countryCode: 'PF',
    countryName: 'French Polynesia',
    zoneName: 'Pacific/Marquesas',
    gmtOffset: -34200,
    timestamp: 1643855006,
    isdCode: '+689',
    id: 76,
  },
  {
    countryCode: 'UM',
    countryName: 'United States Minor Outlying Islands',
    zoneName: 'Pacific/Midway',
    gmtOffset: -39600,
    timestamp: 1643849606,
    isdCode: '+246',
    id: 224,
  },
  {
    countryCode: 'NR',
    countryName: 'Nauru',
    zoneName: 'Pacific/Nauru',
    gmtOffset: 43200,
    timestamp: 1643932406,
    isdCode: '+674',
    id: 148,
  },
  {
    countryCode: 'NU',
    countryName: 'Niue',
    zoneName: 'Pacific/Niue',
    gmtOffset: -39600,
    timestamp: 1643849606,
    isdCode: '+683',
    id: 157,
  },
  {
    countryCode: 'NF',
    countryName: 'Norfolk Island',
    zoneName: 'Pacific/Norfolk',
    gmtOffset: 43200,
    timestamp: 1643932406,
    isdCode: '+672',
    id: 158,
  },
  {
    countryCode: 'NC',
    countryName: 'New Caledonia',
    zoneName: 'Pacific/Noumea',
    gmtOffset: 39600,
    timestamp: 1643928806,
    isdCode: '+687',
    id: 152,
  },
  {
    countryCode: 'AS',
    countryName: 'American Samoa',
    zoneName: 'Pacific/Pago_Pago',
    gmtOffset: -39600,
    timestamp: 1643849606,
    isdCode: '+1-684',
    id: 4,
  },
  {
    countryCode: 'PW',
    countryName: 'Palau',
    zoneName: 'Pacific/Palau',
    gmtOffset: 32400,
    timestamp: 1643921606,
    isdCode: '+680',
    id: 163,
  },
  {
    countryCode: 'PN',
    countryName: 'Pitcairn',
    zoneName: 'Pacific/Pitcairn',
    gmtOffset: -28800,
    timestamp: 1643860406,
    isdCode: '+64',
    id: 169,
  },
  {
    countryCode: 'FM',
    countryName: 'Micronesia (Federated States of)',
    zoneName: 'Pacific/Pohnpei',
    gmtOffset: 39600,
    timestamp: 1643928806,
    isdCode: '+691',
    id: 139,
  },
  {
    countryCode: 'PG',
    countryName: 'Papua New Guinea',
    zoneName: 'Pacific/Port_Moresby',
    gmtOffset: 36000,
    timestamp: 1643925206,
    isdCode: '+675',
    id: 165,
  },
  {
    countryCode: 'CK',
    countryName: 'Cook Islands',
    zoneName: 'Pacific/Rarotonga',
    gmtOffset: -36000,
    timestamp: 1643853206,
    isdCode: '+682',
    id: 50,
  },
  {
    countryCode: 'MP',
    countryName: 'Northern Mariana Islands',
    zoneName: 'Pacific/Saipan',
    gmtOffset: 36000,
    timestamp: 1643925206,
    isdCode: '+1-670',
    id: 159,
  },
  {
    countryCode: 'PF',
    countryName: 'French Polynesia',
    zoneName: 'Pacific/Tahiti',
    gmtOffset: -36000,
    timestamp: 1643853206,
    isdCode: '+689',
    id: 76,
  },
  {
    countryCode: 'KI',
    countryName: 'Kiribati',
    zoneName: 'Pacific/Tarawa',
    gmtOffset: 43200,
    timestamp: 1643932406,
    isdCode: '+686',
    id: 111,
  },
  {
    countryCode: 'TO',
    countryName: 'Tonga',
    zoneName: 'Pacific/Tongatapu',
    gmtOffset: 46800,
    timestamp: 1643936006,
    isdCode: '+676',
    id: 212,
  },
  {
    countryCode: 'UM',
    countryName: 'United States Minor Outlying Islands',
    zoneName: 'Pacific/Wake',
    gmtOffset: 43200,
    timestamp: 1643932406,
    isdCode: '+246',
    id: 224,
  },
  {
    countryCode: 'WF',
    countryName: 'Wallis and Futuna',
    zoneName: 'Pacific/Wallis',
    gmtOffset: 43200,
    timestamp: 1643932406,
    isdCode: '+681',
    id: 233,
  },
]
