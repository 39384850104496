import React from "react";

const IconColoredContact = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="63"
      viewBox="0 0 70 63"
    >
      <g
        id="Group_3299"
        dataname="Group 3299"
        transform="translate(-451 -1101)"
      >
        <rect
          id="Rectangle_1675"
          dataname="Rectangle 1675"
          width="70"
          height="63"
          rx="5"
          transform="translate(451 1101)"
          fill="#f8dae9"
        />
        <g
          id="Group_2983"
          dataname="Group 2983"
          transform="translate(-1180.973 528.182)"
        >
          <g
            id="Group_2983-2"
            dataname="Group 2983"
            transform="translate(1655.448 589.318)"
          >
            <path
              id="Path_10227"
              dataname="Path 10227"
              d="M1676.7,605.632a12.068,12.068,0,0,0,1-4.79,11.525,11.525,0,0,0-23.049,0,12.2,12.2,0,0,0,1.289,5.43m6.551,7.141a26.1,26.1,0,0,0,2.72,1.64,2.08,2.08,0,0,0,1.93,0,25.431,25.431,0,0,0,6.119-4.458,16.194,16.194,0,0,0,3.445-4.963m-20.764.641a16.631,16.631,0,0,0,3.151,4.322,23.784,23.784,0,0,0,3.4,2.818"
              transform="translate(-1654.643 -589.318)"
              fill="none"
              stroke="#ea026e"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
          </g>
          <path
            id="Path_10228"
            dataname="Path 10228"
            d="M1678.949,605.318c1.214.7,1.853,1.5,1.853,2.3s-.639,1.6-1.853,2.3a16.8,16.8,0,0,1-5.062,1.687,39.014,39.014,0,0,1-13.83,0,16.8,16.8,0,0,1-5.062-1.687c-1.214-.7-1.853-1.5-1.853-2.3s.639-1.6,1.853-2.3"
            transform="translate(0 8.586)"
            fill="none"
            stroke="#ea026e"
            strokeLinecap="round"
            strokeWidth="2"
          />
          <g
            id="Group_2867"
            dataname="Group 2867"
            transform="translate(1659.936 593.378)"
          >
            <path
              id="Path_9291"
              dataname="Path 9291"
              d="M1671.637,604.695a5.753,5.753,0,0,0-2.437-3.645,8.025,8.025,0,0,0-4.711-1.389,7.929,7.929,0,0,0-4.65,1.54,5.638,5.638,0,0,0-2.274,3.713"
              transform="translate(-1657.564 -587.829)"
              fill="none"
              stroke="#ea026e"
              strokeLinecap="round"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
            <circle
              id="Ellipse_295"
              dataname="Ellipse 295"
              cx="3.55"
              cy="3.55"
              r="3.55"
              transform="translate(3.506 2)"
              fill="none"
              stroke="#ea026e"
              strokeLinecap="round"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconColoredContact;
