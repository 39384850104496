import React, { useState, useEffect } from "react";
import { useField } from "formik";
//import { useTranslation } from "react-i18next";

const CheckBox = ({ label, options, callback, ...props }) => {
 // const { i18n } = useTranslation();
  const [field, handler] = useField(props);
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = () => {
    setIsChecked(!isChecked);
  };
  useEffect(() => {
    handler.setValue(isChecked);

    if (field.value === 1 || field.value === true) {
      setIsChecked(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked, field.value]);

  return (
    <label className="mb-2">
      <input
        type="checkbox"
        {...field}
        className="w-4 h-4 rounded border-primary-400 focus:ring-0 focus:ring-offset-0 text-primary-400"
        onChange={handleChange}
        checked={isChecked}
      />
      {label && (
        <span className="ml-2 text-gray-700">{label ? label : null}</span>
      )}
    </label>
  );
};

export default CheckBox;
