import React from "react";

const IconArrowDown = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 6.428 7.49">
      <g
        id="Group_2799"
        dataname="Group 2799"
        transform="translate(0.707 0.5)"
      >
        <path
          id="Path_10124"
          dataname="Path 10124"
          d="M0,0,2.507,2.507,5.014,0"
          transform="translate(0 3.983)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_10125"
          dataname="Path 10125"
          d="M0,6.374V0"
          transform="translate(2.507)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export default IconArrowDown;
