import React from "react";

const IconColoredInvestment = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="63"
      viewBox="0 0 70 63"
    >
      <g
        id="Group_3298"
        dataname="Group 3298"
        transform="translate(-413 -1460)"
      >
        <path
          id="Path_10229"
          dataname="Path 10229"
          d="M5,0H65a5,5,0,0,1,5,5V58a5,5,0,0,1-5,5H5a5,5,0,0,1-5-5V5A5,5,0,0,1,5,0Z"
          transform="translate(413 1460)"
          fill="#ccd9f7"
        />
        <g
          id="Group_2984"
          dataname="Group 2984"
          transform="translate(-1197.338 891.871)"
        >
          <g
            id="Group_2860"
            dataname="Group 2860"
            transform="translate(1652.754 593.895)"
          >
            <g id="Group_2007" dataname="Group 2007">
              <path
                id="Path_10230"
                dataname="Path 10230"
                d="M1647.519,598.866a5.053,5.053,0,0,1-5.063,5.063,4.963,4.963,0,0,1-2.675-.779,8.793,8.793,0,0,0,.493-2.986,10.786,10.786,0,0,0-1.324-5.115,5.141,5.141,0,0,1,1.818-1.324l-1.3-3.739,1.688-.545,1.246,1.428,1.247-1.428,1.688.545-1.3,3.661v.026A5.694,5.694,0,0,1,1647.519,598.866Z"
                transform="translate(-1638.95 -589.44)"
                fill="none"
                stroke="#003867"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
              <line
                id="Line_7"
                dataname="Line 7"
                x2="1.683"
                transform="translate(3.458 4.218)"
                fill="none"
                stroke="#003867"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
            </g>
            <g
              id="Group_2008"
              dataname="Group 2008"
              transform="translate(2.438 7.244)"
            >
              <path
                id="Path_9260"
                dataname="Path 9260"
                d="M1642.123,594.485a.964.964,0,0,1-.939.933v.451h-.534v-.457a1,1,0,0,1-.95-.992h.632a.553.553,0,0,0,.606.5l.018,0c.3,0,.516-.129.516-.37s-.244-.3-.739-.4-.956-.259-.956-.87a.925.925,0,0,1,.882-.895v-.456h.534v.46a.942.942,0,0,1,.878.924h-.632a.491.491,0,0,0-.538-.431c-.275,0-.473.116-.473.349s.237.3.739.392S1642.125,593.879,1642.123,594.485Z"
                transform="translate(-1639.7 -591.922)"
                fill="#003867"
              />
            </g>
          </g>
          <g
            id="Group_2860-2"
            dataname="Group 2860"
            transform="translate(1634.864 586.65)"
          >
            <g id="Group_2007-2" dataname="Group 2007">
              <path
                id="Path_10231"
                dataname="Path 10231"
                d="M1649.95,599.5a10.7,10.7,0,0,0-5.3-4.778l.026-.052,2.467-6.959-3.246-1.064-2.363,2.752-2.363-2.752-3.22,1.064,2.493,7.115a10.845,10.845,0,0,0-6.388,9.789,9.6,9.6,0,0,0,18.722,2.986,8.793,8.793,0,0,0,.493-2.986A10.786,10.786,0,0,0,1649.95,599.5Z"
                transform="translate(-1632.06 -586.65)"
                fill="none"
                stroke="#003867"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
              <line
                id="Line_7-2"
                dataname="Line 7"
                x2="3.203"
                transform="translate(9.495 8.034)"
                fill="none"
                stroke="#003867"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
            </g>
          </g>
          <line
            id="Line_144"
            dataname="Line 144"
            x2="14.743"
            transform="translate(1654.702 609.052)"
            fill="none"
            stroke="#003867"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <g
            id="Group_2860-3"
            dataname="Group 2860"
            transform="translate(1644.359 594.684)"
          >
            <g id="Group_2007-3" dataname="Group 2007">
              <line
                id="Line_7-3"
                dataname="Line 7"
                x2="3.203"
                fill="none"
                stroke="#003867"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
            </g>
          </g>
          <path
            id="Path_10233"
            dataname="Path 10233"
            d="M1641.075,598.235a2.563,2.563,0,0,1-2.493,2.493v1.194h-1.428V600.7a2.657,2.657,0,0,1-2.544-2.648h1.688a1.463,1.463,0,0,0,1.61,1.324h.052c.8,0,1.376-.338,1.376-.987s-.649-.8-1.973-1.065c-1.324-.286-2.545-.7-2.545-2.337a2.447,2.447,0,0,1,2.363-2.389v-1.22h1.428v1.22a2.533,2.533,0,0,1,2.337,2.467h-1.688a1.305,1.305,0,0,0-1.428-1.143c-.753,0-1.272.312-1.272.935s.623.805,1.974,1.039C1639.881,596.158,1641.1,596.6,1641.075,598.235Z"
            transform="translate(6.875 7.552)"
            fill="#003867"
          />
          <path
            id="Path_10234"
            dataname="Path 10234"
            d="M1644.359,614.227h-14.7"
            fill="none"
            stroke="#003867"
            strokeLinecap="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
};

export default IconColoredInvestment;
