import { all, call, fork, put, takeEvery,select } from "redux-saga/effects";
import ConfigChoiceService from "../../services/config-choice-service";
import TableDataService from "../../services/table-data-service1";
import {
  GET_ALL_CONFIG_CHOICE,
  GET_CONFIG_CHOICE_LIST,
  ADD_CONFIG_CHOICE,
  GET_CONFIG_CHOICE_DATA,
  EDIT_CONFIG_CHOICE,
  DELETE_CONFIG_CHOICE,
  DELETE_MULTIPLE_CONFIG_CHOICE,
} from "../actions";
import {
  getAllConfigChoiceSuccess,
  getAllConfigChoiceError,
  getConfigChoiceList,
  getConfigChoiceListSuccess,
  getConfigChoiceListError,
  addConfigChoiceSuccess,
  addConfigChoiceError,
  getConfigChoiceDataSuccess,
  getConfigChoiceDataError,
  editConfigChoiceSuccess,
  editConfigChoiceError,
  deleteConfigChoiceSuccess,
  deleteConfigChoiceError,
  deleteMultipleConfigChoiceSuccess,
  deleteMultipleConfigChoiceError,
} from "./action";
import { toast } from "react-toastify";
import { handleMyErrorMessage } from "../../helpers/util";
import ToastElement from "../../components/toast";
const getMetaData = (state) => state.configChoice.metaData;

export function* watchGetAllConfigChoice() {
  yield takeEvery(GET_ALL_CONFIG_CHOICE, getAllConfigChoice);
}

const getAllConfigChoiceAsync = async () => {
  return ConfigChoiceService.getAllConfigChoice();
};

function* getAllConfigChoice() {
  try {
    const response = yield call(getAllConfigChoiceAsync);
    if (response.data.success) {
      yield put(getAllConfigChoiceSuccess(response.data.data,response.data.metadata));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getAllConfigChoiceError(response.data.message));
    }
  } catch (error) {
    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      toast.error(
        <ToastElement type='error' message={errorMessage} />,
        { containerId: 'default' }
      )
      yield put(getAllConfigChoiceError(errorMessage))
    }
  }
}

export function* watchGetConfigChoiceList() {
  yield takeEvery(GET_CONFIG_CHOICE_LIST, getConfigChoiceListAc);
}

const getConfigChoiceListAsync = async (dbParam) => {

  return TableDataService.getAllData(
    "configChoices",
     dbParam
  );
};

function* getConfigChoiceListAc({ payload }) {
  try {
    const response = yield call(getConfigChoiceListAsync, payload.dbParam);
   
    if (response.data.success) {
      yield put(getConfigChoiceListSuccess(response?.data?.data,response?.data?.metadata));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getConfigChoiceListError(response.data.message));
    }
  } catch (error) {
    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      toast.error(
        <ToastElement type='error' message={errorMessage} />,
        { containerId: 'default' }
      )
      yield put(getConfigChoiceListError(errorMessage))
    }
  }
}

export function* watchAddConfigChoice() {
  yield takeEvery(ADD_CONFIG_CHOICE, addConfigChoice);
}

const addConfigChoiceAsync = async (data) => {
  return ConfigChoiceService.addConfigChoice(data);
};

function* addConfigChoice({ payload }) {
  try {
    const response = yield call(addConfigChoiceAsync, payload.configChoiceData);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        addConfigChoiceSuccess(response.data.success, response.data.message)
      );
      // Fetch updated configChoiceCategory list
      yield put(getConfigChoiceList({}));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(addConfigChoiceError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type="error"
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   {
    //     containerId: "default",
    //   }
    // );
    // yield put(addConfigChoiceError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      toast.error(
        <ToastElement type='error' message={errorMessage} />,
        { containerId: 'default' }
      )
      yield put(addConfigChoiceError(errorMessage))
    }
  }
}

export function* watchGetConfigChoiceData() {
  yield takeEvery(GET_CONFIG_CHOICE_DATA, getConfigChoiceData);
}

const getConfigChoiceDataAsync = async (id) => {
  return ConfigChoiceService.getConfigChoice(id);
};

function* getConfigChoiceData({ payload }) {
  try {
    const response = yield call(
      getConfigChoiceDataAsync,
      payload.configChoiceId
    );
    if (response.data.success) {
      yield put(getConfigChoiceDataSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getConfigChoiceDataError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getConfigChoiceDataError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      toast.error(
        <ToastElement type='error' message={errorMessage} />,
        { containerId: 'default' }
      )
      yield put(getConfigChoiceDataError(errorMessage))
    }
  }
}

export function* watchEditConfigChoice() {
  yield takeEvery(EDIT_CONFIG_CHOICE, editConfigChoice);
}

const editConfigChoiceAsync = async (data, id) => {
  return ConfigChoiceService.editConfigChoice(data, id);
};

function* editConfigChoice({ payload }) {
  const metadata = yield select(getMetaData);
  try {
    const response = yield call(
      editConfigChoiceAsync,
      payload.configChoiceData,
      payload.configChoiceId
    );
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        editConfigChoiceSuccess(response.data.success, response.data.message)
      );
      // Fetch updated configChoiceCategory list
      yield put(getConfigChoiceList({page:metadata?.page || 1}));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(editConfigChoiceError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type="error"
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   { containerId: "default" }
    // );
    // yield put(editConfigChoiceError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      toast.error(
        <ToastElement type='error' message={errorMessage} />,
        { containerId: 'default' }
      )
      yield put(editConfigChoiceError(errorMessage))
    }
  }
}

export function* watchDeleteConfigChoice() {
  yield takeEvery(DELETE_CONFIG_CHOICE, deleteConfigChoice);
}

const deleteConfigChoiceAsync = async (id) => {
  return ConfigChoiceService.deleteConfigChoice(id);
};

function* deleteConfigChoice({ payload }) {
  const metadata = yield select(getMetaData);
  try {
    const response = yield call(
      deleteConfigChoiceAsync,
      payload.configChoiceId
    );
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        deleteConfigChoiceSuccess(response.data.success, response.data.message)
      );
      // Fetch updated configChoice list
      yield put(getConfigChoiceList({page:metadata?.page}));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(deleteConfigChoiceError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(deleteConfigChoiceError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      toast.error(
        <ToastElement type='error' message={errorMessage} />,
        { containerId: 'default' }
      )
      yield put(deleteConfigChoiceError(errorMessage))
    }
  }
}

export function* watchDeleteMultipleConfigChoice() {
  yield takeEvery(DELETE_MULTIPLE_CONFIG_CHOICE, deleteMultipleConfigChoice);
}

const deleteMultipleConfigChoiceAsync = async (ids) => {
  return ConfigChoiceService.deleteMultipleConfigChoice(ids);
};

function* deleteMultipleConfigChoice({ payload }) {
  const metadata = yield select(getMetaData);
  try {
    const response = yield call(
      deleteMultipleConfigChoiceAsync,
      payload.configChoiceIds
    );
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        deleteMultipleConfigChoiceSuccess(
          response.data.success,
          response.data.message
        )
      );
      // Fetch updated configChoice list
      yield put(getConfigChoiceList({page:metadata.page || 1}));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(deleteMultipleConfigChoiceError(response.data.message));
    }
  } catch (error) {
    
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(deleteMultipleConfigChoiceError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      toast.error(
        <ToastElement type='error' message={errorMessage} />,
        { containerId: 'default' }
      )
      yield put(deleteMultipleConfigChoiceError(errorMessage))
    }
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAllConfigChoice),
    fork(watchGetConfigChoiceList),
    fork(watchAddConfigChoice),
    fork(watchGetConfigChoiceData),
    fork(watchEditConfigChoice),
    fork(watchDeleteConfigChoice),
    fork(watchDeleteMultipleConfigChoice),
  ]);
}
