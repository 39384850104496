import React, { Component } from "react";
import { connect } from "react-redux";
import { logout } from "../../../reduxs/actions";
import { LayoutSplashScreen } from "../../../configs/core";

class Logout extends Component {
  componentDidMount() {
    this.props.logout(this.props.history);
  }

  render() {
    return this.props.user != null ? <LayoutSplashScreen /> : null;
  }
}

const mapStateToProps = ({ auth }) => {
  const { user } = auth;
  return { user };
};
export default connect(mapStateToProps, {
  logout,
})(Logout);
