import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import PaidInService from "../../services/paid-in-service";
import {
  GET_ALL_PAIDIN,
  ADD_PAIDIN,
  GET_PAIDIN,
  EDIT_PAIDIN,
  DELETE_PAIDIN,
  EXPORT_PAIDIN,
} from "../actions";
import {
  getAllPaidIn,
  getAllPaidInSuccess,
  getAllPaidInError,
  addPaidInSuccess,
  addPaidInError,
  getPaidInSuccess,
  getPaidInError,
  editPaidInSuccess,
  editPaidInError,
  deletePaidInSuccess,
  deletePaidInError,
  exportPaidInSuccess,
  exportPaidInError,
} from "./action";
import { getAllPaidInOut, getpaidOutInvestorList } from "src/reduxs/actions";
import { toast } from "react-toastify";
import { handleMyErrorMessage } from "../../helpers/util";
import ToastElement from "../../components/toast";
import saveAs from "file-saver";
import moment from "moment";

export function* watchGetAllPaidIn() {
  yield takeEvery(GET_ALL_PAIDIN, getAllPaidInAc);
}

const getAllPaidInAsync = async (param) => {
  return PaidInService.getAllPaidIn(param);
};

function* getAllPaidInAc({ payload }) {
  try {
    const response = yield call(getAllPaidInAsync, payload.param);

    if (response.data.success) {
      yield put(
        getAllPaidInSuccess(
          response.data.success,
          response.data.data,
          response.data?.meta?.totalPaidInAmount
        )
      );
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getAllPaidInError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getAllPaidInError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getAllPaidInError(errorMessage));
    }
  }
}

export function* watchAddPaidIn() {
  yield takeEvery(ADD_PAIDIN, addPaidIn);
}

const addPaidInAsync = async (data) => {
  return PaidInService.addPaidIn(data.paidInData);
};

function* addPaidIn({ payload }) {
  try {
    const response = yield call(addPaidInAsync, payload);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(addPaidInSuccess(response.data.success, response.data.message));

      // Fetch updated list
      yield put(
        getAllPaidIn({
          projectId: payload.projectId,
        })
      );

      yield put(
        getAllPaidInOut({
          projectId: payload.projectId,
        })
      );
      yield put(getpaidOutInvestorList(payload.projectId));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(addPaidInError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type="error"
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   { containerId: "default" }
    // );
    // yield put(addPaidInError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(addPaidInError(errorMessage));
    }
  }
}

export function* watchGetPaidIn() {
  yield takeEvery(GET_PAIDIN, getPaidInAc);
}

const getPaidInAsync = async (id) => {
  return PaidInService.getPaidIn(id);
};

function* getPaidInAc({ payload }) {
  try {
    const response = yield call(getPaidInAsync, payload.paidInId);
    if (response.data.success) {
      yield put(getPaidInSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getPaidInError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getPaidInError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getPaidInError(errorMessage));
    }
  }
}

export function* watchEditPaidIn() {
  yield takeEvery(EDIT_PAIDIN, editPaidIn);
}

const editPaidInAsync = async (data, id) => {
  return PaidInService.editPaidIn(data, id);
};

function* editPaidIn({ payload }) {
  try {
    const response = yield call(
      editPaidInAsync,
      payload.paidInData,
      payload.paidInId
    );
    if (response.data.success) {
      yield put(
        editPaidInSuccess(response.data.success, response.data.message)
      );
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        getAllPaidIn({
          projectId: payload.projectId,
        })
      );

      yield put(
        getAllPaidInOut({
          projectId: payload.projectId,
        })
      );
      if (payload.paidInData?.investorId) {
        yield put(getpaidOutInvestorList(payload.projectId));
      }
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(editPaidInError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type="error"
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   { containerId: "default" }
    // );
    // yield put(editPaidInError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(editPaidInError(errorMessage));
    }
  }
}

export function* watchDeletePaidIn() {
  yield takeEvery(DELETE_PAIDIN, deletePaidIn);
}

const deletePaidInAsync = async (id, eachPropertyFund) => {
  return eachPropertyFund
    ? PaidInService.deletePaidInFund(id)
    : PaidInService.deletePaidIn(id);
};

function* deletePaidIn({ payload }) {
  try {
    const response = yield call(
      deletePaidInAsync,
      payload.paidInId,
      payload.eachPropertyFund
    );
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        deletePaidInSuccess(response.data.success, response.data.message)
      );
      // Fetch updated list
      yield put(getpaidOutInvestorList(payload.projectId));

      if (!payload.eachPropertyFund)
        yield put(
          getAllPaidInOut({
            projectId: payload.projectId,
          })
        );

      yield put(
        getAllPaidIn({
          projectId: payload.projectId,
        })
      );
    } else {
      toast.error(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(deletePaidInError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(deletePaidInError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(deletePaidInError(errorMessage));
    }
  }
}

export function* watchExportPaidIn() {
  yield takeEvery(EXPORT_PAIDIN, exportPaidIn);
}

const exportPaidInAsync = async (projectId) => {
  return PaidInService.exportPaidIn(projectId);
};

function* exportPaidIn({ payload }) {
  try {
    const response = yield call(exportPaidInAsync, payload.projectId);
    if (response && response.data) {
      yield put(exportPaidInSuccess(true, ""));
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const fileName =
        "PaidIn-" + moment().format("YYYY-MMM-DD-HH-mm") + ".xlsx";
      saveAs(blob, fileName);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(exportPaidInError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(exportPaidInError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(exportPaidInError(errorMessage));
    }
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAllPaidIn),
    fork(watchAddPaidIn),
    fork(watchGetPaidIn),
    fork(watchEditPaidIn),
    fork(watchDeletePaidIn),
    fork(watchExportPaidIn),
  ]);
}
