import React from "react";

const IconFileAdd = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 39.048 50.49">
      <g id="Group_3122" dataname="Group 3122" transform="translate(0.5 0.5)">
        <path
          id="Path_10306"
          dataname="Path 10306"
          d="M-510.307,516.337v33.888a1.1,1.1,0,0,1-1.1,1.1h-32.566a1.1,1.1,0,0,1-1.1-1.1V506.832a1.1,1.1,0,0,1,1.1-1.1h23.054a1.1,1.1,0,0,1,.778.322l9.513,9.5A1.1,1.1,0,0,1-510.307,516.337Z"
          transform="translate(548.355 -505.732)"
          fill="none"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <path
          id="Path_10307"
          dataname="Path 10307"
          d="M-514.594,515.881h10.157l-10.157-10.149Z"
          transform="translate(542.485 -505.732)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_159"
          dataname="Line 159"
          y2="10.33"
          transform="translate(20.237 20.947)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          id="Line_160"
          dataname="Line 160"
          x1="10.33"
          transform="translate(15.072 26.112)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_10308"
          dataname="Path 10308"
          d="M-515.507,552.716V555.2a1.235,1.235,0,0,1-1.235,1.234H-547.9a1.234,1.234,0,0,1-1.234-1.234V513.252a1.235,1.235,0,0,1,1.234-1.234h2.047"
          transform="translate(549.138 -506.943)"
          fill="none"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export default IconFileAdd;
