import React from "react";

const IconLoginType = (props) => {
  return (
    <svg width="22" height="22" viewBox="0 0 61.408 62.896">
      <defs>
        <clipPath id="clip-path">
          <path
            id="Path_9301"
            dataname="Path 9301"
            d="M744.093,161.13a11.968,11.968,0,0,1-11.121,11.928,7.162,7.162,0,0,1-.877.052,4.96,4.96,0,0,1-.72-.035,11.983,11.983,0,0,1-11.261-11.945,1.861,1.861,0,0,1,.018-.316,11.958,11.958,0,0,1,11.243-11.629,4.96,4.96,0,0,1,.72-.035h.017a6.888,6.888,0,0,1,.86.052,11.943,11.943,0,0,1,11.1,11.612A1.861,1.861,0,0,1,744.093,161.13Z"
            fill="none"
            stroke="#696969"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </clipPath>
        <clipPath id="clip-path-2">
          <rect
            id="Rectangle_1593"
            dataname="Rectangle 1593"
            width="36.52"
            height="26.593"
            transform="translate(717.367 149.15)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-3">
          <path
            id="Path_9296"
            dataname="Path 9296"
            d="M651.043,161.13a10.815,10.815,0,0,1-10.049,10.778,6.6,6.6,0,0,1-.792.047,4.423,4.423,0,0,1-.65-.032,10.827,10.827,0,0,1-10.176-10.793,1.7,1.7,0,0,1,.016-.285,10.807,10.807,0,0,1,10.16-10.509,4.563,4.563,0,0,1,.65-.031h.016a6.139,6.139,0,0,1,.776.047,10.792,10.792,0,0,1,10.033,10.493A1.7,1.7,0,0,1,651.043,161.13Z"
            fill="#696969"
          />
        </clipPath>
      </defs>
      <g
        id="Group_2148"
        dataname="Group 2148"
        transform="translate(-591.292 -113.347)"
      >
        <g id="Group_1944" dataname="Group 1944">
          <g id="Group_1943" dataname="Group 1943">
            <g id="Group_1942" dataname="Group 1942">
              <g id="Group_1941" dataname="Group 1941">
                <path
                  id="Path_9292"
                  dataname="Path 9292"
                  d="M629.085,138.025a20.625,20.625,0,0,1-1.1,6.785,18.514,18.514,0,0,1-4.439,7.08,12.769,12.769,0,0,1-3.327,2.509c-.981.393-2.011,1.431-3.818,1.513-1.806-.082-2.836-1.12-3.817-1.513a10.1,10.1,0,0,1-2.281-1.537,18.567,18.567,0,0,1-5.689-8.632v-.008a20.869,20.869,0,0,1-.908-6.2c0-10.766,4.88-15.806,12.7-16.133C624.213,122.219,629.085,127.259,629.085,138.025Z"
                  fill="none"
                  stroke="#696969"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
                <path
                  id="Path_9293"
                  dataname="Path 9293"
                  d="M603.71,138.6a5.608,5.608,0,0,0-2.116-.674c-3.008-.049-.409,4.137,1.324,6.009,1.66,1.814,1.693.4,1.693.294"
                  fill="none"
                  stroke="#696969"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
                <path
                  id="Path_9294"
                  dataname="Path 9294"
                  d="M629.12,138.6a5.615,5.615,0,0,1,2.117-.674c3.008-.049.409,4.137-1.324,6.009-1.66,1.814-1.693.4-1.693.294"
                  fill="none"
                  stroke="#696969"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
              </g>
              <g id="Group_365" dataname="Group 365">
                <g id="Group_340" dataname="Group 340">
                  <path
                    id="Path_240"
                    dataname="Path 240"
                    d="M616.415,113.347"
                    fill="none"
                    stroke="#696969"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                  <path
                    id="Path_242"
                    dataname="Path 242"
                    d="M623.713,151.949v5.846a2.061,2.061,0,0,1-.009.248,6.509,6.509,0,0,1-13.014-.2v-4.662"
                    fill="none"
                    stroke="#696969"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                </g>
                <g id="Group_341" dataname="Group 341">
                  <path
                    id="Path_244"
                    dataname="Path 244"
                    d="M641.175,173.775q.377.966.678,1.968H591.792a26.144,26.144,0,0,1,18.9-17.891,6.517,6.517,0,0,0,13.028.192,26.17,26.17,0,0,1,4.5,1.694"
                    fill="none"
                    stroke="#696969"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                  <line
                    id="Line_144"
                    dataname="Line 144"
                    y1="2.52"
                    transform="translate(603.659 173.223)"
                    fill="none"
                    stroke="#696969"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                  <line
                    id="Line_145"
                    dataname="Line 145"
                    y1="2.52"
                    transform="translate(630.49 173.223)"
                    fill="none"
                    stroke="#696969"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="Group_1947" dataname="Group 1947">
          <g
            id="Group_2149"
            dataname="Group 2149"
            transform="translate(-91.893 0)"
          >
            {props.type === "norwegian" ? (
              <g
                id="Group_1956"
                dataname="Group 1956"
                clipPath="url(#clip-path)"
              >
                <g id="Norway">
                  <g id="Group_1955" dataname="Group 1955">
                    <g id="Group_1954" dataname="Group 1954">
                      <g
                        id="Group_1953"
                        dataname="Group 1953"
                        clipPath="url(#clip-path-2)"
                      >
                        <rect
                          id="Rectangle_1588"
                          dataname="Rectangle 1588"
                          width="36.52"
                          height="26.593"
                          transform="translate(717.367 149.15)"
                          style={{ fill: "#d0002a" }}
                        />
                        <rect
                          id="Rectangle_1589"
                          dataname="Rectangle 1589"
                          width="5.326"
                          height="26.593"
                          transform="translate(725.356 149.15)"
                          fill="#fff"
                        />
                        <rect
                          id="Rectangle_1590"
                          dataname="Rectangle 1590"
                          width="36.52"
                          height="5.325"
                          transform="translate(717.367 159.205)"
                          fill="#fff"
                        />
                        <rect
                          id="Rectangle_1591"
                          dataname="Rectangle 1591"
                          width="2.663"
                          height="26.593"
                          transform="translate(726.687 149.15)"
                          style={{ fill: "#112253" }}
                        />
                        <rect
                          id="Rectangle_1592"
                          dataname="Rectangle 1592"
                          width="36.52"
                          height="2.663"
                          transform="translate(717.367 160.536)"
                          style={{ fill: "#112253" }}
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            ) : null}

            <path
              id="Path_9302"
              dataname="Path 9302"
              d="M744.093,161.13a11.968,11.968,0,0,1-11.121,11.928,7.162,7.162,0,0,1-.877.052,4.96,4.96,0,0,1-.72-.035,11.983,11.983,0,0,1-11.261-11.945,1.861,1.861,0,0,1,.018-.316,11.958,11.958,0,0,1,11.243-11.629,4.96,4.96,0,0,1,.72-.035h.017a6.888,6.888,0,0,1,.86.052,11.943,11.943,0,0,1,11.1,11.612A1.861,1.861,0,0,1,744.093,161.13Z"
              fill="none"
              stroke="#696969"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />

            {props.type === "malling" ? (
              <g
                id="Group_1946"
                dataname="Group 1946"
                transform="translate(91.893)"
              >
                <g
                  id="Group_1945"
                  dataname="Group 1945"
                  clipPath="url(#clip-path-3)"
                >
                  <path
                    id="Path_9295"
                    dataname="Path 9295"
                    d="M647.353,160.96c-.789.419-5.5,3.936-5.574,3.991,1.967-4.019.988-5.392-1.44-4.979,2.581-4.663-2.264-6.234-2.993-6.458a3.813,3.813,0,0,0-2.505.221c-.489.327-.47.548-.47.711s.2.038,1.305-.145,3-.153,3.934,1.114,1.468,2.753-.173,4.807c-5.018,2.437-6.428,4.356-6.869,5.1s-.394,1.392,0,1.765,1.313.365,3.578-1.823a22.9,22.9,0,0,0,3.531-4.346c2.159-.729,2.438.192,2.025,1.65a9.067,9.067,0,0,1-1.707,3.144.213.213,0,0,0,.074.336c.507.223.891.1,1.556-.409s2.8-1.91,3.353-2.267a.043.043,0,0,1,.061.056c-1.243,2.546-.631,2.8-.056,2.738a4.017,4.017,0,0,0,1.862-.815c.676-.485.86-.606.786-.749s-.211.02-.211.02c-1.161,1.065-2.59,1.516-2.322.556a10.433,10.433,0,0,1,2.591-3.406C648.533,161.037,648.15,160.538,647.353,160.96Zm-14.272,5.59c-.591-.254.517-1.972,2.158-3.138a18.484,18.484,0,0,1,3.137-1.8C635.354,165.528,633.651,166.794,633.081,166.55Z"
                    fill="#696969"
                  />
                </g>
              </g>
            ) : null}

            {props.type === "international" ? (
              <g
                id="Group_2150"
                dataname="Group 2150"
                transform="translate(-85.746 0.23)"
              >
                <path
                  id="Path_9287"
                  dataname="Path 9287"
                  d="M829.838,160.9a11.968,11.968,0,0,1-11.12,11.928,7.19,7.19,0,0,1-.877.052,4.971,4.971,0,0,1-.719-.035A11.983,11.983,0,0,1,805.861,160.9a1.913,1.913,0,0,1,.018-.316,11.961,11.961,0,0,1,11.244-11.629,4.971,4.971,0,0,1,.719-.035h.017a6.9,6.9,0,0,1,.86.052,11.945,11.945,0,0,1,11.1,11.612A2.025,2.025,0,0,1,829.838,160.9Z"
                  transform="translate(0)"
                  fill="none"
                  stroke="#696969"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
                <path
                  id="Path_9288"
                  dataname="Path 9288"
                  d="M819.143,148.974a13.263,13.263,0,0,1,5.051,5.614,13.77,13.77,0,0,1,1.473,6,1.933,1.933,0,0,1,.017.316,14.037,14.037,0,0,1-1.473,6.3,13.346,13.346,0,0,1-5.069,5.63"
                  transform="translate(-0.424 -0.002)"
                  fill="none"
                  stroke="#696969"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
                <path
                  id="Path_9289"
                  dataname="Path 9289"
                  d="M817.136,148.974a13.269,13.269,0,0,0-5.051,5.614,13.768,13.768,0,0,0-1.473,6,1.926,1.926,0,0,0-.017.316,14.036,14.036,0,0,0,1.473,6.3,13.346,13.346,0,0,0,5.069,5.63"
                  transform="translate(-0.151 -0.002)"
                  fill="none"
                  stroke="#696969"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
                <path
                  id="Path_9290"
                  dataname="Path 9290"
                  d="M826.531,152.653a11.656,11.656,0,0,1-2.648,2.052,11.8,11.8,0,0,1-5.911,1.56h-.017a11.863,11.863,0,0,1-8.559-3.613"
                  transform="translate(-0.113 -0.119)"
                  fill="none"
                  stroke="#696969"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
                <path
                  id="Path_9291"
                  dataname="Path 9291"
                  d="M809.376,169.8a12.067,12.067,0,0,1,2.737-2.122,11.906,11.906,0,0,1,5.841-1.508h.017a11.872,11.872,0,0,1,5.928,1.579,11.15,11.15,0,0,1,2.648,2.051"
                  transform="translate(-0.112 -0.551)"
                  fill="none"
                  stroke="#696969"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
                <line
                  id="Line_154"
                  dataname="Line 154"
                  x2="23.957"
                  transform="translate(805.873 160.577)"
                  fill="none"
                  stroke="#696969"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
                <line
                  id="Line_155"
                  dataname="Line 155"
                  y2="23.36"
                  transform="translate(817.852 149.196)"
                  fill="none"
                  stroke="#696969"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
              </g>
            ) : null}
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconLoginType;
