import React from "react";

const IconContact = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 20.052 22.733"
    >
      <g
        id="Group_3010"
        data-name="Group 3010"
        transform="translate(-1652.768 -588.943)"
      >
        <g
          id="Group_2983"
          data-name="Group 2983"
          transform="translate(1654.751 589.318)"
        >
          <path
            id="Path_10227"
            data-name="Path 10227"
            d="M1670.033,600.7a8.419,8.419,0,0,0,.7-3.343,8.042,8.042,0,0,0-16.085,0,8.508,8.508,0,0,0,.9,3.79m4.571,4.983a18.166,18.166,0,0,0,1.9,1.144,1.452,1.452,0,0,0,1.347,0,17.74,17.74,0,0,0,4.27-3.111,11.3,11.3,0,0,0,2.4-3.464m-14.49.447a11.592,11.592,0,0,0,2.2,3.016,16.592,16.592,0,0,0,2.372,1.967"
            transform="translate(-1654.643 -589.318)"
            fill="none"
            stroke="#000"
            strokeMiterlimit="10"
            strokeWidth="0.75"
          />
        </g>
        <path
          id="Path_10228"
          data-name="Path 10228"
          d="M1671.152,605.318c.847.489,1.293,1.044,1.293,1.609s-.446,1.12-1.293,1.609a11.735,11.735,0,0,1-3.532,1.177,27.228,27.228,0,0,1-9.651,0,11.732,11.732,0,0,1-3.532-1.177c-.847-.489-1.293-1.043-1.293-1.609s.446-1.12,1.293-1.609"
          transform="translate(0 1.157)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeWidth="0.75"
        />
        <g
          id="Group_2867"
          data-name="Group 2867"
          transform="translate(1657.884 593.547)"
        >
          <path
            id="Path_9291"
            data-name="Path 9291"
            d="M1667.384,603.173a4.012,4.012,0,0,0-1.7-2.543,5.6,5.6,0,0,0-3.288-.969,5.534,5.534,0,0,0-3.245,1.074,3.935,3.935,0,0,0-1.587,2.591"
            transform="translate(-1657.564 -592.799)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="0.75"
          />
          <circle
            id="Ellipse_295"
            data-name="Ellipse 295"
            cx="2.478"
            cy="2.478"
            r="2.478"
            transform="translate(2.447 0)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="0.75"
          />
        </g>
      </g>
    </svg>
  );
};

export default IconContact;
