import React, { Component } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { IconPhone, IconSpinner } from "../../../components/svg";
import { withRouter } from "react-router-dom";
import PhoneField from "../../../components/form/phone-field";
import { verifyNewUser } from "../../../reduxs/actions";
import SetLanguage from "src/views/auth/set-lang";
import axios from "axios";
class WelcomeInternational extends Component {
  constructor(props) {
    super(props);
    this.validationSchema = Yup.object().shape({
      phone: Yup.number().required(
        this.props.t("AUTH.VALIDATION.REQUIRED_FIELD")
      )
      .test("min5Digit", this.props.t("AUTH.VALIDATION.MIN_LENGTH", {
              name: this.props.t("AUTH.LOGIN.PHONE"),
              min: 5,
            }), 
            val => val && val.toString().length >= 5
          )
          .test("max13Digit", this.props.t("AUTH.VALIDATION.MAX_LENGTH", {
              name: this.props.t("AUTH.LOGIN.PHONE"),
              max: 13,
            }), 
            val => val && val.toString().length <= 13
          )
          .typeError(this.props.t("AUTH.VALIDATION.PHONE.PHONE_TYPE_ERROR"))
          .integer(this.props.t("AUTH.VALIDATION.PHONE.PHONE_INTEGER")),
    });

    this.state = {
      countryId: "",
      isdCode: "",
      selectedCountry: null,
    };
    this.setCountryId = this.setCountryId.bind(this);
    this.setIsdCode = this.setIsdCode.bind(this);
  }

  setCountryId(id) {
    this.setState({ countryId: id });
  }
  setIsdCode(code) {
    this.setState({ isdCode: code });
  }

  onLogin = (values) => {
    if (!this.props.loading) {
      const isdCode = this.state.isdCode;
      const verifyData = { ...values, countryId: this.state.countryId };
      localStorage.setItem(
        "loginData",
        JSON.stringify({
          phone: verifyData.phone,
          isdCode: isdCode,
          countryId: verifyData.countryId,
        })
      );
      this.props.verifyNewUser(verifyData, this.props.history);
    }
  };

  componentDidMount() {
    this.setCountryIsd();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.countryList !== this.props.countryList) {
      this.setCountryIsd();
    }
  }

  setCountryIsd = async () => {
    const currentLocation = JSON.parse(localStorage.getItem("selectedCountry"));
    if (currentLocation) {
      this.setState({
        selectedCountry: currentLocation,
      });
    } else {
      const { data } = await axios.get("https://geolocation-db.com/json/");
      if (data && this.props?.countryList) {
        const country = this.props?.countryList?.find(
          (c) => c.code === data.country_code
        );
        if (country) {
          const countryInfo = {
            value: country.id,
            label: country.isdCode,
            isdCode: country.isdCode,
          };
          this.setState({
            selectedCountry: countryInfo,
          });
          localStorage.setItem("selectedCountry", JSON.stringify(countryInfo));
        }
      }
    }
  };
  render() {
    const { t } = this.props;
    return (
      <div className="auth-wrapper">
        <img
          className="w-52 block xl:hidden my-8 mx-auto"
          src={require("../../../assets/images/Malling-logo-hv.svg").default}
          alt=""
        />

        <div className="auth-wrapper-aside">
          <img width="200px" height="auto"
            src={require("../../../assets/images/Malling-logo-hv.svg").default}
            alt=""
          />

          {/* <h2>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed</h2> */}

          <ul>
            <li>{t("AUTH.WELCOME.FINALISE_TEXT_FOR_INTERNATIONAL")}</li>
            <li>{t("AUTH.WELCOME.AFTER_SUBMIT_TEXT_FOR_INTERNATIONAL")}</li>
            <li>
              {t("AUTH.WELCOME.QUERY_TEXT")}:{" "}
              <a
                className="font-bold underline"
                href="mailto:havard.solbrakke@malling.no"
              >
                havard.solbrakke@malling.no
              </a>
            </li>
          </ul>

          <footer className="flex justify-between">
            <span>
              © {t("AUTH.INTRO.FOOTER")}
            </span>
            <span>{process.env.REACT_APP_WEB_CLIENT_VERSION}</span>
          </footer>
        </div>

        <div className="auth-wrapper-content">
          <header>
            <SetLanguage />
          </header>

          <h1>{t("AUTH.WELCOME.TITLE")}</h1>
          <h6 className="!mb-8">{t("AUTH.WELCOME.SETUP")}</h6>
          <h6>{t("AUTH.WELCOME.INTERNATIONAL_MESSAGE")}</h6>

          <Formik
            initialValues={{
              phone: "",
            }}
            validationSchema={this.validationSchema}
            onSubmit={this.onLogin}
          >
            {(props) => (
              <Form className="w-full xl:w-3/5 2xl:w-2/5">
                {this.props ? (
                  <PhoneField
                    name="phone"
                    label={t("AUTH.LOGIN.PHONE")}
                    placeholder={t("AUTH.LOGIN.PHONE_PLACEHOLDER")}
                    icon={<IconPhone />}
                    iconPosition="end"
                    setCountryId={this.setCountryId}
                    setIsdCode={this.setIsdCode}
                    selectedcountry={this.state?.selectedCountry}
                  />
                ) : (
                  ""
                )}

                <button
                  className="mt-5 btn-primary md"
                  type="submit"
                  disabled={this.props.loading}
                >
                  {t("AUTH.GENERAL.SUBMIT_BUTTON")}

                  {this.props.loading && (
                    <span className="ml-3 -mr-1">
                      <IconSpinner />
                    </span>
                  )}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  error: state.auth.error,
  loading1: state.auth.loading1,
  countryList: state.shared.countryList,
});
export default withRouter(
  connect(mapStateToProps, { verifyNewUser })(
    withTranslation()(WelcomeInternational)
  )
);
