import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import UserService from "../../services/user-service";
import TableDataService from "../../services/table-data-service1";
import ExportDataService from "src/services/export-service";
import saveAs from "file-saver";
import moment from "moment";
import {
  GET_ALL_USER,
  GET_USER_LIST,
  RESEND_VERIFICATION_CODE,
  ADD_USER,
  GET_USER,
  EDIT_USER,
  DELETE_USER,
  DELETE_MULTIPLE_USER,
  CHANGE_PASSWORD,
  UPDATE_EMAIL_SETTING,
  EXPORT_USER,
  GET_INVESTORS_BY_USER,
} from "../actions";
import {
  getAllUserSuccess,
  getAllUserError,
  getUserList,
  getUserListSuccess,
  getUserListError,
  resendVerificationCodeSuccess,
  resendVerificationCodeError,
  addUserSuccess,
  addUserError,
  getUserSuccess,
  getUserError,
  editUserSuccess,
  editUserError,
  deleteUserSuccess,
  deleteUserError,
  deleteMultipleUserSuccess,
  deleteMultipleUserError,
  changePasswordSuccess,
  changePasswordError,
  updateEmailSettingSuccess,
  updateEmailSettingError,
  resetUser,
  exportUserSuccess,
  exportUserError,
  getInvestorsByUserSuccess,
  getInvestorsByUserError,
} from "./action";
import { getCurrentUser } from "src/reduxs/actions";
import { resetMedia } from "../media/action";
import { toast } from "react-toastify";
import { handleMyErrorMessage } from "../../helpers/util";
import i18n from "i18next";
import ToastElement from "../../components/toast";

const getMetaData = (state) => state.user.metaData;

export function* watchGetAllUser() {
  yield takeEvery(GET_ALL_USER, getAllUser);
}

const getAllUserAsync = async () => {
  return UserService.getAllUser();
};

function* getAllUser() {
  try {
    const response = yield call(getAllUserAsync);
    if (response.data.success) {
      yield put(getAllUserSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getAllUserError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getAllUserError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getAllUserError(errorMessage));
    }
  }
}

export function* watchGetUserList() {
  yield takeEvery(GET_USER_LIST, getUserListAc);
}

const getUserListAsync = async (dbParam) => {
  return TableDataService.getAllData("users", dbParam);
};

function* getUserListAc({ payload }) {
  try {
    const response = yield call(getUserListAsync, payload.dbParam);
    if (response.data.success) {
      yield put(getUserListSuccess(response.data.data, response.data.metadata));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getUserListError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getUserListError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getUserListError(errorMessage));
    }
  }
}

export function* watchResendVerificationCode() {
  yield takeEvery(RESEND_VERIFICATION_CODE, resendVerificationCode);
}

const resendVerificationCodeAsync = async (id) => {
  return UserService.resendVerificationCode(id);
};

function* resendVerificationCode({ payload }) {
  const customToastId = toast.info(
    <ToastElement
      type="info"
      message={i18n.t("USER.RESEND_VERIFICATION_CODE_INFO_LABEL")}
    />,
    {
      containerId: "custom",
    }
  );
  try {
    const response = yield call(resendVerificationCodeAsync, payload.userId);
    if (response.data.success) {
      toast.dismiss(customToastId);
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        resendVerificationCodeSuccess(
          response.data.success,
          response.data.message
        )
      );
      // Fetch updated user list
      yield put(getUserList({}));
    } else {
      toast.dismiss(customToastId);
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(resendVerificationCodeError(response.data.message));
    }
  } catch (error) {
    // toast.dismiss(customToastId);
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(resendVerificationCodeError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.dismiss(customToastId);
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(resendVerificationCodeError(errorMessage));
    }
  }
}

export function* watchAddUser() {
  yield takeEvery(ADD_USER, addUser);
}

const addUserAsync = async (data, roleId) => {
  return UserService.addUser(data, roleId);
};

function* addUser({ payload }) {
  try {
    const response = yield call(addUserAsync, payload.userData, payload.roleId);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(addUserSuccess(response.data.success, response.data.message));
      yield put(getUserList({ switchRole: payload.roleId }));
      yield put(resetUser());
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(addUserError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type="error"
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   {
    //     containerId: "default",
    //   }
    // );
    // yield put(addUserError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(addUserError(errorMessage));
    }
  }
}

export function* watchGetUser() {
  yield takeEvery(GET_USER, getUser);
}

const getUserAsync = async (id) => {
  return UserService.getUser(id);
};

function* getUser({ payload }) {
  try {
    const response = yield call(getUserAsync, payload);
    if (response.data.success) {
      yield put(getUserSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getUserError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getUserError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getUserError(errorMessage));
    }
  }
}

export function* watchEditUser() {
  yield takeEvery(EDIT_USER, editUser);
}

const editUserAsync = async (data, id, roleId) => {
  return UserService.editUser(data, id, roleId);
};

function* editUser({ payload }) {
  const metadata = yield select(getMetaData);
  try {
    const response = yield call(
      editUserAsync,
      payload.userData,
      payload.userId,
      payload.roleId
    );
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(editUserSuccess(response.data.success, response.data.message));
      yield put(
        getUserList({ page: metadata?.page || 1, switchRole: payload.roleId })
      );
      yield put(resetUser());
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(editUserError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type="error"
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   { containerId: "default" }
    // );
    // yield put(editUserError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(editUserError(errorMessage));
    }
  }
}

export function* watchDeleteUser() {
  yield takeEvery(DELETE_USER, deleteUser);
}

const deleteUserAsync = async (id) => {
  return UserService.deleteUser(id);
};

function* deleteUser({ payload }) {
  try {
    const metadata = yield select(getMetaData);
    const response = yield call(deleteUserAsync, payload);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        deleteUserSuccess(response.data.success, response.data.message)
      );
      // Fetch updated user list
      yield put(
        getUserList({
          page: metadata?.page || 1,
          switchRole: payload.userData.roleId,
        })
      );
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(deleteUserError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(deleteUserError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(deleteUserError(errorMessage));
    }
  }
}

export function* watchDeleteMultipleUser() {
  yield takeEvery(DELETE_MULTIPLE_USER, deleteMultipleUser);
}

const deleteMultipleUserAsync = async (ids) => {
  return UserService.deleteMultipleUser(ids);
};

function* deleteMultipleUser({ payload }) {
  try {
    const response = yield call(deleteMultipleUserAsync, payload);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        deleteMultipleUserSuccess(response.data.success, response.data.message)
      );
      // Fetch updated user list
      yield put(
        getUserList({
          pageSize: 5,
          page: payload.page,
          switchRole: payload.userIds.roleId,
        })
      );
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(deleteMultipleUserError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(deleteMultipleUserError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(deleteMultipleUserError(errorMessage));
    }
  }
}

export function* watchChangePassword() {
  yield takeEvery(CHANGE_PASSWORD, changePassword);
}

const changePasswordAsync = async (data) => {
  return UserService.changePassword(data);
};

function* changePassword({ payload }) {
  try {
    const response = yield call(
      changePasswordAsync,
      payload.changePasswordData
    );
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        changePasswordSuccess(response.data.success, response.data.message)
      );
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(changePasswordError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type="error"
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   { containerId: "default" }
    // );
    // yield put(changePasswordError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(changePasswordError(errorMessage));
    }
  }
}

export function* watchUpdateEmailSetting() {
  yield takeEvery(UPDATE_EMAIL_SETTING, updateEmailSetting);
}

const updateEmailSettingAsync = async (data) => {
  return UserService.updateEmailSetting(data);
};

function* updateEmailSetting({ payload }) {
  try {
    const response = yield call(
      updateEmailSettingAsync,
      payload.emailSettingData
    );
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        updateEmailSettingSuccess(response.data.success, response.data.message)
      );
      yield put(getCurrentUser());
      // Reset media
      yield put(resetMedia());
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(updateEmailSettingError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type="error"
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   { containerId: "default" }
    // );
    // yield put(updateEmailSettingError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(updateEmailSettingError(errorMessage));
    }
  }
}

export function* watchExportUser() {
  yield takeEvery(EXPORT_USER, exportUser);
}
const exportUserAsync = async (params) => {
  return ExportDataService.getAllData("users/export", params);
};
function* exportUser({ payload }) {
  try {
    const response = yield call(exportUserAsync, payload.dbParam);
    if (response && response.data) {
      yield put(exportUserSuccess(true, ""));
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const fileName =
        "Users-" + moment().format("YYYY-MMM-DD-HH-mm") + ".xlsx";

      saveAs(blob, fileName);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(exportUserError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(exportUserError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(exportUserError(errorMessage));
    }
  }
}

export function* watchGetInvestorsByUser() {
  yield takeEvery(GET_INVESTORS_BY_USER, getInvestorsByUserAc);
}

const getInvestorsByUserAcAsync = async () => {
  return UserService.getInvestorsByUser();
};

function* getInvestorsByUserAc() {
  try {
    const response = yield call(getInvestorsByUserAcAsync);
    if (response.data.success) {
      yield put(getInvestorsByUserSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getInvestorsByUserError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getInvestorsByUserError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getInvestorsByUserError(errorMessage));
    }
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAllUser),
    fork(watchGetUserList),
    fork(watchResendVerificationCode),
    fork(watchAddUser),
    fork(watchGetUser),
    fork(watchEditUser),
    fork(watchDeleteUser),
    fork(watchDeleteMultipleUser),
    fork(watchChangePassword),
    fork(watchUpdateEmailSetting),
    fork(watchExportUser),
    fork(watchGetInvestorsByUser),
  ]);
}
