import React from 'react'

const Brand = (props) => {
  return (
    <figure className='h-20 relative p-6 pb-3 overflow-hidden'>
      <img
        className={`h-[3.125rem] absolute transition-all duration-300 origin-center 
        ${
          props.drawerCollapse
            ? 'scale-125 opacity-0'
            : 'scale-100 opacity-100 delay-300'
        }`}
        src={props.logo}
        alt=''
      />

      <img
        className={`h-[calc(100%-2rem)] absolute transition-all duration-300 origin-center 
        ${
          props.drawerCollapse
            ? 'scale-100 opacity-100 delay-300'
            : 'scale-125 opacity-0'
        }`}
        src={props.secondaryLogo}
        alt=''
      />
    </figure>
  )
}

export default Brand
