import React, { Component } from "react";
import { connect } from "react-redux";
import { verifyOtp, resendOtp, resetAuth } from "../../../reduxs/actions";
import OtpInput from "react-otp-input";
import { withTranslation } from "react-i18next";
import { IconPhoneCode, IconSpinner } from "../../../components/svg";
import { Redirect } from "react-router-dom";
import SetLanguage from "src/views/auth/set-lang";
class Otp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: "",
      isVerify: this.props.location?.state?.isVerify || false, // Return verified or not
      loginData: JSON.parse(localStorage.getItem("loginData")),
    };
    this.props.resetAuth();
  }

  onVerify = () => {
    const { isVerify, loginData } = this.state;
    let otpData = {};
    if (!isVerify) {
      otpData.otp = this.state.otp;
      otpData.isVerify = isVerify;
      otpData.phone = loginData.phone;
      otpData.countryId = loginData.countryId;
    } else {
      otpData.otp = this.state.otp;
      otpData.isVerify = true;
      otpData.token = this.props.verifyData?.code;
    }

    if (!this.props.loading && this.state.otp) {
      this.props.verifyOtp(otpData, this.props.history);
    }
  };

  onResend = () => {
    const { isVerify, loginData } = this.state;
    if (!this.props.loading1) {
      const otpData = {};
      if (!isVerify) {
        otpData.phone = loginData.phone;
        otpData.countryId = loginData.countryId;
        otpData.isVerify = false;
      } else {
        otpData.code = this.props.verifyData.code;
        otpData.typeId = this.props.verifyData.typeId;
        otpData.phone = loginData.phone;
        otpData.countryId = loginData.countryId;
        otpData.isVerify = true;
      }
      this.props.resendOtp(otpData);
    }
  };

  handleChange = (otp) => this.setState({ otp });

  hasLoginData = (obj) => {
    for (var key in obj) {
      if (obj[key] !== null && obj[key] !== "") return true;
    }
    return false;
  };

  componentDidMount = () => {
    document.addEventListener(
      "keydown",
      (e) => e.code === "Enter" && this.onVerify()
    );
  };

  // componentWillUnmount() {
  //   document.removeEventListener("keydown", this.onVerify, false);
  // }

  render() {
    const { t } = this.props;
    const loginData = this.state.loginData;
    if (!this.hasLoginData(loginData)) {
      return <Redirect to="/" />;
    }

    return (
      <div className="auth-wrapper">
        <img
          className="w-52 block xl:hidden my-8 mx-auto"
          src={require("../../../assets/images/Malling-logo-hv.svg").default}
          alt=""
        />

        <div className="auth-wrapper-aside">
          <img width="200px" height="auto"
            src={require("../../../assets/images/Malling-logo-hv.svg").default}
            alt=""
          />

          <h2>{t("AUTH.OTP.HEADER")}</h2>

          <p>{t("AUTH.OTP.DESCRIPTION")}</p>

          <footer>© {t("AUTH.GENERAL.FOOTER")}</footer>
        </div>

        <div className="auth-wrapper-content">
          <header>
            <h4>
              {/* {t("AUTH.GENERAL.FINDING_TROUBLE")}{" "} */}
              <a href="/" className="text-primary-500">
                {t("AUTH.GENERAL.SELECT_LANGUAGE")}
              </a>
            </h4>

            <SetLanguage />
          </header>

          <h1>{t("AUTH.OTP.TITLE")}</h1>
          <div className="flex mt-6">
            <span className="mr-5 fill-current text-primary-600 svg-size-medium">
              <IconPhoneCode />
            </span>

            <h3 className="text-2xl">
              <span className="block mb-1 text-xl font-light text-grey-800">
                {t("AUTH.OTP.SUBTITLE")}
              </span>
              {loginData.isdCode + "" + loginData.phone}
            </h3>
          </div>

          <div className="mt-10 2xl:mt-20">
            <label htmlFor="" className="field-label">
              {t("AUTH.OTP.LABEL")}
            </label>

            <OtpInput
              value={this.state.otp}
              onChange={this.handleChange}
              numInputs={6}
              containerStyle="mt-6"
              inputStyle="field-otp"
              errorStyle={{}}
              isInputNum={true}
              shouldAutoFocus={true}
            />

            {/* {this.props.error ? <span>{this.props.error}</span> : null} */}
          </div>

          <button
            className="mt-16 btn-primary md"
            type="submit"
            onClick={this.onVerify}
            disabled={this.props.loading}
          >
            {t("AUTH.GENERAL.VERFIY_BUTTON")}

            {this.props.loading && (
              <span className="ml-3 -mr-1">
                <IconSpinner />
              </span>
            )}
          </button>

          <p className="text-gray-400 mt-12">
            {t("AUTH.OTP.NOT_RECEIVE_OTP")}{" "}
            <button
              type="button"
              className="text-primary-700 focus:outline-none"
              onClick={this.onResend}
            >
              {t("AUTH.OTP.RESEND_CODE")}{" "}
              {this.props.loading1 && (
                <span className="ml-3 -mr-1">
                  <IconSpinner />
                </span>
              )}
            </button>
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loading1, loading, error, success, message, verifyData } = auth;
  return { loading1, loading, error, success, message, verifyData };
};
export default connect(mapStateToProps, { verifyOtp, resendOtp, resetAuth })(
  withTranslation()(Otp)
);
