import React from "react";

const IconFile = (props) => {
  return (
    <svg width="22" height="22" viewBox="0 0 14.765 19.31">
      <g
        id="Group_1927"
        dataname="Group 1927"
        transform="translate(-143.898 -448.281)"
      >
        <path
          id="Path_9323"
          dataname="Path 9323"
          d="M158.663,452.422v12.555a2.617,2.617,0,0,1-2.619,2.615h-9.531a2.613,2.613,0,0,1-2.615-2.615V450.9a2.613,2.613,0,0,1,2.615-2.615h8.015Z"
          fill="#b1a587"
        />
        <path
          id="Path_9324"
          dataname="Path 9324"
          d="M170.394,452.422l-4.136-4.141V452a.425.425,0,0,0,.425.425Z"
          transform="translate(-11.731)"
          fill="#eae6dc"
        />
        <text
          id="JPG"
          transform="translate(146.484 459.872)"
          fill="#fff"
          fontSize="5"
        >
          <tspan x="0" y="0">
            {props.fileType}
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export default IconFile;
