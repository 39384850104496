import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { connect } from "react-redux";
import { IconBell } from "src/components/svg";
import { toCapitalCase } from "src/helpers/util";
import moment from "moment";
import { useTranslation } from "react-i18next";

const NotificationDropDown = (props) => {
    const { t } = useTranslation();
  const { notifications } = props;
  const unReadCount =
    notifications &&
    notifications.filter((item) => item.readAt === null).length;

  return (
    <Menu as="div" className="relative inline-block">
      <Menu.Button className="relative">
        <IconBell />
        {unReadCount > 0 && <span className="badge"></span>}
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          as="div"
          className="w-80 absolute left-2/4 top-full mt-5 !ml-0 ring-0 shadow-md rounded bg-white origin-top-right -translate-x-1/2 divide-y divide-primary-200 children:py-3 children:px-7"
        >
          <Menu.Item as="h5" className="flex items-center font-medium">
              {t("NOTIFICATION.TITLE")}{" "}
            {unReadCount > 0 && (
              <span className="min-w-[20px] h-5 flex justify-center items-center ml-2 rounded-full bg-red-400 text-white text-xs">
                {unReadCount}
              </span>
            )}
          </Menu.Item>
          {notifications &&
            notifications.length > 0 &&
            notifications.map((item, index) => {
              const location = {
                pathname: `/${item.link}`,
                state: {
                  fromNotification: true,
                  readAt: item.readAt,
                  id: item.id,
                  moduleId: item.moduleId,
                },
              };
              return (
                <Menu.Item
                  as={Link}
                  to={location}
                  className={`block ${
                    item.readAt === null ? "bg-grey-300" : ""
                  }`}
                  key={index}
                >
                  <span className="text-ellipsis line-2">
                    <span className="mr-2 text-primary-500">
                      {toCapitalCase(item.action)} {toCapitalCase(item.type)}
                    </span>
                    {item.data}
                  </span>
                  <small className="block text-gray-400 text-xs">
                    {moment.utc(item.createdAt).fromNow()}
                  </small>
                </Menu.Item>
              );
            })}

          {notifications && notifications.length === 0 && (
            <Menu.Item
              as={Link}
              to="#"
              className="block text-grey-600 font-medium"
            >
                {t("NOTIFICATION.NO_NOTIFICATION")}
            </Menu.Item>
          )}

          {notifications && notifications.length > 0 && (
            <Menu.Item
              as={Link}
              to="/notification"
              className="block text-primary-600 font-medium"
            >
                {t("NOTIFICATION.VIEW_ALL_NOTIFICATION")}
            </Menu.Item>
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

const mapStateToProps = ({ auth }) => {
  const { user } = auth;
  return {
    user,
  };
};

export default connect(mapStateToProps, {})(NotificationDropDown);
