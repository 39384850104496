import React from "react";

const IconCalendar = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 19 19">
      <g
        id="Group_2710"
        dataname="Group 2710"
        transform="translate(-2.5 -2.5)"
      >
        <rect
          id="Rectangle_1669"
          dataname="Rectangle 1669"
          width="18"
          height="15"
          rx="2"
          transform="translate(3 6)"
          fill="none"
          stroke="#000"
          strokeWidth="1"
        />
        <path
          id="Path_10054"
          dataname="Path 10054"
          d="M4,11H20"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeWidth="1"
        />
        <path
          id="Path_10055"
          dataname="Path 10055"
          d="M9,16h6"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeWidth="1"
        />
        <path
          id="Path_10056"
          dataname="Path 10056"
          d="M8,3V7"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeWidth="1"
        />
        <path
          id="Path_10057"
          dataname="Path 10057"
          d="M16,3V7"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export default IconCalendar;
