import Req from "../interceptors/token-interceptor";

const UserService = (function () {
  function _getAllUser() {
    return Req.get(
      `/api/getAllUserList?page=1&pageSize=${process.env.REACT_APP_DEFAULT_DATA_LIST_COUNT ? process.env.REACT_APP_DEFAULT_DATA_LIST_COUNT : 100}&sortedBy=asc&orderBy=first_name`,
      { withCredentials: true }
    );
  }
  function _resendVerificationCode(id) {
    return Req.get(`/api/users/verificationCode/${id}`);
  }
  function _getUser(params) {
    return Req.get(`/api/users/${params.id}?switchRole=${params.roleId}`);
  }
  function _addUser(data, roleId) {
    return Req.post(`/api/users?switchRole=${roleId}`, data);
  }
  function _editUser(data, id, roleId) {
    return Req.put(`/api/users/${id}?switchRole=${roleId}`, data);
  }
  function _deleteUser(data) {
    return Req.delete(`/api/users/${data.userData.id}?switchRole=${data.userData.roleId}`);
  }
  function _deleteMultipleUser(data) {
    return Req.post(`/api/massDelete?switchRole=${data.userIds.roleId}`, { type: "users", ids: data.userIds.id });
  }
  function _changePassword(data) {
    return Req.post(`/api/changePassword`, data);
  }
  function _updateEmailSetting(data) {
    return Req.put(`/api/users/updateUserSetting/${data.id}`, data);
  }
  function _userSearch(search) {
    return Req.get(`/api/getAllUserList?searchData=${search}`);
  }

  function _getInvestorsByUser() {
    return Req.get(`/api/getLoginInvestorList`);
  }
  return {
    getAllUser: _getAllUser,
    resendVerificationCode: _resendVerificationCode,
    getUser: _getUser,
    addUser: _addUser,
    editUser: _editUser,
    deleteUser: _deleteUser,
    deleteMultipleUser: _deleteMultipleUser,
    changePassword: _changePassword,
    updateEmailSetting: _updateEmailSetting,
    userSearch: _userSearch,
    getInvestorsByUser: _getInvestorsByUser,
  };
})();
export default UserService;
