import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import DocumentService from "../../../services/document-service";
import TableDataService from "../../../services/table-data-service1";
import { saveAs } from "file-saver";
import { convertToSlug } from "src/helpers/util";
import moment from "moment";
import {
  GET_ALL_PROJECT_FOLDER,
  ADD_PROJECT_FOLDER,
  EDIT_PROJECT_FOLDER,
  DELETE_PROJECT_FOLDER,
  GET_PROJECT_FOLDER_LIST,
  GET_FOLDER_DOCUMENT_LIST,
  ADD_FOLDER_DOCUMENT,
  GET_PROJECT_FOLDER,
  GET_FOLDER_DOCUMENT,
  EDIT_FOLDER_DOCUMENT,
  DELETE_FOLDER_DOCUMENT,
  DELETE_MULTIPLE_FOLDER_DOCUMENT,
  DELETE_ROOT_PROJECT,
  DOWNLOAD_BOARD_DOCUMENT,
  VIEW_BOARD_DOCUMENT,
} from "../../actions";
import {
  getAllProjectFolder,
  getAllProjectFolderSuccess,
  getAllProjectFolderError,
  addProjectFolderSuccess,
  addProjectFolderError,
  editProjectFolderSuccess,
  editProjectFolderError,
  deleteProjectFolderSuccess,
  deleteProjectFolderError,
  getProjectFolderList,
  getProjectFolderListSuccess,
  getProjectFolderListError,
  getFolderDocumentList,
  getFolderDocumentListSuccess,
  getFolderDocumentListError,
  addFolderDocumentSuccess,
  addFolderDocumentError,
  getProjectFolderSuccess,
  getProjectFolderError,
  getFolderDocumentSuccess,
  getFolderDocumentError,
  editFolderDocumentSuccess,
  editFolderDocumentError,
  deleteFolderDocumentSuccess,
  deleteFolderDocumentError,
  deleteMultipleFolderDocumentSuccess,
  deleteMultipleFolderDocumentError,
  resetFolderDocument,
  deleteRootProjectSuccess,
  deleteRootProjectError,
  downloadBoardDocumentSuccess,
  downloadBoardDocumentError,
  viewBoardDocumentSuccess,
  viewBoardDocumentError,
} from "./action";
import { toast } from "react-toastify";
import { handleMyErrorMessage } from "../../../helpers/util";
import ToastElement from "../../../components/toast";

export const getBoardDocument = (state) => state.boardDocument;
//Fetching project lists
export function* watchGetAllProjectFolder() {
  yield takeEvery(GET_ALL_PROJECT_FOLDER, getAllProjectFolders);
}

const getAllProjectFolderAsync = async (payload) => {
  const currentRole = JSON.parse(localStorage.getItem("currentRole"));
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  if (currentRole) {
    return DocumentService.getAllProjects({
      defaultRoleId: currentRole?.id,
      payload,
    });
  } else {
    return DocumentService.getAllProjects({
      defaultRoleId: currentUser?.default_role_id,
      payload,
    });
  }
};
function* getAllProjectFolders({ payload }) {
  try {
    const response = yield call(getAllProjectFolderAsync, payload);
    if (response.data.success) {
      yield put(getAllProjectFolderSuccess(response.data.data));
      localStorage.setItem(
        "rootProjectFolders",
        JSON.stringify(response.data.data)
      );
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getAllProjectFolderError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getAllProjectFolderError(error.response.data.message));
    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getAllProjectFolderError(errorMessage));
    }
  }
}

//creating project folder

export function* watchAddProjectfolder() {
  yield takeEvery(ADD_PROJECT_FOLDER, addProjectFolder);
}

const addProjectFolderAsync = async (data) => {
  return DocumentService.addProject(data);
};

function* addProjectFolder({ payload }) {
  try {
    const response = yield call(addProjectFolderAsync, payload.folderData);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        addProjectFolderSuccess(response.data.success, response.data.message)
      );

      yield put(getAllProjectFolder({}));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(addProjectFolderError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type="error"
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   { containerId: "default" }
    // );
    // yield put(addProjectFolderError(error.response.data.message));
    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(addProjectFolderError(errorMessage));
    }
  }
}

//get folders of project

export function* watchGetAllProjectFolderList() {
  yield takeEvery(GET_PROJECT_FOLDER_LIST, getAllProjectFolderList);
}

const getAllFolderAsync = async (projectId) => {
  return DocumentService.getAllProjectFolders(projectId);
};

function* getAllProjectFolderList({ payload }) {
  try {
    const response = yield call(getAllFolderAsync, payload.projectId);
    if (response.data.success) {
      yield put(getProjectFolderListSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getProjectFolderListError(response.data.message));
    }
  } catch (error) {
    // if (error.response.status === 404) {
    //   yield put(getProjectFolderListError("404"));
    // } else {
    //   toast.error(
    //     <ToastElement type="error" message={error.response.data.message} />,
    //     { containerId: "default" }
    //   );
    //   yield put(getProjectFolderListError(error.response.data.message));
    // }

    if (error.response && error.response.status === 404) {
      yield put(getProjectFolderListError("404"));
    } else {
      const errorMessage = handleMyErrorMessage(error);
      if (errorMessage) {
        toast.error(<ToastElement type="error" message={errorMessage} />, {
          containerId: "default",
        });
        yield put(getProjectFolderListError(errorMessage));
      }
    }
  }
}

//getting folder data by id while editing
export function* watchGetProjectFolder() {
  yield takeEvery(GET_PROJECT_FOLDER, getProjectFolder);
}

const getProjectFolderAsync = async (id) => {
  return DocumentService.getProjectFolder(id);
};

function* getProjectFolder({ payload }) {
  try {
    const response = yield call(getProjectFolderAsync, payload.folderId);
    if (response.data.success) {
      yield put(getProjectFolderSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getProjectFolderError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getProjectFolderError(error.response.data.message));
    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getProjectFolderError(errorMessage));
    }
  }
}

//update project folder

export function* watchEditProjectFolder() {
  yield takeEvery(EDIT_PROJECT_FOLDER, editProjectFolder);
}

const editProjectFolderAsync = async (data, id) => {
  return DocumentService.editProjectFolder(data, id);
};

function* editProjectFolder({ payload }) {
  try {
    const response = yield call(
      editProjectFolderAsync,
      payload.folderData,
      payload.folderId
    );
    if (response.data.success) {
      const { projectId } = payload.folderData;
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        editProjectFolderSuccess(response.data.success, response.data.message)
      );
      yield put(getProjectFolderList(projectId));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(editProjectFolderError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type="error"
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   { containerId: "default" }
    // );
    // yield put(editProjectFolderError(error.response.data.message));
    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(editProjectFolderError(errorMessage));
    }
  }
}

//get documents of folder inside project

export function* watchGetFolderDocumentList() {
  yield takeEvery(GET_FOLDER_DOCUMENT_LIST, getFolderDocumentListAc);
}

const getFolderDocumentListAsync = async (dbParam) => {
  const currentRole = JSON.parse(localStorage.getItem("currentRole"));
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  if (currentRole) {
    dbParam.defaultRoleId = currentRole?.id;
  } else {
    dbParam.defaultRoleId = currentUser.default_role_id;
  }
  if (dbParam.projectId && dbParam.boardDocumentFolderId) {
    localStorage.setItem(
      "boardDocumentData",
      JSON.stringify({
        projectId: dbParam.projectId,
        boardDocumentFolderId: dbParam.boardDocumentFolderId,
      })
    );
  }

  if (!dbParam.projectId && !dbParam.boardDocumentFolderId) {
    const boardDocumentData = JSON.parse(
      localStorage.getItem("boardDocumentData")
    );
    dbParam.projectId = boardDocumentData.projectId;
    dbParam.boardDocumentFolderId = boardDocumentData.boardDocumentFolderId;
  }
  return TableDataService.getAllData("boardDocuments", dbParam);
};

function* getFolderDocumentListAc({ payload }) {
  try {
    const response = yield call(getFolderDocumentListAsync, payload.dbParam);

    if (response.data.success) {
      yield put(
        getFolderDocumentListSuccess(response.data.data, response.data.metadata)
      );
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getFolderDocumentListError(response.data.message));
    }
  } catch (error) {
    // if (error.response.status === 404) {
    //   yield put(getFolderDocumentListError("404"));
    // } else {
    //   toast.error(
    //     <ToastElement type="error" message={error.response.data.message} />,
    //     { containerId: "default" }
    //   );
    //   yield put(getFolderDocumentListError(error.response.data.message));
    // }

    if (error.response && error.response.status === 404) {
      yield put(getFolderDocumentListError("404"));
    } else {
      const errorMessage = handleMyErrorMessage(error);
      if (errorMessage) {
        toast.error(<ToastElement type="error" message={errorMessage} />, {
          containerId: "default",
        });
        yield put(getFolderDocumentListError(errorMessage));
      }
    }
  }
}

//add documents in folder

export function* watchAddFolderDocument() {
  yield takeEvery(ADD_FOLDER_DOCUMENT, addFolderDocument);
}

const addFolderDocumentAsync = async (data) => {
  if (data["projectId"]) delete data["projectId"];
  return DocumentService.addFolderDocuments(data);
};

function* addFolderDocument({ payload }) {
  try {
    const response = yield call(addFolderDocumentAsync, payload.documentData);
    if (response.data.success) {
      const { projectId, boardDocumentFolderId } = payload.documentData;
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        addFolderDocumentSuccess(response.data.success, response.data.message)
      );
      yield put(getFolderDocumentList({ projectId, boardDocumentFolderId }));
      yield put(resetFolderDocument());
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(addFolderDocumentError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type="error"
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   { containerId: "default" }
    // );
    // yield put(addFolderDocumentError(error.response.data.message));
    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(addFolderDocumentError(errorMessage));
    }
  }
}

//for getting document by id
export function* watchGetFolderDocument() {
  yield takeEvery(GET_FOLDER_DOCUMENT, getFolderDocument);
}

const getFolderDocumentAsync = async (id) => {
  return DocumentService.getFolderDocument(id);
};

function* getFolderDocument({ payload }) {
  try {
    const response = yield call(getFolderDocumentAsync, payload.documentId);
    if (response.data.success) {
      yield put(getFolderDocumentSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getFolderDocumentError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getFolderDocumentError(error.response.data.message));
    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getFolderDocumentError(errorMessage));
    }
  }
}

//updating documents inside folder

export function* watchEditFolderDocument() {
  yield takeEvery(EDIT_FOLDER_DOCUMENT, editFolderDocument);
}

const editFolderDocumentAsync = async (data, id) => {
  return DocumentService.editFolderDocument(data, id);
};

function* editFolderDocument({ payload }) {
  try {
    const response = yield call(
      editFolderDocumentAsync,
      payload.documentData,
      payload.documentId
    );
    if (response.data.success) {
      const { projectId, boardDocumentFolderId } = payload.documentData;
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        editFolderDocumentSuccess(response.data.success, response.data.message)
      );
      yield put(getFolderDocumentList({ projectId, boardDocumentFolderId }));
      yield put(resetFolderDocument());
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(editFolderDocumentError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type="error"
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   { containerId: "default" }
    // );
    // yield put(editFolderDocumentError(error.response.data.message));
    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(editFolderDocumentError(errorMessage));
    }
  }
}

//deleting folder
export function* watchDeleteProjectFolder() {
  yield takeEvery(DELETE_PROJECT_FOLDER, deleteProjectFolder);
}

const deleteProjectFolderAsync = async (id) => {
  return DocumentService.deleteProjectFolder(id);
};

function* deleteProjectFolder({ payload }) {
  const { projectId, boardDocumentFolderId } = payload.deleteData;
  try {
    const response = yield call(
      deleteProjectFolderAsync,
      boardDocumentFolderId
    );
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        deleteProjectFolderSuccess(response.data.success, response.data.message)
      );
      yield put(getProjectFolderList(projectId));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(deleteProjectFolderError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(deleteProjectFolderError(error.response.data.message));
    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(deleteProjectFolderError(errorMessage));
    }
  }
}

//deleting documents of folder
export function* watchDeleteFolderDocument() {
  yield takeEvery(DELETE_FOLDER_DOCUMENT, deleteFolderDocument);
}

const deleteFolderDocumentAsync = async (id) => {
  return DocumentService.deleteFolderDocument(id);
};

function* deleteFolderDocument({ payload }) {
  try {
    const response = yield call(deleteFolderDocumentAsync, payload.documentId);
    if (response.data.success) {
      const { projectId, boardDocumentFolderId } = payload.deleteData;
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        deleteFolderDocumentSuccess(
          response.data.success,
          response.data.message
        )
      );
      yield put(getFolderDocumentList({ projectId, boardDocumentFolderId }));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(deleteFolderDocumentError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(deleteFolderDocumentError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(deleteFolderDocumentError(errorMessage));
    }
  }
}

export function* watchDeleteMultipleFolderDocument() {
  yield takeEvery(
    DELETE_MULTIPLE_FOLDER_DOCUMENT,
    deleteMultipleFolderDocument
  );
}

const deleteMultipleFolderDocumentAsync = async (ids) => {
  return DocumentService.deleteMultipleFolderDocument(ids);
};

function* deleteMultipleFolderDocument({ payload }) {
  try {
    const response = yield call(
      deleteMultipleFolderDocumentAsync,
      payload.documentIds
    );
    if (response.data.success) {
      const { projectId, boardDocumentFolderId } = payload.deleteData;
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        deleteMultipleFolderDocumentSuccess(
          response.data.success,
          response.data.message
        )
      );
      yield put(getFolderDocumentList({ projectId, boardDocumentFolderId }));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(deleteMultipleFolderDocumentError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(deleteMultipleFolderDocumentError(error.response.data.message));
    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(deleteMultipleFolderDocumentError(errorMessage));
    }
  }
}

//deleting main project folder
export function* watchDeleteRootProject() {
  yield takeEvery(DELETE_ROOT_PROJECT, deleteRootProject);
}

const deleteRootProjectAsync = async (id) => {
  return DocumentService.deleteProject(id);
};

function* deleteRootProject({ payload }) {
  try {
    const response = yield call(deleteRootProjectAsync, payload.projectId);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        deleteRootProjectSuccess(response.data.success, response.data.message)
      );
      yield put(getAllProjectFolder());
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(deleteRootProjectError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(deleteRootProjectError(error.response.data.message));
    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(deleteRootProjectError(errorMessage));
    }
  }
}

export function* watchBoardDownloadDocument() {
  yield takeEvery(DOWNLOAD_BOARD_DOCUMENT, downloadBoardDocument);
}

const downloadBoardDocumentAsync = async (id) => {
  return DocumentService.downloadBoardDocuments(id);
};

function* downloadBoardDocument({ payload }) {
  try {
    const response = yield call(downloadBoardDocumentAsync, payload.documentId);
    if (response && response.data) {
      yield put(downloadBoardDocumentSuccess(true, ""));
      const blob = new Blob([response.data], {
        type: "application/zip",
      });
      const fileName =
        convertToSlug(payload.projectName) +
        "-" +
        moment().format("YYYY-MMM-DD-HH-mm");
      saveAs(blob, `${fileName}.zip`);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(downloadBoardDocumentError(response.data.message));
    }
  } catch (error) {
    // let message = "";
    // if (error.response.status === 500) {
    //   message = error.response.statusText;
    // }
    // toast.error(<ToastElement type="error" message={message} />, {
    //   containerId: "default",
    // });
    // yield put(downloadBoardDocumentError(message));

    if (error.response && error.response.status === 500) {
      yield put(downloadBoardDocumentError(error.response.statusText));
    } else {
      const errorMessage = handleMyErrorMessage(error);
      if (errorMessage) {
        toast.error(<ToastElement type="error" message={errorMessage} />, {
          containerId: "default",
        });
        yield put(downloadBoardDocumentError(errorMessage));
      }
    }
  }
}

export function* watchviewBoardDocument() {
  yield takeEvery(VIEW_BOARD_DOCUMENT, viewBoardDocument);
}

const viewBoardDocumentAsync = async (id) => {
  return DocumentService.viewBoardDocument(id);
};

function* viewBoardDocument({ payload }) {
  try {
    const response = yield call(viewBoardDocumentAsync, payload.documentId);
    if (response.data.success) {
      yield put(viewBoardDocumentSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(viewBoardDocumentError(response.data.message));
    }
  } catch (error) {
    // if (error.response.status === 404) {
    //   yield put(viewBoardDocumentError("404"));
    // } else {
    //   toast.error(
    //     <ToastElement type="error" message={error.response.data.message} />,
    //     { containerId: "default" }
    //   );
    //   yield put(viewBoardDocumentError(error.response.data.message));
    // }

    if (error.response && error.response.status === 404) {
      yield put(viewBoardDocumentError("404"));
    } else {
      const errorMessage = handleMyErrorMessage(error);
      if (errorMessage) {
        toast.error(<ToastElement type="error" message={errorMessage} />, {
          containerId: "default",
        });
        yield put(viewBoardDocumentError(errorMessage));
      }
    }
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAllProjectFolder),
    fork(watchAddProjectfolder),
    fork(watchAddFolderDocument),
    fork(watchGetAllProjectFolderList),
    fork(watchEditProjectFolder),
    fork(watchGetFolderDocumentList),
    fork(watchGetProjectFolder),
    fork(watchGetFolderDocument),
    fork(watchEditFolderDocument),
    fork(watchDeleteFolderDocument),
    fork(watchDeleteMultipleFolderDocument),
    fork(watchDeleteProjectFolder),
    fork(watchDeleteRootProject),
    fork(watchBoardDownloadDocument),
    fork(watchviewBoardDocument),
  ]);
}
