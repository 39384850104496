/**
 * Entry application component used to compose providers and render Routes.
 * */
import React, { Suspense } from "react";
import { Provider } from "react-redux";
import store from "./reduxs/store";
import { LayoutSplashScreen } from "./configs/core";
import { AuthGuard } from "./guards";
import { BrowserRouter } from "react-router-dom";
import Route from "./Route";
import "react-toastify/dist/ReactToastify.css";
import ErrorBoundary from "src/components/error/error-boundary";
export default function App() {
  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
      <ErrorBoundary>
        <Suspense fallback={<LayoutSplashScreen />}>
          <BrowserRouter>
            {/* Render routes with provided `Layout`. */}
            <AuthGuard>
              <Route />
            </AuthGuard>
          </BrowserRouter>
        </Suspense>
      </ErrorBoundary>
    </Provider>
  );
}
