import React, { Component } from "react";
import { verifyNewUser } from "../../../reduxs/actions";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import { InputPasswordField } from "../../../components/form";
import * as Yup from "yup";
import { withTranslation } from "react-i18next";
import { IconCheckO, IconSpinner } from "../../../components/svg";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import SetLanguage from "src/views/auth/set-lang";

class WelcomeMalling extends Component {
  constructor(props) {
    super(props);
    this.validationSchema = Yup.object().shape({
      password: Yup.string()
        .required(this.props.t("AUTH.VALIDATION.REQUIRED_FIELD"))
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
          this.props.t("AUTH.VALIDATION.PASSWORD_CRITERIA")
        ),
      passwordConfirmation: Yup.string()
        .required(this.props.t("AUTH.VALIDATION.REQUIRED_FIELD"))
        .oneOf(
          [Yup.ref("password"), null],
          this.props.t("AUTH.VALIDATION.NOT_MATCH")
        ),
    });
  }

  onVerify = (values) => {
    if (!this.props.loading) {
      this.props.verifyNewUser(values, this.props.history);
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div className="auth-wrapper">
        <img
          className="w-52 block xl:hidden my-8 mx-auto"
          src={require("../../../assets/images/Malling-logo-hv.svg").default}
          alt=""
        />

        <div className="auth-wrapper-aside">
          <img width="200px" height="auto"
            src={require("../../../assets/images/Malling-logo-hv.svg").default}
            alt=""
          />

          <h2>{t("AUTH.RESET_PASSWORD.PASSWORD_REQUIREMENT")}</h2>
          <p className="mb-8 !pl-0 text-xl font-light text-white !border-l-0">
            {t("AUTH.RESET_PASSWORD.PASSWORD_MUST_CONTAIN")}
          </p>

          <ul>
            <li>{t("AUTH.RESET_PASSWORD.PASSWORD_CRITERIA_1")}</li>
            <li>{t("AUTH.RESET_PASSWORD.PASSWORD_CRITERIA_2")}</li>
            <li>{t("AUTH.RESET_PASSWORD.PASSWORD_CRITERIA_3")}</li>
            <li>{t("AUTH.RESET_PASSWORD.PASSWORD_CRITERIA_4")}</li>
            <li>{t("AUTH.RESET_PASSWORD.PASSWORD_CRITERIA_5")}</li>
          </ul>

          <footer>© 2021 Malling & CO Investment</footer>
        </div>

        <div className="auth-wrapper-content">
          <header>
            <SetLanguage />
          </header>

          {!this.props.loading && this.props.success ? (
            //Show Success Message If Initial Password Change Success
            <div className="auth-wrapper-content__succes-ui">
              <figure>
                <IconCheckO />
              </figure>
              <h1>{t("AUTH.WELCOME.SUCCESS_TITLE")}</h1>
              <h6>{t("AUTH.WELCOME.SUCCESS_MESSAGE")}</h6>

              <Link to={"/auth/login"} className="btn-back-login">
                {t("AUTH.FORGOT.RETURN_TO_LOGIN")}
              </Link>
            </div>
          ) : (
            //Initial Password Change Form
            <>
              <h1>{t("AUTH.WELCOME.TITLE")}</h1>
              <h6>{t("AUTH.WELCOME.SETUP_FOR_EMPLOYEE")}</h6>

              <Formik
                initialValues={{
                  password: "",
                  passwordConfirmation: "",
                }}
                validationSchema={this.validationSchema}
                onSubmit={this.onVerify}
              >
                {(props) => (
                  <Form className="w-full xl:w-3/5 2xl:w-2/5">
                    {/*<InputPasswordField*/}
                    {/*  name="passwordConfirmation"*/}
                    {/*  label={t("AUTH.INPUT.CONFIRM_PASSWORD")}*/}
                    {/*/>*/}

                    {/*<InputPasswordField*/}
                    {/*  name="password"*/}
                    {/*  label={t("AUTH.INPUT.NEW_PASSWORD")}*/}
                    {/*/>*/}
                    <InputPasswordField
                        name="password"
                        label={t("AUTH.INPUT.NEW_PASSWORD")}
                    />
                    <InputPasswordField
                        name="passwordConfirmation"
                        label={t("AUTH.INPUT.CONFIRM_PASSWORD")}
                    />
                    <div className="flex mt-12 space-x-5">
                      <button
                        className="btn-primary md"
                        type="submit"
                        disabled={this.props.loading}
                      >
                        {t("AUTH.GENERAL.SUBMIT_BUTTON")}
                        {this.props.loading && (
                          <span className="ml-3 -mr-1">
                            <IconSpinner />
                          </span>
                        )}
                      </button>

                      <Link
                        className="inline-flex btn-primary-outlined md"
                        disabled={this.props.loading}
                        to="/auth/login"
                      >
                        {t("AUTH.GENERAL.CANCEL_BUTTON")}
                      </Link>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loading1, loading, error } = auth;
  return { loading1, loading, error };
};
export default withRouter(
  connect(mapStateToProps, { verifyNewUser })(withTranslation()(WelcomeMalling))
);
