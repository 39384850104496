import React from "react";

const IconPhone = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 24.215 22.823"
    >
      <g
        id="Group_1885"
        dataname="Group 1885"
        transform="translate(-296.604 273.812)"
        opacity="0.998"
      >
        <path
          id="Path_9234"
          dataname="Path 9234"
          d="M311.42-258.457l1.426-.769a1.54,1.54,0,0,1,1.628.1l3.414,2.448a1.538,1.538,0,0,1,.18,2.35L315.69-252a1.546,1.546,0,0,1-1.645.331c-11.368-4.443-15.7-14.6-16.789-17.73a1.535,1.535,0,0,1,.381-1.61,26.918,26.918,0,0,1,2.247-1.939,1.54,1.54,0,0,1,2.219.363l2.374,3.545a1.538,1.538,0,0,1,.1,1.549l-.738,1.464a1.538,1.538,0,0,0,.172,1.655,33.855,33.855,0,0,0,5.775,5.8A1.546,1.546,0,0,0,311.42-258.457Z"
          transform="translate(0)"
          fill="none"
          stroke="#1a1818"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.089"
        />
        <path
          id="Path_9235"
          dataname="Path 9235"
          d="M313.519-267.849a7.911,7.911,0,0,1,11.1,3.827,7.83,7.83,0,0,1,.567,3.636"
          transform="translate(-4.943 -1.337)"
          fill="none"
          stroke="#1a1818"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.089"
        />
        <path
          id="Path_9236"
          dataname="Path 9236"
          d="M315.523-264.154a5.048,5.048,0,0,1,7.179,2.692,5.013,5.013,0,0,1,.219,2.445"
          transform="translate(-5.549 -2.563)"
          fill="none"
          stroke="#1a1818"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.089"
        />
      </g>
    </svg>
  );
};

export default IconPhone;
