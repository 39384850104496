import Req from "../interceptors/token-interceptor";
const DocumentService = (function () {
  function _getRecentDocument(payload) {
    return Req.get(
      `/api/documents?orderBy=updated_at&page=1&pageSize=5&sortedBy=desc&defaultRoleId=${payload}`
    );
  }

  function _getAllDocument() {
    return Req.get(`/api/documents`);
  }
  function _getDocument(id) {
    return Req.get(`/api/documents/${id}`);
  }
  function _addDocument(data) {
    return Req.post("/api/documents", data);
  }
  function _editDocument(data, id) {
    return Req.put(`/api/documents/${id}`, data);
  }
  function _deleteDocument(id) {
    return Req.delete(`/api/documents/${id}`);
  }
  function _deleteMultipleDocument(ids) {
    return Req.post("/api/massDelete", { type: "document", ids: ids });
  }

  function _downloadNormalDocument(id) {
    return Req.get(`/api/documents/${id}?download=true`, {
      responseType: "arraybuffer",
    });
  }

  //for board documents services
  //fetch all projects of board documents
  function _getAllProjects(payload) {
    let el = "";
    if (payload.payload && payload.payload.search) {
      el += `&search=${payload.payload.search}`;
    }
    if (payload.payload && payload.payload.SearchFields) {
      el += `&searchFields=${payload.payload.SearchFields}`;
    }

    return Req.get(
      `/api/boardDocument/projects?defaultRoleId=${payload.defaultRoleId}${el}`
    );
  }
  //add projects of board documents
  function _addProject(data) {
    return Req.post("/api/boardDocument/folders", data);
  }
  //fetch all folders inside project
  function _getAllProjectFolders(projectId) {
    return Req.get(`/api/boardDocument/folders?projectId=${projectId}`);
  }
  //add ducuments in project folder
  function _addFolderDocuments(data) {
    return Req.post(`/api/boardDocuments`, data);
  }

  //fetch folderData by folder id
  function _getProjectFolder(folderId) {
    return Req.get(`/api/boardDocument/folders/${folderId}`);
  }
  //fetch update folder by folderid
  function _editProjectFolder(data, folderId) {
    return Req.put(`/api/boardDocument/folders/${folderId}`, data);
  }

  function _getProject(id) {
    return Req.get(`/api/boardDocument/folders/${id}`);
  }
  function _deleteProject(id) {
    return Req.delete(`/api/boardDocument/projects/${id}`);
  }
  function _deleteProjectFolder(id) {
    return Req.delete(`/api/boardDocument/folders/${id}`);
  }
  //get document of folder by id
  function _getFolderDocument(id) {
    return Req.get(`/api/boardDocuments/${id}`);
  }

  function _editFolderDocument(data, id) {
    return Req.put(`/api/boardDocuments/${id}`, data);
  }
  function _deleteFolderDocument(id) {
    return Req.delete(`/api/boardDocuments/${id}`);
  }
  function _deleteMultipleFolderDocument(ids) {
    return Req.post("/api/massDelete", { type: "boardDocument", ids: ids });
  }
  function _downloadBoardDocuments(id) {
    return Req.get(`/api/boardDocuments/${id}?download=true`, {
      responseType: "arraybuffer",
    });
  }
  function _viewBoardDocument(id) {
    return Req.get(`/api/boardDocuments/${id}`);
  }

  function _getDocumentFilesById(id) {
    return Req.get(`/api/getDocumentByDocumentId/${id}`);
  }

  return {
    getRecentDocument: _getRecentDocument,
    getAllDocument: _getAllDocument,
    getDocument: _getDocument,
    addDocument: _addDocument,
    editDocument: _editDocument,
    deleteDocument: _deleteDocument,
    deleteMultipleDocument: _deleteMultipleDocument,
    addProject: _addProject,
    getAllProjects: _getAllProjects,
    getProject: _getProject,
    deleteProject: _deleteProject,
    deleteProjectFolder: _deleteProjectFolder,
    getAllProjectFolders: _getAllProjectFolders,
    getProjectFolder: _getProjectFolder,
    editProjectFolder: _editProjectFolder,
    addFolderDocuments: _addFolderDocuments,
    getFolderDocument: _getFolderDocument,
    editFolderDocument: _editFolderDocument,
    deleteFolderDocument: _deleteFolderDocument,
    deleteMultipleFolderDocument: _deleteMultipleFolderDocument,
    downloadNormalDocument: _downloadNormalDocument,
    downloadBoardDocuments: _downloadBoardDocuments,
    viewBoardDocument: _viewBoardDocument,
    getDocumentFilesById: _getDocumentFilesById,
  };
})();
export default DocumentService;
