import React from "react";

const IconArrowRight = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 7.49 6.428">
      <g
        id="Group_2798"
        dataname="Group 2798"
        transform="translate(-1197.287 1768.151) rotate(-90)"
      >
        <g
          id="Group_2799"
          dataname="Group 2799"
          transform="translate(1762.43 1197.787)"
        >
          <path
            id="Path_10124"
            dataname="Path 10124"
            d="M0,0,2.507,2.507,5.014,0"
            transform="translate(0 3.983)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_10125"
            dataname="Path 10125"
            d="M0,6.374V0"
            transform="translate(2.507)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  );
};

export default IconArrowRight;
