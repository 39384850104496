import {
     GET_ALL_INVESTOR,
     GET_ALL_INVESTOR_SUCCESS,
     GET_ALL_INVESTOR_ERROR,
     GET_INVESTOR_LIST,
     GET_INVESTOR_LIST_SUCCESS,
     GET_INVESTOR_LIST_ERROR,
     ADD_INVESTOR,
     ADD_INVESTOR_SUCCESS,
     ADD_INVESTOR_ERROR,
     GET_INVESTOR,
     GET_INVESTOR_SUCCESS,
     GET_INVESTOR_ERROR,
     EDIT_INVESTOR,
     EDIT_INVESTOR_SUCCESS,
     EDIT_INVESTOR_ERROR,
     DELETE_INVESTOR,
     DELETE_INVESTOR_SUCCESS,
     DELETE_INVESTOR_ERROR,
     DELETE_MULTIPLE_INVESTOR,
     DELETE_MULTIPLE_INVESTOR_SUCCESS,
     DELETE_MULTIPLE_INVESTOR_ERROR,
     RESET_INVESTOR,
     EXPORT_INVESTOR,
     EXPORT_INVESTOR_SUCCESS,
     EXPORT_INVESTOR_ERROR,
     ADD_INVESTOR_COMMENT,
     ADD_INVESTOR_COMMENT_SUCCESS,
     ADD_INVESTOR_COMMENT_ERROR,
     EDIT_INVESTOR_COMMENT,
     EDIT_INVESTOR_COMMENT_SUCCESS,
     EDIT_INVESTOR_COMMENT_ERROR,
     DELETE_INVESTOR_COMMENT,
     DELETE_INVESTOR_COMMENT_ERROR,
     DELETE_INVESTOR_COMMENT_SUCCESS,
     GET_ALL_INVESTOR_COMMENT,
     GET_ALL_INVESTOR_COMMENT_SUCCESS,
     GET_ALL_INVESTOR_COMMENT_ERROR,
     RESET_INVESTOR_LIST
} from "../actions";

const INIT_STATE = {
     dbParam: null,
     investors: null,
     investorList: null,
     investorData: null,
     investorId: null,
     investorIds: null,
     success: false,
     message: null,
     loading: false,
     loading1: false,
     error: null,
     metaData: null,
     loading3: false,
     comments: null
};

const investorReducer = (state = INIT_STATE, action) => {
     switch (action.type) {
          case GET_ALL_INVESTOR:
               return {
                    ...state,
                    error: null,
               };
          case GET_ALL_INVESTOR_SUCCESS:
               return {
                    ...state,
                    investors: action.payload,
                    error: null,
               };
          case GET_ALL_INVESTOR_ERROR:
               return {
                    ...state,
                    investors: null,
                    error: action.payload,
               };
          case GET_INVESTOR_LIST:
               return {
                    ...state,
                    loading: true,
                    investorId: null,
                    investorData: null,
                    investorIds: null,
                    error: null,
               };
          case GET_INVESTOR_LIST_SUCCESS:
               return {
                    ...state,
                    loading: false,
                    investorList: action.payload.investorList,
                    metaData: action.payload.metadata,
                    error: null,
               };
          case GET_INVESTOR_LIST_ERROR:
               return {
                    ...state,
                    loading: false,
                    investorList: null,
                    error: action.payload,
               };
          case ADD_INVESTOR:
               return { ...state, loading: true, error: null };
          case ADD_INVESTOR_SUCCESS:
               return {
                    ...state,
                    loading: false,
                    success: action.payload.success,
                    message: action.payload.message,
                    error: null,
               };
          case ADD_INVESTOR_ERROR:
               return {
                    ...state,
                    loading: false,
                    success: false,
                    message: null,
                    error: action.payload,
               };
          case GET_INVESTOR:
               return { ...state, error: null };
          case GET_INVESTOR_SUCCESS:
               return {
                    ...state,
                    investorData: action.payload,
                    error: null,
               };
          case GET_INVESTOR_ERROR:
               return {
                    ...state,
                    investorData: null,
                    error: action.payload,
               };
          case EDIT_INVESTOR:
               return { ...state, loading: true, error: null };
          case EDIT_INVESTOR_SUCCESS:
               return {
                    ...state,
                    loading: false,
                    success: action.payload.success,
                    message: action.payload.message,
                    error: null,
               };
          case EDIT_INVESTOR_ERROR:
               return {
                    ...state,
                    loading: false,
                    success: false,
                    message: null,
                    error: action.payload,
               };
          case DELETE_INVESTOR:
               return { ...state, loading1: true, error: null };
          case DELETE_INVESTOR_SUCCESS:
               return {
                    ...state,
                    loading1: false,
                    success: action.payload.success,
                    message: action.payload.message,
                    error: null,
               };
          case DELETE_INVESTOR_ERROR:
               return {
                    ...state,
                    loading1: false,
                    success: false,
                    message: null,
                    error: action.payload,
               };
          case DELETE_MULTIPLE_INVESTOR:
               return { ...state, loading1: true, error: null };
          case DELETE_MULTIPLE_INVESTOR_SUCCESS:
               return {
                    ...state,
                    loading1: false,
                    success: action.payload.success,
                    message: action.payload.message,
                    error: null,
               };
          case DELETE_MULTIPLE_INVESTOR_ERROR:
               return {
                    ...state,
                    loading1: false,
                    success: false,
                    message: null,
                    error: action.payload,
               };
          case RESET_INVESTOR:
               return {
                    ...state,
                    loading: false,
                    loading1: false,
                    success: false,
                    message: null,
                    error: null,
                   
               };
          case EXPORT_INVESTOR:
               return { ...state, loading3: true, error: null };
          case EXPORT_INVESTOR_SUCCESS:
               return {
                    ...state,
                    loading3: false,
                    success: action.payload.success,
                    message: action.payload.message,
                    error: null,
               };
          case EXPORT_INVESTOR_ERROR:
               return {
                    ...state,
                    loading3: false,
                    success: false,
                    message: null,
                    error: action.payload,
               };
          case ADD_INVESTOR_COMMENT:
               return { ...state, loading1: true, error: null };
          case ADD_INVESTOR_COMMENT_SUCCESS:
               return {
                    ...state,
                    loading1: false,
                    success: action.payload.success,
                    message: action.payload.message,
                    error: null,
               };
          case ADD_INVESTOR_COMMENT_ERROR:
               return {
                    ...state,
                    loading1: false,
                    success: false,
                    message: null,
                    error: action.payload,
               };
          case EDIT_INVESTOR_COMMENT:
               return { ...state, loading1: true, error: null };
          case EDIT_INVESTOR_COMMENT_SUCCESS:
               return {
                    ...state,
                    loading1: false,
                    success: action.payload.success,
                    message: action.payload.message,
                    error: null,
               };
          case EDIT_INVESTOR_COMMENT_ERROR:
               return {
                    ...state,
                    loading1: false,
                    success: false,
                    message: null,
                    error: action.payload,
               };
          case DELETE_INVESTOR_COMMENT:
               return { ...state, loading1: true, error: null };
          case DELETE_INVESTOR_COMMENT_SUCCESS:
               return {
                    ...state,
                    loading1: false,
                    success: action.payload.success,
                    message: action.payload.message,
                    error: null,
               };
          case DELETE_INVESTOR_COMMENT_ERROR:
               return {
                    ...state,
                    loading1: false,
                    success: false,
                    message: null,
                    error: action.payload,
               };
          case GET_ALL_INVESTOR_COMMENT:
               return {
                    ...state,
                    loading: true,
                    error: null,
               };
          case GET_ALL_INVESTOR_COMMENT_SUCCESS:
               return {
                    ...state,
                    loading: false,
                    comments: action.payload,
                    error: null,
               };
          case GET_ALL_INVESTOR_COMMENT_ERROR:
               return {
                    ...state,
                    loading: false,
                    comments: null,
                    error: action.payload,
               };
          case RESET_INVESTOR_LIST:
               return {
                    ...state,
                    loading: false,
                    loading1: false,
                    success: false,
                    message: null,
                    error: null,
                    investorList:null
               }
          default:
               return { ...state };
     }
};
export default investorReducer;
