import {
  GET_ALL_DOCUMENT,
  GET_ALL_DOCUMENT_SUCCESS,
  GET_ALL_DOCUMENT_ERROR,
  GET_RECENT_DOCUMENT,
  GET_RECENT_DOCUMENT_SUCCESS,
  GET_RECENT_DOCUMENT_ERROR,
  GET_DOCUMENT_LIST,
  GET_DOCUMENT_LIST_SUCCESS,
  GET_DOCUMENT_LIST_ERROR,
  ADD_DOCUMENT,
  ADD_DOCUMENT_SUCCESS,
  ADD_DOCUMENT_ERROR,
  GET_DOCUMENT,
  GET_DOCUMENT_SUCCESS,
  GET_DOCUMENT_ERROR,
  EDIT_DOCUMENT,
  EDIT_DOCUMENT_SUCCESS,
  EDIT_DOCUMENT_ERROR,
  DELETE_DOCUMENT,
  DELETE_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_ERROR,
  DELETE_MULTIPLE_DOCUMENT,
  DELETE_MULTIPLE_DOCUMENT_SUCCESS,
  DELETE_MULTIPLE_DOCUMENT_ERROR,
  DOWNLOAD_DOCUMENT_ERROR,
  DOWNLOAD_DOCUMENT_SUCCESS,
  DOWNLOAD_DOCUMENT,
  GET_DOCUMENT_FILES_BY_DOCID,
  GET_DOCUMENT_FILES_BY_DOCID_SUCCESS,
  GET_DOCUMENT_FILES_BY_DOCID_ERROR,
  RESET_DOCUMENT,
} from "../../actions";

const INIT_STATE = {
  dbParam: null,
  documents: null,
  documentList: null,
  documentData: null,
  documentId: null,
  documentIds: null,
  recentDocuments: null,
  success: false,
  message: null,
  loading: false,
  loading1: false,
  loadingRecent: false,
  error: null,
  metaData: null,
  files: [],
  fileLoading: false,
};

const documentReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_DOCUMENT:
      return {
        ...state,
        error: null,
      };
    case GET_ALL_DOCUMENT_SUCCESS:
      return {
        ...state,
        documents: action.payload,
        error: null,
      };
    case GET_ALL_DOCUMENT_ERROR:
      return {
        ...state,
        documents: null,
        error: action.payload,
      };
    case GET_RECENT_DOCUMENT:
      return {
        ...state,
        loadingRecent: true,
        error: null,
      };
    case GET_RECENT_DOCUMENT_SUCCESS:
      return {
        ...state,
        recentDocuments: action.payload,
        loadingRecent: false,
        error: null,
      };
    case GET_RECENT_DOCUMENT_ERROR:
      return {
        ...state,
        recentDocuments: null,
        loadingRecent: false,
        error: action.payload,
      };
    case GET_DOCUMENT_LIST:
      return {
        ...state,
        loading: true,
        documentId: null,
        documentData: null,
        documentIds: null,
        error: null,
      };
    case GET_DOCUMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        documentList: action.payload.documentList,
        metaData: action.payload.metadata,
        error: null,
      };
    case GET_DOCUMENT_LIST_ERROR:
      return {
        ...state,
        loading: false,
        documentList: null,
        error: action.payload,
      };
    case ADD_DOCUMENT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case ADD_DOCUMENT_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case GET_DOCUMENT:
      return {
        ...state,
        error: null,
      };
    case GET_DOCUMENT_SUCCESS:
      return {
        ...state,
        documentData: action.payload,
        error: null,
      };
    case GET_DOCUMENT_ERROR:
      return {
        ...state,
        documentData: null,
        error: action.payload,
      };
    case EDIT_DOCUMENT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case EDIT_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case EDIT_DOCUMENT_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DELETE_DOCUMENT:
      return {
        ...state,
        loading1: true,
        error: null,
      };
    case DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_DOCUMENT_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DELETE_MULTIPLE_DOCUMENT:
      return {
        ...state,
        loading1: true,
        error: null,
      };
    case DELETE_MULTIPLE_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_MULTIPLE_DOCUMENT_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DOWNLOAD_DOCUMENT:
      return {
        ...state,
        loading1: true,
        error: null,
      };
    case DOWNLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DOWNLOAD_DOCUMENT_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };

    case GET_DOCUMENT_FILES_BY_DOCID:
      return {
        ...state,
        fileLoading: true,
        error: null,
      };
    case GET_DOCUMENT_FILES_BY_DOCID_SUCCESS:
      return {
        ...state,
        fileLoading: false,
        files: action.payload,
        error: null,
      };
    case GET_DOCUMENT_FILES_BY_DOCID_ERROR:
      return {
        ...state,
        fileLoading: false,
        files: [],
        error: action.payload,
      };
    case RESET_DOCUMENT:
      return {
        ...state,
        loading: false,
        loading1: false,
        success: false,
        message: null,
        error: null,
        fileLoading: false,
      };
    default:
      return { ...state };
  }
};
export default documentReducer;
