//For fetching all projects list folder
export const GET_ALL_PROJECT_FOLDER = "GET_ALL_PROJECT_FOLDER";
export const GET_ALL_PROJECT_FOLDER_SUCCESS = "GET_ALL_PROJECT_FOLDER_SUCCESS";
export const GET_ALL_PROJECT_FOLDER_ERROR = "GET_ALL_PROJECT_FOLDER_ERROR";
//for creating new project folder
export const ADD_PROJECT_FOLDER = "ADD_PROJECT_FOLDER";
export const ADD_PROJECT_FOLDER_SUCCESS = "ADD_PROJECT_FOLDER_SUCCESS";
export const ADD_PROJECT_FOLDER_ERROR = "ADD_PROJECT_FOLDER_ERROR";

//for deleting  project
export const DELETE_ROOT_PROJECT = "DELETE_ROOT_PROJECT";
export const DELETE_ROOT_PROJECT_SUCCESS = "DELETE_ROOT_PROJECT_SUCCESS";
export const DELETE_ROOT_PROJECT_ERROR = "DELETE_ROOT_PROJECT_ERROR";

//for deleting  project folder
export const DELETE_PROJECT_FOLDER = "DELETE_PROJECT_FOLDER";
export const DELETE_PROJECT_FOLDER_SUCCESS = "DELETE_PROJECT_FOLDER_SUCCESS";
export const DELETE_PROJECT_FOLDER_ERROR = "DELETE_PROJECT_FOLDER_ERROR";

//for fetching folder data  id
export const GET_PROJECT_FOLDER = "GET_PROJECT_FOLDER";
export const GET_PROJECT_FOLDER_SUCCESS = "GET_PROJECT_FOLDER_SUCCESS";
export const GET_PROJECT_FOLDER_ERROR = "GET_PROJECT_FOLDER_ERROR";

//for updating folder
export const EDIT_PROJECT_FOLDER = "EDIT_PROJECT_FOLDER";
export const EDIT_PROJECT_FOLDER_SUCCESS = "EDIT_PROJECT_FOLDER_SUCCESS";
export const EDIT_PROJECT_FOLDER_ERROR = "EDIT_PROJECT_FOLDER_ERROR";

//for fetching folders inside project folder
export const GET_PROJECT_FOLDER_LIST = "GET_PROJECT_FOLDER_LIST";
export const GET_PROJECT_FOLDER_LIST_SUCCESS =
  "GET_PROJECT_FOLDER_LIST_SUCCESS";
export const GET_PROJECT_FOLDER_LIST_ERROR = "GET_PROJECT_FOLDER_LIST_ERROR";
//for fetching documents of folder
export const GET_FOLDER_DOCUMENT_LIST = "GET_FOLDER_DOCUMENT_LIST";
export const GET_FOLDER_DOCUMENT_LIST_SUCCESS =
  "GET_FOLDER_DOCUMENT_LIST_SUCCESS";
export const GET_FOLDER_DOCUMENT_LIST_ERROR = "GET_FOLDER_DOCUMENT_LIST_ERROR";
//for adding documents on folder
export const ADD_FOLDER_DOCUMENT = "ADD_FOLDER_DOCUMENT";
export const ADD_FOLDER_DOCUMENT_SUCCESS = "ADD_FOLDER_DOCUMENT_SUCCESS";
export const ADD_FOLDER_DOCUMENT_ERROR = "ADD_FOLDER_DOCUMENT_ERROR";
//for fetching documents of folder by id
export const GET_FOLDER_DOCUMENT = "GET_FOLDER_DOCUMENT";
export const GET_FOLDER_DOCUMENT_SUCCESS = "GET_FOLDER_DOCUMENT_SUCCESS";
export const GET_FOLDER_DOCUMENT_ERROR = "GET_FOLDER_DOCUMENT_ERROR";
//for updating documents of folder
export const EDIT_FOLDER_DOCUMENT = "EDIT_FOLDER_DOCUMENT";
export const EDIT_FOLDER_DOCUMENT_SUCCESS = "EDIT_FOLDER_DOCUMENT_SUCCESS";
export const EDIT_FOLDER_DOCUMENT_ERROR = "EDIT_FOLDER_DOCUMENT_ERROR";
//for deleting documents inside folder
export const DELETE_FOLDER_DOCUMENT = "DELETE_FOLDER_DOCUMENT";
export const DELETE_FOLDER_DOCUMENT_SUCCESS = "DELETE_FOLDER_DOCUMENT_SUCCESS";
export const DELETE_FOLDER_DOCUMENT_ERROR = "DELETE_FOLDER_DOCUMENT_ERROR";
export const DELETE_MULTIPLE_FOLDER_DOCUMENT =
  "DELETE_MULTIPLE_FOLDER_DOCUMENT";
export const DELETE_MULTIPLE_FOLDER_DOCUMENT_SUCCESS =
  "DELETE_MULTIPLE_FOLDER_DOCUMENT_SUCCESS";
export const DELETE_MULTIPLE_FOLDER_DOCUMENT_ERROR =
  "DELETE_MULTIPLE_FOLDER_DOCUMENT_ERROR";
export const DOWNLOAD_BOARD_DOCUMENT = "DOWNLOAD_BOARD_DOCUMENT";
export const DOWNLOAD_BOARD_DOCUMENT_SUCCESS =
  "DOWNLOAD_BOARD_DOCUMENT_SUCCESS";
export const DOWNLOAD_BOARD_DOCUMENT_ERROR = "DOWNLOAD_BOARD_DOCUMENT_ERROR";
export const RESET_FOLDER_DOCUMENT = "RESET_FOLDER_DOCUMENT";
export const VIEW_BOARD_DOCUMENT = "VIEW_BOARD_DOCUMENT";
export const VIEW_BOARD_DOCUMENT_SUCCESS = "VIEW_BOARD_DOCUMENT_SUCCESS";
export const VIEW_BOARD_DOCUMENT_ERROR = "VIEW_BOARD_DOCUMENT_ERROR";
export const UPDATE_PROJECT_LIST = "UPDATE_PROJECT_LIST";
export const CLEAR_PROJECT_LIST = "CLEAR_PROJECT_LIST";
export const INITIAL_PROJECT_LIST = "INITAL_PROJECT_LIST";
export const getAllProjectFolder = (payload) => ({
  type: GET_ALL_PROJECT_FOLDER,
  payload: payload,
});

export const getAllProjectFolderSuccess = (projects) => ({
  type: GET_ALL_PROJECT_FOLDER_SUCCESS,
  payload: projects,
});
export const getAllProjectFolderError = (error) => ({
  type: GET_ALL_PROJECT_FOLDER_ERROR,
  payload: error,
});

export const addProjectFolder = (folderData) => ({
  type: ADD_PROJECT_FOLDER,
  payload: { folderData },
});

export const addProjectFolderSuccess = (success, message) => ({
  type: ADD_PROJECT_FOLDER_SUCCESS,
  payload: { success, message },
});

export const addProjectFolderError = (error) => ({
  type: ADD_PROJECT_FOLDER_ERROR,
  payload: error,
});

export const editProjectFolder = (folderId, folderData) => ({
  type: EDIT_PROJECT_FOLDER,
  payload: { folderId, folderData },
});

export const editProjectFolderSuccess = (success, message) => ({
  type: EDIT_PROJECT_FOLDER_SUCCESS,
  payload: { success, message },
});

export const editProjectFolderError = (error) => ({
  type: EDIT_PROJECT_FOLDER_ERROR,
  payload: error,
});

export const deleteProjectFolder = (deleteData) => ({
  type: DELETE_PROJECT_FOLDER,
  payload: { deleteData },
});

export const deleteProjectFolderSuccess = (success, message) => ({
  type: DELETE_PROJECT_FOLDER_SUCCESS,
  payload: { success, message },
});

export const deleteProjectFolderError = (error) => ({
  type: DELETE_PROJECT_FOLDER_ERROR,
  payload: error,
});

export const getProjectFolderList = (projectId) => ({
  type: GET_PROJECT_FOLDER_LIST,
  payload: { projectId },
});

export const getProjectFolderListSuccess = (folders) => ({
  type: GET_PROJECT_FOLDER_LIST_SUCCESS,
  payload: folders,
});
export const getProjectFolderListError = (error) => ({
  type: GET_PROJECT_FOLDER_LIST_ERROR,
  payload: error,
});

//get folder details by id

export const getProjectFolder = (folderId) => ({
  type: GET_PROJECT_FOLDER,
  payload: { folderId },
});

export const getProjectFolderSuccess = (folderData) => ({
  type: GET_PROJECT_FOLDER_SUCCESS,
  payload: folderData,
});

export const getProjectFolderError = (error) => ({
  type: GET_PROJECT_FOLDER_ERROR,
  payload: error,
});

export const getFolderDocumentList = (dbParam) => ({
  type: GET_FOLDER_DOCUMENT_LIST,
  payload: { dbParam },
});

export const getFolderDocumentListSuccess = (documents, metaData) => ({
  type: GET_FOLDER_DOCUMENT_LIST_SUCCESS,
  payload: { documents, metaData },
});
export const getFolderDocumentListError = (error) => ({
  type: GET_FOLDER_DOCUMENT_LIST_ERROR,
  payload: error,
});

export const addFolderDocument = (documentData) => ({
  type: ADD_FOLDER_DOCUMENT,
  payload: { documentData },
});

export const addFolderDocumentSuccess = (success, message) => ({
  type: ADD_FOLDER_DOCUMENT_SUCCESS,
  payload: { success, message },
});

export const addFolderDocumentError = (error) => ({
  type: ADD_FOLDER_DOCUMENT_ERROR,
  payload: error,
});

export const getFolderDocument = (documentId) => ({
  type: GET_FOLDER_DOCUMENT,
  payload: { documentId },
});

export const getFolderDocumentSuccess = (documentData) => ({
  type: GET_FOLDER_DOCUMENT_SUCCESS,
  payload: documentData,
});

export const getFolderDocumentError = (error) => ({
  type: GET_FOLDER_DOCUMENT_ERROR,
  payload: error,
});

export const editFolderDocument = (documentId, documentData) => ({
  type: EDIT_FOLDER_DOCUMENT,
  payload: { documentId, documentData },
});

export const editFolderDocumentSuccess = (success, message) => ({
  type: EDIT_FOLDER_DOCUMENT_SUCCESS,
  payload: { success, message },
});

export const editFolderDocumentError = (error) => ({
  type: EDIT_FOLDER_DOCUMENT_ERROR,
  payload: error,
});

export const deleteFolderDocument = (documentId, deleteData) => ({
  type: DELETE_FOLDER_DOCUMENT,
  payload: { documentId, deleteData },
});

export const deleteFolderDocumentSuccess = (success, message) => ({
  type: DELETE_FOLDER_DOCUMENT_SUCCESS,
  payload: { success, message },
});

export const deleteFolderDocumentError = (error) => ({
  type: DELETE_FOLDER_DOCUMENT_ERROR,
  payload: error,
});

export const deleteMultipleFolderDocument = (documentIds, deleteData) => ({
  type: DELETE_MULTIPLE_FOLDER_DOCUMENT,
  payload: { documentIds, deleteData },
});

export const deleteMultipleFolderDocumentSuccess = (success, message) => ({
  type: DELETE_MULTIPLE_FOLDER_DOCUMENT_SUCCESS,
  payload: { success, message },
});

export const deleteMultipleFolderDocumentError = (error) => ({
  type: DELETE_MULTIPLE_FOLDER_DOCUMENT_ERROR,
  payload: error,
});

export const resetFolderDocument = () => ({
  type: RESET_FOLDER_DOCUMENT,
  payload: {},
});

export const deleteRootProject = (projectId) => ({
  type: DELETE_ROOT_PROJECT,
  payload: { projectId },
});

export const deleteRootProjectSuccess = (success, message) => ({
  type: DELETE_ROOT_PROJECT_SUCCESS,
  payload: { success, message },
});

export const downloadBoardDocument = (documentId, projectName) => ({
  type: DOWNLOAD_BOARD_DOCUMENT,
  payload: { documentId, projectName },
});

export const downloadBoardDocumentSuccess = (success, message) => ({
  type: DOWNLOAD_BOARD_DOCUMENT_SUCCESS,
  payload: { success, message },
});

export const downloadBoardDocumentError = (error) => ({
  type: DOWNLOAD_BOARD_DOCUMENT_ERROR,
  payload: error,
});

export const deleteRootProjectError = (error) => ({
  type: DELETE_ROOT_PROJECT_ERROR,
  payload: error,
});

export const viewBoardDocument = (documentId) => ({
  type: VIEW_BOARD_DOCUMENT,
  payload: { documentId },
});

export const viewBoardDocumentSuccess = (documentData) => ({
  type: VIEW_BOARD_DOCUMENT_SUCCESS,
  payload: documentData,
});

export const viewBoardDocumentError = (error) => ({
  type: VIEW_BOARD_DOCUMENT_ERROR,
  payload: error,
});
export const clearProjectList = () => ({
  type: CLEAR_PROJECT_LIST,
  payload: {},
});
export const initialProjectList = () => ({
  type: INITIAL_PROJECT_LIST,
  payload: {},
});
