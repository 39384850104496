export const GET_INBOX_LIST = "GET_INBOX_LIST";
export const GET_INBOX_LIST_SUCCESS = "GET_INBOX_LIST_SUCCESS";
export const GET_INBOX_LIST_ERROR = "GET_INBOX_LIST_ERROR";
export const GET_MALLING_CONTACT_PERSON = "GET_MALLING_CONTACT_PERSON";
export const GET_MALLING_CONTACT_PERSON_SUCCESS =
  "GET_MALLING_CONTACT_PERSON_SUCCESS";
export const GET_MALLING_CONTACT_PERSON_ERROR =
  "GET_MALLING_CONTACT_PERSON_ERROR";
export const GET_SENT_LIST = "GET_SENT_LIST";
export const GET_SENT_LIST_SUCCESS = "GET_SENT_LIST_SUCCESS";
export const GET_SENT_LIST_ERROR = "GET_SENT_LIST_ERROR";
export const GET_DRAFT_LIST = "GET_DRAFT_LIST";
export const GET_DRAFT_LIST_SUCCESS = "GET_DRAFT_LIST_SUCCESS";
export const GET_DRAFT_LIST_ERROR = "GET_DRAFT_LIST_ERROR";
export const DRAFT_MESSAGE = "DRAFT_MESSAGE";
export const DRAFT_MESSAGE_SUCCESS = "DRAFT_MESSAGE_SUCCESS";
export const DRAFT_MESSAGE_ERROR = "DRAFT_MESSAGE_ERROR";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_ERROR = "SEND_MESSAGE_ERROR";
export const GET_MESSAGE = "GET_MESSAGE";
export const GET_MESSAGE_SUCCESS = "GET_MESSAGE_SUCCESS";
export const GET_MESSAGE_ERROR = "GET_MESSAGE_ERROR";
export const REPLY_MESSAGE = "REPLY_MESSAGE";
export const REPLY_MESSAGE_SUCCESS = "REPLY_MESSAGE_SUCCESS";
export const REPLY_MESSAGE_ERROR = "REPLY_MESSAGE_ERROR";
export const DELETE_MESSAGE = "DELETE_MESSAGE";
export const DELETE_MESSAGE_SUCCESS = "DELETE_MESSAGE_SUCCESS";
export const DELETE_MESSAGE_ERROR = "DELETE_MESSAGE_ERROR";
export const DELETE_MULTIPLE_MESSAGE = "DELETE_MULTIPLE_MESSAGE";
export const DELETE_MULTIPLE_MESSAGE_SUCCESS =
  "DELETE_MULTIPLE_MESSAGE_SUCCESS";
export const DELETE_MULTIPLE_MESSAGE_ERROR = "DELETE_MULTIPLE_MESSAGE_ERROR";
export const GET_DOCUMENT_BY_PROJECT = "GET_DOCUMENT_BY_PROJECT";
export const GET_DOCUMENT_BY_PROJECT_SUCCESS =
  "GET_DOCUMENT_BY_PROJECT_SUCCESS";
export const GET_DOCUMENT_BY_PROJECT_ERROR = "GET_DOCUMENT_BY_PROJECT_ERROR";
export const SET_FORWARD_MESSAGE = "SET_FORWARD_MESSAGE";
export const CLEAR_FORWARD_MESSAGE = "CLEAR_FORWARD_MESSAGE";
export const GET_UNREAD_INBOX_COUNT = "GET_UNREAD_INBOX_COUNT";
export const GET_UNREAD_INBOX_COUNT_SUCCESS = "GET_UNREAD_INBOX_COUNT_SUCCESS";
export const GET_UNREAD_INBOX_COUNT_ERROR = "GET_UNREAD_INBOX_COUNT_ERROR";
export const MARK_MESSAGE_SEEN = "MARK_MESSAGE_SEEN";
export const MARK_MESSAGE_SEEN_SUCCESS = "MARK_MESSAGE_SEEN_SUCCESS";
export const MARK_MESSAGE_SEEN_ERROR = "MARK_MESSAGE_SEEN_ERROR";
export const MARK_DOCUMENT_SEEN = "MARK_DOCUMENT_SEEN";
export const MARK_DOCUMENT_SEEN_SUCCESS = "MARK_DOCUMENT_SEEN_SUCCESS";
export const MARK_DOCUMENT_SEEN_ERROR = "MARK_DOCUMENT_SEEN_ERROR";
export const DOWNLOAD_MESSAGE_DOCUMENT = "DOWNLOAD_MESSAGE_DOCUMENT";
export const DOWNLOAD_MESSAGE_DOCUMENT_SUCCESS =
  "DOWNLOAD_MESSAGE_DOCUMENT_SUCCESS";
export const DOWNLOAD_MESSAGE_DOCUMENT_ERROR =
  "DOWNLOAD_MESSAGE_DOCUMENT_ERROR";

export const GET_DOCUMENT_FILES = "GET_DOCUMENT_FILES";
export const GET_DOCUMENT_FILES_SUCCESS = "GET_DOCUMENT_FILES_SUCCESS";
export const GET_DOCUMENT_FILES_ERROR = "GET_DOCUMENT_FILES_ERROR";

// export const GET_PROJECT_BY_MESSAGE_TYPE = "GET_PROJECT_BY_MESSAGE_TYPE";
// export const GET_PROJECT_BY_MESSAGE_TYPE_SUCCESS =
//   "GET_PROJECT_BY_MESSAGE_TYPE_SUCCESS";
// export const GET_PROJECT_BY_MESSAGE_TYPE_ERROR =
//   "GET_PROJECT_BY_MESSAGE_TYPE_ERROR";

export const RESET_MESSAGE = "RESET_MESSAGE";
export const GET_NEW_MESSAGE = "GET_NEW_MESSAGE";
export const RESET_MESSAGE_DATA = "RESET_MESSAGE_DATA";

export const getUnreadInboxCount = (defaultRoleId) => ({
  type: GET_UNREAD_INBOX_COUNT,
  payload: { defaultRoleId },
});

export const getUnreadInboxCountSuccess = (count) => ({
  type: GET_UNREAD_INBOX_COUNT_SUCCESS,
  payload: count,
});

export const getUnreadInboxCountError = (error) => ({
  type: GET_UNREAD_INBOX_COUNT_ERROR,
  payload: error,
});
export const markMessageSeen = (messageId, data) => ({
  type: MARK_MESSAGE_SEEN,
  payload: { messageId, data },
});

export const markMessageSeenSuccess = () => ({
  type: MARK_MESSAGE_SEEN_SUCCESS,
  payload: {},
});

export const markMessageSeenError = (error) => ({
  type: MARK_MESSAGE_SEEN_ERROR,
  payload: error,
});

export const markDocumentSeen = (messageId, data) => ({
  type: MARK_DOCUMENT_SEEN,
  payload: { messageId, data },
});

export const markDocumentSeenSuccess = () => ({
  type: MARK_DOCUMENT_SEEN_SUCCESS,
  payload: {},
});

export const markDocumentSeenError = (error) => ({
  type: MARK_DOCUMENT_SEEN_ERROR,
  payload: error,
});

export const getMallingContactPerson = () => ({
  type: GET_MALLING_CONTACT_PERSON,
  payload: {},
});

export const getMallingContactPersonSuccess = (contactPersons) => ({
  type: GET_MALLING_CONTACT_PERSON_SUCCESS,
  payload: contactPersons,
});

export const getMallingContactPersonError = (error) => ({
  type: GET_MALLING_CONTACT_PERSON_ERROR,
  payload: error,
});

export const getInboxList = (inboxParam) => ({
  type: GET_INBOX_LIST,
  payload: { inboxParam },
});

export const getInboxListSuccess = (inboxList, metaData) => ({
  type: GET_INBOX_LIST_SUCCESS,
  payload: { inboxList, metaData },
});

export const getInboxListError = (error) => ({
  type: GET_INBOX_LIST_ERROR,
  payload: error,
});

export const getSentList = (sentParam, type) => ({
  type: GET_SENT_LIST,
  payload: { sentParam, type },
});

export const getSentListSuccess = (sentList, metaData) => ({
  type: GET_SENT_LIST_SUCCESS,
  payload: { sentList, metaData },
});

export const getSentListError = (error) => ({
  type: GET_SENT_LIST_ERROR,
  payload: error,
});

export const getDraftList = (draftParam) => ({
  type: GET_DRAFT_LIST,
  payload: { draftParam },
});

export const getDraftListSuccess = (draftList, metaData, messageCount) => ({
  type: GET_DRAFT_LIST_SUCCESS,
  payload: { draftList, metaData, messageCount },
});

export const getDraftListError = (error) => ({
  type: GET_DRAFT_LIST_ERROR,
  payload: error,
});

export const draftMessage = (messageId = null, messageData) => ({
  type: DRAFT_MESSAGE,
  payload: { messageId, messageData },
});

export const draftMessageSuccess = (success, message) => ({
  type: DRAFT_MESSAGE_SUCCESS,
  payload: { success, message },
});

export const draftMessageError = (error) => ({
  type: DRAFT_MESSAGE_ERROR,
  payload: error,
});

export const sendMessage = (messageData) => ({
  type: SEND_MESSAGE,
  payload: { messageData },
});

export const sendMessageSuccess = (success, message) => ({
  type: SEND_MESSAGE_SUCCESS,
  payload: { success, message },
});

export const sendMessageError = (error) => ({
  type: SEND_MESSAGE_ERROR,
  payload: error,
});

export const getMessage = (messageId, defaultRoleId) => ({
  type: GET_MESSAGE,
  payload: { messageId, defaultRoleId },
});

export const getMessageSuccess = (messageData) => ({
  type: GET_MESSAGE_SUCCESS,
  payload: messageData,
});

export const getMessageError = (error) => ({
  type: GET_MESSAGE_ERROR,
  payload: error,
});

export const replyMessage = (replyMessageData) => ({
  type: REPLY_MESSAGE,
  payload: { replyMessageData },
});

export const replyMessageSuccess = (success, message) => ({
  type: REPLY_MESSAGE_SUCCESS,
  payload: { success, message },
});

export const replyMessageError = (error) => ({
  type: REPLY_MESSAGE_ERROR,
  payload: error,
});

export const deleteMessage = (messageIds) => ({
  type: DELETE_MESSAGE,
  payload: { messageIds },
});

export const deleteMessageSuccess = (success, message) => ({
  type: DELETE_MESSAGE_SUCCESS,
  payload: { success, message },
});

export const deleteMessageError = (error) => ({
  type: DELETE_MESSAGE_ERROR,
  payload: error,
});
export const deleteMultipleMessage = (messageIds, type, defaultRoleId) => ({
  type: DELETE_MULTIPLE_MESSAGE,
  payload: { messageIds, type, defaultRoleId },
});

export const deleteMultipleMessageSuccess = (success, message) => ({
  type: DELETE_MULTIPLE_MESSAGE_SUCCESS,
  payload: { success, message },
});

export const deleteMultipleMessageError = (error) => ({
  type: DELETE_MULTIPLE_MESSAGE_ERROR,
  payload: error,
});

export const getDocumentByProject = (projectId) => ({
  type: GET_DOCUMENT_BY_PROJECT,
  payload: { projectId },
});

export const getDocumentByProjectSuccess = (documents) => ({
  type: GET_DOCUMENT_BY_PROJECT_SUCCESS,
  payload: documents,
});

export const getDocumentByProjectError = (error) => ({
  type: GET_DOCUMENT_BY_PROJECT_ERROR,
  payload: error,
});

export const downloadMessageDocument = (document) => ({
  type: DOWNLOAD_MESSAGE_DOCUMENT,
  payload: { document },
});

export const downloadMessageDocumentSuccess = (success, message) => ({
  type: DOWNLOAD_MESSAGE_DOCUMENT_SUCCESS,
  payload: { success, message },
});

export const downloadMessageDocumentError = (error) => ({
  type: DOWNLOAD_MESSAGE_DOCUMENT_ERROR,
  payload: error,
});

export const getDocumentFiles = (messageId, investorId, history) => ({
  type: GET_DOCUMENT_FILES,
  payload: { messageId, investorId, history },
});

export const getDocumentFilesSuccess = (documentFiles) => ({
  type: GET_DOCUMENT_FILES_SUCCESS,
  payload: documentFiles,
});

export const getDocumentFilesError = (error) => ({
  type: GET_DOCUMENT_FILES_ERROR,
  payload: error,
});

export const resetMessage = () => ({
  type: RESET_MESSAGE,
  payload: {},
});

export const resetMessageData = () => ({
  type: RESET_MESSAGE_DATA,
  payload: {},
});

// old code
// export const newMessage = (newMessage) => ({
//   type: GET_NEW_MESSAGE,
//   payload: newMessage,
// });

export const newMessage = (inboxCount, newMessage) => ({
  type: GET_NEW_MESSAGE,
  payload: {inboxCount, newMessage},
});

// export const getProjectByMessageType = (type) => ({
//   type: GET_PROJECT_BY_MESSAGE_TYPE,
//   payload: { type },
// });

// export const getProjectByMessageTypeSuccess = (projects) => ({
//   type: GET_PROJECT_BY_MESSAGE_TYPE_SUCCESS,
//   payload: projects,
// });

// export const getProjectByMessageTypeError = (error) => ({
//   type: GET_PROJECT_BY_MESSAGE_TYPE_ERROR,
//   payload: error,
// });
