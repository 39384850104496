import { Component } from "react";
import { withTranslation } from "react-i18next";
import qs from "query-string";
import AuthService from "../../../services/auth-service";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import ToastElement from "../../../components/toast";
import { IconSpinner } from "src/components/svg";
class LoginNorwegian extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      credentials: {
        client_id: process.env.REACT_APP_BANKID_CLIENT_ID,
        scope: process.env.REACT_APP_BANKID_SCOPE,
        redirect_uri: process.env.REACT_APP_BANKID_REDIRECT_URI,
        response_type: process.env.REACT_APP_BANKID_RESPONSE_TYPE,
        nonce: process.env.REACT_APP_NONCE,
        state: "login",
      },
    };
  }

  loginWithBankId = () => {
    this.setState({ loading: true });
    AuthService.bandIdAuth()
      .then((res) => {
        const { authorization_endpoint } = res.data;
        if (authorization_endpoint) {
          this.setState({ loading: false });
          let redirectUrl = `${authorization_endpoint}?${qs.stringify(
            this.state.credentials
          )}`;
          window.open(redirectUrl, "_self");
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(
          <ToastElement
            type="error"
            message={"Something went wrong,please try again"}
          />,
          { containerId: "default", position: "top-right" }
        );
      });
  };

  render() {
    const { t } = this.props;
    const { loading } = this.state;
    return (
      <div
        className={`"inline-flex flex-col animate__animated animate__faster ${
          this.props.classes?.root || ""
        }`}
      >
        <button
          className="flex items-center mb-4 btn-primary-outlined md"
          onClick={this.loginWithBankId}
          disabled={loading}
          style={{ height: 48 }}
        >
          <img
            className="w-7 mr-7"
            src={
              require("../../../assets/images/bankid-vector-logo.svg").default
            }
            alt=""
          />
          {t("AUTH.LOGIN.LOGIN_BANKID")}
          {this.state.loading && (
            <span className="ml-3 -mr-1">
              <IconSpinner />
            </span>
          )}
        </button>

        <p className="w-60 text-right text-grey-700">
          {t("AUTH.LOGIN.BANKID_INFO")}
        </p>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { user, error } = auth;
  return { user, error };
};

export default connect(mapStateToProps, {})(withTranslation()(LoginNorwegian));
