import React from "react";
import { withResizeDetector } from "react-resize-detector";

const ButtonLoginType = (props) => {
  return (
    <button
      className={`w-full sm:w-3/12 xl:w-3/12 2xl:w-2/12 h-40 flex flex-col items-center justify-center relative rounded-md ring-1 ring-primary-600 svg-size-medium text-grey-800 focus:outline-none transition-all
    ${
      props.selected
        ? "bg-primary-500 text-grey-100 -translate-y-2 svg-color-white"
        : ""
    }`}
      onClick={props.btnClick}
    >
      {props.icon}

      <span className="mt-3 text-sm">{props.title}</span>

      <span
        className={`w-5 h-5 flex justify-center absolute top-3 right-3 pt-0.5 rounded-full bg-white animate__animated animate__faster
      ${props.selected ? "animate__zoomIn" : "animate__zoomOut"}`}
      >
        <i className="w-2 h-3 border-b-2 border-r-2 border-solid border-primary-500 rotate-45"></i>
      </span>
    </button>
  );
};

export default withResizeDetector(ButtonLoginType);
