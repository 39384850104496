import React from "react";

const IconChevronLeftDouble = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22">
      <path
        fill="none"
        stroke="#000000"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="15.2154"
        d="M11.7,19.8L2.9,11l8.8-8.8"
      />
      <path
        fill="none"
        stroke="#000000"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="15.2154"
        d="M18.1,19.8L9.3,11l8.8-8.8"
      />
    </svg>
  );
};

export default IconChevronLeftDouble;
