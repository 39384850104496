import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import SharedService from "../../services/shared-service";
import { toast } from "react-toastify";
import ToastElement from "../../components/toast";
import {
  GET_CURRENT_USER,
  GET_PERMISSION,
  GET_COUNTRY_LIST,
  GET_CONFIG_CHOICE,
  GET_MULTIPLE_CONFIG_CHOICE,
  GET_INVESTOR_BY_PROJECT,
} from "../actions";
import {
  getCurrentUserSuccess,
  getCurrentUserError,
  getPermissionSuccess,
  getPermissionError,
  getCountryListSuccess,
  getCountryListError,
  getConfigChoiceSuccess,
  getConfigChoiceError,
  getMultipleConfigChoiceSuccess,
  getMultipleConfigChoiceError,
  getInvestorByProjectSuccess,
  getInvestorByProjectError,
  getCurrentUserProfileFail,
  getCurrentUserProfileSuccess,
} from "./action";
import { logout } from "src/reduxs/actions";
import history from "src/helpers/history";
import { handleMyErrorMessage } from "../../helpers/util";

export function* watchGetCurrentUser() {
  yield takeEvery(GET_CURRENT_USER, getCurrentUser);
}

const getCurrentUserAsync = async (id) => {
  return SharedService.getCurrentUser(id);
};

function* getCurrentUser() {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  try {
    const response = yield call(getCurrentUserAsync, currentUser.id);

    if (response.data.success) {
      yield put(getCurrentUserSuccess(response.data.data));
    } else {
      yield put(getCurrentUserError(response.data.message));
    }
  } catch (error) {
    // yield put(getCurrentUserError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      yield put(getCurrentUserError(errorMessage))
    }
  }
}

export function* watchGetCurrentUserProfile() {
  yield takeEvery(GET_CURRENT_USER, getCurrentUserProfile);
}

const getCurrentUserProfileAsync = async (id) => {
  return SharedService.getCurrentUserProfile(id);
};

function* getCurrentUserProfile() {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  try {
    const response = yield call(getCurrentUserProfileAsync, currentUser.id);
    if (response.data.success) {
      yield put(getCurrentUserProfileSuccess(response.data.data));
    } else {
      yield put(getCurrentUserProfileFail(response.data.message));
    }
  } catch (error) {
    // yield put(getCurrentUserProfileFail(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      yield put(getCurrentUserProfileFail(errorMessage))
    }
  }
}

export function* watchGetPermission() {
  yield takeEvery(GET_PERMISSION, getPermission);
}

const getPermissionAsync = async (id) => {
  return SharedService.getPermission(id);
};

function* getPermission({ payload }) {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  let default_role_id = currentUser.default_role_id;

  if (payload.roleId) {
    default_role_id = payload.roleId;
  }

  try {
    const response = yield call(getPermissionAsync, default_role_id);
    if (response.data.success) {
      yield put(getPermissionSuccess(response.data.data));
    } else {
      yield put(getPermissionError(response.data.message));
    }
  } catch (error) {
    // if (error.response.status === 404) {
    //   yield put(logout(history));
    // }
    // yield put(getPermissionError(error.response.data.message));

    if (error.response && error.response.status === 404) {
      yield put(logout(history))
    } else {
      const errorMessage = handleMyErrorMessage(error)
      if(errorMessage){
        yield put(getPermissionError(errorMessage))
      }
    }
  }
}

export function* watchGetCountryList() {
  yield takeEvery(GET_COUNTRY_LIST, getCountryList);
}

const getCountryListAsync = async () => {
  return SharedService.getCountryList();
};

function* getCountryList() {
  try {
    const response = yield call(getCountryListAsync);
    if (response.data.success) {
      yield put(getCountryListSuccess(response.data.data));
    } else {
      yield put(getCountryListError(response.data.message));
    }
  } catch (error) {
    // yield put(getCountryListError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      yield put(getCountryListError(errorMessage))
    }

  }
}

export function* watchGetConfigChoice() {
  yield takeEvery(GET_CONFIG_CHOICE, getConfigChoice);
}

const getConfigChoiceAsync = async (category) => {
  return SharedService.getConfigChoice(category);
};

function* getConfigChoice({ payload }) {
  try {
    const response = yield call(getConfigChoiceAsync, payload.category);
    if (response.data.success) {
      yield put(getConfigChoiceSuccess(response.data.data));
    } else {
      yield put(getConfigChoiceError(response.data.message));
    }
  } catch (error) {
    // yield put(getConfigChoiceError(error.response.data.message));
    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      yield put(getConfigChoiceError(errorMessage))
    }
  }
}

export function* watchGetMultipleConfigChoice() {
  yield takeEvery(GET_MULTIPLE_CONFIG_CHOICE, getMultipleConfigChoice);
}

const getMultipleConfigChoiceAsync = async (categories) => {
  return SharedService.getMultipleConfigChoice(categories);
};

function* getMultipleConfigChoice({ payload }) {
  try {
    const response = yield call(
      getMultipleConfigChoiceAsync,
      payload.categories
    );
    if (response.data.success) {
      yield put(getMultipleConfigChoiceSuccess(response.data.data));
    } else {
      yield put(getMultipleConfigChoiceError(response.data.message));
    }
  } catch (error) {
    
    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      yield put(getMultipleConfigChoiceError(errorMessage))
    }
  }
}

export function* watchGetInvestorByProject() {
  yield takeEvery(GET_INVESTOR_BY_PROJECT, getInvestorByProject);
}

const getInvestorByProjectAsync = async (id, params) => {
  return SharedService.getInvestorsByProject(id, params);
};

function* getInvestorByProject({ payload }) {
  try {
    const response = yield call(
      getInvestorByProjectAsync,
      payload.projectId,
      payload.params
    );
    if (response.data.success) {
      yield put(getInvestorByProjectSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getInvestorByProjectError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getInvestorByProjectError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      toast.error(
        <ToastElement type='error' message={errorMessage} />,
        { containerId: 'default' }
      )
      yield put(getInvestorByProjectError(errorMessage))
    }
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetCurrentUser),
    fork(watchGetCurrentUserProfile),
    fork(watchGetPermission),
    fork(watchGetCountryList),
    fork(watchGetConfigChoice),
    fork(watchGetMultipleConfigChoice),
    fork(watchGetInvestorByProject),
  ]);
}
