import React, { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { LayoutSplashScreen } from "../configs/core";
import { logout, getPermission, getCurrentUser } from "../reduxs/actions";

function AuthGuard(props) {
  const didRequest = useRef(false);
  const [showSplashScreen, setShowSplashScreen] = useState(true);

  // We should request permission by token before rendering the application
  useEffect(() => {
    const requestPermission = async () => {
      try {
        if (!didRequest.current) {
          // props.getPermission(props.currentRole?.id);
          props.getCurrentUser();

          // props.getNotificationList({});
        }
      } catch (error) {
        // console.error(error);
        if (!didRequest.current) {
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => (didRequest.current = true);
    };

    if (props.user) {
      requestPermission();
    } else {
      setShowSplashScreen(false);
    }
    // eslint-disable-next-line
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>;
}
const mapStateToProps = ({ auth }) => {
  const { user, currentRole } = auth;
  return { user, currentRole };
};

export default connect(mapStateToProps, {
  logout,
  getPermission,
  getCurrentUser,
})(AuthGuard);
