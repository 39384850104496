import Req from "../interceptors/token-interceptor";

const PaidOutService = (function () {
  function _getAllPaidOut(param) {
    return Req.get(
      `/api/paidOut/${param.projectId}?search=${
        param?.search || ""
      }&searchFields=${param?.searchFields || ""}`,
      { withCredentials: true }
    );
  }
  function _addPaidOut(data) {
    return Req.post("/api/paidOut", data);
  }
  function _getPaidOut(id) {
    return Req.get(`/api/paidOut/${id}`);
  }
  function _editPaidOut(data, id) {
    return Req.put(`/api/paidOut/${id}`, data);
  }
  function _deletePaidOut(id) {
    return Req.delete(`/api/paidOut/${id}`);
  }
  function _exportPaidOut(projectId) {
    return Req.get(`/api/exportPaidOut/${projectId}`, {
      responseType: "arraybuffer",
    });
  }

  function _getAllPaidOutFund(param) {
    return Req.get(
      `/api/fundPaidOut/${param.projectId}?search=${
        param?.search || ""
      }&searchFields=${param?.searchFields || ""}`,
      { withCredentials: true }
    );
  }
  function _addPaidOutFund(data) {
    return Req.post("/api/fundPaidOut", data);
  }
  function _getPaidOutFund(id) {
    return Req.get(`/api/fundPaidOut/${id}`);
  }
  function _editPaidOutFund(data, id) {
    return Req.put(`/api/fundPaidOut/${id}`, data);
  }
  function _deletePaidOutFund(id) {
    return Req.delete(`/api/fundPaidOut/${id}`);
  }
  function _exportPaidOutFund(projectId) {
    return Req.get(`/api/exportFundPaidOut/${projectId}`, {
      responseType: "arraybuffer",
    });
  }
  function _paidOutInvestorList(projectId) {
    if (projectId) {
      return Req.get(`/api/paidInInvestor/${projectId}`);
    }
  }
  function _updatePaidOutFund(data,id) {
    return Req.put(`/api/updateProjectPaidOutCapital/${id}`, data);
  }
  return {
    getAllPaidOut: _getAllPaidOut,
    addPaidOut: _addPaidOut,
    getPaidOut: _getPaidOut,
    editPaidOut: _editPaidOut,
    deletePaidOut: _deletePaidOut,
    exportPaidOut: _exportPaidOut,
    paidOutInvestorList: _paidOutInvestorList,
    getAllPaidOutFund: _getAllPaidOutFund,
    addPaidOutFund: _addPaidOutFund,
    getPaidOutFund: _getPaidOutFund,
    editPaidOutFund: _editPaidOutFund,
    deletePaidOutFund: _deletePaidOutFund,
    exportPaidOutFund: _exportPaidOutFund,
    updatePaidOutFund: _updatePaidOutFund,
  };
})();
export default PaidOutService;
