import React from "react";

const IconColoredInvestor = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="62"
      viewBox="0 0 70 62"
    >
      <g id="Group_3296" dataname="Group 3296" transform="translate(-413 -333)">
        <rect
          id="Rectangle_1676"
          dataname="Rectangle 1676"
          width="70"
          height="62"
          rx="5"
          transform="translate(413 333)"
          fill="#e0d6e5"
        />
        <g
          id="Group_3028"
          dataname="Group 3028"
          transform="translate(1427.934 -200.35)"
        >
          <g id="Group_3028-2" dataname="Group 3028">
            <path
              id="Path_10264"
              dataname="Path 10264"
              d="M-975.088,567.151a8.054,8.054,0,0,1-2.177.3,6.782,6.782,0,0,1-7.156-6.334,5.838,5.838,0,0,1,1.347-3.7l.026-.03.24-.271.023-.026,3.262-3.741.417-1.74a1.016,1.016,0,0,0-.051-.628l-.932-2.223a1.028,1.028,0,0,1,.306-1.187,1.013,1.013,0,0,1,.631-.22h3.854a1.018,1.018,0,0,1,.718.3l.026.026a1.02,1.02,0,0,1,.231,1.082l-.937,2.426a.97.97,0,0,0-.058.5l.218,1.665,3.288,3.657a5.953,5.953,0,0,1,1.674,3.5"
              fill="none"
              stroke="#730d5b"
              strokeLinecap="round"
              strokeMiterlimit="10"
              strokeWidth="1.3"
            />
            <path
              id="Path_10265"
              dataname="Path 10265"
              d="M-975.495,551.384"
              fill="none"
              stroke="#730d5b"
              strokeLinecap="round"
              strokeMiterlimit="10"
              strokeWidth="1.3"
            />
            <line
              id="Line_157"
              dataname="Line 157"
              x2="2.076"
              transform="translate(-978.913 551.385)"
              fill="none"
              stroke="#730d5b"
              strokeLinecap="round"
              strokeMiterlimit="10"
              strokeWidth="1.3"
            />
            <line
              id="Line_158"
              dataname="Line 158"
              x2="2.076"
              transform="translate(-977.264 553.401)"
              fill="none"
              stroke="#730d5b"
              strokeLinecap="round"
              strokeMiterlimit="10"
              strokeWidth="1.3"
            />
            <g id="Group_3027" dataname="Group 3027">
              <g id="Group_2863" dataname="Group 2863">
                <g id="Group_2009" dataname="Group 2009">
                  <g id="Group_2006" dataname="Group 2006">
                    <path
                      id="Path_9257"
                      dataname="Path 9257"
                      d="M-989.239,572.343l3.206-2.723a3.279,3.279,0,0,1,2.138-.786h2.822a3.144,3.144,0,0,1,1.351.306l.2.1a3.12,3.12,0,0,0,1.347.306h3.617a1.5,1.5,0,0,1,1.5,1.5h0v.385"
                      fill="none"
                      stroke="#730d5b"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      strokeWidth="1.3"
                    />
                    <path
                      id="Path_9258"
                      dataname="Path 9258"
                      d="M-978.877,572.479h4.758l6.173-5.29a1.214,1.214,0,0,1,1.71.126.665.665,0,0,1,.071.088l.122.171a1.151,1.151,0,0,1-.066,1.417l-6.2,7.186a2.337,2.337,0,0,1-1.772.815h-9.052a.921.921,0,0,0-.569.2l-.427.333"
                      fill="none"
                      stroke="#730d5b"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      strokeWidth="1.3"
                    />
                    <rect
                      id="Rectangle_1612"
                      dataname="Rectangle 1612"
                      width="4.598"
                      height="9.39"
                      rx="0.628"
                      transform="translate(-993.933 573.709) rotate(-31.465)"
                      fill="none"
                      stroke="#730d5b"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      strokeWidth="1.3"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <path
            id="Path_10266"
            dataname="Path 10266"
            d="M-969.643,561.386a4.283,4.283,0,0,0-.5-.881,4.339,4.339,0,0,0-3.508-1.783,4.335,4.335,0,0,0-4.335,4.335h0a4.343,4.343,0,0,0,2.892,4.091,4.278,4.278,0,0,0,1.443.246,4.286,4.286,0,0,0,2.271-.644,4.349,4.349,0,0,0,1.567-1.669c.043-.079.083-.162.12-.244a4.292,4.292,0,0,0,.378-1.669v-.113A4.3,4.3,0,0,0-969.643,561.386Z"
            fill="none"
            stroke="#730d5b"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="1.3"
          />
          <path
            id="Path_10276"
            dataname="Path 10276"
            d="M-971.368,563.058a.692.692,0,0,1,0,.09,2.236,2.236,0,0,1-.206.865,2.225,2.225,0,0,1-.239.408,2.452,2.452,0,0,1-.222.255,2.065,2.065,0,0,1-.262.223,2.192,2.192,0,0,1-.281.175,2.279,2.279,0,0,1-1.061.262,2.273,2.273,0,0,1-2.273-2.273v0"
            fill="none"
            stroke="#730d5b"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="1.034"
          />
        </g>
      </g>
    </svg>
  );
};

export default IconColoredInvestor;
