import React from "react";

const IconFileXLS = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22.146 23.207"
    >
      <g
        id="Group_2698"
        dataname="Group 2698"
        transform="translate(-301.131 -814.227)"
      >
        <path
          id="Rectangle_1666"
          dataname="Rectangle 1666"
          d="M301.631,827.435h10.7a3.407,3.407,0,0,1,3.4,3.407h0a3.407,3.407,0,0,1-3.4,3.408h-9a1.7,1.7,0,0,1-1.7-1.7v-5.115Z"
          fill="none"
          stroke="#636365"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <path
          id="Path_10027"
          dataname="Path 10027"
          d="M309.061,827.435h-7.43v-1.322a1.494,1.494,0,0,1,1.494-1.494h1.245"
          fill="none"
          stroke="#636365"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <path
          id="Path_10034"
          dataname="Path 10034"
          d="M304.366,826.385v-11.45h7.743"
          fill="none"
          stroke="#636365"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_10035"
          dataname="Path 10035"
          d="M322.576,826.385v10.55h-18.21v-.94"
          fill="none"
          stroke="#636365"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_10030"
          dataname="Path 10030"
          d="M308.406,832.6a2.553,2.553,0,0,0,.361.007h.7a.332.332,0,1,0,0-.664H308.8v-2.538a.332.332,0,1,0-.664,0v2.867a.331.331,0,0,0,.276.327Z"
          fill="#464648"
        />
        <path
          id="Path_10031"
          dataname="Path 10031"
          d="M311.773,832.609a1.156,1.156,0,0,0,1.181-.9,1.057,1.057,0,0,0-.817-1.16c-.357-.132-.689-.275-.786-.318a.223.223,0,0,1-.083-.213.312.312,0,0,1,.247-.252.987.987,0,0,1,.814.187.332.332,0,0,0,.426-.51,1.648,1.648,0,0,0-1.432-.314.984.984,0,0,0-.713.793.883.883,0,0,0,.4.877.251.251,0,0,0,.046.025c.017.007.411.183.855.347.132.049.435.187.393.42a.51.51,0,0,1-.527.348,1.021,1.021,0,0,1-.754-.316.332.332,0,1,0-.492.446h0a1.694,1.694,0,0,0,1.246.535Z"
          fill="#464648"
        />
        <path
          id="Path_10032"
          dataname="Path 10032"
          d="M304.654,832.548a.332.332,0,0,0,.463-.079h0l.811-1.151.81,1.151a.332.332,0,0,0,.543-.382l-.947-1.346.8-1.142a.332.332,0,1,0-.543-.383h0l-.667.947-.666-.947a.332.332,0,0,0-.549.374l.006.008.8,1.142-.948,1.346a.333.333,0,0,0,.079.463h.008Z"
          fill="#464648"
        />
        <path
          id="Path_10036"
          dataname="Path 10036"
          d="M318.507,814.935h4.064V819"
          fill="none"
          stroke="#636365"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_11"
          dataname="Line 11"
          y1="7.406"
          x2="7.406"
          transform="translate(315.165 814.935)"
          fill="none"
          stroke="#636365"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export default IconFileXLS;
