import {
  GET_NOTIFICATION_LIST,
  GET_NOTIFICATION_LIST_SUCCESS,
  GET_NOTIFICATION_LIST_ERROR,
  GET_RECENT_NOTIFICATIONS,
  GET_RECENT_NOTIFICATIONS_SUCCESS,
  GET_RECENT_NOTIFICATIONS_ERROR,
  MARK_NOTIFICATION_AS_READ,
  MARK_NOTIFICATION_AS_READ_ERROR,
  MARK_NOTIFICATION_AS_READ_SUCCESS,
  RESET_NOTIFICATION,
} from "../actions";

const INIT_STATE = {
  loading: false,
  notificationList: null,
  recentNotifications: null,
  metaData: null,
  readSuccess: false,
};

const notificationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_NOTIFICATION_LIST:
      return {
        ...state,
        loading: true,
        notificationList: null,
        error: null,
      };
    case GET_NOTIFICATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        notificationList: action.payload.notificationList,
        metaData: action.payload.metaData,
        error: null,
      };
    case GET_NOTIFICATION_LIST_ERROR:
      return {
        ...state,
        loading: false,
        notificationList: null,
        error: action.payload,
      };
    case GET_RECENT_NOTIFICATIONS:
      return {
        ...state,
        loading: true,
        recentNotifications: null,
        error: null,
      };
    case GET_RECENT_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        recentNotifications: action.payload.notifications,
        error: null,
      };
    case GET_RECENT_NOTIFICATIONS_ERROR:
      return {
        ...state,
        loading: false,
        recentNotifications: null,
        error: action.payload,
      };
    case MARK_NOTIFICATION_AS_READ:
      return {
        ...state,
        readSuccess: false,
        error: null,
      };
    case MARK_NOTIFICATION_AS_READ_SUCCESS:
      return {
        ...state,
        readSuccess: true,
        error: null,
      };
    case MARK_NOTIFICATION_AS_READ_ERROR:
      return {
        ...state,
        readSuccess: false,
        error: action.payload,
      };
    case RESET_NOTIFICATION:
      return {
        ...state,
        loading: false,
        readSuccess: false,
        error: null,
      };

    default:
      return { ...state };
  }
};
export default notificationReducer;
