import React from "react";

const IconUserGroup = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 16 11.331">
      <g
        id="Group_5968"
        dataname="Group 5968"
        transform="translate(-448.429 -1137.5)"
      >
        <g
          id="Group_2763"
          dataname="Group 2763"
          transform="translate(449 1138)"
        >
          <path
            id="Path_9291"
            dataname="Path 9291"
            d="M-190.233,14.734a2.247,2.247,0,0,0-.953-1.427,3.143,3.143,0,0,0-1.843-.544,3.107,3.107,0,0,0-1.818.6,2.214,2.214,0,0,0-.89,1.452"
            transform="translate(200.483 -3.557)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeWidth="1"
          />
          <circle
            id="Ellipse_295"
            dataname="Ellipse 295"
            cx="1.389"
            cy="1.389"
            r="1.389"
            transform="translate(6.115 4.578)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeWidth="1"
          />
          <path
            id="Path_9291-2"
            dataname="Path 9291"
            d="M-197.44,11.741a3.137,3.137,0,0,0-1.1-.171,3.091,3.091,0,0,0-1.818.6,2.206,2.206,0,0,0-.89,1.452"
            transform="translate(201.25 -3.391)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeWidth="1"
          />
          <circle
            id="Ellipse_295-2"
            dataname="Ellipse 295"
            cx="1.389"
            cy="1.389"
            r="1.389"
            transform="translate(1.369 3.551)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeWidth="1"
          />
          <path
            id="Path_9291-3"
            dataname="Path 9291"
            d="M-184.009,13.624a2.21,2.21,0,0,0-.89-1.452,3.1,3.1,0,0,0-1.818-.6,3.131,3.131,0,0,0-1.1.171"
            transform="translate(199.382 -3.391)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeWidth="1"
          />
          <circle
            id="Ellipse_295-3"
            dataname="Ellipse 295"
            cx="1.389"
            cy="1.389"
            r="1.389"
            transform="translate(11.225 3.551)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeWidth="1"
          />
          <circle
            id="Ellipse_295-4"
            dataname="Ellipse 295"
            cx="1.389"
            cy="1.389"
            r="1.389"
            transform="translate(5.832 0)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  );
};

export default IconUserGroup;
