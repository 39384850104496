export const GET_ALL_DOCUMENT = "GET_ALL_DOCUMENT";
export const GET_ALL_DOCUMENT_SUCCESS = "GET_ALL_DOCUMENT_SUCCESS";
export const GET_ALL_DOCUMENT_ERROR = "GET_ALL_DOCUMENT_ERROR";
export const GET_RECENT_DOCUMENT = "GET_RECENT_DOCUMENT";
export const GET_RECENT_DOCUMENT_SUCCESS = "GET_RECENT_DOCUMENT_SUCCESS";
export const GET_RECENT_DOCUMENT_ERROR = "GET_RECENT_DOCUMENT_ERROR";
export const GET_DOCUMENT_LIST = "GET_DOCUMENT_LIST";
export const GET_DOCUMENT_LIST_SUCCESS = "GET_DOCUMENT_LIST_SUCCESS";
export const GET_DOCUMENT_LIST_ERROR = "GET_DOCUMENT_LIST_ERROR";
export const ADD_DOCUMENT = "ADD_DOCUMENT";
export const ADD_DOCUMENT_SUCCESS = "ADD_DOCUMENT_SUCCESS";
export const ADD_DOCUMENT_ERROR = "ADD_DOCUMENT_ERROR";
export const GET_DOCUMENT = "GET_DOCUMENT";
export const GET_DOCUMENT_SUCCESS = "GET_DOCUMENT_SUCCESS";
export const GET_DOCUMENT_ERROR = "GET_DOCUMENT_ERROR";
export const EDIT_DOCUMENT = "EDIT_DOCUMENT";
export const EDIT_DOCUMENT_SUCCESS = "EDIT_DOCUMENT_SUCCESS";
export const EDIT_DOCUMENT_ERROR = "EDIT_DOCUMENT_ERROR";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT_ERROR = "DELETE_DOCUMENT_ERROR";
export const DELETE_MULTIPLE_DOCUMENT = "DELETE_MULTIPLE_DOCUMENT";
export const DELETE_MULTIPLE_DOCUMENT_SUCCESS =
  "DELETE_MULTIPLE_DOCUMENT_SUCCESS";
export const DELETE_MULTIPLE_DOCUMENT_ERROR = "DELETE_MULTIPLE_DOCUMENT_ERROR";
export const DOWNLOAD_DOCUMENT = "DOWNLOAD_DOCUMENT";
export const DOWNLOAD_DOCUMENT_SUCCESS = "DOWNLOAD_DOCUMENT_SUCCESS";
export const DOWNLOAD_DOCUMENT_ERROR = "DOWNLOAD_DOCUMENT_ERROR";
export const GET_DOCUMENT_FILES_BY_DOCID = "GET_DOCUMENT_FILES_BY_DOCID";
export const GET_DOCUMENT_FILES_BY_DOCID_SUCCESS =
  "GET_DOCUMENT_FILES_BY_DOCID_SUCCESS";
export const GET_DOCUMENT_FILES_BY_DOCID_ERROR =
  "GET_DOCUMENT_FILES_BY_DOCID_ERROR";
export const RESET_DOCUMENT = "RESET_DOCUMENT";
export const getAllDocument = () => ({
  type: GET_ALL_DOCUMENT,
  payload: {},
});

export const getAllDocumentSuccess = (documents) => ({
  type: GET_ALL_DOCUMENT_SUCCESS,
  payload: documents,
});
export const getAllDocumentError = (error) => ({
  type: GET_ALL_DOCUMENT_ERROR,
  payload: error,
});

export const getRecentDocument = () => ({
  type: GET_RECENT_DOCUMENT,
  payload: {},
});

export const getRecentDocumentSuccess = (documents) => ({
  type: GET_RECENT_DOCUMENT_SUCCESS,
  payload: documents,
});
export const getRecentDocumentError = (error) => ({
  type: GET_RECENT_DOCUMENT_ERROR,
  payload: error,
});

export const getDocumentList = (dbParam) => ({
  type: GET_DOCUMENT_LIST,
  payload: { dbParam },
});

export const getDocumentListSuccess = (documentList, metadata) => ({
  type: GET_DOCUMENT_LIST_SUCCESS,
  payload: { documentList, metadata },
});

export const getDocumentListError = (error) => ({
  type: GET_DOCUMENT_LIST_ERROR,
  payload: error,
});

export const addDocument = (documentData) => ({
  type: ADD_DOCUMENT,
  payload: { documentData },
});

export const addDocumentSuccess = (success, message) => ({
  type: ADD_DOCUMENT_SUCCESS,
  payload: { success, message },
});

export const addDocumentError = (error) => ({
  type: ADD_DOCUMENT_ERROR,
  payload: error,
});

export const getDocument = (documentId) => ({
  type: GET_DOCUMENT,
  payload: { documentId },
});

export const getDocumentSuccess = (documentData) => ({
  type: GET_DOCUMENT_SUCCESS,
  payload: documentData,
});

export const getDocumentError = (error) => ({
  type: GET_DOCUMENT_ERROR,
  payload: error,
});

export const editDocument = (documentId, documentData) => ({
  type: EDIT_DOCUMENT,
  payload: { documentId, documentData },
});

export const editDocumentSuccess = (success, message) => ({
  type: EDIT_DOCUMENT_SUCCESS,
  payload: { success, message },
});

export const editDocumentError = (error) => ({
  type: EDIT_DOCUMENT_ERROR,
  payload: error,
});

export const deleteDocument = (documentId) => ({
  type: DELETE_DOCUMENT,
  payload: { documentId },
});

export const deleteDocumentSuccess = (success, message) => ({
  type: DELETE_DOCUMENT_SUCCESS,
  payload: { success, message },
});

export const deleteDocumentError = (error) => ({
  type: DELETE_DOCUMENT_ERROR,
  payload: error,
});

export const deleteMultipleDocument = (documentIds) => ({
  type: DELETE_MULTIPLE_DOCUMENT,
  payload: { documentIds },
});

export const deleteMultipleDocumentSuccess = (success, message) => ({
  type: DELETE_MULTIPLE_DOCUMENT_SUCCESS,
  payload: { success, message },
});

export const deleteMultipleDocumentError = (error) => ({
  type: DELETE_MULTIPLE_DOCUMENT_ERROR,
  payload: error,
});

export const downloadDocument = (documentId, projectName) => ({
  type: DOWNLOAD_DOCUMENT,
  payload: { documentId, projectName },
});

export const downloadDocumentSuccess = (success, message) => ({
  type: DOWNLOAD_DOCUMENT_SUCCESS,
  payload: { success, message },
});

export const downloadDocumentError = (error) => ({
  type: DOWNLOAD_DOCUMENT_ERROR,
  payload: error,
});

export const resetDocument = () => ({
  type: RESET_DOCUMENT,
  payload: {},
});

export const getFilesByDocId = (documentId) => ({
  type: GET_DOCUMENT_FILES_BY_DOCID,
  payload: { documentId },
});

export const getFilesByDocIdSuccess = (documentData) => ({
  type: GET_DOCUMENT_FILES_BY_DOCID_SUCCESS,
  payload: documentData,
});

export const getFilesByDocIdError = (error) => ({
  type: GET_DOCUMENT_FILES_BY_DOCID_ERROR,
  payload: error,
});
