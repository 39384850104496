export const SET_PAIDIN = "SET_PAIDIN";
export const GET_ALL_PAIDIN = "GET_ALL_PAIDIN";
export const GET_ALL_PAIDIN_SUCCESS = "GET_ALL_PAIDIN_SUCCESS";
export const GET_ALL_PAIDIN_ERROR = "GET_ALL_PAIDIN_ERROR";
export const ADD_PAIDIN = "ADD_PAIDIN";
export const ADD_PAIDIN_SUCCESS = "ADD_PAIDIN_SUCCESS";
export const ADD_PAIDIN_ERROR = "ADD_PAIDIN_ERROR";
export const GET_PAIDIN = "GET_PAIDIN";
export const GET_PAIDIN_SUCCESS = "GET_PAIDIN_SUCCESS";
export const GET_PAIDIN_ERROR = "GET_PAIDIN_ERROR";
export const EDIT_PAIDIN = "EDIT_PAIDIN";
export const EDIT_PAIDIN_SUCCESS = "EDIT_PAIDIN_SUCCESS";
export const EDIT_PAIDIN_ERROR = "EDIT_PAIDIN_ERROR";
export const DELETE_PAIDIN = "DELETE_PAIDIN";
export const DELETE_PAIDIN_SUCCESS = "DELETE_PAIDIN_SUCCESS";
export const DELETE_PAIDIN_ERROR = "DELETE_PAIDIN_ERROR";
export const EXPORT_PAIDIN = "EXPORT_PAIDIN";
export const EXPORT_PAIDIN_SUCCESS = "EXPORT_PAIDIN_SUCCESS";
export const EXPORT_PAIDIN_ERROR = "EXPORT_PAIDIN_ERROR";
export const RESET_PAIDIN = "RESET_PAIDIN";


export const setPaidIn = (rowIndex, columnId, value) => ({
  type: SET_PAIDIN,
  payload: { rowIndex, columnId, value },
});

export const getAllPaidIn = (param) => ({
  type: GET_ALL_PAIDIN,
  payload: { param },
});

export const getAllPaidInSuccess = (success, data, totalPaidInAmount) => ({
  type: GET_ALL_PAIDIN_SUCCESS,
  payload: { success, data, totalPaidInAmount },
});

export const getAllPaidInError = (error) => ({
  type: GET_ALL_PAIDIN_ERROR,
  payload: error,
});

export const addPaidIn = (paidInData, projectId, eachPropertyFund) => ({
  type: ADD_PAIDIN,
  payload: { paidInData, projectId, eachPropertyFund },
});

export const addPaidInSuccess = (success, message) => ({
  type: ADD_PAIDIN_SUCCESS,
  payload: { success, message },
});

export const addPaidInError = (error) => ({
  type: ADD_PAIDIN_ERROR,
  payload: error,
});

export const getPaidIn = (paidInId) => ({
  type: GET_PAIDIN,
  payload: { paidInId },
});

export const getPaidInSuccess = (paidInData) => ({
  type: GET_PAIDIN_SUCCESS,
  payload: paidInData,
});

export const getPaidInError = (error) => ({
  type: GET_PAIDIN_ERROR,
  payload: error,
});

export const editPaidIn = (
  paidInId,
  paidInData,
  projectId,
  eachPropertyFund
) => ({
  type: EDIT_PAIDIN,
  payload: { paidInId, paidInData, projectId, eachPropertyFund },
});

export const editPaidInSuccess = (success, message) => ({
  type: EDIT_PAIDIN_SUCCESS,
  payload: { success, message },
});

export const editPaidInError = (error) => ({
  type: EDIT_PAIDIN_ERROR,
  payload: error,
});

export const deletePaidIn = (paidInId, projectId, eachPropertyFund) => ({
  type: DELETE_PAIDIN,
  payload: { paidInId, projectId, eachPropertyFund },
});

export const deletePaidInSuccess = (success1, message) => ({
  type: DELETE_PAIDIN_SUCCESS,
  payload: { success1, message },
});

export const deletePaidInError = (error) => ({
  type: DELETE_PAIDIN_ERROR,
  payload: error,
});

export const exportPaidIn = (projectId, eachPropertyFund) => ({
  type: EXPORT_PAIDIN,
  payload: { projectId, eachPropertyFund },
});

export const exportPaidInSuccess = (success, message) => ({
  type: EXPORT_PAIDIN_SUCCESS,
  payload: { success, message },
});

export const exportPaidInError = (error) => ({
  type: EXPORT_PAIDIN_ERROR,
  payload: error,
});

export const resetPaidIn = () => ({
  type: RESET_PAIDIN,
  payload: {},
});

