import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import PaidInOutService from "../../services/paid-in-out-service";
import {
  GET_ALL_PAIDINOUT,
  GET_CAPITAL_CALL_CREDIT,
  GET_CAPITAL_CALL_DEBIT,
  ADD_PAIDINOUT,
  GET_PAIDINOUT,
  EDIT_PAIDINOUT,
  DELETE_PAIDINOUT,
  SET_CHECKED,
  EXPORT_PAIDINOUT,
} from "../actions";
import {
  getAllPaidInOut,
  getAllPaidInOutSuccess,
  getAllPaidInOutError,
  getCapitalCallDebitSuccess,
  getCapitalCallCreditSuccess,
  getCapitalCallDebitError,
  getCapitalCallCreditError,
  addPaidInOutSuccess,
  addPaidInOutError,
  getPaidInOutSuccess,
  getPaidInOutError,
  editPaidInOutSuccess,
  editPaidInOutError,
  deletePaidInOutSuccess,
  deletePaidInOutError,
  exportPaidInOutSuccess,
  exportPaidInOutError,
  setIsCheckedError,
  setIsCheckedSuccess,
} from "./action";
import {
  getAllPaidIn,
  getAllPaidOut,
  getCapitalCallCredit as callCredit,
  getCapitalCallDebit as callDebit,
  getProject,
} from "src/reduxs/actions";
import { toast } from "react-toastify";
import { handleMyErrorMessage } from "../../helpers/util";
import ToastElement from "../../components/toast";
import saveAs from "file-saver";
import moment from "moment";

export function* watchGetAllPaidInOut() {
  yield takeEvery(GET_ALL_PAIDINOUT, getAllPaidInOutAc);
}

const getAllPaidInOutAsync = async (param) => {
  return PaidInOutService.getAllPaidInOut(param);
};

function* getAllPaidInOutAc({ payload }) {
  try {
    const response = yield call(getAllPaidInOutAsync, payload.param);
    if (response.data.success) {
      yield put(
        getAllPaidInOutSuccess(response.data.success, response.data.data)
      );
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getAllPaidInOutError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getAllPaidInOutError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getAllPaidInOutError(errorMessage));
    }
  }
}

export function* watchGetCapitalCallCredit() {
  yield takeEvery(GET_CAPITAL_CALL_CREDIT, getCapitalCallCredit);
}

const getCapitalCallAsync = async (param) => {
  return PaidInOutService.getCapitalCall(param);
};

function* getCapitalCallCredit({ payload }) {
  try {
    const response = yield call(getCapitalCallAsync, payload.capitalParam);
    if (response.data.success) {
      yield put(getCapitalCallCreditSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getCapitalCallCreditError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getCapitalCallCreditError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getCapitalCallCreditError(errorMessage));
    }
  }
}
export function* watchGetCapitalCallDebit() {
  yield takeEvery(GET_CAPITAL_CALL_DEBIT, getCapitalCallDebit);
}

function* getCapitalCallDebit({ payload }) {
  try {
    const response = yield call(getCapitalCallAsync, payload.capitalParam);
    if (response.data.success) {
      yield put(getCapitalCallDebitSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getCapitalCallDebitError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getCapitalCallDebitError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getCapitalCallDebitError(errorMessage));
    }
  }
}

export function* watchAddPaidInOut() {
  yield takeEvery(ADD_PAIDINOUT, addPaidInOut);
}

const addPaidInOutAsync = async (data) => {
  return PaidInOutService.addPaidInOut(data.paidInOutData);
};

function* addPaidInOut({ payload }) {
  try {
    const response = yield call(addPaidInOutAsync, payload);

    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        addPaidInOutSuccess(response.data.success, response.data.message)
      );
      // Fetch updated list

      yield put(
        getAllPaidInOut({
          projectId: payload.projectId,
          eachPropertyFund: payload.eachPropertyFund,
        })
      );
      yield put(
        getAllPaidIn({
          projectId: payload.projectId,
          eachPropertyFund: payload?.eachPropertyFund,
        })
      );
      yield put(
        getAllPaidOut({
          projectId: payload.projectId,
          eachPropertyFund: payload?.eachPropertyFund,
        })
      );

      response.data.data?.transactionType === "credit"
        ? yield put(
            callCredit({ projectId: payload.projectId, type: "credit" })
          )
        : yield put(callDebit({ projectId: payload.projectId, type: "debit" }));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(addPaidInOutError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type="error"
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   { containerId: "default" }
    // );
    // yield put(addPaidInOutError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(addPaidInOutError(errorMessage));
    }
  }
}

export function* watchGetPaidInOut() {
  yield takeEvery(GET_PAIDINOUT, getPaidInOutAc);
}

const getPaidInOutAsync = async (id) => {
  return PaidInOutService.getPaidInOut(id);
};

function* getPaidInOutAc({ payload }) {
  try {
    const response = yield call(getPaidInOutAsync, payload.paidInOutId);
    if (response.data.success) {
      yield put(getPaidInOutSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getPaidInOutError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getPaidInOutError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getPaidInOutError(errorMessage));
    }
  }
}

export function* watchEditPaidInOut() {
  yield takeEvery(EDIT_PAIDINOUT, editPaidInOut);
}

const editPaidInOutAsync = async (data, id) => {
  return PaidInOutService.editPaidInOut(data, id);
};

function* editPaidInOut({ payload }) {
  try {
    const response = yield call(
      editPaidInOutAsync,
      payload.paidInOutData,
      payload.paidInOutId
    );
    if (response.data.success) {
      yield put(
        editPaidInOutSuccess(response.data.success, response.data.message)
      );

      yield put(
        getAllPaidIn({
          projectId: payload.projectId,
          eachPropertyFund: payload?.eachPropertyFund,
        })
      );
      yield put(
        getAllPaidOut({
          projectId: payload.projectId,
          eachPropertyFund: payload?.eachPropertyFund,
        })
      );

      yield put(callCredit({ projectId: payload.projectId, type: "credit" }));
      yield put(callDebit({ projectId: payload.projectId, type: "debit" }));
      yield put(
        getAllPaidInOut({
          projectId: payload.projectId,
          eachPropertyFund: payload?.eachPropertyFund,
        })
      );

      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(editPaidInOutError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type="error"
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   { containerId: "default" }
    // );
    // yield put(editPaidInOutError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(editPaidInOutError(errorMessage));
    }
  }
}

export function* watchDeletePaidInOut() {
  yield takeEvery(DELETE_PAIDINOUT, deletePaidInOut);
}

const deletePaidInOutAsync = async (id, eachPropertyFund) => {
  return eachPropertyFund
    ? PaidInOutService.deletePaidInOutFund(id)
    : PaidInOutService.deletePaidInOut(id);
};

function* deletePaidInOut({ payload }) {
  try {
    const response = yield call(
      deletePaidInOutAsync,
      payload.paidInOutId,
      payload.eachPropertyFund
    );
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        deletePaidInOutSuccess(response.data.success, response.data.message)
      );
      // Fetch updated list
      yield put(
        getAllPaidInOut({
          projectId: payload.projectId,
          eachPropertyFund: payload?.eachPropertyFund,
        })
      );
      yield put(
        getAllPaidIn({
          projectId: payload.projectId,
          eachPropertyFund: payload?.eachPropertyFund,
        })
      );
      yield put(
        getAllPaidOut({
          projectId: payload.projectId,
          eachPropertyFund: payload?.eachPropertyFund,
        })
      );

      yield put(callCredit({ projectId: payload.projectId, type: "credit" }));
      yield put(callDebit({ projectId: payload.projectId, type: "debit" }));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(deletePaidInOutError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(deletePaidInOutError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(deletePaidInOutError(errorMessage));
    }
  }
}

export function* watchExportPaidInOut() {
  yield takeEvery(EXPORT_PAIDINOUT, exportPaidInOut);
}

const exportPaidInOutAsync = async (projectId, eachPropertyFund) => {
  return eachPropertyFund
    ? PaidInOutService.exportPaidInOutFund(projectId)
    : PaidInOutService.exportPaidInOut(projectId);
};

function* exportPaidInOut({ payload }) {
  try {
    const response = yield call(
      exportPaidInOutAsync,
      payload.projectId,
      payload.eachPropertyFund
    );
    if (response && response.data) {
      yield put(exportPaidInOutSuccess(true, ""));
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const fileName =
        "PaidInOut-" + moment().format("YYYY-MMM-DD-HH-mm") + ".xlsx";
      saveAs(blob, fileName);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(exportPaidInOutError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(exportPaidInOutError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(exportPaidInOutError(errorMessage));
    }
  }
}

export function* watchIsChecked() {
  yield takeEvery(SET_CHECKED, setIsChecked);
}

const isCheckedAsync = async ({ param }) => {
  return PaidInOutService.setIsChecked(param);
};

function* setIsChecked({ payload }) {
  try {
    const response = yield call(isCheckedAsync, payload);
    if (response && response.data) {
      yield put(setIsCheckedSuccess(true, response.data.data.eachPropertyFund));
      yield put(getProject(response.data.data.id));
    } else {
      yield put(setIsCheckedSuccess(response.data.message));
    }
  } catch (error) {
    // yield put(setIsCheckedError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      yield put(setIsCheckedError(errorMessage));
    }
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAllPaidInOut),
    fork(watchGetCapitalCallCredit),
    fork(watchGetCapitalCallDebit),
    fork(watchAddPaidInOut),
    fork(watchGetPaidInOut),
    fork(watchEditPaidInOut),
    fork(watchDeletePaidInOut),
    fork(watchExportPaidInOut),
    fork(watchIsChecked),
  ]);
}
