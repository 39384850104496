import React from "react";

const IconEye = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.79843 3.59404C7.79843 4.54735 6.99179 5.32016 5.99674 5.32016C5.0017 5.32016 4.19506 4.54735 4.19506 3.59404C4.19506 2.64072 5.0017 1.86792 5.99674 1.86792C6.99179 1.86791 7.79843 2.64073 7.79843 3.59404ZM6 0C4.96975 0.00457 3.90186 0.25538 2.89096 0.73557C2.14038 1.10679 1.4089 1.63053 0.77394 2.27698C0.46208 2.60697 0.06431 3.08479 0 3.5946C0.0076 4.03621 0.4813 4.58129 0.77394 4.91223C1.36937 5.53329 2.0818 6.04241 2.89096 6.45402C3.83367 6.91153 4.87712 7.17494 6 7.18959C7.03123 7.18495 8.09888 6.93125 9.10866 6.45402C9.85924 6.0828 10.5911 5.55868 11.2261 4.91223C11.5379 4.58224 11.9357 4.10441 12 3.5946C11.9924 3.15299 11.5187 2.60789 11.2261 2.27696C10.6306 1.6559 9.91782 1.14717 9.10866 0.73555C8.16644 0.27839 7.12042 0.01679 6 0ZM5.99924 0.89248C7.56043 0.89248 8.82599 2.10242 8.82599 3.59499C8.82599 5.08755 7.56043 6.29749 5.99924 6.29749C4.43805 6.29749 3.17249 5.08754 3.17249 3.59499C3.17249 2.10242 4.43805 0.89248 5.99924 0.89248Z"
        fill="#656D78"
      />
    </svg>
  );
};

export default IconEye;
