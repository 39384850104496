import React, { useEffect } from "react";
import reactDom from "react-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import BackDrop from "src/components/backdrop";
import { hideScroll } from "src/helpers/util";

const Header = ({ children }) => (
  <header className="flex justify-between items-center mb-5 sm:mb-10 mr-4 sm:mr-9">
    {children}
  </header>
);
const Body = ({ children }) => (
  <div className="cookie-dialog-body mr-4 sm:mr-8">
    <PerfectScrollbar className="rail-visible">{children}</PerfectScrollbar>
  </div>
);

const FloatingForm = (props) => {
  const { children, classes, handlebackdropclick, ...others } = props;

  useEffect(() => {
    hideScroll(true);

    return () => {
      // remove body class element while unmount
      hideScroll(false);
    };
  });

  return reactDom.createPortal(
    <>
      <div
        {...others}
        className={`cookie-dialog animate__animated animate__fast animate__bounceInDown 
      ${classes?.root || ""}`}
      >
        {children}
      </div>

      <BackDrop
        classes="bg-gray-500 bg-opacity-20"
        onClick={handlebackdropclick}
      />
    </>,
    document.getElementById("root")
  );
};

FloatingForm.Header = Header;
FloatingForm.Body = Body;

export default FloatingForm;
