import React from "react";
const IconClose = (props) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 12.229 12.228"
    >
      <g
        id="Group_3140"
        dataname="Group 3140"
        transform="translate(1.414 1.414)"
      >
        <path
          id="Path_10321"
          dataname="Path 10321"
          d="M17086.293-14558.332l-9.4,9.4"
          transform="translate(-17076.893 14558.332)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeWidth="2"
        />
        <path
          id="Path_10322"
          dataname="Path 10322"
          d="M17086.293-14548.934l-9.4-9.4"
          transform="translate(-17076.893 14558.333)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};
export default IconClose;
