import React from "react";

const IconMoney = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 26.293 22.12">
      <g
        id="Group_3012"
        dataname="Group 3012"
        transform="translate(-1629.282 -586.275)"
      >
        <g
          id="Group_2860"
          dataname="Group 2860"
          transform="translate(1647.556 592.264)"
        >
          <g id="Group_2007" dataname="Group 2007">
            <path
              id="Path_10230"
              dataname="Path 10230"
              d="M1645.59,596.744a3.916,3.916,0,0,1-3.924,3.924,3.845,3.845,0,0,1-2.072-.6,6.825,6.825,0,0,0,.382-2.314,8.357,8.357,0,0,0-1.026-3.964,3.983,3.983,0,0,1,1.408-1.026l-1.006-2.9,1.308-.423.966,1.107.966-1.107,1.308.423-1.006,2.837v.02A4.412,4.412,0,0,1,1645.59,596.744Z"
              transform="translate(-1638.95 -589.44)"
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
            <line
              id="Line_7"
              dataname="Line 7"
              x2="1.304"
              transform="translate(2.68 3.268)"
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
          </g>
          <g
            id="Group_2008"
            dataname="Group 2008"
            transform="translate(1.889 5.614)"
          >
            <path
              id="Path_9260"
              dataname="Path 9260"
              d="M1641.577,593.908a.747.747,0,0,1-.727.723v.349h-.414v-.354a.778.778,0,0,1-.737-.769h.49a.428.428,0,0,0,.47.385h.014c.229,0,.4-.1.4-.286s-.189-.236-.573-.312-.74-.2-.74-.675a.717.717,0,0,1,.683-.693v-.354h.414v.357a.729.729,0,0,1,.68.716h-.49a.38.38,0,0,0-.417-.334c-.213,0-.366.09-.366.271s.184.231.573.3S1641.579,593.439,1641.577,593.908Z"
              transform="translate(-1639.7 -591.922)"
            />
          </g>
        </g>
        <g
          id="Group_2860-2"
          dataname="Group 2860"
          transform="translate(1633.692 586.65)"
        >
          <g id="Group_2007-2" dataname="Group 2007">
            <path
              id="Path_10231"
              dataname="Path 10231"
              d="M1645.924,596.61a8.292,8.292,0,0,0-4.1-3.7l.02-.041,1.912-5.392-2.515-.825-1.831,2.133-1.831-2.133-2.495.825,1.931,5.513a8.4,8.4,0,0,0-4.95,7.586,7.437,7.437,0,0,0,14.508,2.314,6.825,6.825,0,0,0,.382-2.314A8.355,8.355,0,0,0,1645.924,596.61Z"
              transform="translate(-1632.06 -586.65)"
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
            <line
              id="Line_7-2"
              dataname="Line 7"
              x2="2.482"
              transform="translate(7.358 6.226)"
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
          </g>
        </g>
        <line
          id="Line_144"
          dataname="Line 144"
          x2="6.135"
          transform="translate(1649.065 604.009)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.75"
        />
        <g
          id="Group_2860-3"
          dataname="Group 2860"
          transform="translate(1641.05 592.876)"
        >
          <g id="Group_2007-3" dataname="Group 2007">
            <line
              id="Line_7-3"
              dataname="Line 7"
              x2="2.482"
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
          </g>
        </g>
        <path
          id="Path_10233"
          dataname="Path 10233"
          d="M1639.62,596.692a1.987,1.987,0,0,1-1.932,1.932v.926h-1.106V598.6a2.059,2.059,0,0,1-1.972-2.052h1.307a1.134,1.134,0,0,0,1.248,1.026h.04c.624,0,1.066-.261,1.066-.765s-.5-.624-1.529-.825c-1.026-.221-1.972-.543-1.972-1.811a1.9,1.9,0,0,1,1.831-1.851v-.946h1.106v.946a1.963,1.963,0,0,1,1.812,1.912h-1.308a1.012,1.012,0,0,0-1.107-.886c-.583,0-.986.242-.986.724s.483.624,1.53.805C1638.694,595.082,1639.64,595.424,1639.62,596.692Z"
          transform="translate(4.213 4.788)"
        />
        <path
          id="Path_10234"
          dataname="Path 10234"
          d="M1641.049,614.227h-11.392"
          transform="translate(0 -6.207)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeWidth="0.75"
        />
      </g>
    </svg>
  );
};

export default IconMoney;
