import React, { Fragment, useEffect } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { connect, useDispatch } from 'react-redux'
import { IconChevronDown } from '../../../components/svg'
import { getInvestorsByUser, setSelectedInvestor } from 'src/reduxs/actions'

const InvestorDropdown = (props) => {
  const dispatch = useDispatch()
  const setInvestor = (investor) => {
    dispatch(props.setSelectedInvestor(investor))
    localStorage.setItem('selectedInvestor', JSON.stringify(investor))
  }

  useEffect(() => {
    props.getInvestorsByUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Menu as='div' className='relative inline-block z-1 bg-white'>
        <Menu.Button className='inline-flex py-2 px-2 border border-solid border-primary-200 rounded-md text-xs focus:outline-none'>
          {props.selectedInvestor?.full_name || 'All'}
          <span className='w-4 h-3.5 flex items-center justify-center ml-3 p-0.5 rounded-sm bg-primary-200'>
            <IconChevronDown />
          </span>
        </Menu.Button>

        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items
            as='ul'
            className='absolute right-0 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg min-w-[12rem] ring-1 ring-black ring-opacity-5 focus:outline-none'
          >
            <Menu.Item
              as='li'
              className={`group p-1 flex rounded-md items-center w-full text-sm`}
            >
              <button
                type='button'
                className={`group flex rounded-md items-center w-full px-3 py-2 text-sm text-gray-900 hover:bg-primary-500 hover:text-white focus:outline-none svg-color-grey hover:svg-color-white`}
                onClick={() => setInvestor({ id: 0, full_name: 'All' })}
              >
                All
              </button>
            </Menu.Item>
            {!props.loading &&
              props.investors?.map((investor) => (
                <Menu.Item
                  key={investor.id}
                  as='li'
                  className={`group p-1 flex rounded-md items-center w-full text-sm`}
                >
                  <button
                    type='button'
                    className={`group flex rounded-md items-center w-full px-3 py-2 text-sm text-gray-900 hover:bg-primary-500 hover:text-white focus:outline-none svg-color-grey hover:svg-color-white`}
                    onClick={() => setInvestor(investor)}
                  >
                    {investor.full_name}
                  </button>
                </Menu.Item>
              ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  )
}

const mapStateToProps = (state) => ({
  investors: state.user.investors,
  loading: state.user.loading4,
  selectedInvestor: state.user.selectedInvestor,
})
export default connect(mapStateToProps, {
  getInvestorsByUser,
  setSelectedInvestor,
})(InvestorDropdown)
