import React, { Component } from "react";
import { connect } from "react-redux";
import { login, resetAuth } from "src/reduxs/actions";
import { withTranslation } from "react-i18next";
import { IconLoginType } from "src/components/svg";
import ButtonLoginType from "./button-login-type";
import LoginMalling from "./login-malling";
import LoginNorwegian from "./login-norwegian";
import LoginInternational from "./login-international";
import SetLanguage from "src/views/auth/set-lang";
import CookieDialog from "src/components//cookie-dialog";
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLoginType: "",
      type: this.props.location.state ? this.props.location.state.type : null,
      overflowScroll: true,
      cookieContent: "LESS",
      displayCookieDialog: false,
    };
    // Redirect To App dashboard if User Token Available
    if (localStorage.getItem("token")) {
      this.props.history.push("/");
    }
  }

  onLoginTypeSelect = (type) => {
    this.setState({ selectedLoginType: type });

    // Hide Scroll On Transision
    if (this.state.overflowScroll) {
      this.setState({ overflowScroll: false });

      setTimeout(() => {
        this.setState({ overflowScroll: true });
      }, 800);
    }
  };

  handleAcceptCookie = ()=>{
    localStorage.setItem("acceptCookie", "true");
    this.setState({displayCookieDialog: false})
  }

  componentDidMount() {
    const { type } = this.state;
    if (type) {
      if (type === "malling") {
        this.setState({ selectedLoginType: "malling" });
      } else if (type === "norwegian") {
        this.setState({ selectedLoginType: "norwegian" });
      } else if (type === "international") {
        this.setState({ selectedLoginType: "international" });
      } else {
        this.setState({ selectedLoginType: "" });
      }
    }
    if(localStorage.getItem("acceptCookie") !== "true"){
      this.setState({displayCookieDialog: true})
    }
  }

  render() {
    const { t } = this.props;

    return (
      <div className="auth-wrapper">
        <img
          className="w-52 block xl:hidden my-8 mx-auto"
          src={require("../../../assets/images/Malling-logo-hv.svg").default}
          alt=""
        />

        <div className="auth-wrapper-aside">
          {/*<img*/}
          {/*  src={require("../../../assets/images/logo-light.svg").default}*/}
          {/*  alt=""*/}
          {/*/>*/}
          {/*latest logo provided by client*/}
          <img width="200px" height="auto"
              src={require("../../../assets/images/Malling-logo-hv.svg").default}
              alt=""
          />

          <h2>{t("AUTH.INTRO.TITLE")}</h2>

          <ul>
            {/*<li>{t("AUTH.INTRO.SECTION1")}</li>*/}
            {/*<li>{t("AUTH.INTRO.SECTION2")}</li>*/}
            <li>
              {t("AUTH.INTRO.SECTION3")}{" "}
              <a
                className="font-bold underline"
                href="mailto:havard.solbrakke@malling.no"
              >
                havard.solbrakke@malling.no
              </a>
              {/*, {t("AUTH.INTRO.SECTION4")}.*/}
            </li>
          </ul>

          <footer className="flex justify-between">
            <span>
              © {t("AUTH.INTRO.FOOTER")}
            </span>
            <span>{process.env.REACT_APP_WEB_CLIENT_VERSION}</span>
          </footer>
        </div>

        <div
          className={`auth-wrapper-content 
          ${this.state.overflowScroll ? "overflow-auto" : "overflow-hidden"}
          `}
        >
          <header>
            <h4>
              {/* {t("AUTH.GENERAL.FINDING_TROUBLE")}{" "} */}
              <a href="/" className="text-primary-500">
                {t("AUTH.GENERAL.SELECT_LANGUAGE")}
              </a>
            </h4>

            <SetLanguage />
          </header>

          <h1>{t("AUTH.LOGIN.CHOOSE_LOGIN_TYPE")}</h1>
          {/*<h6>{t("AUTH.LOGIN.SELECT_APPROPRIATE")}</h6>*/}

          <div className="flex mb-8 2xl:mb-14 space-x-4">
            <ButtonLoginType
              title={t("AUTH.LOGIN.LOGIN_TYPE_NORWEGIAN")}
              icon={<IconLoginType type="norwegian" />}
              selected={this.state.selectedLoginType === "norwegian"}
              btnClick={() => this.onLoginTypeSelect("norwegian")}
            />
            <ButtonLoginType
              title={t("AUTH.LOGIN.LOGIN_TYPE_INTERNATIONAL")}
              icon={<IconLoginType type="international" />}
              selected={this.state.selectedLoginType === "international"}
              btnClick={() => this.onLoginTypeSelect("international")}
            />
            <ButtonLoginType
              title={t("AUTH.LOGIN.LOGIN_TYPE_MALLING")}
              icon={<IconLoginType type="malling" />}
              selected={this.state.selectedLoginType === "malling"}
              btnClick={() => this.onLoginTypeSelect("malling")}
            />
          </div>

          {this.state.selectedLoginType === "malling" ? (
            <LoginMalling
              classes={{
                root:
                  this.state.selectedLoginType === "malling"
                    ? "animate__fadeInUp"
                    : "animate__fadeOut",
              }}
            />
          ) : null}

          {this.state.selectedLoginType === "norwegian" ? (
            <LoginNorwegian
              classes={{
                root:
                  this.state.selectedLoginType === "norwegian"
                    ? "animate__fadeInUp"
                    : "animate__fadeOut",
              }}
            />
          ) : null}

          {this.state.selectedLoginType === "international" ? (
            <LoginInternational
              classes={{
                root:
                  this.state.selectedLoginType === "international"
                    ? "animate__fadeInUp"
                    : "animate__fadeOut",
              }}
            />
          ) : null}
        </div>

        {this.state.displayCookieDialog && (<CookieDialog>
          <CookieDialog.Header>
              <h5 className="text-xl font-bold">
                Dine innstillinger for informasjonskapsler
              </h5>
            </CookieDialog.Header>

            <CookieDialog.Body>
              {this.state.cookieContent === "LESS" ? (<>
              <p className="text-grey-700">
                Nettstedet spør om du godtar informasjonskapsler for ytelsesformål, sosiale medier og annonsering. Informasjonskapsler for sosiale medier og annonsering fra tredjeparter brukes for å tilby deg funksjoner på sosiale medier og tilpassede annonser. <strong>Dersom du ønsker mer informasjon eller vil endre innstillingene dine, kan du trykke knappen ”Mer informasjon” eller gå til ”Innstillinger for informasjonskapsler” nederst på nettsiden.</strong> Mer informasjon om informasjonskapsler og behandling av dine personopplysninger, finner du under <a href="https://malling.no/informasjonskapsler" title="Vilkår for personvern og informasjonskapsler" target="_blank" rel="noreferrer" className="text-primary-500">Vilkår for personvern og informasjonskapsler</a>. Godtar du disse informasjonskapslene og den involverte behandlingen av dine personopplysninger?
              </p>
              </>) : (<>
              <div className="flex items-center mb-2">
                <input
                  id="cookie-checkbox-1"
                  type="checkbox"
                  className="mr-3 rounded border-primary-400 bg-primary-200 focus:ring-0 focus:ring-offset-0 text-primary-400 form-checkbox cursor-pointer"
                  defaultChecked
                  disabled
                /> 
                <label className="cursor-pointer font-bold" htmlFor="cookie-checkbox-1">Funksjonell</label>
              </div>
              <p className="text-grey-700">
                Disse informasjonskapslene er nødvendig for nettsidens grunnleggende funksjoner og vil derfor alltid være aktivert. Dette inkluderer informasjonskapsler som gjør at du blir husket når du besøker nettsiden innenfor én enkelt økt eller, hvis du ber om det, fra økt til økt. Disse informasjonskapslene muliggjør gjennomføring av aktiviteter og handlinger på nettstedet som du ønsker å gjennomføre, som for eksempel handlevogn- og betalingsprosessen. I tillegg har informasjonskapslene funksjoner i tilknytning til ivaretakelse av nettstedets sikkerhet og drift i samsvar med gjeldende regler.
              </p>
              <div className="flex items-center mt-4 mb-2">
                <input
                  id="cookie-checkbox-2"
                  type="checkbox"
                  className="mr-3 rounded border-primary-400 bg-primary-200 focus:ring-0 focus:ring-offset-0 text-primary-400 form-checkbox cursor-pointer"
                  defaultChecked
                /> 
                <label className="cursor-pointer font-bold" htmlFor="cookie-checkbox-2">Ytelse</label>
              </div>
              <p className="text-grey-700">
                Disse informasjonskapslene bidrar til at vi kan forbedre nettstedets funksjoner ved å spore atferden på dette nettstedet. I noen tilfeller øker disse informasjonskapslene hastigheten som vi kan behandle henvendelsene dine på, ved at de husker innstillingene du har valgt for nettstedet. Hvis du deaktiverer informasjonskapslene, kan det medføre dårlig tilpassede anbefalinger og langsom ytelse på nettstedet.
              </p>
              <div className="flex items-center mt-4 mb-2">
                <input
                  id="cookie-checkbox-3"
                  type="checkbox"
                  className="mr-3 rounded border-primary-400 bg-primary-200 focus:ring-0 focus:ring-offset-0 text-primary-400 form-checkbox cursor-pointer"
                  defaultChecked
                /> 
                <label className="cursor-pointer font-bold" htmlFor="cookie-checkbox-3">Sosiale medier og reklame</label>
              </div>
              <p className="text-grey-700">
                Informasjonskapsler på sosiale medier gir deg mulighet for å koble deg til det sosiale nettverket ditt og dele innhold fra nettstedet vårt via sosiale medier. Informasjonskapsler på annonser (fra tredjeparter) samler informasjon som bidrar til at vi kan tilpasse annonseringen bedre i forhold til dine interesser, både innenfor og utenfor dette nettstedet. I noen tilfeller kan disse informasjonskapslene innebære behandling av dine personopplysninger. Hvis du vil ha mer informasjon om denne behandlingen av personopplysninger, kan du se våre <a href="https://malling.no/informasjonskapsler" title="Vilkår for personvern og informasjonskapsler" target="_blank" rel="noreferrer" className="text-primary-500">Vilkår for personvern og informasjonskapsler</a>. Dersom du deaktiverer disse informasjonskapslene, kan det medføre at du ser annonser som ikke er like relevante for deg, eller at det ikke er mulig å koble til like effektivt med Facebook, Google eller andre nettverk, og/eller at du ikke får delt innhold på sosiale medier.
              </p>
              </>)}
              <div className="flex items-start mt-12 space-x-5">
                {this.state.cookieContent === "LESS" ? <button
                  className="btn-primary-outlined md"
                  type="button"
                  onClick={()=>{this.setState({ cookieContent: "MORE" });}}
                >
                  Mer informasjon
                </button> : <button
                  className="btn-primary-outlined md"
                  type="button"
                  onClick={()=>{this.setState({ cookieContent: "LESS" });}}
                >
                  Vis mindre informasjon
                </button>}
                

                <button
                  className="btn-primary md"
                  type="button"
                  onClick={()=>{this.handleAcceptCookie()}}
                >
                  Ja, jeg godtar
                </button>
              </div>
              <div className="mt-8 mb-8 h-px bg-primary-100"></div>
              <p className="text-grey-700">
                Du kan alltid endre innstillingene dine ved å gå til innstillingene for informasjonskapsler nederst på siden.
              </p>
            </CookieDialog.Body>
        </CookieDialog>)}
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { user, loading, error } = auth;
  return { user, loading, error };
};
export default connect(mapStateToProps, { login, resetAuth })(
  withTranslation()(Login)
);
