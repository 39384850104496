import React from "react";

const IconColoredChecked = (props) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22">
      <g
        id="Group_2827"
        dataname="Group 2827"
        transform="translate(-20988.828 10217)"
      >
        <circle
          id="Ellipse_303"
          dataname="Ellipse 303"
          cx="11"
          cy="11"
          r="11"
          transform="translate(20988.828 -10217)"
          fill={props.color?.bg || "#bef4d0"}
        />
        <g
          id="Group_2826"
          dataname="Group 2826"
          transform="translate(20997.074 -10209.054)"
        >
          <path
            id="Path_9327"
            dataname="Path 9327"
            d="M2250.776-10449.868l-6.509,6.51-2.8-2.8"
            transform="translate(-2242.865 10449.868)"
            fill="none"
            stroke={props.color?.text || "#0c8256"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  );
};

export default IconColoredChecked;
