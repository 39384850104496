import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as io from "socket.io-client";
import Echo from "laravel-echo";
import { getRecentNotification, newMessage } from "src/reduxs/actions";

const EchoClient = () => {
  const {
    shared: { currentUserData },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  window.io = io;
  if (!window.Echo) {
    window.Echo = new Echo({
      broadcaster: "socket.io",
      host: process.env.REACT_APP_HOST,
    });
  }

  useEffect(() => {
    if (currentUserData && window.Echo) {
      window.Echo.channel(`notification_${currentUserData?.id}`).listen(
        ".notification_broadcast",
        (data) => {
          console.log("listening for notification:", data);
          if (data) {
            dispatch(getRecentNotification(currentUserData?.defaultRoleId));
          }
        }
      );

      window.Echo.channel(
        `message_${currentUserData?.id}_${currentUserData?.defaultRoleId}`
      ).listen(".notification_broadcast", (data) => {
        console.log("listening for message_notification:", data);
        if (data) {
          const newMessageData = {
            select: false,
            id: data.message?.newMessage?.[0]?.id,
            msgId: data.message?.newMessage?.[0]?.message_receiver?.[0]?.id,
            subject: data.message?.newMessage?.[0]?.subject,
            message: data.message?.newMessage?.[0]?.message,
            createdAt: data.message?.newMessage?.[0]?.created_at,
            readAt: null,
          };
          dispatch(newMessage(data.message?.inboxCount, newMessageData));
        }
      });
    }
    return () => {
      // window.Echo.disconnect();
      window.Echo.leaveChannel(
        `message_${currentUserData?.id}_${currentUserData?.defaultRoleId}`
      );
      window.Echo.leaveChannel(`notification_${currentUserData?.id}`);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.Echo, currentUserData, dispatch]);

  return null;
};
export default EchoClient;
