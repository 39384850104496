import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import ProjectService from "../../services/project-service";
import TableDataService from "../../services/table-data-service1";
import ExportDataService from "../../services/export-service";
import moment from "moment";
import {
  GET_ALL_PROJECT,
  GET_PROJECT_LIST,
  ADD_PROJECT,
  GET_PROJECT,
  EDIT_PROJECT,
  DELETE_PROJECT,
  DELETE_MULTIPLE_PROJECT,
  ADD_PROPERTY,
  GET_PROPERTY,
  EDIT_PROPERTY,
  DELETE_PROPERTY,
  EXPORT_PROJECT,
  GET_PROJECT_BY_INVESTOR,
} from "../actions";
import {
  getAllProjectSuccess,
  getAllProjectError,
  getProjectList,
  getProjectListSuccess,
  getProjectListError,
  addProjectSuccess,
  addProjectError,
  getProject,
  getProjectSuccess,
  getProjectError,
  editProjectSuccess,
  editProjectError,
  deleteProjectSuccess,
  deleteProjectError,
  deleteMultipleProjectSuccess,
  deleteMultipleProjectError,
  addPropertySuccess,
  addPropertyError,
  getProperty,
  getPropertySuccess,
  getPropertyError,
  editPropertySuccess,
  editPropertyError,
  deletePropertySuccess,
  deletePropertyError,
  exportProjectSuccess,
  exportProjectFail,
  getProjectByInvestorSuccess,
  getProjectByInvestorError,
  setTabState,
} from "./action";
import { toast } from "react-toastify";
import saveAs from "file-saver";
import { handleMyErrorMessage } from "../../helpers/util";
import ToastElement from "../../components/toast";

export function* watchGetAllProject() {
  yield takeEvery(GET_ALL_PROJECT, getAllProject);
}

const getAllProjectAsync = async (params) => {
  return ProjectService.getAllProject(params);
};

function* getAllProject({ payload }) {
  try {
    const response = yield call(getAllProjectAsync, payload.params);
    if (response.data.success) {
      yield put(getAllProjectSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getAllProjectError(response.data.message));
    }
  } catch (error) {
    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getAllProjectError(errorMessage));
    }
  }
}

export function* watchGetProjectList() {
  yield takeEvery(GET_PROJECT_LIST, getProjectListAc);
}

const getProjectListAsync = async (dbParam) => {
  return TableDataService.getAllData("projects", dbParam);
};

function* getProjectListAc({ payload }) {
  try {
    const response = yield call(getProjectListAsync, payload.dbParam);
    if (response.data.success) {
      yield put(getProjectListSuccess(response.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getProjectListError(response.data.message));
    }
  } catch (error) {
    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getProjectListError(errorMessage));
    }
  }
}

export function* watchAddProject() {
  yield takeEvery(ADD_PROJECT, addProject);
}

const addProjectAsync = async (data) => {
  return ProjectService.addProject(data);
};

function* addProject({ payload }) {
  const { history } = payload;
  try {
    const response = yield call(addProjectAsync, payload.projectData);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        addProjectSuccess(response.data.success, response.data.message)
      );
      history.push({
        pathname: `/project-management/project/edit/${response.data.data.id}`,
        state: { tab: 1 },
      });
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(addProjectError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type='error'
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   { containerId: 'default' }
    // )
    // yield put(addProjectError(error.response.data.message))

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(addProjectError(errorMessage));
    }
  }
}

export function* watchGetProject() {
  yield takeEvery(GET_PROJECT, getProjectAc);
}

const getProjectAsync = async (id) => {
  return ProjectService.getProject(id);
};

function* getProjectAc({ payload }) {
  try {
    const response = yield call(getProjectAsync, payload.projectId);
    if (response.data.success) {
      yield put(getProjectSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getProjectError(response.data.message));
    }
  } catch (error) {
    if (error.response && error.response.status === 404) {
      yield put(getProjectError("404"));
    } else {
      const errorMessage = handleMyErrorMessage(error);
      if (errorMessage) {
        toast.error(<ToastElement type="error" message={errorMessage} />, {
          containerId: "default",
        });
        yield put(getProjectError(errorMessage));
      }
    }
  }
}

export function* watchEditProject() {
  yield takeEvery(EDIT_PROJECT, editProject);
}

const editProjectAsync = async (data, id) => {
  return ProjectService.editProject(data, id);
};

function* editProject({ payload }) {
  // const { history } = payload;
  try {
    const response = yield call(
      editProjectAsync,
      payload.projectData,
      payload.projectId
    );
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        editProjectSuccess(response.data.success, response.data.message)
      );
      yield put(setTabState(false));
      yield put(getProject(payload.projectId));
      // history.push(`/project-management/project/edit/${payload.projectId}`);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(editProjectError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type='error'
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   { containerId: 'default' }
    // )
    // yield put(editProjectError(error.response.data.message))

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(editProjectError(errorMessage));
    }
  }
}

export function* watchDeleteProject() {
  yield takeEvery(DELETE_PROJECT, deleteProject);
}

const deleteProjectAsync = async (id) => {
  return ProjectService.deleteProject(id);
};

function* deleteProject({ payload }) {
  try {
    const response = yield call(deleteProjectAsync, payload.projectId);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        deleteProjectSuccess(response.data.success, response.data.message)
      );
      // Fetch updated project list
      yield put(getProjectList({}));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(deleteProjectError(response.data.message));
    }
  } catch (error) {
    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(deleteProjectError(errorMessage));
    }
  }
}

export function* watchDeleteMultipleProject() {
  yield takeEvery(DELETE_MULTIPLE_PROJECT, deleteMultipleProject);
}

const deleteMultipleProjectAsync = async (ids) => {
  return ProjectService.deleteMultipleProject(ids);
};

function* deleteMultipleProject({ payload }) {
  try {
    const response = yield call(deleteMultipleProjectAsync, payload.projectIds);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        deleteMultipleProjectSuccess(
          response.data.success,
          response.data.message
        )
      );
      // Fetch updated project list
      yield put(getProjectList({}));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(deleteMultipleProjectError(response.data.message));
    }
  } catch (error) {
    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(deleteMultipleProjectError(errorMessage));
    }
  }
}

export function* watchAddProperty() {
  yield takeEvery(ADD_PROPERTY, addProperty);
}

const addPropertyAsync = async (data) => {
  return ProjectService.addProperty(data);
};

function* addProperty({ payload }) {
  try {
    const response = yield call(addPropertyAsync, payload.propertyData);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        addPropertySuccess(response.data.success, response.data.message)
      );
      // Fetch projectData to update property list
      yield put(getProject(payload.propertyData.projectId));
      // Fetch added property to update initialValues in form
      yield put(getProperty(response.data.data.id));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(addPropertyError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type='error'
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   { containerId: 'default' }
    // )
    // yield put(addPropertyError(error.response.data.message))

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(addPropertyError(errorMessage));
    }
  }
}

export function* watchGetProperty() {
  yield takeEvery(GET_PROPERTY, getPropertyAc);
}

const getPropertyAsync = async (id) => {
  return ProjectService.getProperty(id);
};

function* getPropertyAc({ payload }) {
  try {
    const response = yield call(getPropertyAsync, payload.propertyId);
    if (response.data.success) {
      yield put(getPropertySuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getPropertyError(response.data.message));
    }
  } catch (error) {
    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getPropertyError(errorMessage));
    }
  }
}

export function* watchEditProperty() {
  yield takeEvery(EDIT_PROPERTY, editProperty);
}

const editPropertyAsync = async (data, id) => {
  return ProjectService.editProperty(data, id);
};

function* editProperty({ payload }) {
  try {
    const response = yield call(
      editPropertyAsync,
      payload.propertyData,
      payload.propertyId
    );
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        editPropertySuccess(response.data.success, response.data.message)
      );
      // Fetch projectData to update property list
      yield put(getProject(payload.propertyData.projectId));
      // Fetch updated property to update initialValues in form
      yield put(getProperty(payload.propertyId));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(editPropertyError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type='error'
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   { containerId: 'default' }
    // )
    // yield put(editPropertyError(error.response.data.message))

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(editPropertyError(errorMessage));
    }
  }
}

export function* watchDeleteProperty() {
  yield takeEvery(DELETE_PROPERTY, deleteProperty);
}

const deletePropertyAsync = async (id) => {
  return ProjectService.deleteProperty(id);
};

function* deleteProperty({ payload }) {
  try {
    const response = yield call(deletePropertyAsync, payload.propertyId);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        deletePropertySuccess(response.data.success, response.data.message)
      );
      // Fetch projectData to update property list
      yield put(getProject(payload.projectId));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(deletePropertyError(response.data.message));
    }
  } catch (error) {
    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(deletePropertyError(errorMessage));
    }
  }
}

export function* watchExportProject() {
  yield takeEvery(EXPORT_PROJECT, exportProject);
}

export const exportProjectAsync = (param) => {
  return ExportDataService.getAllData("exportProject", param);
};
function* exportProject({ payload }) {
  try {
    const response = yield call(exportProjectAsync, payload.dbParam);
    if (response && response.data) {
      yield put(exportProjectSuccess(true, ""));
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const fileName =
        "Projects-" + moment().format("YYYY-MMM-DD-HH-mm") + ".xlsx";
      saveAs(blob, fileName);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(exportProjectFail(response.data.message));
    }
  } catch (error) {
    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(exportProjectFail(errorMessage));
    }
  }
}

export function* watchGetProjectByInvestor() {
  yield takeEvery(GET_PROJECT_BY_INVESTOR, getProjectByInvestorAc);
}

const getProjectByInvestorAsync = async (id, params) => {
  return ProjectService.getProjectsByInvestor(id, params);
};

function* getProjectByInvestorAc({ payload }) {
  try {
    const response = yield call(
      getProjectByInvestorAsync,
      payload.investorId,
      payload.params
    );
    if (response.data.success) {
      yield put(getProjectByInvestorSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getProjectByInvestorError(response.data.message));
    }
  } catch (error) {
    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getProjectByInvestorError(errorMessage));
    }
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAllProject),
    fork(watchGetProjectList),
    fork(watchAddProject),
    fork(watchGetProject),
    fork(watchEditProject),
    fork(watchDeleteProject),
    fork(watchDeleteMultipleProject),
    fork(watchAddProperty),
    fork(watchGetProperty),
    fork(watchEditProperty),
    fork(watchDeleteProperty),
    fork(watchExportProject),
    fork(watchGetProjectByInvestor),
  ]);
}
