import Req from "../interceptors/token-interceptor";

const NotificationService = (function () {
  function _getRecentNotifications(id) {
    return Req.get(`/api/notifications?defaultRoleId=${id}&pageSize=5`);
  }
  function _markAsRead(id) {
    return Req.get(`api/notifications/read/${id}`);
  }

  return {
    getRecentNotifications: _getRecentNotifications,
    markAsRead: _markAsRead,
  };
})();
export default NotificationService;
