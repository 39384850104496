import React from "react";

const IconProject = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 13.969 13.687">
      <defs>
        <clipPath id="clip-path">
          <path
            id="Path_9252"
            dataname="Path 9252"
            d="M621.756-16.908l-7.589,4.2-6.38-3.469,1.595-3.152,5.006,2.146,5.005-2.146Z"
            transform="translate(-607.787 19.331)"
            fill="none"
            stroke="#000"
            strokeWidth="1"
          />
        </clipPath>
        <clipPath id="clip-path-2">
          <path
            id="Path_9252-2"
            dataname="Path 9252"
            d="M621.756-20.147l-7.589,4.2-6.38-3.469,1.595-3.152,5.006,2.146,5.005-2.146Z"
            transform="translate(-607.787 22.57)"
            fill="none"
            stroke="#000"
            strokeWidth="1"
          />
        </clipPath>
      </defs>
      <g
        id="Group_2005"
        dataname="Group 2005"
        transform="translate(-607.787 26.046)"
      >
        <path
          id="Path_9252-3"
          dataname="Path 9252"
          d="M614.3-25.671,609.3-23.525,614.3-21.38l5.005-2.146Z"
          transform="translate(0.084 0)"
          fill="none"
          stroke="#000"
          strokeLinejoin="round"
          strokeWidth="0.75"
        />
        <g
          id="Group_2002"
          dataname="Group 2002"
          transform="translate(607.787 -18.98)"
        >
          <g id="Group_2001" dataname="Group 2001" clipPath="url(#clip-path)">
            <path
              id="Path_9252-4"
              dataname="Path 9252"
              d="M611.954-18.061,609.3-16.922l5.006,2.146,5.005-2.146L614.3-19.068l-2.349,1.007"
              transform="translate(-607.703 19.346)"
              fill="none"
              stroke="#000"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
          </g>
        </g>
        <g
          id="Group_2004"
          dataname="Group 2004"
          transform="translate(607.787 -22.398)"
        >
          <g
            id="Group_2003"
            dataname="Group 2003"
            clipPath="url(#clip-path-2)"
          >
            <path
              id="Path_9252-5"
              dataname="Path 9252"
              d="M611.954-21.3,609.3-20.161l5.006,2.146,5.005-2.146L614.3-22.307,611.954-21.3"
              transform="translate(-607.703 22.585)"
              fill="none"
              stroke="#000"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconProject;
