export const GET_ALL_PROJECT = 'GET_ALL_PROJECT'
export const GET_ALL_PROJECT_SUCCESS = 'GET_ALL_PROJECT_SUCCESS'
export const GET_ALL_PROJECT_ERROR = 'GET_ALL_PROJECT_ERROR'
export const GET_PROJECT_LIST = 'GET_PROJECT_LIST'
export const GET_PROJECT_LIST_SUCCESS = 'GET_PROJECT_LIST_SUCCESS'
export const GET_PROJECT_LIST_ERROR = 'GET_PROJECT_LIST_ERROR'
export const ADD_PROJECT = 'ADD_PROJECT'
export const ADD_PROJECT_SUCCESS = 'ADD_PROJECT_SUCCESS'
export const ADD_PROJECT_ERROR = 'ADD_PROJECT_ERROR'
export const GET_PROJECT = 'GET_PROJECT'
export const GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS'
export const GET_PROJECT_ERROR = 'GET_PROJECT_ERROR'
export const EDIT_PROJECT = 'EDIT_PROJECT'
export const EDIT_PROJECT_SUCCESS = 'EDIT_PROJECT_SUCCESS'
export const EDIT_PROJECT_ERROR = 'EDIT_PROJECT_ERROR'
export const DELETE_PROJECT = 'DELETE_PROJECT'
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS'
export const DELETE_PROJECT_ERROR = 'DELETE_PROJECT_ERROR'
export const DELETE_MULTIPLE_PROJECT = 'DELETE_MULTIPLE_PROJECT'
export const DELETE_MULTIPLE_PROJECT_SUCCESS = 'DELETE_MULTIPLE_PROJECT_SUCCESS'
export const DELETE_MULTIPLE_PROJECT_ERROR = 'DELETE_MULTIPLE_PROJECT_ERROR'
export const ADD_PROPERTY = 'ADD_PROPERTY'
export const ADD_PROPERTY_SUCCESS = 'ADD_PROPERTY_SUCCESS'
export const ADD_PROPERTY_ERROR = 'ADD_PROPERTY_ERROR'
export const GET_PROPERTY = 'GET_PROPERTY'
export const GET_PROPERTY_SUCCESS = 'GET_PROPERTY_SUCCESS'
export const GET_PROPERTY_ERROR = 'GET_PROPERTY_ERROR'
export const EDIT_PROPERTY = 'EDIT_PROPERTY'
export const EDIT_PROPERTY_SUCCESS = 'EDIT_PROPERTY_SUCCESS'
export const EDIT_PROPERTY_ERROR = 'EDIT_PROPERTY_ERROR'
export const DELETE_PROPERTY = 'DELETE_PROPERTY'
export const DELETE_PROPERTY_SUCCESS = 'DELETE_PROPERTY_SUCCESS'
export const DELETE_PROPERTY_ERROR = 'DELETE_PROPERTY_ERROR'
export const RESET_PROPERTY = 'RESET_PROPERTY'
export const RESET_PROJECT = 'RESET_PROJECT'
export const SET_COLUMN_FILTER_PAIDIN = 'SET_COLUMN_FILTER_PAIDIN'
export const SET_COLUMN_FILTER_PAIDOUT = 'SET_COLUMN_FILTER_PAIDOUT'
export const EXPORT_PROJECT = 'EXPORT_PROJECT'
export const EXPORT_PROJECT_SUCCESS = 'EXPORT_PROJECT_SUCCESS'
export const EXPORT_PROJECT_FAIL = 'EXPORT_PROJECT_FAIL'

export const GET_PROJECT_BY_INVESTOR = 'GET_PROJECT_BY_INVESTOR'
export const GET_PROJECT_BY_INVESTOR_SUCCESS = 'GET_PROJECT_BY_INVESTOR_SUCCESS'
export const GET_PROJECT_BY_INVESTOR_ERROR = 'GET_PROJECT_BY_INVESTOR_ERROR'

export const SET_TAB_STATE = 'SET_TAB_STATE'
export const setTabState = (value) => ({
  type: SET_TAB_STATE,
  payload: value,
})

export const getAllProject = (params) => ({
  type: GET_ALL_PROJECT,
  payload: { params },
})

export const getAllProjectSuccess = (projects) => ({
  type: GET_ALL_PROJECT_SUCCESS,
  payload: projects,
})

export const getAllProjectError = (error) => ({
  type: GET_ALL_PROJECT_ERROR,
  payload: error,
})

export const getProjectList = (dbParam) => ({
  type: GET_PROJECT_LIST,
  payload: { dbParam },
})

export const getProjectListSuccess = (projectList) => ({
  type: GET_PROJECT_LIST_SUCCESS,
  payload: projectList,
})

export const getProjectListError = (error) => ({
  type: GET_PROJECT_LIST_ERROR,
  payload: error,
})

export const addProject = (projectData, history) => ({
  type: ADD_PROJECT,
  payload: { projectData, history },
})

export const addProjectSuccess = (success, message) => ({
  type: ADD_PROJECT_SUCCESS,
  payload: { success, message },
})

export const addProjectError = (error) => ({
  type: ADD_PROJECT_ERROR,
  payload: error,
})

export const getProject = (projectId) => ({
  type: GET_PROJECT,
  payload: { projectId },
})

export const getProjectSuccess = (projectData) => ({
  type: GET_PROJECT_SUCCESS,
  payload: projectData,
})

export const getProjectError = (error) => ({
  type: GET_PROJECT_ERROR,
  payload: error,
})

export const editProject = (projectId, projectData, history) => ({
  type: EDIT_PROJECT,
  payload: { projectId, projectData, history },
})

export const editProjectSuccess = (success, message) => ({
  type: EDIT_PROJECT_SUCCESS,
  payload: { success, message },
})

export const editProjectError = (error) => ({
  type: EDIT_PROJECT_ERROR,
  payload: error,
})

export const deleteProject = (projectId) => ({
  type: DELETE_PROJECT,
  payload: { projectId },
})

export const deleteProjectSuccess = (success, message) => ({
  type: DELETE_PROJECT_SUCCESS,
  payload: { success, message },
})

export const deleteProjectError = (error) => ({
  type: DELETE_PROJECT_ERROR,
  payload: error,
})

export const deleteMultipleProject = (projectIds) => ({
  type: DELETE_MULTIPLE_PROJECT,
  payload: { projectIds },
})

export const deleteMultipleProjectSuccess = (success, message) => ({
  type: DELETE_MULTIPLE_PROJECT_SUCCESS,
  payload: { success, message },
})

export const deleteMultipleProjectError = (error) => ({
  type: DELETE_MULTIPLE_PROJECT_ERROR,
  payload: error,
})

export const addProperty = (propertyData) => ({
  type: ADD_PROPERTY,
  payload: { propertyData },
})

export const addPropertySuccess = (success, message) => ({
  type: ADD_PROPERTY_SUCCESS,
  payload: { success, message },
})

export const addPropertyError = (error) => ({
  type: ADD_PROPERTY_ERROR,
  payload: error,
})

export const getProperty = (propertyId) => ({
  type: GET_PROPERTY,
  payload: { propertyId },
})

export const getPropertySuccess = (propertyData) => ({
  type: GET_PROPERTY_SUCCESS,
  payload: propertyData,
})

export const getPropertyError = (error) => ({
  type: GET_PROPERTY_ERROR,
  payload: error,
})

export const editProperty = (propertyId, propertyData) => ({
  type: EDIT_PROPERTY,
  payload: { propertyId, propertyData },
})

export const editPropertySuccess = (success, message) => ({
  type: EDIT_PROPERTY_SUCCESS,
  payload: { success, message },
})

export const editPropertyError = (error) => ({
  type: EDIT_PROPERTY_ERROR,
  payload: error,
})

export const deleteProperty = (propertyId, projectId) => ({
  type: DELETE_PROPERTY,
  payload: { propertyId, projectId },
})

export const deletePropertySuccess = (success, message) => ({
  type: DELETE_PROPERTY_SUCCESS,
  payload: { success, message },
})

export const deletePropertyError = (error) => ({
  type: DELETE_PROPERTY_ERROR,
  payload: error,
})

export const resetProperty = () => ({
  type: RESET_PROPERTY,
  payload: {},
})

export const resetProject = () => ({
  type: RESET_PROJECT,
  payload: {},
})

export const setColumnFilterPaidIn = (value) => ({
  type: SET_COLUMN_FILTER_PAIDIN,
  payload: value,
})
export const setColumnFilterPaidOut = (value) => ({
  type: SET_COLUMN_FILTER_PAIDOUT,
  payload: value,
})

export const exportProject = (dbParam) => ({
  type: EXPORT_PROJECT,
  payload: { dbParam },
})

export const exportProjectSuccess = (success, message) => ({
  type: EXPORT_PROJECT_SUCCESS,
  payload: { success, message },
})
export const exportProjectFail = (error) => ({
  type: EXPORT_PROJECT_FAIL,
  payload: error,
})

export const getProjectByInvestor = (investorId, params) => ({
  type: GET_PROJECT_BY_INVESTOR,
  payload: { investorId, params },
})

export const getProjectByInvestorSuccess = (projects) => ({
  type: GET_PROJECT_BY_INVESTOR_SUCCESS,
  payload: projects,
})

export const getProjectByInvestorError = (error) => ({
  type: GET_PROJECT_BY_INVESTOR_ERROR,
  payload: error,
})
