import React, { useState } from "react";
import { useField } from "formik";
import { IconEye, IconEyeOff } from "../svg";

const InputPasswordField = ({ label, classes, ...props }) => {
  const [field, meta] = useField(props);
  const [type, setType] = useState("password");

  const pwdToggle = () => {
  
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  return (
    <div
      className={`field-group 
      ${classes?.root || ""}
      ${meta.touched && meta.error ? "field-group-error" : ""}`}
    >
      {label && (
        <label htmlFor="" className="field-label">
          {label ? label : null}
        </label>
      )}

      <div className="field-wrap">
        <input
          type={type}
          className={`field-password 
       ${classes?.input || ""}`}
          {...field}
          {...props}
        />
        <button
          type="button"
          onClick={pwdToggle}
          className="absolute right-5 top-3.5 focus:outline-none z-1"
        >
          {type === "password" ? <IconEyeOff /> : <IconEye />}
        </button>
      </div>

      {meta.touched && meta.error ? (
        <span className="block mt-2 text-xs text-right text-red-700">
          {meta.touched && meta.error ? meta.error : null}
        </span>
      ) : null}
    </div>
  );
};

export default InputPasswordField;
