import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import en from "../../../assets/images/en.svg";
import no from "../../../assets/images/no.svg";
import { IconChevronDown } from "../../../components/svg";

export default function LanguageDropdown() {
  const { i18n } = useTranslation();

  const setLocale = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <Menu as="div" className="relative inline-block">
        <Menu.Button className="inline-flex py-2 px-2 border border-solid border-primary-200 rounded-md text-primary-500 text-sm focus:outline-none">
          <img
            src={i18n.language === "en" ? en : no}
            alt=""
            className="img-fluid"
          />
          <span className="w-4 h-3.5 flex items-center justify-center ml-3 p-0.5 rounded-sm bg-primary-200">
            <IconChevronDown />
          </span>
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            as="ul"
            className="min-w-[12rem] absolute left-2/4 mt-2 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 origin-top-right bg-white -translate-x-1/2 divide-y divide-gray-100 focus:outline-none"
          >
            <Menu.Item
              as="li"
              className={`group p-1 flex rounded-md items-center w-full text-sm`}
            >
              <button
                type="button"
                className={`group flex rounded-md items-center w-full px-3 py-2 text-sm text-gray-900 hover:bg-primary-500 hover:text-white focus:outline-none svg-color-grey hover:svg-color-white`}
                onClick={() => setLocale("en")}
              >
                <img src={en} alt="" className="img-fluid mr-2" />
                English
              </button>
            </Menu.Item>

            <Menu.Item
              as="li"
              className={`group p-1 flex rounded-md items-center w-full text-sm`}
            >
              <button
                type="button"
                className={`group flex rounded-md items-center w-full px-3 py-2 text-sm text-gray-900 hover:bg-primary-500 hover:text-white focus:outline-none svg-color-grey hover:svg-color-white`}
                onClick={() => setLocale("no")}
              >
                <img src={no} alt="" className="img-fluid mr-2" />
                Norwegian
              </button>
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
}
