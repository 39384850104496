import React from "react";

const IconMailReply = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 15.071 12.449"
    >
      <g
        id="Group_3562"
        dataname="Group 3562"
        transform="translate(-1019.292 -1356.625)"
      >
        <path
          id="Path_10581"
          dataname="Path 10581"
          d="M311.767,400.912a.2.2,0,0,1-.174-.094,3.262,3.262,0,0,0-2.782-1.541h-.116V400.3a.2.2,0,0,1-.349.146L306.3,398.4a.205.205,0,0,1,0-.29h0l2.048-2.048a.2.2,0,0,1,.349.145v1.029a3.486,3.486,0,0,1,3.277,3.475.2.2,0,0,1-.149.2A.2.2,0,0,1,311.767,400.912Z"
          transform="translate(721.417 967.788)"
          fill="none"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.75"
        />
        <path
          id="Path_10582"
          dataname="Path 10582"
          d="M301.72,396.018h-4.882a1.3,1.3,0,0,1-1.3-1.3v-6.509a1.3,1.3,0,0,1,1.3-1.3h11.718a1.3,1.3,0,0,1,1.3,1.3v6.184"
          transform="translate(724.131 970.094)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.75"
        />
        <path
          id="Path_10583"
          dataname="Path 10583"
          d="M295.591,388.934l6.712,3.325a.917.917,0,0,0,.811,0l6.666-3.3"
          transform="translate(724.117 969.58)"
          fill="none"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="0.75"
        />
      </g>
    </svg>
  );
};

export default IconMailReply;
