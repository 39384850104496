import {
  SET_PAIDOUT,
  GET_ALL_PAIDOUT,
  GET_ALL_PAIDOUT_SUCCESS,
  GET_ALL_PAIDOUT_ERROR,
  ADD_PAIDOUT,
  ADD_PAIDOUT_SUCCESS,
  ADD_PAIDOUT_ERROR,
  GET_PAIDOUT,
  GET_PAIDOUT_SUCCESS,
  GET_PAIDOUT_ERROR,
  EDIT_PAIDOUT,
  EDIT_PAIDOUT_SUCCESS,
  EDIT_PAIDOUT_ERROR,
  DELETE_PAIDOUT,
  DELETE_PAIDOUT_SUCCESS,
  DELETE_PAIDOUT_ERROR,
  EXPORT_PAIDOUT,
  EXPORT_PAIDOUT_SUCCESS,
  EXPORT_PAIDOUT_ERROR,
  RESET_PAIDOUT,
  GET_ALL_PAIDOUTINVESTOR,
  GET_ALL_PAIDOUTINVESTOR_FAIL,
  GET_ALL_PAIDOUTINVESTOR_SUCCESS,
} from "../actions";

const INIT_STATE = {
  rowIndex: null,
  columnId: null,
  value: null,
  param: null,
  paidOutList: null,
  paidOutData: null,
  paidOutId: null,
  projectId: null,
  success: false,
  success1: false,
  message: null,
  loading: false,
  loading1: false,
  loading2: false,
  loading3: false,
  error: null,
  paidOutInvestorList: [],
  totalPaidOutAmount: null,
};

function _setPaidOutList(paidOutList, rowIndex, columnId, value) {
  paidOutList?.data?.forEach(function (item, index) {
    if (index === rowIndex) this[index][columnId] = value;
  }, paidOutList?.data);
  return paidOutList;
}

const paidOutReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_PAIDOUT:
      return {
        ...state,
        paidOutList: _setPaidOutList(
          state.paidOutList,
          action.payload.rowIndex,
          action.payload.columnId,
          action.payload.value
        ),
      };
    case GET_ALL_PAIDOUT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ALL_PAIDOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        paidOutList: action.payload.paidOutList,
        success: action.payload.success,
        totalPaidOutAmount: action.payload.totalPaidOutAmount,
        error: null,
      };
    case GET_ALL_PAIDOUT_ERROR:
      return {
        ...state,
        loading: false,
        paidOutList: null,
        error: action.payload,
      };
    case ADD_PAIDOUT:
      return { ...state, loading1: true, error: null };
    case ADD_PAIDOUT_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case ADD_PAIDOUT_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case GET_PAIDOUT:
      return { ...state, error: null };
    case GET_PAIDOUT_SUCCESS:
      return {
        ...state,
        paidOutData: action.payload,
        error: null,
      };
    case GET_PAIDOUT_ERROR:
      return {
        ...state,
        paidOutData: null,
        error: action.payload,
      };
    case EDIT_PAIDOUT:
      return { ...state, loading1: true, error: null };
    case EDIT_PAIDOUT_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case EDIT_PAIDOUT_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DELETE_PAIDOUT:
      return { ...state, loading2: true, error: null };
    case DELETE_PAIDOUT_SUCCESS:
      return {
        ...state,
        loading2: false,
        success1: action.payload.success1,
        message: action.payload.message,
        error: null,
      };
    case DELETE_PAIDOUT_ERROR:
      return {
        ...state,
        loading2: false,
        success1: false,
        message: null,
        error: action.payload,
      };
    case EXPORT_PAIDOUT:
      return { ...state, loading3: true, error: null };
    case EXPORT_PAIDOUT_SUCCESS:
      return {
        ...state,
        loading3: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case EXPORT_PAIDOUT_ERROR:
      return {
        ...state,
        loading3: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case RESET_PAIDOUT:
      return {
        ...state,
        loading: false,
        loading1: false,
        loading2: false,
        loading3: false,
        success: false,
        success1: false,
        message: null,
        error: null,
      };
    case GET_ALL_PAIDOUTINVESTOR:
      return {
        ...state,
      };

    case GET_ALL_PAIDOUTINVESTOR_SUCCESS:
      return {
        ...state,
        success: action.payload.success,
        paidOutInvestorList: action.payload.data,
      };

    case GET_ALL_PAIDOUTINVESTOR_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return { ...state };
  }
};
export default paidOutReducer;
