import { all } from "redux-saga/effects";
import authSagas from "./auth/saga";
import mediaSagas from "./media/saga";
import sharedSagas from "./shared/saga";
import userSagas from "./user/saga";
import roleSagas from "./role/saga";
import configChoiceSagas from "./config-choice/saga";
import configChoiceCategorySagas from "./config-choice-category/saga";
import projectSagas from "./project/saga";
import investorSagas from "./investor/saga";
import paidInOutSagas from "./paid-in-out/saga";
import paidInSagas from "./paid-in/saga";
import paidOutSagas from "./paid-out/saga";
import normalDocumentSagas from "./document/normal/saga";
import boardDocumentSagas from "./document/board/saga";
import messageSagas from "./message/saga";
import notificationSagas from "./notification/saga";
import dashboardSagas from "./dashboard/saga";
import paidInOutFundSagas from "./paid-in-out-fund/saga";
import paidInFund from "./paid-in-fund/saga";
import paidOutFund from "./paid-out-fund/saga";
export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    mediaSagas(),
    sharedSagas(),
    userSagas(),
    roleSagas(),
    configChoiceSagas(),
    configChoiceCategorySagas(),
    projectSagas(),
    investorSagas(),
    paidInOutSagas(),
    paidInSagas(),
    paidOutSagas(),
    normalDocumentSagas(),
    boardDocumentSagas(),
    messageSagas(),
    notificationSagas(),
    dashboardSagas(),
    paidInOutFundSagas(),
    paidInFund(),
    paidOutFund()
  ]);
}
