import React from "react";

const IconDashboard = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 15.841 16.039">
      <g dataname="Group 895" transform="translate(993.105 -359.996)">
        <g
          id="Group_891"
          dataname="Group 891"
          transform="translate(-993.105 359.996)"
        >
          <path
            id="Path_3548"
            dataname="Path 3548"
            d="M-987.142,367.212h-4.71a1.254,1.254,0,0,1-1.252-1.253v-4.71A1.254,1.254,0,0,1-991.853,360h4.71a1.254,1.254,0,0,1,1.253,1.253v4.71A1.254,1.254,0,0,1-987.142,367.212Zm-4.71-6.384a.421.421,0,0,0-.421.421v4.71a.421.421,0,0,0,.421.421h4.71a.421.421,0,0,0,.421-.421v-4.71a.421.421,0,0,0-.421-.421Z"
            transform="translate(993.105 -359.996)"
          />
        </g>
        <g
          id="Group_892"
          dataname="Group 892"
          transform="translate(-984.48 359.996)"
        >
          <path
            id="Path_3549"
            dataname="Path 3549"
            d="M-973.665,367.212h-4.71a1.254,1.254,0,0,1-1.253-1.253v-4.71A1.254,1.254,0,0,1-978.375,360h4.71a1.254,1.254,0,0,1,1.252,1.253v4.71A1.254,1.254,0,0,1-973.665,367.212Zm-4.71-6.384a.421.421,0,0,0-.421.421v4.71a.421.421,0,0,0,.421.421h4.71a.421.421,0,0,0,.421-.421v-4.71a.421.421,0,0,0-.421-.421Z"
            transform="translate(979.628 -359.996)"
          />
        </g>
        <g
          id="Group_893"
          dataname="Group 893"
          transform="translate(-993.105 368.819)"
        >
          <path
            id="Path_3550"
            dataname="Path 3550"
            d="M-987.142,381h-4.71a1.254,1.254,0,0,1-1.252-1.253v-4.71a1.254,1.254,0,0,1,1.252-1.253h4.71a1.254,1.254,0,0,1,1.253,1.253v4.71A1.254,1.254,0,0,1-987.142,381Zm-4.71-6.384a.422.422,0,0,0-.421.421v4.71a.421.421,0,0,0,.421.42h4.71a.421.421,0,0,0,.421-.42v-4.71a.421.421,0,0,0-.421-.421Z"
            transform="translate(993.105 -373.782)"
          />
        </g>
        <g
          id="Group_894"
          dataname="Group 894"
          transform="translate(-984.48 368.819)"
        >
          <path
            id="Path_3551"
            dataname="Path 3551"
            d="M-973.665,381h-4.71a1.254,1.254,0,0,1-1.253-1.253v-4.71a1.254,1.254,0,0,1,1.253-1.253h4.71a1.254,1.254,0,0,1,1.252,1.253v4.71A1.254,1.254,0,0,1-973.665,381Zm-4.71-6.384a.421.421,0,0,0-.421.421v4.71a.421.421,0,0,0,.421.42h4.71a.421.421,0,0,0,.421-.42v-4.71a.421.421,0,0,0-.421-.421Z"
            transform="translate(979.628 -373.782)"
          />
        </g>
      </g>
    </svg>
  );
};

export default IconDashboard;
