export const GET_ALL_INVESTOR = "GET_ALL_INVESTOR";
export const GET_ALL_INVESTOR_SUCCESS = "GET_ALL_INVESTOR_SUCCESS";
export const GET_ALL_INVESTOR_ERROR = "GET_ALL_INVESTOR_ERROR";
export const GET_INVESTOR_LIST = "GET_INVESTOR_LIST";
export const GET_INVESTOR_LIST_SUCCESS = "GET_INVESTOR_LIST_SUCCESS";
export const GET_INVESTOR_LIST_ERROR = "GET_INVESTOR_LIST_ERROR";
export const ADD_INVESTOR = "ADD_INVESTOR";
export const ADD_INVESTOR_SUCCESS = "ADD_INVESTOR_SUCCESS";
export const ADD_INVESTOR_ERROR = "ADD_INVESTOR_ERROR";
export const GET_INVESTOR = "GET_INVESTOR";
export const GET_INVESTOR_SUCCESS = "GET_INVESTOR_SUCCESS";
export const GET_INVESTOR_ERROR = "GET_INVESTOR_ERROR";
export const EDIT_INVESTOR = "EDIT_INVESTOR";
export const EDIT_INVESTOR_SUCCESS = "EDIT_INVESTOR_SUCCESS";
export const EDIT_INVESTOR_ERROR = "EDIT_INVESTOR_ERROR";
export const DELETE_INVESTOR = "DELETE_INVESTOR";
export const DELETE_INVESTOR_SUCCESS = "DELETE_INVESTOR_SUCCESS";
export const DELETE_INVESTOR_ERROR = "DELETE_INVESTOR_ERROR";
export const DELETE_MULTIPLE_INVESTOR = "DELETE_MULTIPLE_INVESTOR";
export const DELETE_MULTIPLE_INVESTOR_SUCCESS =
  "DELETE_MULTIPLE_INVESTOR_SUCCESS";
export const DELETE_MULTIPLE_INVESTOR_ERROR = "DELETE_MULTIPLE_INVESTOR_ERROR";
export const RESET_INVESTOR = "RESET_INVESTOR";
export const EXPORT_INVESTOR = "EXPORT_INVESTOR";
export const EXPORT_INVESTOR_SUCCESS = "EXPORT_INVESTOR_SUCCESS";
export const EXPORT_INVESTOR_ERROR = "EXPORT_INVESTOR_ERROR";
export const GET_ALL_INVESTOR_COMMENT = "GET_ALL_INVESTOR_COMMENT";
export const GET_ALL_INVESTOR_COMMENT_SUCCESS =
  "GET_ALL_INVESTOR_COMMENT_SUCCESS";
export const GET_ALL_INVESTOR_COMMENT_ERROR = "GET_ALL_INVESTOR_COMMENT_ERROR";
export const ADD_INVESTOR_COMMENT = "ADD_INVESTOR_COMMENT";
export const ADD_INVESTOR_COMMENT_SUCCESS = "ADD_INVESTOR_COMMENT_SUCCESS";
export const ADD_INVESTOR_COMMENT_ERROR = "ADD_INVESTOR_COMMENT_ERROR";
export const EDIT_INVESTOR_COMMENT = "EDIT_INVESTOR_COMMENT";
export const EDIT_INVESTOR_COMMENT_SUCCESS = "EDIT_INVESTOR_COMMENT_SUCCESS";
export const EDIT_INVESTOR_COMMENT_ERROR = "EDIT_INVESTOR_COMMENT_ERROR";
export const DELETE_INVESTOR_COMMENT = "DELETE_INVESTOR_COMMENT";
export const DELETE_INVESTOR_COMMENT_SUCCESS =
  "DELETE_INVESTOR_COMMENT_SUCCESS";
export const DELETE_INVESTOR_COMMENT_ERROR = "DELETE_INVESTOR_COMMENT_ERROR";
export const RESET_INVESTOR_LIST = "RESET_INVESTOR_LIST";
export const exportInvestor = (dbParam) => ({
  type: EXPORT_INVESTOR,
  payload: { dbParam },
});
export const exportInvestorSuccess = (success, message) => ({
  type: EXPORT_INVESTOR_SUCCESS,
  payload: { success, message },
});

export const exportInvestorError = (error) => ({
  type: EXPORT_INVESTOR_ERROR,
  payload: error,
});

export const getAllInvestor = () => ({
  type: GET_ALL_INVESTOR,
  payload: {},
});
// export const getAllInvestor = (roleId) => ({
//   type: GET_ALL_INVESTOR,
//   payload: { roleId },
// });

export const getAllInvestorSuccess = (investors) => ({
  type: GET_ALL_INVESTOR_SUCCESS,
  payload: investors,
});

export const getAllInvestorError = (error) => ({
  type: GET_ALL_INVESTOR_ERROR,
  payload: error,
});

export const getInvestorList = (dbParam) => ({
  type: GET_INVESTOR_LIST,
  payload: { dbParam },
});

export const getInvestorListSuccess = (investorList, metadata) => ({
  type: GET_INVESTOR_LIST_SUCCESS,
  payload: { investorList, metadata },
});

export const getInvestorListError = (error) => ({
  type: GET_INVESTOR_LIST_ERROR,
  payload: error,
});

export const addInvestor = (investorData, history) => ({
  type: ADD_INVESTOR,
  payload: { investorData, history },
});

export const addInvestorSuccess = (success, message) => ({
  type: ADD_INVESTOR_SUCCESS,
  payload: { success, message },
});

export const addInvestorError = (error) => ({
  type: ADD_INVESTOR_ERROR,
  payload: error,
});

export const getInvestor = (investorId) => ({
  type: GET_INVESTOR,
  payload: { investorId },
});

export const getInvestorSuccess = (investorData) => ({
  type: GET_INVESTOR_SUCCESS,
  payload: investorData,
});

export const getInvestorError = (error) => ({
  type: GET_INVESTOR_ERROR,
  payload: error,
});

export const editInvestor = (investorId, investorData, history) => ({
  type: EDIT_INVESTOR,
  payload: { investorId, investorData, history },
});

export const editInvestorSuccess = (success, message) => ({
  type: EDIT_INVESTOR_SUCCESS,
  payload: { success, message },
});

export const editInvestorError = (error) => ({
  type: EDIT_INVESTOR_ERROR,
  payload: error,
});

export const deleteInvestor = (investorId) => ({
  type: DELETE_INVESTOR,
  payload: { investorId },
});

export const deleteInvestorSuccess = (success, message) => ({
  type: DELETE_INVESTOR_SUCCESS,
  payload: { success, message },
});

export const deleteInvestorError = (error) => ({
  type: DELETE_INVESTOR_ERROR,
  payload: error,
});

export const deleteMultipleInvestor = (investorIds) => ({
  type: DELETE_MULTIPLE_INVESTOR,
  payload: { investorIds },
});

export const deleteMultipleInvestorSuccess = (success, message) => ({
  type: DELETE_MULTIPLE_INVESTOR_SUCCESS,
  payload: { success, message },
});

export const deleteMultipleInvestorError = (error) => ({
  type: DELETE_MULTIPLE_INVESTOR_ERROR,
  payload: error,
});

export const resetInvestor = () => ({
  type: RESET_INVESTOR,
  payload: {},
});

export const resetInvestorList = () => ({
  type: RESET_INVESTOR_LIST,
  payload: {},
});

export const addInvestorComment = (commentData) => ({
  type: ADD_INVESTOR_COMMENT,
  payload: { commentData },
});

export const addInvestorCommentSuccess = (success, message) => ({
  type: ADD_INVESTOR_COMMENT_SUCCESS,
  payload: { success, message },
});

export const addInvestorCommentError = (error) => ({
  type: ADD_INVESTOR_COMMENT_ERROR,
  payload: error,
});

export const editInvestorComment = (commentId, commentData) => ({
  type: EDIT_INVESTOR_COMMENT,
  payload: { commentId, commentData },
});

export const editInvestorCommentSuccess = (success, message) => ({
  type: EDIT_INVESTOR_COMMENT_SUCCESS,
  payload: { success, message },
});

export const editInvestorCommentError = (error) => ({
  type: EDIT_INVESTOR_COMMENT_ERROR,
  payload: error,
});

export const deleteInvestorComment = (comment) => ({
  type: DELETE_INVESTOR_COMMENT,
  payload: { comment },
});

export const deleteInvestorCommentSuccess = (success, message) => ({
  type: DELETE_INVESTOR_COMMENT_SUCCESS,
  payload: { success, message },
});

export const deleteInvestorCommentError = (error) => ({
  type: DELETE_INVESTOR_COMMENT_ERROR,
  payload: error,
});

export const getAllInvestorComment = (investorId) => ({
  type: GET_ALL_INVESTOR_COMMENT,
  payload: { investorId },
});

export const getAllInvestorCommentSuccess = (comments) => ({
  type: GET_ALL_INVESTOR_COMMENT_SUCCESS,
  payload: comments,
});

export const getAllInvestorCommentError = (error) => ({
  type: GET_ALL_INVESTOR_COMMENT_ERROR,
  payload: error,
});
