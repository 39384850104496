import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import PaidOutService from "../../services/paid-out-service";
import {
  GET_ALL_PAIDOUT_FUND,
  ADD_PAIDOUT_FUND,
  GET_PAIDOUT,
  EDIT_PAIDOUT_FUND,
  DELETE_PAIDOUT_FUND,
  EXPORT_PAIDOUT_FUND,
  GET_ALL_PAIDOUTINVESTOR_FUND,
  UPDATE_PAID_OUT,
} from "../actions";

import {
  getAllPaidOutFund,
  getAllPaidOutFundSuccess,
  getAllPaidOutFundError,
  addPaidOutFundSuccess,
  addPaidOutFundError,
  getPaidOutSuccess,
  getPaidOutError,
  editPaidOutFundSuccess,
  editPaidOutFundError,
  deletePaidOutFundSuccess,
  deletePaidOutFundError,
  exportPaidOutFundSuccess,
  exportPaidOutFundError,
  paidOutInvestorListFundSuccess,
  paidOutInvestorListFundFail,
  updatePaidOutSuccess,
  updatePaidOutError,
} from "./action";
import { toast } from "react-toastify";
import { handleMyErrorMessage } from "../../helpers/util";
import ToastElement from "../../components/toast";
import saveAs from "file-saver";
import moment from "moment";

export function* watchGetAllPaidOutFund() {
  yield takeEvery(GET_ALL_PAIDOUT_FUND, getAllPaidOutFundAc);
}

const getAllPaidOutAsync = async (param) => {
  return PaidOutService.getAllPaidOutFund(param);
};

function* getAllPaidOutFundAc({ payload }) {
  try {
    const response = yield call(getAllPaidOutAsync, payload.param);
    if (response.data.success) {
      yield put(
        getAllPaidOutFundSuccess(
          response.data.success,
          response.data.data,
          response.data?.meta?.totalPaidOutAmount
        )
      );
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getAllPaidOutFundError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getAllPaidOutFundError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getAllPaidOutFundError(errorMessage));
    }
  }
}

export function* watchAddPaidOutFund() {
  yield takeEvery(ADD_PAIDOUT_FUND, addPaidOutFund);
}

const addPaidOutAsync = async (data) => {
  return PaidOutService.addPaidOutFund(data.paidOutData);
};

function* addPaidOutFund({ payload }) {
  try {
    const response = yield call(addPaidOutAsync, payload);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        addPaidOutFundSuccess(response.data.success, response.data.message)
      );
      // Fetch updated list
      yield put(
        getAllPaidOutFund({
          projectId: payload.projectId,
          eachPropertyFund: payload.eachPropertyFund,
        })
      );
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(addPaidOutFundError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type="error"
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   { containerId: "default" }
    // );
    // yield put(addPaidOutFundError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(addPaidOutFundError(errorMessage));
    }
  }
}

export function* watchGetPaidOut() {
  yield takeEvery(GET_PAIDOUT, getPaidOutAc);
}

const getPaidOutAsync = async (id) => {
  return PaidOutService.getPaidOut(id);
};

function* getPaidOutAc({ payload }) {
  try {
    const response = yield call(getPaidOutAsync, payload.paidOutId);
    if (response.data.success) {
      yield put(getPaidOutSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getPaidOutError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getPaidOutError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getPaidOutError(errorMessage));
    }
  }
}

export function* watchEditPaidOutFund() {
  yield takeEvery(EDIT_PAIDOUT_FUND, editPaidOutFund);
}

const editPaidOutAsync = async (data, id) => {
  return PaidOutService.editPaidOutFund(data, id);
};

function* editPaidOutFund({ payload }) {
  try {
    const response = yield call(
      editPaidOutAsync,
      payload.paidOutData,
      payload.paidOutId
    );
    if (response.data.success) {
      yield put(
        editPaidOutFundSuccess(response.data.success, response.data.message)
      );
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );

      yield put(
        getAllPaidOutFund({
          projectId: payload.projectId,
        })
      );
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(editPaidOutFundError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type="error"
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   { containerId: "default" }
    // );
    // yield put(editPaidOutFundError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(editPaidOutFundError(errorMessage));
    }
  }
}

export function* watchDeletePaidOutFund() {
  yield takeEvery(DELETE_PAIDOUT_FUND, deletePaidOutFund);
}

const deletePaidOutAsync = async (id) => {
  return PaidOutService.deletePaidOutFund(id);
};

function* deletePaidOutFund({ payload }) {
  try {
    const response = yield call(deletePaidOutAsync, payload.paidOutId);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        deletePaidOutFundSuccess(response.data.success, response.data.message)
      );
      // Fetch updated list
      yield put(
        getAllPaidOutFund({
          projectId: payload.projectId,
        })
      );
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(deletePaidOutFundError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(deletePaidOutFundError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(deletePaidOutFundError(errorMessage));
    }
  }
}

export function* watchExportPaidOutFund() {
  yield takeEvery(EXPORT_PAIDOUT_FUND, exportPaidOut);
}

const exportPaidOutAsync = async (projectId) => {
  return PaidOutService.exportPaidOutFund(projectId);
};

function* exportPaidOut({ payload }) {
  try {
    const response = yield call(exportPaidOutAsync, payload.projectId);
    console.log(response);
    if (response && response.data) {
      yield put(exportPaidOutFundSuccess(true, ""));
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const fileName =
        "PaidOutFund-" + moment().format("YYYY-MMM-DD-HH-mm") + ".xlsx";
      saveAs(blob, fileName);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(exportPaidOutFundError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(exportPaidOutFundError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(exportPaidOutFundError(errorMessage));
    }
  }
}
export function* watchGetPaidOutInvestorFund() {
  yield takeEvery(GET_ALL_PAIDOUTINVESTOR_FUND, getPaidOutInvestor);
}

const getPaidOutInvestorAsync = async (projectId) => {
  return PaidOutService.paidOutInvestorList(projectId);
};

function* getPaidOutInvestor({ payload }) {
  try {
    const response = yield call(getPaidOutInvestorAsync, payload.projectId);
    if (response && response.data) {
      yield put(
        paidOutInvestorListFundSuccess(
          response?.data?.success,
          response?.data?.data
        )
      );
    }
  } catch (error) {
    // yield put(paidOutInvestorListFundFail(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      yield put(paidOutInvestorListFundFail(errorMessage));
    }
  }
}

export function* watchUpdatePaidOut() {
  yield takeEvery(UPDATE_PAID_OUT, updatePaidOutAc);
}

const updatePaidOutAsync = async (data, projectId, id) => {
  return PaidOutService.updatePaidOutFund(data, projectId, id);
};

function* updatePaidOutAc({ payload }) {
  try {
    const response = yield call(
      updatePaidOutAsync,
      payload.data,
      payload.projectId,
      payload.id
    );
    if (response.data.success) {
      yield put(
        updatePaidOutSuccess(response.data.success, response.data.message)
      );
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        getAllPaidOutFund({
          projectId: payload.projectId,
          eachPropertyFund: payload.eachPropertyFund,
        })
      );
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(updatePaidOutError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type="error"
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   { containerId: "default" }
    // );
    // yield put(updatePaidOutError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(updatePaidOutError(errorMessage));
    }
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAllPaidOutFund),
    fork(watchAddPaidOutFund),
    fork(watchGetPaidOut),
    fork(watchEditPaidOutFund),
    fork(watchDeletePaidOutFund),
    fork(watchExportPaidOutFund),
    fork(watchGetPaidOutInvestorFund),
    fork(watchUpdatePaidOut),
  ]);
}
