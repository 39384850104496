import React from "react";

const IconCamera = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 15.188 15.188">
      <g id="Group_1542" dataname="Group 1542" transform="translate(-159 -140)">
        <path
          id="Path_3915"
          dataname="Path 3915"
          d="M12.023,2.531H11.7L10.32.739A1.913,1.913,0,0,0,8.816,0H6.371a1.913,1.913,0,0,0-1.5.739L3.486,2.531H3.164A3.168,3.168,0,0,0,0,5.7v6.328a3.168,3.168,0,0,0,3.164,3.164h8.859a3.168,3.168,0,0,0,3.164-3.164V5.7a3.168,3.168,0,0,0-3.164-3.164ZM5.87,1.512a.637.637,0,0,1,.5-.247H8.816a.638.638,0,0,1,.5.247L10.1,2.531H5.084Zm8.052,10.511a1.9,1.9,0,0,1-1.9,1.9H3.164a1.9,1.9,0,0,1-1.9-1.9V5.7a1.9,1.9,0,0,1,1.9-1.9h8.859a1.9,1.9,0,0,1,1.9,1.9Z"
          transform="translate(159 140)"
        />
        <path
          id="Path_3916"
          dataname="Path 3916"
          d="M9.8,8a3.8,3.8,0,1,0,3.8,3.8A3.8,3.8,0,0,0,9.8,8Zm0,6.328A2.531,2.531,0,1,1,12.328,11.8,2.531,2.531,0,0,1,9.8,14.328Z"
          transform="translate(156.797 137.063)"
        />
      </g>
    </svg>
  );
};

export default IconCamera;
