import React, { useState, useEffect } from "react";
import { useField } from "formik";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getCountryList } from "src/reduxs/actions";
import { useFormikContext } from "formik";
import { GetLocationDetails } from "src/helpers/util";

const PhoneField = ({
  label,
  classes,
  icon,
  iconPosition,
  disableSelectCountry,
  setCountryId,
  setIsdCode,
  isRequired,
  loading = false,
  ...props
}) => {
  const { values, setFieldValue } = useFormikContext();
  const dispatch = useDispatch();
  const [field, meta] = useField(props);
  const [selectedOption, setSelectedOption] = useState(null);
  const { countryList } = useSelector((state) => state.shared);
  const handleChange = (val) => {
    setCountryId && setCountryId(val.value);
    setIsdCode && setIsdCode(val.code);
    setFieldValue("isdCodeId", val.value);
    setSelectedOption(val);
  };

  const setCountryIsd = async () => {
        const locationResponse = await GetLocationDetails()
        if(locationResponse && locationResponse.success && countryList){
          const country = countryList?.find(
            (c) => c.code === locationResponse.data.countryCode
          );
          if (country) {
            setCountryId && setCountryId(country.id);
            setIsdCode && setIsdCode(country.isdCode);
            setFieldValue("isdCodeId", country.id);
            setSelectedOption({ value: country.id, label: country.isdCode, code: country.isdCode, })
          }
        }
      };

  useEffect(() => {
    if (!countryList) {
      dispatch(getCountryList());
    }else{
      setCountryIsd()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryList]);

  useEffect(() => {
    if (props.selectedcountry) {
      setSelectedOption(props.selectedcountry);
      setIsdCode && setIsdCode(props.selectedcountry?.isdCode);
      setCountryId && setCountryId(props.selectedcountry?.value);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedcountry]);

  useEffect(
    () => {
      setSelectedOption(
        countryList &&
          countryList
            ?.filter((val) => val.id === values.isdCodeId)
            .map((val) => {
              return { value: val.id, label: val.isdCode };
            })?.[0]
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // [countryList,props.countryId, values.countryId]
    [countryList, values.isdCodeId]
  );

  return loading ? (
    <div className="field-group animate-pulse">
      <label className="w-28 h-6 field-label rounded bg-grey-400"></label>
      <span className="w-full h-[50px] block border-0 rounded bg-grey-400"></span>
    </div>
  ) : (
    <div
      className={`field-group 
    ${classes?.root || ""}
    ${meta.touched && meta.error ? "field-group-error" : ""} `}
    >
      {label && (
        <label htmlFor="" className="field-label">
          {label ? label : null}
          {isRequired && <strong className="text-red-700">*</strong>}
        </label>
      )}

      <div
        className={`field-wrap 
       ${iconPosition === "end" ? "icon-end" : ""} 
       ${iconPosition === "start" ? "icon-start" : ""}
       `}
      >
        <div className="absolute top-0 left-0 h-full z-[2]">
          <Select
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            className="w-20 phone-country-select"
            classNamePrefix="phone-country-select"
            value={selectedOption}
            onChange={handleChange}
            isDisabled={disableSelectCountry}
            placeholder="ISD"
            options={
              countryList &&
              countryList.map((item) => {
                return { value: item.id, label: item.name, code: item.isdCode };
              })
            }
            menuPortalTarget={document.body}
            menuPosition="absolute"
            menuPlacement="auto"
            menuShouldScrollIntoView={false}
            menuShouldBlockScroll={true}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          />
        </div>

        <input
          className={`field-phone
          ${classes?.input || ""}`}
          {...field}
          {...props}
        />

        {icon ? (
          <span
            className={`field-icon focus:outline-none ${
              iconPosition === "end" ? "right-5" : "left-5"
            }`}
          >
            {icon}
          </span>
        ) : null}
      </div>

      {meta.touched && meta.error ? (
        <span className="block mt-2 text-xs text-right text-red-700">
          {meta.touched && meta.error ? meta.error : null}
        </span>
      ) : null}
    </div>
  );
};

export default PhoneField;
