import {
  GET_DASHBOARD_STUFF_COUNT,
  GET_DASHBOARD_STUFF_COUNT_ERROR,
  GET_DASHBOARD_STUFF_COUNT_SUCCESS,
  GET_DASHBOARD_PAID_IN_PROJECT_LIST,
  GET_DASHBOARD_PAID_IN_PROJECT_LIST_ERROR,
  GET_DASHBOARD_PAID_IN_PROJECT_LIST_SUCCESS,
  GET_DASHBOARD_PAID_OUT_PROJECT_LIST,
  GET_DASHBOARD_PAID_OUT_PROJECT_LIST_ERROR,
  GET_DASHBOARD_PAID_OUT_PROJECT_LIST_SUCCESS,
  GET_TOP_PAID_IN_OUT_PROJECT_LIST,
  GET_TOP_PAID_IN_OUT_PROJECT_LIST_ERROR,
  GET_TOP_PAID_IN_OUT_PROJECT_LIST_SUCCESS,
  EXPORT_TOP_PAID_IN_OUT_PROJECT,
  EXPORT_TOP_PAID_IN_OUT_PROJECT_ERROR,
  EXPORT_TOP_PAID_IN_OUT_PROJECT_SUCCESS,
  GET_TOP_RENT_PROJECT,
  GET_TOP_RENT_PROJECT_ERROR,
  GET_TOP_RENT_PROJECT_SUCCESS,
  GET_SUM_PAID_IN_OUT_PROJECT,
  GET_SUM_PAID_IN_OUT_PROJECT_ERROR,
  GET_SUM_PAID_IN_OUT_PROJECT_SUCCESS,
  GET_LARGEST_INVESTOR_PER_PROJECT,
  GET_LARGEST_INVESTOR_PER_PROJECT_ERROR,
  GET_LARGEST_INVESTOR_PER_PROJECT_SUCCESS,
  GET_TOTAL_DEBT_PER_PROJECT,
  GET_TOTAL_DEBT_PER_PROJECT_ERROR,
  GET_TOTAL_DEBT_PER_PROJECT_SUCCESS,
  GET_SQM_PROJECT,
  GET_SQM_PROJECT_SUCCESS,
  GET_SQM_PROJECT_ERROR,
  GET_ALL_NEWS,
  GET_ALL_NEWS_SUCCESS,
  GET_ALL_NEWS_ERROR,
  GET_NEWS,
  GET_NEWS_SUCCESS,
  GET_NEWS_ERROR,
  ADD_NEWS,
  ADD_NEWS_SUCCESS,
  ADD_NEWS_ERROR,
  UPDATE_NEWS,
  UPDATE_NEWS_SUCCESS,
  UPDATE_NEWS_ERROR,
  DELETE_NEWS,
  DELETE_NEWS_SUCCESS,
  DELETE_NEWS_ERROR,
  RESET_DASHBOARD,
} from "../actions";
const INIT_STATE = {
  dashboardData: null,
  paidInProjectList: null,
  paidOutProjectList: null,
  topInOutProjectList: null,
  topRentProjectList: null,
  sumPaidInOutProject: null,
  largestInvestors: null,
  sqmProjects: null,
  debtProjects: null,
  allNews: [],
  newsData: null,
  success: false,
  loading: false,
  loading1: false,
  loading2: false,
  loading3: false,
  exporting: false,
  loading4: false,
  loading5: false,
  loading6: false,
  loading7: false,
  loading8: false,
  loading9: false,
  loading10: false,
  error: null,
  message: null,
  metaData:null
};

const dashboardReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DASHBOARD_STUFF_COUNT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_DASHBOARD_STUFF_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: action.payload,
        error: null,
      };
    case GET_DASHBOARD_STUFF_COUNT_ERROR:
      return {
        ...state,
        loading: false,
        dashboardData: null,
        error: action.payload,
      };
    case GET_DASHBOARD_PAID_IN_PROJECT_LIST:
      return {
        ...state,
        loading1: true,
        error: null,
      };
    case GET_DASHBOARD_PAID_IN_PROJECT_LIST_SUCCESS:
      return {
        ...state,
        loading1: false,
        paidInProjectList: action.payload,
        error: null,
      };
    case GET_DASHBOARD_PAID_IN_PROJECT_LIST_ERROR:
      return {
        ...state,
        loading1: false,
        paidInProjectList: null,
        error: action.payload,
      };
    case GET_DASHBOARD_PAID_OUT_PROJECT_LIST:
      return {
        ...state,
        loading2: true,
        error: null,
      };
    case GET_DASHBOARD_PAID_OUT_PROJECT_LIST_SUCCESS:
      return {
        ...state,
        loading2: false,
        paidOutProjectList: action.payload,
        error: null,
      };
    case GET_DASHBOARD_PAID_OUT_PROJECT_LIST_ERROR:
      return {
        ...state,
        loading2: false,
        paidOutProjectList: null,
        error: action.payload,
      };
    case GET_TOP_PAID_IN_OUT_PROJECT_LIST:
      return {
        ...state,
        loading3: true,
        error: null,
      };
    case GET_TOP_PAID_IN_OUT_PROJECT_LIST_SUCCESS:
      return {
        ...state,
        loading3: false,
        topInOutProjectList: action.payload,
        error: null,
      };
    case GET_TOP_PAID_IN_OUT_PROJECT_LIST_ERROR:
      return {
        ...state,
        loading3: false,
        topInOutProjectList: null,
        error: action.payload,
      };
    case EXPORT_TOP_PAID_IN_OUT_PROJECT:
      return { ...state, exporting: true, error: null };
    case EXPORT_TOP_PAID_IN_OUT_PROJECT_SUCCESS:
      return {
        ...state,
        exporting: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case EXPORT_TOP_PAID_IN_OUT_PROJECT_ERROR:
      return {
        ...state,
        exporting: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case GET_TOP_RENT_PROJECT:
      return {
        ...state,
        loading4: true,
        error: null,
      };
    case GET_TOP_RENT_PROJECT_SUCCESS:
      return {
        ...state,
        loading4: false,
        topRentProjectList: action.payload,
        error: null,
      };
    case GET_TOP_RENT_PROJECT_ERROR:
      return {
        ...state,
        loading4: false,
        topRentProjectList: null,
        error: action.payload,
      };
    case GET_SUM_PAID_IN_OUT_PROJECT:
      return {
        ...state,
        loading5: true,
        error: null,
      };
    case GET_SUM_PAID_IN_OUT_PROJECT_SUCCESS:
      return {
        ...state,
        loading5: false,
        sumPaidInOutProject: action.payload,
        error: null,
      };
    case GET_SUM_PAID_IN_OUT_PROJECT_ERROR:
      return {
        ...state,
        loading5: false,
        sumPaidInOutProject: null,
        error: action.payload,
      };
    case GET_LARGEST_INVESTOR_PER_PROJECT:
      return {
        ...state,
        loading6: true,
        error: null,
      };
    case GET_LARGEST_INVESTOR_PER_PROJECT_SUCCESS:
      return {
        ...state,
        loading6: false,
        largestInvestors: action.payload,
        error: null,
      };
    case GET_LARGEST_INVESTOR_PER_PROJECT_ERROR:
      return {
        ...state,
        loading6: false,
        largestInvestors: null,
        error: action.payload,
      };
    case GET_TOTAL_DEBT_PER_PROJECT:
      return {
        ...state,
        loading7: true,
        error: null,
      };
    case GET_TOTAL_DEBT_PER_PROJECT_SUCCESS:
      return {
        ...state,
        loading7: false,
        debtProjects: action.payload,
        error: null,
      };
    case GET_TOTAL_DEBT_PER_PROJECT_ERROR:
      return {
        ...state,
        loading7: false,
        debtProjects: null,
        error: action.payload,
      };

    case GET_SQM_PROJECT:
      return {
        ...state,
        loading8: true,
        error: null,
      };
    case GET_SQM_PROJECT_SUCCESS:
      return {
        ...state,
        loading8: false,
        sqmProjects: action.payload,
        error: null,
      };
    case GET_SQM_PROJECT_ERROR:
      return {
        ...state,
        loading8: false,
        sqmProjects: null,
        error: action.payload,
      };

    case GET_ALL_NEWS:
      return {
        ...state,
        loading9: true,
        error: null,
      };
    case GET_ALL_NEWS_SUCCESS:
      return {
        ...state,
        loading9: false,
        allNews: action.payload.news,
        metaData:action.payload.metaData,
        error: null,
      };
    case GET_ALL_NEWS_ERROR:
      return {
        ...state,
        loading9: false,
        allNews: [],
        error: action.payload,
      };

    case GET_NEWS:
      return {
        ...state,
        loading9: true,
        error: null,
      };
    case GET_NEWS_SUCCESS:
      return {
        ...state,
        loading9: false,
        newsData: action.payload,
        error: null,
      };
    case GET_NEWS_ERROR:
      return {
        ...state,
        loading9: false,
        newsData: null,
        error: action.payload,
      };

    case ADD_NEWS:
      return {
        ...state,
        loading10: true,
        error: null,
      };
    case ADD_NEWS_SUCCESS:
      return {
        ...state,
        loading10: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case ADD_NEWS_ERROR:
      return {
        ...state,
        loading10: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case UPDATE_NEWS:
      return {
        ...state,
        loading10: true,
        error: null,
      };
    case UPDATE_NEWS_SUCCESS:
      return {
        ...state,
        loading10: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case UPDATE_NEWS_ERROR:
      return {
        ...state,
        loading10: false,
        success: false,
        message: null,
        error: action.payload,
      };

    case DELETE_NEWS:
      return {
        ...state,
        loading10: true,
        error: null,
      };
    case DELETE_NEWS_SUCCESS:
      return {
        ...state,
        loading10: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_NEWS_ERROR:
      return {
        ...state,
        loading10: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case RESET_DASHBOARD:
      return {
        ...state,
        loading: false,
        loading1: false,
        loading2: false,
        loading3: false,
        loading4: false,
        loading5: false,
        loading6: false,
        loading7: false,
        loading8: false,
        loading9: false,
        loading10: false,
        exporting: false,
        success: false,
        message: null,
        error: null,
      };

    default:
      return { ...state };
  }
};
export default dashboardReducer;
