export const SET_PAIDINOUT_FUND = "SET_PAIDINOUT_FUND";
export const GET_ALL_PAIDINOUT_FUND = "GET_ALL_PAIDINOUT_FUND";
export const GET_ALL_PAIDINOUT_FUND_SUCCESS = "GET_ALL_PAIDINOUT_FUND_SUCCESS";
export const GET_ALL_PAIDINOUT_FUND_ERROR = "GET_ALL_PAIDINOUT_FUND_ERROR";
export const GET_CAPITAL_CALL_CREDIT_FUND = "GET_CAPITAL_CREDIT_FUND";
export const GET_CAPITAL_CALL_CREDIT_FUND_SUCCESS =
  "GET_CAPITAL_CALL_CREDIT_FUND_SUCCESS";
export const GET_CAPITAL_CALL_CREDIT_FUND_ERROR =
  "GET_CAPITAL_CALL_CREIDT_FUND_ERROR";
export const GET_CAPITAL_CALL_DEBIT_FUND = "GET_CAPITAL_CALL_DEBIT_FUND";
export const GET_CAPITAL_CALL_DEBIT_FUND_SUCCESS =
  "GET_CAPIAL_CALL_DEBIT_FUND_SUCCESS";
export const GET_CAPITAL_CALL_DEBIT_FUND_ERROR =
  "GET_CAPITAL_CALL_DEBIT_FUND_ERROR";
export const ADD_PAIDINOUT_FUND = "ADD_PAIDINOUT_FUND";
export const ADD_PAIDINOUT_FUND_SUCCESS = "ADD_PAIDINOUT_FUND_SUCCESS";
export const ADD_PAIDINOUT_FUND_ERROR = "ADD_PAIDINOUT_FUND_ERROR";
export const GET_PAIDINOUT = "GET_PAIDINOUT";
export const GET_PAIDINOUT_SUCCESS = "GET_PAIDINOUT_SUCCESS";
export const GET_PAIDINOUT_ERROR = "GET_PAIDINOUT_ERROR";
export const EDIT_PAIDINOUT_FUND = "EDIT_PAIDINOUT_FUND";
export const EDIT_PAIDINOUT_FUND_SUCCESS = "EDIT_PAIDINOUT_FUND_SUCCESS";
export const EDIT_PAIDINOUT_FUND_ERROR = "EDIT_PAIDINOUT_ERROR";
export const DELETE_PAIDINOUT_FUND = "DELETE_PAIDINOUT_FUND";
export const DELETE_PAIDINOUT_FUND_SUCCESS = "DELETE_PAIDINOUT_FUND_SUCCESS";
export const DELETE_PAIDINOUT_FUND_ERROR = "DELETE_PAIDINOUT_FUND_ERROR";
export const EXPORT_PAIDINOUT_FUND = "EXPORT_PAIDINOUT_FUND";
export const EXPORT_PAIDINOUT_FUND_SUCCESS = "EXPORT_PAIDINOUT_FUND_SUCCESS";
export const EXPORT_PAIDINOUT_FUND_ERROR = "EXPORT_PAIDINOUT_FUND_ERROR";
export const RESET_PAIDINOUT = "RESET_PAIDINOUT_FUND";
export const UPDATE_CAPITAL_CALL = "UPDATE_CAPITAL_CALL";
export const UPDATE_CAPITAL_CALL_SUCCESS = "UPDATE_CAPITAL_CALL_SUCCESS";
export const UPDATE_CAPITAL_CALL_ERROR = "UPDATE_CAPITAL_CALL_ERROR";
export const SET_EACH_PROPERTYFUND = "SET_EACH_PROPERTYFUND";

export const setPaidInOutFund = (rowIndex, columnId, value) => ({
  type: SET_PAIDINOUT_FUND,
  payload: { rowIndex, columnId, value },
});
export const setEachPropertyFund = (eachPropertyFund) => ({
  type: SET_EACH_PROPERTYFUND,
  payload: eachPropertyFund,
});

export const getAllPaidInOutFund = (param) => ({
  type: GET_ALL_PAIDINOUT_FUND,
  payload: { param },
});

export const getAllPaidInOutFundSuccess = (success, paidInOutList) => ({
  type: GET_ALL_PAIDINOUT_FUND_SUCCESS,
  payload: { success, paidInOutList },
});

export const getAllPaidInOutFundError = (error) => ({
  type: GET_ALL_PAIDINOUT_FUND_ERROR,
  payload: error,
});

export const getCapitalCallCreditFund = (capitalParam) => ({
  type: GET_CAPITAL_CALL_CREDIT_FUND,
  payload: { capitalParam },
});

export const getCapitalCallCreditFundSuccess = (capitalCall) => ({
  type: GET_CAPITAL_CALL_CREDIT_FUND_SUCCESS,
  payload: capitalCall,
});

export const getCapitalCallCreditFundError = (error) => ({
  type: GET_CAPITAL_CALL_CREDIT_FUND_ERROR,
  payload: error,
});
export const getCapitalCallDebitFund = (capitalParam) => ({
  type: GET_CAPITAL_CALL_DEBIT_FUND,
  payload: { capitalParam },
});

export const getCapitalCallDebitFundSuccess = (capitalCall) => ({
  type: GET_CAPITAL_CALL_DEBIT_FUND_SUCCESS,
  payload: capitalCall,
});

export const getCapitalCallDebitFundError = (error) => ({
  type: GET_CAPITAL_CALL_DEBIT_FUND_ERROR,
  payload: error,
});

export const addPaidInOutFund = (
  paidInOutData,
  projectId,

) => ({
  type: ADD_PAIDINOUT_FUND,
  payload: { paidInOutData, projectId },
});

export const addPaidInOutFundSuccess = (success, message) => ({
  type: ADD_PAIDINOUT_FUND_SUCCESS,
  payload: { success, message },
});

export const addPaidInOutFundError = (error) => ({
  type: ADD_PAIDINOUT_FUND_ERROR,
  payload: error,
});

export const getPaidInOut = (paidInOutId) => ({
  type: GET_PAIDINOUT,
  payload: { paidInOutId },
});

export const getPaidInOutSuccess = (paidInOutData) => ({
  type: GET_PAIDINOUT_SUCCESS,
  payload: paidInOutData,
});

export const getPaidInOutError = (error) => ({
  type: GET_PAIDINOUT_ERROR,
  payload: error,
});

export const editPaidInOutFund = (paidInOutId, paidInOutData, projectId) => ({
  type: EDIT_PAIDINOUT_FUND,
  payload: { paidInOutId, paidInOutData, projectId },
});

export const editPaidInOutFundSuccess = (success, message) => ({
  type: EDIT_PAIDINOUT_FUND_SUCCESS,
  payload: { success, message },
});

export const editPaidInOutFundError = (error) => ({
  type: EDIT_PAIDINOUT_FUND_ERROR,
  payload: error,
});

export const deletePaidInOutFund = (
  paidInOutId,
  projectId,
  eachPropertyFund
) => ({
  type: DELETE_PAIDINOUT_FUND,
  payload: { paidInOutId, projectId, eachPropertyFund },
});

export const deletePaidInOutFundSuccess = (success1, message) => ({
  type: DELETE_PAIDINOUT_FUND_SUCCESS,
  payload: { success1, message },
});

export const deletePaidInOutFundError = (error) => ({
  type: DELETE_PAIDINOUT_FUND_ERROR,
  payload: error,
});

export const exportPaidInOutFund = (projectId, eachPropertyFund) => ({
  type: EXPORT_PAIDINOUT_FUND,
  payload: { projectId, eachPropertyFund },
});

export const exportPaidInOutFundSuccess = (success, message) => ({
  type: EXPORT_PAIDINOUT_FUND_SUCCESS,
  payload: { success, message },
});

export const exportPaidInOutFundError = (error) => ({
  type: EXPORT_PAIDINOUT_FUND_ERROR,
  payload: error,
});

export const resetPaidInOutFund = () => ({
  type: RESET_PAIDINOUT,
  payload: {},
});

export const updatePaidInOut = (data, projectId, eachPropertyFund) => ({
  type: UPDATE_CAPITAL_CALL,
  payload: { data, projectId, eachPropertyFund },
});
export const updatePaidInOutSuccess = (success, message) => ({
  type: UPDATE_CAPITAL_CALL_SUCCESS,
  payload: { success, message },
});

export const updatePaidInOutError = (error) => ({
  type: UPDATE_CAPITAL_CALL_ERROR,
  payload: error,
});
