import React from "react";

const IconEyeOn = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 18.541 13">
      <g
        id="Group_2918"
        dataname="Group 2918"
        transform="translate(-2.73 -5.5)"
      >
        <circle
          id="Ellipse_310"
          dataname="Ellipse 310"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(8.5 8.5)"
          fill="none"
          stroke="#000"
          strokeWidth="1"
        />
        <path
          id="Path_10178"
          dataname="Path 10178"
          d="M20.188,10.934c.388.471.582.707.582,1.066s-.194.594-.582,1.066C18.768,14.79,15.636,18,12,18s-6.768-3.21-8.188-4.934c-.388-.471-.582-.707-.582-1.066s.194-.594.582-1.066C5.232,9.21,8.364,6,12,6S18.768,9.21,20.188,10.934Z"
          fill="none"
          stroke="#000"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export default IconEyeOn;
