import React,{useCallback} from "react";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import { useField,useFormikContext } from "formik";

const LimitedChipsContainer = ({ children, hasValue, ...props }) => {
  if (!hasValue) {
    return (
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    );
  }

  const CHIPS_LIMIT = 1;
  const [chips, otherChildren] = children;
  const overflowCounter = chips.slice(CHIPS_LIMIT).length;
  const displayChips = chips.slice(
    overflowCounter,
    overflowCounter + CHIPS_LIMIT
  );

  return (
    <components.ValueContainer {...props}>
      {displayChips}
      {overflowCounter > 0 && (
        <span className="flex-shrink-0 py-1 px-2 rounded bg-primary-200 text-topNav text-xs font-medium">
          + {overflowCounter}
        </span>
      )}
      {otherChildren}
    </components.ValueContainer>
  );
};


const AsyncMultiSelectBox = (props) => {
  const {
    classes,
    label,
    isRequired,
    loading = false,
    callback,
    loadSuggestions,
    options,
    placeholder,
    isMulti,
  } = props;

  // eslint-disable-next-line no-unused-vars
  const [query, setQuery] = React.useState("");
  const [field, meta, handler] = useField(props);
  const { setFieldValue,values } = useFormikContext(props);
  const [selectedOption, setSelectedOption] = React.useState();
  const handleChange = (value) => {
    setSelectedOption(value[0]);
    if (callback) {
      callback(value|| "");
    }
    if (value.length === 1) {
      // props.defaultId && setFieldValue(props.defaultId, value[0].value);
      if (props.renderOption) {
        setFieldValue(
          "roleType",
          value.map((value) => value.configChoice)
        );
      }

      handler.setValue(value.map((value) => value.value));
    } else {
      if (props.renderOption) {
        setFieldValue(
          "roleType",
          value.map((value) => value.configChoice)
        );
      }

      handler.setValue(value.map((val) => val.value));
    }
  }
  const renderOptions = useCallback(() => {
    if (values?.roleType?.includes("investorNorwegian")) {
      return props.options?.filter(
        (val) => val.configChoice !== "investorInternational"
      );
    } else if (values?.roleType?.includes("investorInternational")) {
      return props?.options?.filter(
        (val) => val.configChoice !== "investorNorwegian"
      );
    } else {
      return props.options;
    }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.roleType]);
  const handleInputChange = (val) => {
    setQuery(val);
  };
  React.useEffect(() => {
    let val = [];
    if (props.options && props.options.length > 0 && field.value) {
      props.options.forEach((element) => {
        if (field.value.indexOf(element.value) !== -1) {
          val.push(element);
        }
      });
      setSelectedOption(val);
    } else {
      setSelectedOption(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.options, field.value]);

  return loading ? (
    <div className="field-group animate-pulse">
      <label className="w-28 h-6 field-label rounded bg-grey-400"></label>
      <span className="w-full h-[50px] block border-0 rounded bg-grey-400"></span>
    </div>
  ) : (
    <div
      className={`field-group
      ${classes?.root || ""}
      ${meta.touched && meta.error ? "field-group-error" : ""}
      `}
    >
      {label && (
        <label htmlFor="" className="field-label">
          {label ? label : null}{" "}
          {isRequired && <strong className="text-red-700">*</strong>}
        </label>
      )}
      <AsyncSelect
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
        isMulti={isMulti}
        cacheOptions
        components={{ ValueContainer: LimitedChipsContainer }}
        defaultOptions={props?.renderOption ? renderOptions():options}
        loadOptions={loadSuggestions}
        getOptionLabel={(e) => e.label}
        getOptionValue={(e) => e.value}
        onInputChange={handleInputChange}
        onChange={handleChange}
        isDisabled={props.disabled}
        className="w-full react-select"
        classNamePrefix="react-select"
        placeholder={placeholder}
        value={selectedOption}
        hideSelectedOptions={false}
        menuPortalTarget={document.body}
        menuPosition="absolute"
        menuPlacement="auto"
        menuShouldBlockScroll={true}
        menuShouldScrollIntoView={false}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      />
      {meta.touched && meta.error ? (
        <span className="block mt-2 text-xs text-right text-red-700">
          {meta.touched && meta.error ? meta.error : null}
        </span>
      ) : null}
    </div>
  );
};
export default AsyncMultiSelectBox;
