import React from "react";

const IconUserManagement = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 15.499 14.497">
      <g
        id="Group_2683"
        dataname="Group 2683"
        transform="translate(-328.102 -227.117)"
      >
        <g id="Group_2077" dataname="Group 2077">
          <g id="Group_2078" dataname="Group 2078">
            <circle
              id="Ellipse_295"
              dataname="Ellipse 295"
              cx="2.321"
              cy="2.321"
              r="2.321"
              transform="translate(332.589 229.683)"
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeWidth="1"
            />
            <path
              id="Ellipse_296"
              dataname="Ellipse 296"
              d="M335.1,240.617a6.5,6.5,0,1,1,6.5-6.706"
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeWidth="1"
            />
            <path
              id="Path_9291"
              dataname="Path 9291"
              d="M336.126,235.938a5.52,5.52,0,0,0-4.024.63,3.779,3.779,0,0,0-1.643,1.983"
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeWidth="1"
            />
          </g>
        </g>
        <g id="Group_2682" dataname="Group 2682">
          <g
            id="Group_2681"
            dataname="Group 2681"
            transform="translate(-0.482 -0.482)"
          >
            <g
              id="Group_2680"
              dataname="Group 2680"
              transform="translate(337.9 235.915)"
            >
              <path
                id="Path_9292"
                dataname="Path 9292"
                d="M343.615,238.4h-.39a.221.221,0,0,1-.214-.155,2.277,2.277,0,0,0-.179-.431.213.213,0,0,1,.041-.252l.281-.281a.215.215,0,0,0,0-.307l-.376-.376a.218.218,0,0,0-.309,0l-.281.281a.213.213,0,0,1-.252.041,2.1,2.1,0,0,0-.431-.179.22.22,0,0,1-.153-.214v-.39a.217.217,0,0,0-.219-.219H340.6a.218.218,0,0,0-.219.219v.39a.22.22,0,0,1-.153.214,2.121,2.121,0,0,0-.43.179.214.214,0,0,1-.253-.041l-.281-.281a.218.218,0,0,0-.309,0l-.328.331-.045.045a.215.215,0,0,0,0,.307l.281.281a.217.217,0,0,1,.041.252,2.281,2.281,0,0,0-.18.431.221.221,0,0,1-.213.155h-.393a.219.219,0,0,0-.219.216v.533a.22.22,0,0,0,.219.217h.393a.221.221,0,0,1,.213.154,2.249,2.249,0,0,0,.18.431.219.219,0,0,1-.041.253l-.281.281a.208.208,0,0,0-.055.209.184.184,0,0,0,.055.1l.374.376a.218.218,0,0,0,.309,0l.281-.281a.214.214,0,0,1,.253-.041,2.353,2.353,0,0,0,.43.18.219.219,0,0,1,.153.213v.392a.218.218,0,0,0,.219.217h.531a.218.218,0,0,0,.219-.217v-.392a.219.219,0,0,1,.153-.213,2.329,2.329,0,0,0,.431-.18.213.213,0,0,1,.252.041l.281.281a.218.218,0,0,0,.309,0l.376-.376a.215.215,0,0,0,0-.307l-.281-.281a.215.215,0,0,1-.041-.253,2.245,2.245,0,0,0,.179-.431.222.222,0,0,1,.214-.154h.39a.218.218,0,0,0,.219-.217v-.533A.217.217,0,0,0,343.615,238.4Z"
                transform="translate(-337.9 -235.915)"
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.5"
              />
            </g>
            <path
              id="Path_9293"
              dataname="Path 9293"
              d="M341.483,238.551a.942.942,0,0,1-.831.937.856.856,0,0,1-.112.007.944.944,0,1,1,.943-.945Z"
              transform="translate(0.329 0.329)"
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.5"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconUserManagement;
