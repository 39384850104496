import React from "react";

const IconDocuments = () => {
  return (
    <svg version="1.1" viewBox="0 0 8.5 8.5" width="22" height="22">
      <g id="Group_6011" transform="translate(0.5 0.5)">
        <path
          fill="none"
          stroke="#000000"
          strokeWidth="1"
          d="M7.5,2.7v3.2c0,0.8,0,1.2-0.2,1.4C7,7.5,6.6,7.5,5.8,7.5H1.7c-0.8,0-1.2,0-1.4-0.2C0,7,0,6.6,0,5.9V1.7
     c0-0.8,0-1.2,0.2-1.4C0.5,0,0.9,0,1.7,0H5L7.5,2.7z"
        />
        <path
          id="Path_9263"
          fill="none"
          stroke="#000000"
          strokeWidth="1"
          strokeLinecap="round"
          d="M1.7,5.4H5"
        />
        <path
          id="Path_9263_00000093162224351663108340000007004606930211271553_"
          fill="none"
          stroke="#000000"
          strokeWidth="1"
          strokeLinecap="round"
          d="M1.7,3.4h1.6"
        />
        <path
          fill="none"
          stroke="#000000"
          strokeWidth="1"
          strokeLinejoin="round"
          d="M6.5,2.7h1L4.8,0.2v0.9c0,0.8,0,1.2,0.2,1.4C5.3,2.7,5.7,2.7,6.5,2.7z"
        />
      </g>
    </svg>
  );
};

export default IconDocuments;
