import React, { Fragment, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import { connect, useDispatch } from "react-redux";
import { IconChevronDown } from "../../../components/svg";
import {
  getPermission,
  resetInvestorList,
  clearSelectedInvestor,
} from "src/reduxs/actions";
import { SET_CURRENT_ROLE } from "src/reduxs/auth/action";
import { isAdminOrEmployee } from "src/helpers/util";

const RoleDropdown = (props) => {
  const dispatch = useDispatch();
  const { permission, loading } = props.shared;
  const { currentRole } = props.auth;
  const setRole = (role) => {
    dispatch({ type: SET_CURRENT_ROLE, payload: role });
    localStorage.setItem("currentRole", JSON.stringify(role) || null);
    props.getPermission(role.id);
    if (isAdminOrEmployee(role)) {
      localStorage.removeItem("selectedInvestor");
      props.clearSelectedInvestor();
    }
  };

  useEffect(() => {
    if (permission && !loading) {
      const currentUserRole = currentRole;
      if (currentUserRole) {
        const defaultRoleObj = permission.roles.find(
          (obj) => obj.id === currentUserRole.id
        );
        dispatch({ type: SET_CURRENT_ROLE, payload: defaultRoleObj });
      } else {
        const { default_role_id } = JSON.parse(
          localStorage.getItem("currentUser")
        );
        const defaultRoleObj = permission.roles.find(
          (obj) => obj.id === default_role_id
        );
        dispatch({ type: SET_CURRENT_ROLE, payload: defaultRoleObj });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permission, currentRole, loading, dispatch]);

  return (
    <>
      <Menu as="div" className="relative inline-block">
        <Menu.Button className="inline-flex py-2 px-2 border border-solid border-primary-200 rounded-md text-xs focus:outline-none">
          {currentRole?.name}
          <span className="w-4 h-3.5 flex items-center justify-center ml-3 p-0.5 rounded-sm bg-primary-200">
            <IconChevronDown />
          </span>
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            as="ul"
            className="absolute right-0 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg min-w-[12rem] ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            {!loading &&
              permission?.roles?.map((role) => (
                <Menu.Item
                  key={role.id}
                  as="li"
                  className={`group p-1 flex rounded-md items-center w-full text-sm`}
                >
                  <button
                    type="button"
                    className={`group flex rounded-md items-center w-full px-3 py-2 text-sm text-gray-900 hover:bg-primary-500 hover:text-white focus:outline-none svg-color-grey hover:svg-color-white`}
                    onClick={() => setRole(role)}
                  >
                    {role.name}
                  </button>
                </Menu.Item>
              ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

const mapStateToProps = (state) => ({
  shared: state.shared,
  auth: state.auth,
});
export default connect(mapStateToProps, {
  getPermission,
  resetInvestorList,
  clearSelectedInvestor,
})(RoleDropdown);
