import moment from "moment";
import { each, find } from "lodash";
import { defaultCurrency } from "src/configs/constant";
import { timeZoneData } from "src/helpers/timezone-data";
import _ from "lodash";

export const formatCurrency = (value) => {
  let sym = defaultCurrency.symbol || "";
  let pos = defaultCurrency.symbolPosition || "after";
  let val = value ? numberWithCommas(value) : "0";
  if (pos === "before") {
    return `${sym} ${val}`;
  } else {
    return `${val} ${sym}`;
  }
};

export const parseMessage = (message) => {
  var text = "";
  if (typeof message == "object") {
    for (let key in message) {
      if (Array.isArray(message[key])) {
        // eslint-disable-next-line no-loop-func
        message[key].forEach((elem) => {
          text += elem;
        });
      } else if (typeof message[key] == "string") {
        text += message[key];
      } else {
        text += JSON.stringify(message[key]);
      }
    }
  } else if (typeof message == "string") {
    text = message;
  } else {
    text += JSON.stringify(message);
  }
  return text;
};

export const getDiffDate = (d1, d2) => {
  var duration = moment.duration(d1.diff(d2));
  var hours = parseInt(duration.asHours());
  var minutes = parseInt(duration.asMinutes()) - hours * 60;
  var seconds = parseInt(duration.asSeconds()) - minutes * 60 - hours * 3600;
  return (
    (hours < 10 ? "0" : "") +
    hours +
    ":" +
    (minutes < 10 ? "0" : "") +
    minutes +
    ":" +
    (seconds < 10 ? "0" : "") +
    seconds
  );
};

export const generateSlug = (text) => {
  var slug = "";
  if (text) {
    slug = text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^\w-]+/g, "") // Remove all non-word chars
      .replace(/--+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text
  }
  return slug;
};

export const bytesToHuman = (bytes) => {
  let units = ["B", "KB", "MB", "GB", "TB", "PB"];
  let i = 0;
  for (i = 0; bytes >= 1024; i++) {
    bytes /= 1024;
  }
  return Math.round(bytes, 2) + " " + units[`${i}`];
};

export const countryToFlag = (isoCode) => {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
};

export const getMonthlyAnchorList = () => {
  let arr = [];
  for (var i = 1; i < 31; i++) {
    arr.push({ name: `${ordinalSuffix(i)}`, id: i });
  }
  return arr;
};

export const numberWithCommas = (x) => {
  return x.toLocaleString("no-NO");
};

export const ordinalSuffix = (i) => {
  var j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + "st";
  }
  if (j === 2 && k !== 12) {
    return i + "nd";
  }
  if (j === 3 && k !== 13) {
    return i + "rd";
  }
  return i + "th";
};

export const checkPermission = (module, section, action, permission) => {
  if (
    permission
      ?.find((item) => item.key === module)
      ?.section?.find((value) => value.key === section)
      ?.action?.find((data) => data.key === action)?.value
  ) {
    return true;
  }
  return false;
};

export const intersection = (a, b) => {
  return a.filter((element) => b.some((x) => x.productId === element));
};

export const union = (a, b) => {
  return [...a, ...not(b, a)];
};

export const not = (a, b) => {
  return a
    .filter((value) => b.indexOf(value.productId) === -1)
    .map(function (value) {
      return value.productId;
    });
};

export const extractValue = (arr, prop) => {
  // extract value from property
  let extractedValue = arr.map((item) => item[prop]);

  return extractedValue;
};

export const getPermission = (_module, _section, _actions, permission) => {
  const result = {};
  let sectionGranted;
  let actionGranted;
  const moduleGranted = find(permission, (module) => {
    return module.key.toLocaleLowerCase() === _module.toLocaleLowerCase();
  });
  if (moduleGranted) {
    sectionGranted = find(moduleGranted.section, (section) => {
      return section.key.toLocaleLowerCase() === _section.toLocaleLowerCase();
    });
  }
  if (sectionGranted) {
    each(_actions, (_action) => {
      actionGranted = find(sectionGranted.action, (action) => {
        return action.key.toLocaleLowerCase() === _action.toLocaleLowerCase();
      });
      if (actionGranted) {
        result[actionGranted.key] = actionGranted.value;
      }
    });
  }
  return result;
};

export const cleanObj = (props) => {
  for (const obj in props) {
    if (
      obj === "search" &&
      (props[obj] == null || props[obj] === "" || props[obj] === undefined)
    ) {
      delete props["searchFields"];
    }
    if (props[obj] === "" || props[obj] === null || props[obj] === undefined) {
      delete props[obj];
    }
  }
  return props;
};

export const enterPressListener = (method) =>
  document.addEventListener("keydown", method);

export const toCapitalCase = (str) => {
  // return str[0].toUpperCase() + str.slice(1).toLowerCase();
  if (str) {
    const arr = str.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }

    return arr.join(" ");
  } else {
    return null;
  }
};

export const strLimit = (str, limit = 50) => {
  if (str) {
    return str.slice(0, limit) + (str.length > limit ? "..." : "");
  } else {
    return null;
  }
};

export const hideScroll = (scroll) => {
  let body = document.querySelector("body");
  let root = document.querySelector("#root");
  if (scroll) {
    if (root.scrollHeight > root.clientHeight) {
      body.classList.add("scroll-hidden-padding");
    } else {
      body.classList.add("scroll-hidden");
    }
  } else {
    body.classList.remove("scroll-hidden", "scroll-hidden-padding");
  }
};

export const areEqual = (obj1, obj2) => _.isEqual(obj1, obj2);
export const viewCurrency = (x) => {
  return x.split("").slice(-2, -1)[0];
};

export const pagination_limit = 100;

export const isAdminOrEmployee = (currentRole) => {
  if (
    currentRole &&
    (currentRole?.roleTypeConficChoice?.configChoice === "internalEmployee" ||
      currentRole?.roleTypeConficChoice?.configChoice === "superAdmin")
  ) {
    return true;
  } else {
    return false;
  }
};

export const isSystemRole = (slug) => {
  if (
    slug === "internal-employee" ||
    slug === "intl-investor" ||
    slug === "norwegian-investor"
  ) {
    return true;
  }
  return false;
};

export const convertToSlug = (string) => {
  return string
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
};

export const MAP_API_KEY = process.env.REACT_APP_MAP_API_KEY;

export const handleMyErrorMessage = (error)=>{
  if(error.response){
    //API Error Response
    return error.response.data.message
  }else if(error.message){
    //App Error
    return error.message
  }else{
    //Custom Error
    return error
  }
}


export const GetLocationDetails = async () => {
  try {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const finalzone = timeZoneData?.find(val => val.zoneName === timezone)

    if (timezone === '' || !timezone) {
      return { success: false, data: null }
    }

    return { success: true, data: finalzone }
  } catch (e) {
    return { success: false, data: e }
  }
}