import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import DashboardService from "../../services/dashboard-service";
import TableDataService from "../../services/table-data-service1";
import {
  GET_DASHBOARD_STUFF_COUNT,
  GET_DASHBOARD_PAID_IN_PROJECT_LIST,
  GET_DASHBOARD_PAID_OUT_PROJECT_LIST,
  GET_TOP_PAID_IN_OUT_PROJECT_LIST,
  EXPORT_TOP_PAID_IN_OUT_PROJECT,
  GET_TOP_RENT_PROJECT,
  GET_SUM_PAID_IN_OUT_PROJECT,
  GET_LARGEST_INVESTOR_PER_PROJECT,
  GET_TOTAL_DEBT_PER_PROJECT,
  GET_SQM_PROJECT,
  GET_ALL_NEWS,
  GET_NEWS,
  ADD_NEWS,
  UPDATE_NEWS,
  DELETE_NEWS,
} from "../actions";
import {
  getDashboardStuffCountSuccess,
  getDashboardStuffCountError,
  getDashboardPaidInProjectSuccess,
  getDashboardPaidInProjectError,
  getDashboardPaidOutProjectSuccess,
  getDashboardPaidOutProjectError,
  getTopPaidInOutProjectSuccess,
  getTopPaidInOutProjectError,
  exportTopPaidInOutProjectSuccess,
  exportTopPaidInOutProjectError,
  getTopRentProjectSuccess,
  getTopRentProjectError,
  getSumPaidInOutProjectSuccess,
  getSumPaidInOutProjectError,
  getLargestInvestorPerProjectSuccess,
  getLargestInvestorPerProjectError,
  getTotalDebtPerProjectSuccess,
  getTotalDebtPerProjectError,
  getSqmProjectSuccess,
  getSqmProjectError,
  getAllNews,
  getAllNewsSuccess,
  getAllNewsError,
  getNewsSuccess,
  getNewsError,
  addNewsSuccess,
  addNewsError,
  updateNewsSuccess,
  updateNewsError,
  deleteNewsSuccess,
  deleteNewsError,
} from "./action";
import { toast } from "react-toastify";
import ToastElement from "../../components/toast";
import { handleMyErrorMessage } from "../../helpers/util";
import saveAs from "file-saver";
import moment from "moment";

export function* watchGetDashboardStuffCountData() {
  yield takeEvery(GET_DASHBOARD_STUFF_COUNT, getDashboardStuffCountData);
}

const getDashboardStuffCountDataAsync = async (params) => {
  const endpoint = `dashboards`;
  return DashboardService.getDashboardData(endpoint, params);
};

function* getDashboardStuffCountData({ payload }) {
  try {
    const response = yield call(
      getDashboardStuffCountDataAsync,
      payload.params
    );
    if (response.data.success) {
      yield put(getDashboardStuffCountSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getDashboardStuffCountError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getDashboardStuffCountError(error.response.data.message));
    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getDashboardStuffCountError(errorMessage));
    }
  }
}

export function* watchPaidInProjectList() {
  yield takeEvery(GET_DASHBOARD_PAID_IN_PROJECT_LIST, getPaidInProjectListAc);
}

const getPaidInProjectListAsync = async (params) => {
  const endpoint = "paidInProjectList";
  return DashboardService.getDashboardData(endpoint, params);
};

function* getPaidInProjectListAc({ payload }) {
  try {
    const response = yield call(getPaidInProjectListAsync, payload.params);

    if (response.data.success) {
      yield put(getDashboardPaidInProjectSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getDashboardPaidInProjectError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getDashboardPaidInProjectError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getDashboardPaidInProjectError(errorMessage));
    }
  }
}

export function* watchPaidOutProjectList() {
  yield takeEvery(GET_DASHBOARD_PAID_OUT_PROJECT_LIST, getPaidOutProjectListAc);
}

const getPaidOutProjectListAsync = async (params) => {
  const endpoint = "paidOutProjectList";
  return DashboardService.getDashboardData(endpoint, params);
};

function* getPaidOutProjectListAc({ payload }) {
  try {
    const response = yield call(getPaidOutProjectListAsync, payload.params);

    if (response.data.success) {
      yield put(getDashboardPaidOutProjectSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getDashboardPaidOutProjectError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getDashboardPaidOutProjectError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getDashboardPaidOutProjectError(errorMessage));
    }
  }
}

export function* watchTopPaidInOutProjectList() {
  yield takeEvery(
    GET_TOP_PAID_IN_OUT_PROJECT_LIST,
    getTopPaidInOutProjectListAc
  );
}

const getTopPaidInOutProjectListAsync = async (params) => {
  const endpoint = "top5PaidInOutProjectList";
  return DashboardService.getDashboardData(endpoint, params);
};

function* getTopPaidInOutProjectListAc({ payload }) {
  try {
    const response = yield call(
      getTopPaidInOutProjectListAsync,
      payload.params
    );

    if (response.data.success) {
      yield put(getTopPaidInOutProjectSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getTopPaidInOutProjectError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getTopPaidInOutProjectError(error.response.data.message));
    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getTopPaidInOutProjectError(errorMessage));
    }
  }
}

export function* watchExportTopPaidInOutProject() {
  yield takeEvery(EXPORT_TOP_PAID_IN_OUT_PROJECT, exportTopPaidInOutProject);
}

const exportTopPaidInOutProjectAsync = async (id, investorId) => {
  return DashboardService.exportTopPaidInOutProject(id, investorId);
};

function* exportTopPaidInOutProject({ payload }) {
  try {
    const response = yield call(
      exportTopPaidInOutProjectAsync,
      payload.defaultRoleId,
      payload.investorId
    );
    if (response && response.data) {
      yield put(exportTopPaidInOutProjectSuccess(true, ""));
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const fileName =
        "ProjectLists-" + moment().format("YYYY-MMM-DD-HH-mm") + ".xlsx";

      saveAs(blob, fileName);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(exportTopPaidInOutProjectError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(exportTopPaidInOutProjectError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(exportTopPaidInOutProjectError(errorMessage));
    }
  }
}

export function* watchTopRentProjectList() {
  yield takeEvery(GET_TOP_RENT_PROJECT, getTopRentProjectListAc);
}

const getTopRentProjectListAsync = async (params) => {
  const endpoint = "top5PropertyRentProject";
  return DashboardService.getDashboardData(endpoint, params);
};

function* getTopRentProjectListAc({ payload }) {
  try {
    const response = yield call(getTopRentProjectListAsync, payload.params);

    if (response.data.success) {
      yield put(getTopRentProjectSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getTopRentProjectError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getTopRentProjectError(error.response.data.message));
    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getTopRentProjectError(errorMessage));
    }
  }
}

export function* watchSumPaidInOutProjectList() {
  yield takeEvery(GET_SUM_PAID_IN_OUT_PROJECT, getSumPaidInOutProjectAc);
}

const getSumPaidInOutProjectAsync = async (params) => {
  const endpoint = "sumPaidInPaidOutProjectList";
  return DashboardService.getDashboardData(endpoint, params);
};

function* getSumPaidInOutProjectAc({ payload }) {
  try {
    const response = yield call(getSumPaidInOutProjectAsync, payload.params);

    if (response.data.success) {
      yield put(getSumPaidInOutProjectSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getSumPaidInOutProjectError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getSumPaidInOutProjectError(error.response.data.message));
    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getSumPaidInOutProjectError(errorMessage));
    }
  }
}

export function* watchLargestInvestorPerProject() {
  yield takeEvery(
    GET_LARGEST_INVESTOR_PER_PROJECT,
    getLargestInvestorPertProjectAc
  );
}

const getLargestInvestorPertProjectAsync = async (params) => {
  const endpoint = "top5InternalInvestorProject";
  return DashboardService.getDashboardData(endpoint, params);
};

function* getLargestInvestorPertProjectAc({ payload }) {
  try {
    const response = yield call(
      getLargestInvestorPertProjectAsync,
      payload.params
    );

    if (response.data.success) {
      yield put(getLargestInvestorPerProjectSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getLargestInvestorPerProjectError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getLargestInvestorPerProjectError(error.response.data.message));
    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getLargestInvestorPerProjectError(errorMessage));
    }
  }
}

export function* watchTotalDebtPerProject() {
  yield takeEvery(GET_TOTAL_DEBT_PER_PROJECT, getTotalDebtPerProjectAc);
}

const getTotalDebtPerProjectAsync = async (params) => {
  const endpoint = "top5TotalDebtProject";
  return DashboardService.getDashboardData(endpoint, params);
};

function* getTotalDebtPerProjectAc({ payload }) {
  try {
    const response = yield call(getTotalDebtPerProjectAsync, payload.params);

    if (response.data.success) {
      yield put(getTotalDebtPerProjectSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getTotalDebtPerProjectError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getTotalDebtPerProjectError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getTotalDebtPerProjectError(errorMessage));
    }
  }
}

export function* watchGetSqmProject() {
  yield takeEvery(GET_SQM_PROJECT, getSqmProjectAc);
}

const getSqmProjectAsync = async (params) => {
  const endpoint = "top5SQMProject";
  return DashboardService.getDashboardData(endpoint, params);
};

function* getSqmProjectAc({ payload }) {
  try {
    const response = yield call(getSqmProjectAsync, payload.params);

    if (response.data.success) {
      yield put(getSqmProjectSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getSqmProjectError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getSqmProjectError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getSqmProjectError(errorMessage));
    }
  }
}

export function* watchGetAllNews() {
  yield takeEvery(GET_ALL_NEWS, getAllNewsAc);
}

const getAllNewsAsync = async (params) => {
  return TableDataService.getAllData("news_informations", params);
};

function* getAllNewsAc({ payload }) {
  try {
    const response = yield call(getAllNewsAsync, payload.params);
    if (response.data.success) {
      yield put(getAllNewsSuccess(response.data.data, response.data.metadata));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getAllNewsError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getAllNewsError(error.response.data.message));
    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getAllNewsError(errorMessage));
    }
  }
}

export function* watchGetNews() {
  yield takeEvery(GET_NEWS, getNewsAc);
}

const getNewsAsync = async (id) => {
  return DashboardService.getNews(id);
};

function* getNewsAc({ payload }) {
  try {
    const response = yield call(getNewsAsync, payload.id);
    if (response.data.success) {
      yield put(getNewsSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getNewsError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getNewsError(error.response.data.message));
    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getNewsError(errorMessage));
    }
  }
}

export function* watchAddNews() {
  yield takeEvery(ADD_NEWS, addNewsAc);
}

const addNewsAsync = async (data) => {
  return DashboardService.addNews(data);
};

function* addNewsAc({ payload }) {
  try {
    const response = yield call(addNewsAsync, payload.newsData);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(addNewsSuccess(response.data.success, response.data.message));
      yield put(getAllNews({}));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(addNewsError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type="error"
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   { containerId: "default" }
    // );
    // yield put(addNewsError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(addNewsError(errorMessage));
    }
  }
}

export function* watchUpdateNews() {
  yield takeEvery(UPDATE_NEWS, updateNewsAc);
}

const updateNewsAsync = async (data, id) => {
  return DashboardService.updateNews(data, id);
};

function* updateNewsAc({ payload }) {
  try {
    const response = yield call(updateNewsAsync, payload.newsData, payload.id);

    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        updateNewsSuccess(response.data.success, response.data.message)
      );
      yield put(getAllNews({}));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(updateNewsError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type="error"
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   { containerId: "default" }
    // );
    // yield put(updateNewsError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(updateNewsError(errorMessage));
    }
  }
}

export function* watchDeleteNews() {
  yield takeEvery(DELETE_NEWS, deleteNewsAc);
}

const deleteNewsAsync = async (id) => {
  return DashboardService.deleteNews(id);
};

function* deleteNewsAc({ payload }) {
  try {
    const response = yield call(deleteNewsAsync, payload.id);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        deleteNewsSuccess(response.data.success, response.data.message)
      );
      // Fetch updated investor list
      yield put(getAllNews({}));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(deleteNewsError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(deleteNewsError(error.response.data.message));
    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(deleteNewsError(errorMessage));
    }
  }
}
export default function* rootSaga() {
  yield all([
    fork(watchGetDashboardStuffCountData),
    fork(watchPaidInProjectList),
    fork(watchPaidOutProjectList),
    fork(watchTopPaidInOutProjectList),
    fork(watchExportTopPaidInOutProject),
    fork(watchTopRentProjectList),
    fork(watchSumPaidInOutProjectList),
    fork(watchLargestInvestorPerProject),
    fork(watchTotalDebtPerProject),
    fork(watchGetSqmProject),
    fork(watchGetAllNews),
    fork(watchGetNews),
    fork(watchAddNews),
    fork(watchUpdateNews),
    fork(watchDeleteNews),
  ]);
}
