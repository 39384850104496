import React from "react";

const IconCaretUp = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 292.4 164.5">
      <g>
        <path
          d="M5.4,159c3.6,3.6,7.9,5.4,12.8,5.4h255.8c5,0,9.2-1.8,12.8-5.4c3.6-3.6,5.4-7.9,5.4-12.9c0-4.9-1.8-9.2-5.4-12.8L159,5.4
		c-3.6-3.6-7.9-5.4-12.9-5.4s-9.2,1.8-12.8,5.4L5.4,133.3C1.8,137,0,141.2,0,146.2C0,151.1,1.8,155.4,5.4,159z"
        />
      </g>
    </svg>
  );
};

export default IconCaretUp;
