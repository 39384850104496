import React from "react";

const IconFileAttachment = () => {
  return (
    <svg width="22" height="22" viewBox="-63 0 479 480">
      <path d="m352.394531 135.519531c-.042969-.78125-.203125-1.546875-.480469-2.28125-.082031-.273437-.183593-.539062-.300781-.796875-.421875-.902344-1.007812-1.71875-1.730469-2.402344l-88-80c-.675781-.59375-1.445312-1.070312-2.277343-1.40625-.242188-.105468-.488281-.152343-.738281-.234374-.621094-.195313-1.261719-.3125-1.910157-.351563-.152343-.023437-.304687-.039063-.457031-.046875h-112v-8c-.027344-22.082031-17.917969-39.9726562-40-40h-16c-22.082031.0273438-39.972656 17.917969-40 40v8h-8c-22.082031.027344-39.972656 17.917969-40 40v352c.027344 22.082031 17.917969 39.972656 40 40h272c22.082031-.027344 39.972656-17.917969 40-40v-304c0-.167969-.089844-.3125-.105469-.480469zm-87.894531-61.429687 59.304688 53.910156h-51.304688c-4.417969 0-8-3.582031-8-8zm-200-34.089844c0-13.253906 10.746094-24 24-24h16c13.253906 0 24 10.746094 24 24v96c0 13.253906-10.746094 24-24 24h-16c-13.253906 0-24-10.746094-24-24v-40h8v40c0 8.835938 7.164062 16 16 16h16c8.835938 0 16-7.164062 16-16v-96c0-8.835938-7.164062-16-16-16h-16c-8.835938 0-16 7.164062-16 16v8h-8zm40 8h-16v-8h16zm208 416h-272c-13.253906 0-24-10.746094-24-24v-352c0-13.253906 10.746094-24 24-24h64v72h-16v-48c0-4.417969-3.582031-8-8-8h-24c-4.417969 0-8 3.582031-8 8v48c.027344 22.082031 17.917969 39.972656 40 40h16c22.082031-.027344 39.972656-17.917969 40-40v-72h104v56c0 13.253906 10.746094 24 24 24h64v296c0 13.253906-10.746094 24-24 24zm0 0" />
      <path d="m120.5 224h176c4.417969 0 8-3.582031 8-8s-3.582031-8-8-8h-176c-4.417969 0-8 3.582031-8 8s3.582031 8 8 8zm0 0" />
      <path d="m56.5 272h240c4.417969 0 8-3.582031 8-8s-3.582031-8-8-8h-240c-4.417969 0-8 3.582031-8 8s3.582031 8 8 8zm0 0" />
      <path d="m56.5 320h240c4.417969 0 8-3.582031 8-8s-3.582031-8-8-8h-240c-4.417969 0-8 3.582031-8 8s3.582031 8 8 8zm0 0" />
      <path d="m56.5 368h240c4.417969 0 8-3.582031 8-8s-3.582031-8-8-8h-240c-4.417969 0-8 3.582031-8 8s3.582031 8 8 8zm0 0" />
      <path d="m232.5 400h-176c-4.417969 0-8 3.582031-8 8s3.582031 8 8 8h176c4.417969 0 8-3.582031 8-8s-3.582031-8-8-8zm0 0" />
      <path d="m312.5 400c-4.417969 0-8 3.582031-8 8v16c0 4.417969-3.582031 8-8 8h-16c-4.417969 0-8 3.582031-8 8s3.582031 8 8 8h16c13.253906 0 24-10.746094 24-24v-16c0-4.417969-3.582031-8-8-8zm0 0" />
    </svg>
  );
};

export default IconFileAttachment;
