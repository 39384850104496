import React, { useState, useEffect } from "react";
import AsyncSelect from "react-select/async";
import { useField, useFormikContext } from "formik";

const SelectFieldAsync = ({
  label,
  options,
  callback,
  classes,
  isRequired,
  otherField,
  loading = false,
  myRef,
  ...props
}) => {
  // eslint-disable-next-line no-unused-vars
  const [query, setQuery] = useState();
  const [field, meta, handler] = useField(props);
  const [selectedOption, setSelectedOption] = useState(null);
  const { setFieldValue } = useFormikContext(props);

  const handleChange = (value) => {
    setSelectedOption(value);
    handler.setValue(value?.value || "");
    if (otherField) {
      setFieldValue(otherField, value?.configChoice);
    }
    if (callback) {
      callback(value?.value);
    }
  };
  const handleInputChange = (value) => {
    setQuery(value);
  };

  useEffect(() => {
    field.value
      ? setSelectedOption(
          (options && options?.find((val) => val.value === field.value)) || null
        )
      : setSelectedOption(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, field.value]);

  return loading ? (
    <div className="field-group animate-pulse">
      <label className="w-28 h-6 field-label rounded bg-grey-400"></label>
      <span className="w-full h-[50px] block border-0 rounded bg-grey-400"></span>
    </div>
  ) : (
    <div
      className={`field-group 
    ${classes?.root || ""}
    ${meta.touched && meta.error ? "field-group-error" : ""} 
    `}
    >
      {label && (
        <label htmlFor="" className="field-label">
          {label ? label : null}
          {isRequired && <strong className="text-red-700">*</strong>}
        </label>
      )}
      <AsyncSelect
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
        ref={myRef}
        {...field}
        isClearable
        className="w-full react-select"
        classNamePrefix="react-select"
        defaultOptions={options}
        placeholder={props?.placeholder}
        loadOptions={props.loadSuggestions}
        value={selectedOption}
        onChange={handleChange}
        onInputChange={handleInputChange}
        isDisabled={props.disabled}
        menuPortalTarget={document.body}
        menuPosition="absolute"
        menuPlacement="auto"
        menuShouldBlockScroll={true}
        menuShouldScrollIntoView={false}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      />
      {meta.touched && meta.error ? (
        <span className="block mt-2 text-xs text-right text-red-700">
          {meta.touched && meta.error ? meta.error : null}
        </span>
      ) : null}
    </div>
  );
};

export default SelectFieldAsync;
