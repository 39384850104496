import Req from "../interceptors/token-interceptor";

const InvestorService = (function () {
  function _getAllInvestors() {
    return Req.get(
      `/api/getAllInvestorList?page=1&pageSize=${process.env.REACT_APP_DEFAULT_DATA_LIST_COUNT ? process.env.REACT_APP_DEFAULT_DATA_LIST_COUNT : 100}&sortedBy=asc&orderBy=full_name`
    );
  }

  // function _getAllInvestors(id) {
  //   // return Req.get(`/api/investors`);
  //   return Req.get(
  //     `/api/investors??defaultRoleId=${id}&orderBy=id&page=1&pageSize=2000&sortedBy=desc`
  //   );
  // }
  function _getInvestor(id) {
    return Req.get(`/api/investors/${id}`);
  }
  function _addInvestor(data) {
    return Req.post("/api/investors", data);
  }
  function _editInvestor(data, id) {
    return Req.put(`/api/investors/${id}`, data);
  }
  function _deleteInvestor(id) {
    return Req.delete(`/api/investors/${id}`);
  }
  function _deleteMultipleInvestor(ids) {
    return Req.post("/api/massDelete", { type: "investor", ids: ids });
  }

  function _getInvestorComments(id) {
    return Req.get(`/api/investor/comments?investorId=${id}`);
  }

  function _addInvestorComment(data) {
    return Req.post("/api/investor/comments/", data);
  }
  function _editInvestorComment(data, id) {
    return Req.put(`/api/investor/comments/${id}`, data);
  }
  function _deleteInvestorComment(id) {
    return Req.delete(`/api/investor/comments/${id}`);
  }
  function _searchInvestor(query) {
    return Req.get(`/api/getAllInvestorList?searchData=${query}`);
  }

  return {
    getAllInvestor: _getAllInvestors,
    getInvestor: _getInvestor,
    addInvestor: _addInvestor,
    editInvestor: _editInvestor,
    deleteInvestor: _deleteInvestor,
    deleteMultipleInvestor: _deleteMultipleInvestor,
    getInvestorComments: _getInvestorComments,
    addInvestorComment: _addInvestorComment,
    editInvestorComment: _editInvestorComment,
    deleteInvestorComment: _deleteInvestorComment,
    searchInvestor: _searchInvestor,
  };
})();
export default InvestorService;
