import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import MessageService from "../../services/message.service";
import TableDataService from "../../services/table-data-service1";
import { saveAs } from "file-saver";
import {
  GET_INBOX_LIST,
  GET_SENT_LIST,
  GET_DRAFT_LIST,
  DRAFT_MESSAGE,
  SEND_MESSAGE,
  GET_MESSAGE,
  REPLY_MESSAGE,
  DELETE_MESSAGE,
  DELETE_MULTIPLE_MESSAGE,
  GET_DOCUMENT_BY_PROJECT,
  GET_MALLING_CONTACT_PERSON,
  GET_UNREAD_INBOX_COUNT,
  MARK_MESSAGE_SEEN,
  MARK_DOCUMENT_SEEN,
  DOWNLOAD_MESSAGE_DOCUMENT,
  GET_DOCUMENT_FILES,
  // GET_PROJECT_BY_MESSAGE_TYPE,
} from "../actions";
import {
  getUnreadInboxCount,
  getInboxList,
  getSentList,
  getDraftList,
  getInboxListSuccess,
  getInboxListError,
  getSentListSuccess,
  getSentListError,
  getDraftListSuccess,
  getDraftListError,
  draftMessageSuccess,
  draftMessageError,
  sendMessageSuccess,
  sendMessageError,
  getMessageSuccess,
  getMessageError,
  replyMessageSuccess,
  replyMessageError,
  deleteMessageSuccess,
  deleteMessageError,
  deleteMultipleMessageSuccess,
  deleteMultipleMessageError,
  getDocumentByProjectSuccess,
  getDocumentByProjectError,
  getMallingContactPersonSuccess,
  getMallingContactPersonError,
  getUnreadInboxCountSuccess,
  getUnreadInboxCountError,
  markMessageSeenSuccess,
  markMessageSeenError,
  markDocumentSeenSuccess,
  markDocumentSeenError,
  downloadMessageDocumentSuccess,
  downloadMessageDocumentError,
  getDocumentFilesSuccess,
  // getDocumentFilesError,

  // getProjectByMessageTypeSuccess,
  // getProjectByMessageTypeError,
} from "./action";
import { toast } from "react-toastify";
import { handleMyErrorMessage } from "../../helpers/util";
import ToastElement from "../../components/toast";

export function* watchGetInboxList() {
  yield takeEvery(GET_INBOX_LIST, getInboxLists);
}

const getInboxListAsync = async (param) => {
  return TableDataService.getAllData("messages", param);
};

function* getInboxLists({ payload }) {
  try {
    const response = yield call(getInboxListAsync, payload.inboxParam);

    if (response.data.success) {
      yield put(
        getInboxListSuccess(response.data.data, response.data.metadata)
      );
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getInboxListError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getInboxListError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      toast.error(
        <ToastElement type='error' message={errorMessage} />,
        { containerId: 'default' }
      )
      yield put(getInboxListError(errorMessage))
    }
  }
}

export function* watchGetSentList() {
  yield takeEvery(GET_SENT_LIST, getSentLists);
}

const getSentListAsync = async (param) => {
  return TableDataService.getAllData("messageStatus", param);
};

function* getSentLists({ payload }) {
  try {
    const response = yield call(getSentListAsync, payload.sentParam);
    if (response.data.success) {
      yield put(
        getSentListSuccess(response.data.data.message, response.data.metadata)
      );
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getSentListError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getSentListError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      toast.error(
        <ToastElement type='error' message={errorMessage} />,
        { containerId: 'default' }
      )
      yield put(getSentListError(errorMessage))
    }
  }
}

export function* watchGetDraftList() {
  yield takeEvery(GET_DRAFT_LIST, getDraftLists);
}

const getDraftListAsync = async (param) => {
  return TableDataService.getAllData("messageStatus", param);
};

function* getDraftLists({ payload }) {
  try {
    const response = yield call(getDraftListAsync, payload.draftParam);
    if (response.data.success) {
      yield put(
        getDraftListSuccess(
          response.data.data.message,
          response.data.metadata,
          response.data.data.messageStatusCount
        )
      );
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getDraftListError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getDraftListError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      toast.error(
        <ToastElement type='error' message={errorMessage} />,
        { containerId: 'default' }
      )
      yield put(getDraftListError(errorMessage))
    }
  }
}

export function* watchDraftMessage() {
  yield takeEvery(DRAFT_MESSAGE, draftMessage);
}

const draftMessageAsync = async (id = null, data) => {
  return MessageService.draftMessage(id, data);
};

function* draftMessage({ payload }) {
  try {
    const response = yield call(
      draftMessageAsync,
      payload.messageId,
      payload.messageData
    );
    if (response.data.success) {
      const { defaultRoleId } = payload.messageData;
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        draftMessageSuccess(response.data.success, response.data.message)
      );
      yield put(getDraftList({ status: 0, defaultRoleId }));
      // Fetch updated list
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(draftMessageError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type="error"
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   { containerId: "default" }
    // );
    // yield put(draftMessageError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      toast.error(
        <ToastElement type='error' message={errorMessage} />,
        { containerId: 'default' }
      )
      yield put(draftMessageError(errorMessage))
    }
  }
}

export function* watchSendMessage() {
  yield takeEvery(SEND_MESSAGE, sendMessage);
}

const sendMessageAsync = async (data) => {
  return MessageService.sendMessage(data);
};

function* sendMessage({ payload }) {
  try {
    const response = yield call(sendMessageAsync, payload.messageData);

    if (response.data.success) {
      const { defaultRoleId } = payload.messageData;
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        sendMessageSuccess(response.data.success, response.data.message)
      );
      yield put(getSentList({ status: 1, defaultRoleId }));

      // Fetch updated list
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(sendMessageError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type="error"
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   { containerId: "default" }
    // );
    // yield put(sendMessageError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      toast.error(
        <ToastElement type='error' message={errorMessage} />,
        { containerId: 'default' }
      )
      yield put(sendMessageError(errorMessage))
    }
  }
}

export function* watchGetMessage() {
  yield takeEvery(GET_MESSAGE, getMessage);
}

const getMessageAsync = async (id, roleId) => {
  return MessageService.getMessage(id, roleId);
};

function* getMessage({ payload }) {
  try {
    const response = yield call(
      getMessageAsync,
      payload.messageId,
      payload.defaultRoleId
    );
    if (response.data.success) {
      yield put(getMessageSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getMessageError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getMessageError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      toast.error(
        <ToastElement type='error' message={errorMessage} />,
        { containerId: 'default' }
      )
      yield put(getMessageError(errorMessage))
    }
  }
}

export function* watchReplyMessage() {
  yield takeEvery(REPLY_MESSAGE, replyMessage);
}

const replyMessageAsync = async (data) => {
  return MessageService.replyMessage(data);
};

function* replyMessage({ payload }) {
  try {
    const response = yield call(replyMessageAsync, payload.replyMessageData);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        replyMessageSuccess(response.data.success, response.data.message)
      );
      // Fetch updated list
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(replyMessageError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type="error"
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   { containerId: "default" }
    // );
    // yield put(replyMessageError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      toast.error(
        <ToastElement type='error' message={errorMessage} />,
        { containerId: 'default' }
      )
      yield put(replyMessageError(errorMessage))
    }
  }
}

export function* watchDeleteMessage() {
  yield takeEvery(DELETE_MESSAGE, deleteMessage);
}

const deleteMessageAsync = async (ids) => {
  return MessageService.deleteMessage(ids);
};

function* deleteMessage({ payload }) {
  try {
    const response = yield call(deleteMessageAsync, payload.messageIds);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        deleteMessageSuccess(response.data.success, response.data.message)
      );
      // Fetch updated list
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(deleteMessageError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(deleteMessageError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      toast.error(
        <ToastElement type='error' message={errorMessage} />,
        { containerId: 'default' }
      )
      yield put(deleteMessageError(errorMessage))
    }
  }
}
export function* watchDeleteMultipleMessage() {
  yield takeEvery(DELETE_MULTIPLE_MESSAGE, deleteMultipleMessage);
}

const deleteMultipleMessageAsync = async (ids, type, defaultRoleId) => {
  return MessageService.deleteMultipleMessage(ids, type, defaultRoleId);
};

function* deleteMultipleMessage({ payload }) {
  try {
    const { type, messageIds, defaultRoleId } = payload;
    const response = yield call(
      deleteMultipleMessageAsync,
      messageIds,
      type,
      defaultRoleId
    );
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        deleteMultipleMessageSuccess(
          response.data.success,
          response.data.message
        )
      );
      if (type === "inbox") {
        yield put(getInboxList({ defaultRoleId }));
        yield put(getUnreadInboxCount(defaultRoleId));
      } else if (type === "sent") {
        yield put(getSentList({ status: 1, defaultRoleId }));
      } else {
        yield put(getDraftList({ status: 0, defaultRoleId }));
      }
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(deleteMultipleMessageError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(deleteMultipleMessageError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      toast.error(
        <ToastElement type='error' message={errorMessage} />,
        { containerId: 'default' }
      )
      yield put(deleteMultipleMessageError(errorMessage))
    }
  }
}

export function* watchGetDocumentByProject() {
  yield takeEvery(GET_DOCUMENT_BY_PROJECT, getDocumentByProject);
}

const getDocumentByProjectAsync = async (id) => {
  return MessageService.getDocumentsByProject(id);
};

function* getDocumentByProject({ payload }) {
  try {
    const response = yield call(getDocumentByProjectAsync, payload.projectId);
    if (response.data.success) {
      yield put(getDocumentByProjectSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getDocumentByProjectError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getDocumentByProjectError(error.response.data.message));
    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      toast.error(
        <ToastElement type='error' message={errorMessage} />,
        { containerId: 'default' }
      )
      yield put(getDocumentByProjectError(errorMessage))
    }
  }
}

export function* watchGetMallingContactPerson() {
  yield takeEvery(GET_MALLING_CONTACT_PERSON, getMallingContactPersons);
}

const getMallingContactPersonAsync = async () => {
  return MessageService.getMallingContactPerson();
};

function* getMallingContactPersons() {
  try {
    const response = yield call(getMallingContactPersonAsync);
    if (response.data.success) {
      yield put(getMallingContactPersonSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getMallingContactPersonError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getMallingContactPersonError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      toast.error(
        <ToastElement type='error' message={errorMessage} />,
        { containerId: 'default' }
      )
      yield put(getMallingContactPersonError(errorMessage))
    }
  }
}

export function* watchGetUnreadInboxCount() {
  yield takeEvery(GET_UNREAD_INBOX_COUNT, getUnreadInboxCounts);
}

const getUnreadInboxCountAsync = async (id) => {
  return MessageService.getUnreadInboxMessage(id);
};

function* getUnreadInboxCounts({ payload }) {
  try {
    const response = yield call(
      getUnreadInboxCountAsync,
      payload.defaultRoleId
    );

    if (response.data.success) {
      yield put(getUnreadInboxCountSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getUnreadInboxCountError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getUnreadInboxCountError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      toast.error(
        <ToastElement type='error' message={errorMessage} />,
        { containerId: 'default' }
      )
      yield put(getUnreadInboxCountError(errorMessage))
    }
  }
}

export function* watchMarkMessageSeen() {
  yield takeEvery(MARK_MESSAGE_SEEN, markMessageAsSeen);
}

const markMessageAsSeenAsync = async (messageId, data) => {
  return MessageService.markMessageSeen(messageId, data);
};

function* markMessageAsSeen({ payload }) {
  try {
    const response = yield call(
      markMessageAsSeenAsync,
      payload.messageId,
      payload.data
    );
    if (response.data.success) {
      // const { defaultRoleId } = payload.data;
      yield put(markMessageSeenSuccess({}));
      // yield put(getUnreadInboxCount(defaultRoleId));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(markMessageSeenError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(markMessageSeenError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      toast.error(
        <ToastElement type='error' message={errorMessage} />,
        { containerId: 'default' }
      )
      yield put(markMessageSeenError(errorMessage))
    }
  }
}
export function* watchMarkDocumentSeen() {
  yield takeEvery(MARK_DOCUMENT_SEEN, markDocumentAsSeen);
}

const markDocumentAsSeenAsync = async (messageId, data) => {
  return MessageService.markDocumentSeen(messageId, data);
};

function* markDocumentAsSeen({ payload }) {
  try {
    const response = yield call(
      markDocumentAsSeenAsync,
      payload.messageId,
      payload.data
    );
    if (response.data.success) {
      yield put(markDocumentSeenSuccess({}));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(markDocumentSeenError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(markDocumentSeenError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      toast.error(
        <ToastElement type='error' message={errorMessage} />,
        { containerId: 'default' }
      )
      yield put(markDocumentSeenError(errorMessage))
    }
  }
}

export function* watchDownloadMessageDocument() {
  yield takeEvery(DOWNLOAD_MESSAGE_DOCUMENT, downloadMessageDocument);
}

const downloadMessageDocumentAsync = async (id) => {
  return MessageService.downloadMessageDocument(id);
};

function* downloadMessageDocument({ payload }) {
  try {
    const response = yield call(
      downloadMessageDocumentAsync,
      payload.document?.id
    );
    if (response && response.data) {
      yield put(downloadMessageDocumentSuccess(true, ""));
      const blob = new Blob([response.data], {
        type: payload.document?.mimeType,
      });
      saveAs(blob, payload.document?.name);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(downloadMessageDocumentError(response.data.message));
    }
  } catch (error) {
    // let message = "";
    // if (error.response.status === 500) {
    //   message = error.response.statusText;
    // }
    // toast.error(<ToastElement type="error" message={message} />, {
    //   containerId: "default",
    // });
    // yield put(downloadMessageDocumentError(message));

    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      toast.error(
        <ToastElement type='error' message={errorMessage} />,
        { containerId: 'default' }
      )
      yield put(downloadMessageDocumentError(errorMessage))
    }
  }
}

export function* watchGetDocumentFiles() {
  yield takeEvery(GET_DOCUMENT_FILES, getDocumentFile);
}

const getDocumentFilesAsync = async (msgId, investorId) => {
  return MessageService.getMessageDocuments(msgId, investorId);
};

function* getDocumentFile({ payload }) {
  try {
    const response = yield call(
      getDocumentFilesAsync,
      payload.messageId,
      payload.investorId
    );
    if (response.data.success) {
      yield put(getDocumentFilesSuccess(response.data.data));
    } else {
      // toast.error(
      //   <ToastElement type="error" message={response.data.message} />,
      //   { containerId: "default" }
      // );
      // yield put(getDocumentFilesError(response.data.message));
      payload.history.push("/404");
    }
  } catch (error) {
    payload.history.push("/404");
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getDocumentFilesError(error.response.data.message));
  }
}

// export function* watchGetProjectByMessageType() {
//   yield takeEvery(GET_PROJECT_BY_MESSAGE_TYPE, getMessageByProjectType);
// }

// const getProjectByMessageTypeAsync = async (type) => {
//   return MessageService.getProjectByMessageType(type);
// };

// function* getMessageByProjectType({ payload }) {
//   try {
//     const response = yield call(getProjectByMessageTypeAsync, payload.type);
//     if (response.data.success) {
//       yield put(getProjectByMessageTypeSuccess(response.data.data));
//     } else {
//       toast.error(
//         <ToastElement type="error" message={response.data.message} />,
//         { containerId: "default" }
//       );
//       yield put(getProjectByMessageTypeError(response.data.message));
//     }
//   } catch (error) {
//     toast.error(
//       <ToastElement type="error" message={error.response.data.message} />,
//       { containerId: "default" }
//     );
//     yield put(getProjectByMessageTypeError(error.response.data.message));
//   }
// }

export default function* rootSaga() {
  yield all([
    fork(watchGetInboxList),
    fork(watchGetSentList),
    fork(watchGetDraftList),
    fork(watchDraftMessage),
    fork(watchSendMessage),
    fork(watchGetMessage),
    fork(watchReplyMessage),
    fork(watchDeleteMessage),
    fork(watchGetDocumentByProject),
    fork(watchDeleteMultipleMessage),
    fork(watchGetMallingContactPerson),
    fork(watchGetUnreadInboxCount),
    fork(watchMarkMessageSeen),
    fork(watchDownloadMessageDocument),
    fork(watchGetDocumentFiles),
    fork(watchMarkDocumentSeen),
    // fork(watchGetProjectByMessageType),
  ]);
}
