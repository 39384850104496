import React from 'react'

const IconAnimLogo = (props) => {
  return (
    <svg
      viewBox='0 0 150.964 45.424'
      className={props.className ? props.className : ''}
    >
      <defs>
        <linearGradient id='logo-gradient' x1='50%' y1='0%' x2='50%' y2='100%'>
          <stop offset='0%' stopColor='#695738'>
            <animate
              attributeName='stop-color'
              values='#695738; #ECE8E1; #695738'
              dur='4s'
              repeatCount='indefinite'
            ></animate>
          </stop>

          <stop offset='100%' stopColor='#ECE8E1'>
            <animate
              attributeName='stop-color'
              values='#ECE8E1; #695738; #ECE8E1'
              dur='4s'
              repeatCount='indefinite'
            ></animate>
          </stop>
        </linearGradient>
      </defs>

      <g
        id='Group_2017'
        dataname='Group 2017'
        transform='translate(-212.421 -129.922)'
        className='loader-text'
      >
        <g
          id='Group_2015'
          dataname='Group 2015'
          transform='translate(212.421 165.346)'
        >
          <g className='text-focus-in'>
            <path
              id='Path_9268'
              dataname='Path 9268'
              d='M455.169,306.66c-.89.878-.83,1.905-.124,2.41.785.56,2.3-.019,2.958-1.382a1.91,1.91,0,0,0,.174-1.762h-1.812s.411-.273.411-1.188a.92.92,0,0,0-1.831.148c0,1.382,4.06,3.892,4.882,4.365s1.924.187,2.03-.168c1.1,1.32-1.3,1.87-2.871.809a21.165,21.165,0,0,1-4.509-4.034,1.543,1.543,0,0,1,1.352-2.448c1.723,0,1.893,1.189,1.376,2.086,3.045,0,3.96-.231,4.321-1.4s-.834-1.046-.8-1.059c1.32-.728,1.8.143,1.569,1.208s-2.16,1.775-3.468,1.75a3.612,3.612,0,0,1-2.167,3.512c-1.482.791-2.733.189-2.939-1.025s1.544-2.207,1.544-2.207Z'
              transform='translate(-402.625 -301.599)'
              fill='#030504'
            />
            <text
              id='MALLING_'
              dataname='MALLING  '
              transform='translate(0 8)'
              fill='#030504'
              fontSize='9'
              fontFamily='Helvetica'
              letterSpacing='0.091em'
            >
              MALLING
            </text>

            <text
              id='CO_INVESTMENTS'
              dataname='CO INVESTMENTS'
              transform='translate(65.964 8)'
              fill='#030504'
              fontSize='9'
              fontFamily='Helvetica'
              letterSpacing='0.091em'
            >
              <tspan x='0' y='0'>
                CO
              </tspan>
            </text>
          </g>
          <text
            transform='translate(84.964 8)'
            fill='#030504'
            fontSize='9'
            fontFamily='Helvetica'
            letterSpacing='0.091em'
          >
            <tspan
              y='0'
              fill='#6f5b36'
              letterSpacing='0.062em'
              className='tracking-in-expand'
            >
              {' '}
              INVESTMENTS
            </tspan>
          </text>
        </g>

        {/* Logo */}
        <g
          id='Group_2016'
          dataname='Group 2016'
          transform='translate(269.977 129.922)'
        >
          <path
            id='Path_9269'
            dataname='Path 9269'
            d='M518.851,147.231c-1.807.958-12.592,9.011-12.76,9.136,4.5-9.2,2.261-12.343-3.3-11.4,5.909-10.676-5.183-14.272-6.852-14.784a8.711,8.711,0,0,0-5.734.506c-1.12.747-1.077,1.253-1.077,1.626s.463.088,2.987-.33,6.876-.351,9.007,2.549,3.361,6.3-.4,11c-11.488,5.579-14.716,9.972-15.727,11.686s-.9,3.185,0,4.04,3.008.836,8.193-4.173a52.431,52.431,0,0,0,8.083-9.95c4.942-1.669,5.581.439,4.636,3.777a20.753,20.753,0,0,1-3.908,7.2.487.487,0,0,0,.17.769c1.159.51,2.039.23,3.562-.937,1.544-1.184,6.421-4.371,7.676-5.189a.1.1,0,0,1,.14.127c-2.845,5.829-1.445,6.421-.129,6.27a9.189,9.189,0,0,0,4.263-1.867c1.548-1.109,1.97-1.387,1.8-1.714-.153-.295-.484.045-.484.045-2.657,2.438-5.93,3.471-5.316,1.273s4-6.105,5.932-7.8S520.676,146.264,518.851,147.231Zm-32.674,12.8c-1.352-.581,1.186-4.515,4.942-7.183a42.249,42.249,0,0,1,7.181-4.117C491.383,157.687,487.484,160.586,486.177,160.026Z'
            transform='translate(-484.285 -129.922)'
            fill='url(#logo-gradient)'
          />
        </g>
      </g>
    </svg>
  )
}

export default IconAnimLogo
