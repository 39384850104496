import {
  GET_INBOX_LIST,
  GET_INBOX_LIST_SUCCESS,
  GET_INBOX_LIST_ERROR,
  GET_SENT_LIST,
  GET_SENT_LIST_SUCCESS,
  GET_SENT_LIST_ERROR,
  GET_DRAFT_LIST,
  GET_DRAFT_LIST_SUCCESS,
  GET_DRAFT_LIST_ERROR,
  DRAFT_MESSAGE,
  DRAFT_MESSAGE_SUCCESS,
  DRAFT_MESSAGE_ERROR,
  SEND_MESSAGE,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_ERROR,
  GET_MESSAGE,
  GET_MESSAGE_SUCCESS,
  GET_MESSAGE_ERROR,
  REPLY_MESSAGE,
  REPLY_MESSAGE_SUCCESS,
  REPLY_MESSAGE_ERROR,
  DELETE_MESSAGE,
  DELETE_MESSAGE_SUCCESS,
  DELETE_MESSAGE_ERROR,
  DELETE_MULTIPLE_MESSAGE,
  DELETE_MULTIPLE_MESSAGE_SUCCESS,
  DELETE_MULTIPLE_MESSAGE_ERROR,
  GET_DOCUMENT_BY_PROJECT,
  GET_DOCUMENT_BY_PROJECT_SUCCESS,
  GET_DOCUMENT_BY_PROJECT_ERROR,
  GET_MALLING_CONTACT_PERSON,
  GET_MALLING_CONTACT_PERSON_SUCCESS,
  GET_MALLING_CONTACT_PERSON_ERROR,
  SET_FORWARD_MESSAGE,
  CLEAR_FORWARD_MESSAGE,
  RESET_MESSAGE,
  GET_UNREAD_INBOX_COUNT,
  GET_UNREAD_INBOX_COUNT_ERROR,
  GET_UNREAD_INBOX_COUNT_SUCCESS,
  DOWNLOAD_MESSAGE_DOCUMENT,
  DOWNLOAD_MESSAGE_DOCUMENT_SUCCESS,
  DOWNLOAD_MESSAGE_DOCUMENT_ERROR,
  GET_DOCUMENT_FILES,
  GET_DOCUMENT_FILES_SUCCESS,
  GET_DOCUMENT_FILES_ERROR,
  GET_NEW_MESSAGE,
  MARK_MESSAGE_SEEN,
  MARK_MESSAGE_SEEN_SUCCESS,
  MARK_MESSAGE_SEEN_ERROR,
  RESET_MESSAGE_DATA,
  // GET_PROJECT_BY_MESSAGE_TYPE,
  // GET_PROJECT_BY_MESSAGE_TYPE_SUCCESS,
  // GET_PROJECT_BY_MESSAGE_TYPE_ERROR,
} from "../actions";

const INIT_STATE = {
  inboxParam: null,
  sentParam: null,
  draftParam: null,
  inboxList: null,
  sentList: null,
  draftList: null,
  messageData: null,
  replyMessageData: null,
  messageId: null,
  messageIds: null,
  success: false,
  message: null,
  loading: false,
  loading1: false,
  loading2: false,
  loading3: false,
  inboxLoading: false,
  sentLoading: false,
  draftLoading: false,
  draftButtonLoading: false,
  error: null,
  // projects: null,
  documents: null,
  inboxMetaData: null,
  sentMetaData: null,
  draftMetaData: null,
  draftMessageCount: 0,
  inboxMessageCount: 0,
  contactPersons: null,
  forwardMessageData: null,
  inboxCountLoading: false,
  documentFiles: [],
  newMessage: null,
};

const messageReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INBOX_LIST:
      return {
        ...state,
        inboxLoading: true,
        error: null,
      };
    case GET_INBOX_LIST_SUCCESS:
      return {
        ...state,
        inboxLoading: false,
        inboxList: action.payload.inboxList,
        inboxMetaData: action.payload.metaData,
        error: null,
      };
    case GET_INBOX_LIST_ERROR:
      return {
        ...state,
        inboxLoading: false,
        inboxList: null,
        error: action.payload,
      };
    case GET_SENT_LIST:
      return {
        ...state,
        sentLoading: true,
        error: null,
      };
    case GET_SENT_LIST_SUCCESS:
      return {
        ...state,
        sentLoading: false,
        sentList: action.payload.sentList,
        sentMetaData: action.payload.metaData,
        error: null,
      };
    case GET_SENT_LIST_ERROR:
      return {
        ...state,
        sentLoading: false,
        sentList: null,
        error: action.payload,
      };
    case GET_DRAFT_LIST:
      return {
        ...state,
        draftLoading: true,
        error: null,
      };
    case GET_DRAFT_LIST_SUCCESS:
      return {
        ...state,
        draftLoading: false,
        draftList: action.payload.draftList,
        draftMetaData: action.payload.metaData,
        draftMessageCount: action.payload.messageCount,
        error: null,
      };
    case GET_DRAFT_LIST_ERROR:
      return {
        ...state,
        draftLoading: false,
        draftList: null,
        error: action.payload,
      };
    case DRAFT_MESSAGE:
      return { ...state, draftButtonLoading: true, error: null };
    case DRAFT_MESSAGE_SUCCESS:
      return {
        ...state,
        draftButtonLoading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DRAFT_MESSAGE_ERROR:
      return {
        ...state,
        draftButtonLoading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case SEND_MESSAGE:
      return { ...state, loading1: true, error: null };
    case SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case SEND_MESSAGE_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case GET_MESSAGE:
      return { ...state, loading2: true, error: null };
    case GET_MESSAGE_SUCCESS:
      return {
        ...state,
        loading2: false,
        messageData: action.payload,
        error: null,
      };
    case GET_MESSAGE_ERROR:
      return {
        ...state,
        messageData: null,
        loading2: false,
        error: action.payload,
      };
    case REPLY_MESSAGE:
      return { ...state, loading1: true, error: null };
    case REPLY_MESSAGE_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case REPLY_MESSAGE_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DELETE_MESSAGE:
      return { ...state, loading2: true, error: null };
    case DELETE_MESSAGE_SUCCESS:
      return {
        ...state,
        loading2: false,
        success: action.payload.success1,
        message: action.payload.message,
        error: null,
      };
    case DELETE_MESSAGE_ERROR:
      return {
        ...state,
        loading2: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DELETE_MULTIPLE_MESSAGE:
      return { ...state, loading2: true, error: null };
    case DELETE_MULTIPLE_MESSAGE_SUCCESS:
      return {
        ...state,
        loading2: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_MULTIPLE_MESSAGE_ERROR:
      return {
        ...state,
        loading2: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case GET_DOCUMENT_BY_PROJECT:
      return {
        ...state,
        loading2: true,
        error: null,
      };
    case GET_DOCUMENT_BY_PROJECT_SUCCESS:
      return {
        ...state,
        loading2: false,
        documents: action.payload,
        error: null,
      };
    case GET_DOCUMENT_BY_PROJECT_ERROR:
      return {
        ...state,
        loading2: false,
        documents: null,
        error: action.payload,
      };
    case GET_MALLING_CONTACT_PERSON:
      return {
        ...state,
        error: null,
      };
    case GET_MALLING_CONTACT_PERSON_SUCCESS:
      return {
        ...state,
        contactPersons: action.payload,
        error: null,
      };
    case GET_MALLING_CONTACT_PERSON_ERROR:
      return {
        ...state,
        contactPersons: null,
        error: action.payload,
      };
    case SET_FORWARD_MESSAGE:
      return {
        ...state,
        forwardMessageData: action.payload,
      };
    case CLEAR_FORWARD_MESSAGE:
      return {
        ...state,
        forwardMessageData: null,
      };
    case GET_UNREAD_INBOX_COUNT:
      return {
        ...state,
        inboxCountLoading: true,
        error: null,
      };
    case GET_UNREAD_INBOX_COUNT_SUCCESS:
      return {
        ...state,
        inboxCountLoading: false,
        inboxMessageCount: action.payload,
        error: null,
      };
    case GET_UNREAD_INBOX_COUNT_ERROR:
      return {
        ...state,
        inboxCountLoading: false,
        inboxMessageCount: null,
        error: action.payload,
      };
    case DOWNLOAD_MESSAGE_DOCUMENT:
      return {
        ...state,
        loading1: true,
        error: null,
      };
    case DOWNLOAD_MESSAGE_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DOWNLOAD_MESSAGE_DOCUMENT_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case GET_DOCUMENT_FILES:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case GET_DOCUMENT_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        documentFiles: action.payload,
        error: null,
      };
    case GET_DOCUMENT_FILES_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        documentFiles: [],
        error: action.payload,
      };

    case GET_NEW_MESSAGE:
      return {
        ...state,
        inboxCountLoading: true,
        // inboxMessageCount: state.inboxMessageCount + 1, // old code
        // newMessage: action.payload, // old code
        inboxMessageCount: action.payload.inboxCount,
        newMessage: action.payload.newMessage,
      };
    case MARK_MESSAGE_SEEN:
      return {
        ...state,
        inboxCountLoading: true,
        error: null,
      };
    case MARK_MESSAGE_SEEN_SUCCESS:
      return {
        ...state,
        inboxCountLoading: false,
        inboxMessageCount: state.inboxMessageCount - 1,
        error: null,
      };
    case MARK_MESSAGE_SEEN_ERROR:
      return {
        ...state,
        inboxCountLoading: false,
        error: action.payload,
      };
    // case GET_PROJECT_BY_MESSAGE_TYPE:
    //   return {
    //     ...state,
    //     loading3: true,
    //     error: null,
    //   };
    // case GET_PROJECT_BY_MESSAGE_TYPE_SUCCESS:
    //   return {
    //     ...state,
    //     loading3: false,
    //     projects: action.payload,
    //     error: null,
    //   };
    // case GET_PROJECT_BY_MESSAGE_TYPE_ERROR:
    //   return {
    //     ...state,
    //     loading3: false,
    //     projects: null,
    //     error: action.payload,
    //   };
    case RESET_MESSAGE:
      return {
        ...state,
        success: false,
        message: null,
        loading: false,
        loading1: false,
        loading2: false,
        draftLoading: false,
        inboxLoading: false,
        sentLoading: false,
        draftButtonLoading: false,
        error: null,
        newMessage: null,
        inboxCountLoading: false,
      };
    case RESET_MESSAGE_DATA:
      return {
        ...state,
        messageData: null,
      };

    default:
      return { ...state };
  }
};
export default messageReducer;
