import React from "react";
const IconBarChart = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 24.815 22.83">
      <g id="Group_3204" dataname="Group 3204" transform="translate(0.5 0.5)">
        <path
          id="Path_9247"
          dataname="Path 9247"
          d="M8,10v7.966"
          transform="translate(-1.244 -1.726)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_9248"
          dataname="Path 9248"
          d="M12,12v5.311"
          transform="translate(0.067 -1.071)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_9249"
          dataname="Path 9249"
          d="M16,8V18.622"
          transform="translate(1.377 -2.382)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <rect
          id="Rectangle_1607"
          dataname="Rectangle 1607"
          width="23.815"
          height="21.83"
          rx="2"
          transform="translate(0 0)"
          fill="none"
          stroke="#000"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};
export default IconBarChart;
