import {
  SET_PAIDINOUT_FUND,
  GET_ALL_PAIDINOUT_FUND,
  GET_ALL_PAIDINOUT_FUND_SUCCESS,
  GET_ALL_PAIDINOUT_FUND_ERROR,
  GET_CAPITAL_CALL_CREDIT_FUND,
  GET_CAPITAL_CALL_CREDIT_FUND_SUCCESS,
  GET_CAPITAL_CALL_CREDIT_FUND_ERROR,
  GET_CAPITAL_CALL_DEBIT_FUND,
  GET_CAPITAL_CALL_DEBIT_FUND_SUCCESS,
  GET_CAPITAL_CALL_DEBIT_FUND_ERROR,
  ADD_PAIDINOUT_FUND,
  ADD_PAIDINOUT_FUND_SUCCESS,
  ADD_PAIDINOUT_FUND_ERROR,
  GET_PAIDINOUT,
  GET_PAIDINOUT_SUCCESS,
  SET_EACH_PROPERTYFUND,
  GET_PAIDINOUT_ERROR,
  EDIT_PAIDINOUT_FUND,
  EDIT_PAIDINOUT_FUND_SUCCESS,
  EDIT_PAIDINOUT_FUND_ERROR,
  DELETE_PAIDINOUT_FUND,
  DELETE_PAIDINOUT_FUND_SUCCESS,
  DELETE_PAIDINOUT_FUND_ERROR,
  EXPORT_PAIDINOUT_FUND,
  EXPORT_PAIDINOUT_FUND_SUCCESS,
  EXPORT_PAIDINOUT_FUND_ERROR,
  RESET_PAIDINOUT,
  UPDATE_CAPITAL_CALL,
  UPDATE_CAPITAL_CALL_ERROR,
  UPDATE_CAPITAL_CALL_SUCCESS,
} from "../actions";

const INIT_STATE = {
  rowIndex: null,
  columnId: null,
  value: null,
  param: null,
  capitalParam: null,
  paidInOutList: null,
  capitalCall: null,
  paidInOutData: null,
  paidInOutId: null,
  projectId: null,
  success: false,
  success1: false,
  message: null,
  loading: false,
  loading1: false,
  loading2: false,
  loading3: false,
  error: null,
  paidInCapitalCall: null,
  paidOutCapitalCall: null,
  eachPropertyFund: false,
};

function _setPaidInOutList(paidInOutList, rowIndex, columnId, value) {
  paidInOutList?.data?.project?.forEach(function (item, index) {
    if (index === rowIndex) this[index][columnId] = value;
  }, paidInOutList?.data?.project);
  return paidInOutList;
}

const paidInOutReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_PAIDINOUT_FUND:
      return {
        ...state,
        paidInOutList: _setPaidInOutList(
          state.paidInOutList,
          action.payload.rowIndex,
          action.payload.columnId,
          action.payload.value
        ),
      };
    case GET_ALL_PAIDINOUT_FUND:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ALL_PAIDINOUT_FUND_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        paidInOutList: action.payload.paidInOutList,
        error: null,
      };
    case GET_ALL_PAIDINOUT_FUND_ERROR:
      return {
        ...state,
        loading: false,
        paidInOutList: null,
        error: action.payload,
      };
    case GET_CAPITAL_CALL_CREDIT_FUND:
      return {
        ...state,
        error: null,
      };
    case GET_CAPITAL_CALL_CREDIT_FUND_SUCCESS:
      return {
        ...state,
        paidInCapitalCall: action.payload,
        error: null,
      };
    case GET_CAPITAL_CALL_CREDIT_FUND_ERROR:
      return {
        ...state,
        paidInCapitalCall: null,
        error: action.payload,
      };
    case GET_CAPITAL_CALL_DEBIT_FUND:
      return {
        ...state,
        error: null,
      };
    case GET_CAPITAL_CALL_DEBIT_FUND_SUCCESS:
      return {
        ...state,
        paidOutCapitalCall: action.payload,
        error: null,
      };
    case GET_CAPITAL_CALL_DEBIT_FUND_ERROR:
      return {
        ...state,
        paidOutCapitalCall: null,
        error: action.payload,
      };
    case ADD_PAIDINOUT_FUND:
      return { ...state, loading1: true, error: null };
    case ADD_PAIDINOUT_FUND_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case ADD_PAIDINOUT_FUND_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case GET_PAIDINOUT:
      return { ...state, error: null };
    case GET_PAIDINOUT_SUCCESS:
      return {
        ...state,
        paidInOutData: action.payload,
        error: null,
      };
    case GET_PAIDINOUT_ERROR:
      return {
        ...state,
        paidInOutData: null,
        error: action.payload,
      };
    case EDIT_PAIDINOUT_FUND:
      return { ...state, loading1: true, error: null };
    case EDIT_PAIDINOUT_FUND_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case EDIT_PAIDINOUT_FUND_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DELETE_PAIDINOUT_FUND:
      return { ...state, loading2: true, error: null };
    case DELETE_PAIDINOUT_FUND_SUCCESS:
      return {
        ...state,
        loading2: false,
        success1: action.payload.success1,
        success2: action.payload.success1,
        message: action.payload.message,
        error: null,
      };
    case DELETE_PAIDINOUT_FUND_ERROR:
      return {
        ...state,
        loading2: false,
        success1: false,
        message: null,
        error: action.payload,
      };
    case EXPORT_PAIDINOUT_FUND:
      return { ...state, loading3: true, error: null };
    case EXPORT_PAIDINOUT_FUND_SUCCESS:
      return {
        ...state,
        loading3: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case EXPORT_PAIDINOUT_FUND_ERROR:
      return {
        ...state,
        loading3: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case RESET_PAIDINOUT:
      return {
        ...state,
        loading: false,
        loading1: false,
        loading2: false,
        loading3: false,
        success: false,
        success1: false,
        message: null,
        error: null,
      };

    case UPDATE_CAPITAL_CALL:
      return {
        ...state,
        loading1: true,
        error: null,
      };
    case UPDATE_CAPITAL_CALL_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case UPDATE_CAPITAL_CALL_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };

    case SET_EACH_PROPERTYFUND:
      return {
        ...state,
        eachPropertyFund: action.payload,
      };
    default:
      return { ...state };
  }
};
export default paidInOutReducer;
