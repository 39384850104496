import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import PaidOutService from "../../services/paid-out-service";
import {
  GET_ALL_PAIDOUT,
  ADD_PAIDOUT,
  GET_PAIDOUT,
  EDIT_PAIDOUT,
  DELETE_PAIDOUT,
  EXPORT_PAIDOUT,
  GET_ALL_PAIDOUTINVESTOR,
} from "../actions";
import { getAllPaidInOut } from "src/reduxs/actions";
import {
  getAllPaidOut,
  getAllPaidOutSuccess,
  getAllPaidOutError,
  addPaidOutSuccess,
  addPaidOutError,
  getPaidOutSuccess,
  getPaidOutError,
  editPaidOutSuccess,
  editPaidOutError,
  deletePaidOutSuccess,
  deletePaidOutError,
  exportPaidOutSuccess,
  exportPaidOutError,
  paidOutInvestorListSuccess,
  paidOutInvestorListFail,
} from "./action";
import { toast } from "react-toastify";
import { handleMyErrorMessage } from "../../helpers/util";
import ToastElement from "../../components/toast";
import saveAs from "file-saver";
import moment from "moment";

export function* watchGetAllPaidOut() {
  yield takeEvery(GET_ALL_PAIDOUT, getAllPaidOutAc);
}

const getAllPaidOutAsync = async (param) => {
  return PaidOutService.getAllPaidOut(param);
};

function* getAllPaidOutAc({ payload }) {
  try {
    const response = yield call(getAllPaidOutAsync, payload.param);
    if (response.data.success) {
      yield put(
        getAllPaidOutSuccess(
          response.data.success,
          response.data.data,
          response.data?.meta?.totalPaidOutAmount
        )
      );
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getAllPaidOutError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getAllPaidOutError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getAllPaidOutError(errorMessage));
    }
  }
}

export function* watchAddPaidOut() {
  yield takeEvery(ADD_PAIDOUT, addPaidOut);
}

const addPaidOutAsync = async (data) => {
  return PaidOutService.addPaidOut(data.paidOutData);
};

function* addPaidOut({ payload }) {
  try {
    const response = yield call(addPaidOutAsync, payload);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        addPaidOutSuccess(response.data.success, response.data.message)
      );
      // Fetch updated list

      yield put(
        getAllPaidInOut({
          projectId: payload.projectId,
          eachPropertyFund: payload.eachPropertyFund,
        })
      );
      yield put(
        getAllPaidOut({
          projectId: payload.projectId,
          eachPropertyFund: payload.eachPropertyFund,
        })
      );
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(addPaidOutError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type="error"
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   { containerId: "default" }
    // );
    // yield put(addPaidOutError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(addPaidOutError(errorMessage));
    }
  }
}

export function* watchGetPaidOut() {
  yield takeEvery(GET_PAIDOUT, getPaidOutAc);
}

const getPaidOutAsync = async (id) => {
  return PaidOutService.getPaidOut(id);
};

function* getPaidOutAc({ payload }) {
  try {
    const response = yield call(getPaidOutAsync, payload.paidOutId);
    if (response.data.success) {
      yield put(getPaidOutSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getPaidOutError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getPaidOutError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getPaidOutError(errorMessage));
    }
  }
}

export function* watchEditPaidOut() {
  yield takeEvery(EDIT_PAIDOUT, editPaidOut);
}

const editPaidOutAsync = async (data, id) => {
  return PaidOutService.editPaidOut(data, id);
};

function* editPaidOut({ payload }) {
  try {
    const response = yield call(
      editPaidOutAsync,
      payload.paidOutData,
      payload.paidOutId
    );
    if (response.data.success) {
      yield put(
        editPaidOutSuccess(response.data.success, response.data.message)
      );
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );

      yield put(
        getAllPaidOut({
          projectId: payload.projectId,
        })
      );

      yield put(
        getAllPaidInOut({
          projectId: payload.projectId,
        })
      );
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(editPaidOutError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type="error"
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   { containerId: "default" }
    // );
    // yield put(editPaidOutError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(editPaidOutError(errorMessage));
    }
  }
}

export function* watchDeletePaidOut() {
  yield takeEvery(DELETE_PAIDOUT, deletePaidOut);
}

const deletePaidOutAsync = async (id) => {
  return PaidOutService.deletePaidOut(id);
};

function* deletePaidOut({ payload }) {
  try {
    const response = yield call(
      deletePaidOutAsync,
      payload.paidOutId,
      payload.eachPropertyFund
    );
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        deletePaidOutSuccess(response.data.success, response.data.message)
      );
      // Fetch updated list

      yield put(
        getAllPaidInOut({
          projectId: payload.projectId,
        })
      );
      yield put(
        getAllPaidOut({
          projectId: payload.projectId,
        })
      );
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(deletePaidOutError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(deletePaidOutError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(deletePaidOutError(errorMessage));
    }
  }
}

export function* watchExportPaidOut() {
  yield takeEvery(EXPORT_PAIDOUT, exportPaidOut);
}

const exportPaidOutAsync = async (projectId) => {
  return PaidOutService.exportPaidOut(projectId);
};

function* exportPaidOut({ payload }) {
  try {
    const response = yield call(
      exportPaidOutAsync,
      payload.projectId,
      payload.eachPropertyFund
    );
    if (response && response.data) {
      yield put(exportPaidOutSuccess(true, ""));
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const fileName =
        "PaidOut-" + moment().format("YYYY-MMM-DD-HH-mm") + ".xlsx";
      saveAs(blob, fileName);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(exportPaidOutError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(exportPaidOutError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(exportPaidOutError(errorMessage));
    }
  }
}
export function* watchGetPaidOutInvestor() {
  yield takeEvery(GET_ALL_PAIDOUTINVESTOR, getPaidOutInvestor);
}

const getPaidOutInvestorAsync = async (projectId) => {
  return PaidOutService.paidOutInvestorList(projectId);
};

function* getPaidOutInvestor({ payload }) {
  try {
    const response = yield call(getPaidOutInvestorAsync, payload.projectId);
    if (response && response.data) {
      yield put(
        paidOutInvestorListSuccess(
          response?.data?.success,
          response?.data?.data
        )
      );
    }
  } catch (error) {
    // yield put(paidOutInvestorListFail(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      yield put(paidOutInvestorListFail(errorMessage));
    }
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAllPaidOut),
    fork(watchAddPaidOut),
    fork(watchGetPaidOut),
    fork(watchEditPaidOut),
    fork(watchDeletePaidOut),
    fork(watchExportPaidOut),
    fork(watchGetPaidOutInvestor),
  ]);
}
