import { useField, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getCountryList } from "src/reduxs/actions";

const ContactPhone = ({
  label,
  classes,
  icon,
  iconPosition,
  disableSelectCountry,
  countryName,
  index,
  validateError,
  isRequired,
  ...props
}) => {
  const { values, setFieldValue } = useFormikContext();
  const dispatch = useDispatch();
  const [field, meta] = useField(props);
  const [selectedOption, setSelectedOption] = useState(null);
  const { countryList } = useSelector((state) => state.shared);
  const handleChange = (val) => {
    countryName && setFieldValue(countryName, val.value);
    setSelectedOption(val);
  };
  useEffect(() => {
    if (!countryList) {
      dispatch(getCountryList());
    }
  }, [countryList, dispatch]);

  useEffect(
    () => {
      if (countryList) {
        const matchedCountry = countryList.find(
          (item) => item.id === values.investorContacts[index].countryId
        );
        matchedCountry &&
          setSelectedOption({
            value: matchedCountry.id,
            label: matchedCountry.isdCode,
          });
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [countryList, values.investorContacts[index].countryId]
  );

  return (
    <div
      className={`field-group ${classes?.root || ""} ${
        meta.touched && meta.error ? "field-group-error" : ""
      } `}
    >
      {label && (
        <label htmlFor="" className="field-label">
          {label ? label : null}
          {isRequired && <strong className="text-red-700">*</strong>}
        </label>
      )}

      <div
        className={`field-wrap ${iconPosition === "end" ? "icon-end" : ""} ${
          iconPosition === "start" ? "icon-start" : ""
        }`}
      >
        <div className="absolute top-0 left-0 h-full z-[2]">
          <Select
            onFocus={props.onFocus ? props.onFocus : null}
            onBlur={props.onBlur ? props.onBlur : null}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            className="w-20 phone-country-select"
            classNamePrefix="phone-country-select"
            value={selectedOption}
            onChange={handleChange}
            isDisabled={disableSelectCountry}
            placeholder="ISD"
            options={
              countryList &&
              countryList.map((item) => {
                return { value: item.id, label: item.name, code: item.isdCode };
              })
            }
          />
          {/* <ErrorMessage
                              className
                              name={`investorContacts.${index}.countryId`}
                         /> */}
        </div>

        <input
          className={`field-phone ${classes?.input || ""}`}
          {...field}
          {...props}
        />

        {icon ? (
          <span
            className={`field-icon focus:outline-none ${
              iconPosition === "end" ? "right-5" : "left-5"
            }`}
          >
            {icon}
          </span>
        ) : null}
      </div>

      {meta.touched && meta.error ? (
        <span className="block mt-2 text-xs text-right text-red-700">
          {meta.touched && meta.error ? meta.error : null}
        </span>
      ) : null}
    </div>
  );
};

export default ContactPhone;
