import React from "react";

const IconFileBadge = (props) => {
  return (
    <svg width="22" height="22" viewBox="0 0 30.116 31.581">
      <g id="Group_3132" dataname="Group 3132" transform="translate(0.5 0.5)">
        <path
          id="Rectangle_1666"
          dataname="Rectangle 1666"
          d="M280.573,338.874h14.874a4.737,4.737,0,0,1,4.727,4.736h0a4.735,4.735,0,0,1-4.727,4.737H282.936a2.363,2.363,0,0,1-2.363-2.363h0Z"
          transform="translate(-280.573 -321.498)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <path
          id="Path_10027"
          dataname="Path 10027"
          d="M290.9,339.972H280.573v-1.838a2.077,2.077,0,0,1,2.077-2.077h1.731"
          transform="translate(-280.573 -322.597)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <path
          id="Path_10034"
          dataname="Path 10034"
          d="M283.308,342.29V326.374h10.764"
          transform="translate(-279.506 -326.374)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_10035"
          dataname="Path 10035"
          d="M308.622,333.1v21.236H283.308v-1.307"
          transform="translate(-279.506 -323.752)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_10311"
          dataname="Path 10311"
          d="M289.941,326.374h7.89"
          transform="translate(-276.918 -326.374)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_10312"
          dataname="Path 10312"
          d="M303.82,335.72h-8.2v-9.345Z"
          transform="translate(-274.704 -326.374)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <text
          id="DOC"
          transform="translate(2 23.325)"
          fill="#505152"
          fontSize="6.5"
        >
          <tspan x="0" y="0">
            {props.fileType}
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export default IconFileBadge;
