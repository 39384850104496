import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import NotificationService from "../../services/notification-service";
import TableDataService from "../../services/table-data-service1";

import {
  GET_NOTIFICATION_LIST,
  GET_RECENT_NOTIFICATIONS,
  MARK_NOTIFICATION_AS_READ,
} from "../actions";
import {
  getNotificationListSuccess,
  getNotificationListError,
  getRecentNotificationSuccess,
  getRecentNotificationError,
  markNotificationAsReadSuccess,
  markNotificationAsReadError,
} from "./action";
import { toast } from "react-toastify";
import ToastElement from "../../components/toast";
import { handleMyErrorMessage } from "../../helpers/util";

export function* watchGetNotificationList() {
  yield takeEvery(GET_NOTIFICATION_LIST, getNotificationListAc);
}

const getNotificationListAsync = async (dbParam) => {
  return TableDataService.getAllData("notifications", dbParam);
};

function* getNotificationListAc({ payload }) {
  try {
    const response = yield call(getNotificationListAsync, payload.dbParam);
    if (response.data.success) {
      yield put(
        getNotificationListSuccess(response.data.data, response.data.metadata)
      );
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getNotificationListError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getNotificationListError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      toast.error(
        <ToastElement type='error' message={errorMessage} />,
        { containerId: 'default' }
      )
      yield put(getNotificationListError(errorMessage))
    }
  }
}

export function* watchGetRecentNotification() {
  yield takeEvery(GET_RECENT_NOTIFICATIONS, getRecentNotificationAc);
}

const getRecentNotificationAsync = async (id) => {
  return NotificationService.getRecentNotifications(id);
};

function* getRecentNotificationAc({ payload }) {
  try {
    const response = yield call(getRecentNotificationAsync, payload.roleId);
    if (response.data.success) {
      yield put(getRecentNotificationSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getRecentNotificationError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getRecentNotificationError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      toast.error(
        <ToastElement type='error' message={errorMessage} />,
        { containerId: 'default' }
      )
      yield put(getRecentNotificationError(errorMessage))
    }
  }
}

export function* watchMarkNotificationRead() {
  yield takeEvery(MARK_NOTIFICATION_AS_READ, markNotificationAsReadAc);
}

const markNotificationAsReadAsync = async (notificationId) => {
  return NotificationService.markAsRead(notificationId);
};

function* markNotificationAsReadAc({ payload }) {
  try {
    const response = yield call(
      markNotificationAsReadAsync,
      payload.notificationId
    );
    if (response.data.success) {
      yield put(markNotificationAsReadSuccess(true));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(markNotificationAsReadError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(markNotificationAsReadError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      toast.error(
        <ToastElement type='error' message={errorMessage} />,
        { containerId: 'default' }
      )
      yield put(markNotificationAsReadError(errorMessage))
    }
  }
}

export default function* rootSaga() {
  yield all([fork(watchGetNotificationList)]);
  yield all([fork(watchGetRecentNotification)]);
  yield all([fork(watchMarkNotificationRead)]);
}
