import React, { useEffect, useState } from "react";
import Select from "react-select";
import { components } from "react-select";
import { useField } from "formik";

const MultiSelect = (props) => {
  const { classes, label, isRequired, loading = false, callback } = props;
  const [field, meta, handler] = useField(props);
  const [selectedOption, setSelectedOption] = React.useState();
  const [limitTags] = useState(props.limitTags || 2);

  const handleChange = (value) => {
    setSelectedOption(value[0]);
    if (callback) {
      callback(value || "");
    }
    if (value.length === 1) {
      // props.defaultId && setFieldValue(props.defaultId, value[0].value);

      handler.setValue(value.map((value) => value.value));
    } else {
      handler.setValue(value.map((val) => val.value));
    }

    if (props.selectAllOption && value.length) {
      const allSelected = value.find(
        (option) => option.label === "All" || option.label === "all"
      );
      if (allSelected) {
        const d = props.options.filter(
          (option) => option.label !== allSelected.label
        );
        handler.setValue(d.map((value) => value.value));
      }
    }
  };

  const LimitedChipsContainer = ({ children, hasValue, ...props }) => {
    if (!hasValue) {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }

    const CHIPS_LIMIT = limitTags;
    const [chips, otherChildren] = children;
    const overflowCounter = chips.slice(CHIPS_LIMIT).length;
    const displayChips = chips.slice(
      overflowCounter,
      overflowCounter + CHIPS_LIMIT
    );

    return (
      <components.ValueContainer {...props}>
        {displayChips}

        {overflowCounter > 0 && (
          <span className="flex-shrink-0 py-1 px-2 rounded bg-primary-200 text-topNav text-xs font-medium">
            + {overflowCounter}
          </span>
        )}

        {otherChildren}
      </components.ValueContainer>
    );
  };

  useEffect(() => {
    let val = [];
    if (props.options && props.options.length > 0 && field.value) {
      props.options.forEach((element) => {
        if (field.value.indexOf(element.value) !== -1) {
          val.push(element);
        }
      });
      setSelectedOption(val);
    } else {
      setSelectedOption(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.options, field.value]);

  return loading ? (
    <div className="field-group animate-pulse">
      <label className="w-28 h-6 field-label rounded bg-grey-400"></label>
      <span className="w-full h-[50px] block border-0 rounded bg-grey-400"></span>
    </div>
  ) : (
    <div
      className={`field-group 
      ${classes?.root || ""}
      ${meta.touched && meta.error ? "field-group-error" : ""}
      `}
    >
      {label && (
        <label htmlFor="" className="field-label">
          {label ? label : null}{" "}
          {isRequired && <strong className="text-red-700">*</strong>}
        </label>
      )}

      <Select
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
        isDisabled={props.disabled}
        className="w-full react-select"
        classNamePrefix="react-select"
        name={props.name}
        options={props.options}
        placeholder={props?.placeholder}
        onChange={handleChange}
        // onFocus={console.log('111')}
        value={selectedOption}
        isMulti
        hideSelectedOptions={false}
        components={{ ValueContainer: LimitedChipsContainer }}
        menuPortalTarget={document.body}
        menuPosition="absolute"
        menuPlacement="auto"
        menuShouldBlockScroll={true}
        menuShouldScrollIntoView={false}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      />
      {meta.touched && meta.error ? (
        <span className="block mt-2 text-xs text-right text-red-700">
          {meta.touched && meta.error ? meta.error : null}
        </span>
      ) : null}
    </div>
  );
};
export default MultiSelect;
