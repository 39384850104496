import React, { Component } from "react";
import { connect } from "react-redux";
import {
  verifyResetToken,
  resetPassword,
  resetAuth,
} from "../../../reduxs/actions";
import { Formik, Form } from "formik";
import {
  InputField,
  InputHiddenField,
  InputPasswordField,
} from "../../../components/form";
import * as Yup from "yup";
import { withTranslation } from "react-i18next";
import { IconSpinner } from "../../../components/svg";
import { Link } from "react-router-dom";
import SetLanguage from "src/views/auth/set-lang";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.props.resetAuth();
    // this.props.verifyResetToken(
    //   this.props.match.params.token,
    //   this.props.history
    // );
  }

  onResetPassword = (values) => {
    if (!this.props.loading) {
      this.props.resetPassword(values, this.props.history);
    }
  };

  render() {
    const { t } = this.props;
    this.validationSchema = Yup.object().shape({
      email: Yup.string()
        .email(this.props.t("AUTH.VALIDATION.INVALID_EMAIL"))
        .required(this.props.t("AUTH.VALIDATION.REQUIRED_FIELD")),
      password: Yup.string()
        .required(this.props.t("AUTH.VALIDATION.REQUIRED_FIELD"))
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
          this.props.t("AUTH.VALIDATION.PASSWORD_CRITERIA")
        ),
      passwordConfirmation: Yup.string()
        .required(this.props.t("AUTH.VALIDATION.REQUIRED_FIELD"))
        .oneOf(
          [Yup.ref("password"), null],
          this.props.t("AUTH.VALIDATION.NOT_MATCH")
        ),
    });

    return (
      <div className="auth-wrapper">
        <img
          className="w-52 block xl:hidden my-8 mx-auto"
          src={require("../../../assets/images/Malling-logo-hv.svg").default}
          alt=""
        />

        <div className="auth-wrapper-aside">
          <img width="200px" height="auto"
            src={require("../../../assets/images/Malling-logo-hv.svg").default}
            alt=""
          />

          <h2>{t("AUTH.RESET_PASSWORD.PASSWORD_REQUIREMENT")}</h2>
          <p className="mb-8 !pl-0 text-xl font-light text-white !border-l-0">
            {t("AUTH.RESET_PASSWORD.PASSWORD_MUST_CONTAIN")}
          </p>

          <ul>
            <li>{t("AUTH.RESET_PASSWORD.PASSWORD_CRITERIA_1")}</li>
            <li>{t("AUTH.RESET_PASSWORD.PASSWORD_CRITERIA_2")}</li>
            <li>{t("AUTH.RESET_PASSWORD.PASSWORD_CRITERIA_3")}</li>
            <li>{t("AUTH.RESET_PASSWORD.PASSWORD_CRITERIA_4")}</li>
            <li>{t("AUTH.RESET_PASSWORD.PASSWORD_CRITERIA_5")}</li>
          </ul>

          <footer>© 2021 Malling & CO Investment</footer>
        </div>

        <div className="auth-wrapper-content">
          <header>
            <h4>
              {/* {t("AUTH.GENERAL.FINDING_TROUBLE")}{" "} */}
              <a href="/" className="text-primary-500">
                {t("AUTH.GENERAL.SELECT_LANGUAGE")}
              </a>
            </h4>

            <SetLanguage />
          </header>

          <h1>{t("AUTH.RESET_PASSWORD.TITLE")}</h1>
          <h6>{t("AUTH.RESET_PASSWORD.CREATE_NEW_PASSWORD")}</h6>

          {this.props.error ? (
            <span className="block mb-2 text-red-700">{this.props.error}</span>
          ) : null}

          <Formik
            initialValues={{
              token: this.props.match.params.token,
              email: "",
              password: "",
              passwordConfirmation: "",
            }}
            validationSchema={this.validationSchema}
            onSubmit={this.onResetPassword}
          >
            {(props) => (
              <Form className="w-full xl:w-3/5 2xl:w-2/5">
                <InputHiddenField name="token" />

                <InputField
                  name="email"
                  type="email"
                  label={t("AUTH.INPUT.EMAIL")}
                  placeholder={t("AUTH.INPUT.EMAIL")}
                />
                <InputPasswordField
                  name="password"
                  label={t("AUTH.INPUT.NEW_PASSWORD")}
                  placeholder={t("AUTH.INPUT.NEW_PASSWORD")}
                />
                <InputPasswordField
                  name="passwordConfirmation"
                  label={t("AUTH.INPUT.CONFIRM_PASSWORD")}
                  placeholder={t("AUTH.INPUT.CONFIRM_PASSWORD")}
                />

                <div className="flex mt-12 space-x-5">
                  <button
                    className="inline-flex btn-primary md"
                    type="submit"
                    disabled={this.props.loading}
                  >
                    {t("AUTH.GENERAL.SUBMIT_BUTTON")}
                    {this.props.loading && (
                      <span className="ml-3 -mr-1">
                        <IconSpinner />
                      </span>
                    )}
                  </button>

                  <Link
                    to="/"
                    className="inline-flex btn-primary-outlined md"
                    disabled={this.props.loading}
                  >
                    {t("AUTH.GENERAL.CANCEL_BUTTON")}
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loading1, loading, error } = auth;
  return { loading1, loading, error };
};
export default connect(mapStateToProps, {
  verifyResetToken,
  resetPassword,
  resetAuth,
})(withTranslation()(ResetPassword));
