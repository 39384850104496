export const SET_PAIDOUT_FUND = "SET_PAIDOUT_FUND";
export const GET_ALL_PAIDOUT_FUND = "GET_ALL_PAIDOUT_FUND";
export const GET_ALL_PAIDOUT_FUND_SUCCESS = "GET_ALL_PAIDOUT_FUND_SUCCESS";
export const GET_ALL_PAIDOUT_FUND_ERROR = "GET_ALL_PAIDOUT_FUND_ERROR";
export const ADD_PAIDOUT_FUND = "ADD_PAIDOUT_FUND";
export const ADD_PAIDOUT_FUND_SUCCESS = "ADD_PAIDOUT_FUND_SUCCESS";
export const ADD_PAIDOUT_FUND_ERROR = "ADD_PAIDOUT_FUND_ERROR";
export const GET_PAIDOUT = "GET_PAIDOUT";
export const GET_PAIDOUT_SUCCESS = "GET_PAIDOUT_SUCCESS";
export const GET_PAIDOUT_ERROR = "GET_PAIDOUT_ERROR";
export const EDIT_PAIDOUT_FUND = "EDIT_PAIDOUT_FUND";
export const EDIT_PAIDOUT_FUND_SUCCESS = "EDIT_PAIDOUT_FUND_SUCCESS";
export const EDIT_PAIDOUT_FUND_ERROR = "EDIT_PAIDOUT_FUND_ERROR";
export const DELETE_PAIDOUT_FUND = "DELETE_PAIDOUT_FUND";
export const DELETE_PAIDOUT_FUND_SUCCESS = "DELETE_PAIDOUT_FUND_SUCCESS";
export const DELETE_PAIDOUT_FUND_ERROR = "DELETE_PAIDOUT_FUND_ERROR";
export const EXPORT_PAIDOUT_FUND = "EXPORT_PAIDOUT_FUND";
export const EXPORT_PAIDOUT_FUND_SUCCESS = "EXPORT_PAIDOUT_FUND_SUCCESS";
export const EXPORT_PAIDOUT_FUND_ERROR = "EXPORT_PAIDOUT_FUND_ERROR";
export const RESET_PAIDOUT = "RESET_PAIDOUT";
export const GET_ALL_PAIDOUTINVESTOR_FUND = "GET_ALL_PAIDOUTINVESTOR_FUND";
export const GET_ALL_PAIDOUTINVESTOR_FUND_SUCCESS =
  "GET_ALL_PAIDOUTINVESTOR_FUND_SUCCESS";
export const GET_ALL_PAIDOUTINVESTOR_FUND_FAIL = "GET_ALL_PAIDOUTINVESTOR_FUND_FAIL";
export const UPDATE_PAID_OUT = "UPDATE_PAID_OUT";
export const UPDATE_PAID_OUT_SUCCESS = "UPDATE_PAID_OUT_SUCCESS";
export const UPDATE_PAID_OUT_ERROR = "UPDATE_PAID_OUT_ERROR";

export const setPaidOutFund = (rowIndex, columnId, value) => ({
  type: SET_PAIDOUT_FUND,
  payload: { rowIndex, columnId, value },
});

export const getAllPaidOutFund = (param) => ({
  type: GET_ALL_PAIDOUT_FUND,
  payload: { param },
});

export const getAllPaidOutFundSuccess = (
  success,
  data,
  totalPaidOutAmount
) => ({
  type: GET_ALL_PAIDOUT_FUND_SUCCESS,
  payload: { success, data, totalPaidOutAmount },
});

export const getAllPaidOutFundError = (error) => ({
  type: GET_ALL_PAIDOUT_FUND_ERROR,
  payload: error,
});

export const addPaidOutFund = (paidOutData, projectId) => ({
  type: ADD_PAIDOUT_FUND,
  payload: { paidOutData, projectId },
});

export const addPaidOutFundSuccess = (success, message) => ({
  type: ADD_PAIDOUT_FUND_SUCCESS,
  payload: { success, message },
});

export const addPaidOutFundError = (error) => ({
  type: ADD_PAIDOUT_FUND_ERROR,
  payload: error,
});

export const getPaidOut = (paidOutId) => ({
  type: GET_PAIDOUT,
  payload: { paidOutId },
});

export const getPaidOutSuccess = (paidOutData) => ({
  type: GET_PAIDOUT_SUCCESS,
  payload: paidOutData,
});

export const getPaidOutError = (error) => ({
  type: GET_PAIDOUT_ERROR,
  payload: error,
});

export const editPaidOutFund = (
  paidOutId,
  paidOutData,
  projectId,
  eachPropertyFund
) => ({
  type: EDIT_PAIDOUT_FUND,
  payload: { paidOutId, paidOutData, projectId, eachPropertyFund },
});

export const editPaidOutFundSuccess = (success, message) => ({
  type: EDIT_PAIDOUT_FUND_SUCCESS,
  payload: { success, message },
});

export const editPaidOutFundError = (error) => ({
  type: EDIT_PAIDOUT_FUND_ERROR,
  payload: error,
});

export const deletePaidOutFund = (paidOutId, projectId, eachPropertyFund) => ({
  type: DELETE_PAIDOUT_FUND,
  payload: { paidOutId, projectId, eachPropertyFund },
});

export const deletePaidOutFundSuccess = (success1, message) => ({
  type: DELETE_PAIDOUT_FUND_SUCCESS,
  payload: { success1, message },
});

export const deletePaidOutFundError = (error) => ({
  type: DELETE_PAIDOUT_FUND_ERROR,
  payload: error,
});

export const exportPaidOutFund = (projectId, eachPropertyFund) => ({
  type: EXPORT_PAIDOUT_FUND,
  payload: { projectId, eachPropertyFund },
});

export const exportPaidOutFundSuccess = (success, message) => ({
  type: EXPORT_PAIDOUT_FUND_SUCCESS,
  payload: { success, message },
});

export const exportPaidOutFundError = (error) => ({
  type: EXPORT_PAIDOUT_FUND_ERROR,
  payload: error,
});

export const resetPaidOut = () => ({
  type: RESET_PAIDOUT,
  payload: {},
});
export const getpaidOutInvestorListFund = (projectId) => ({
  type: GET_ALL_PAIDOUTINVESTOR_FUND,
  payload: { projectId },
});
export const paidOutInvestorListFundSuccess = (success, data) => ({
  type: GET_ALL_PAIDOUTINVESTOR_FUND_SUCCESS,
  payload: { success, data },
});
export const paidOutInvestorListFundFail = (error) => ({
  type: GET_ALL_PAIDOUTINVESTOR_FUND_FAIL,
  payload: { error },
});

export const updatePaidOut = (data, projectId, eachPropertyFund, id) => ({
  type: UPDATE_PAID_OUT,
  payload: { data, projectId, eachPropertyFund, id },
});
export const updatePaidOutSuccess = (success, message) => ({
  type: UPDATE_PAID_OUT_SUCCESS,
  payload: { success, message },
});

export const updatePaidOutError = (error) => ({
  type: UPDATE_PAID_OUT_ERROR,
  payload: error,
});
