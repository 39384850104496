/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/views/auth/index`, `src/app/Base`).
 */

import React, { useEffect } from "react";
import { Redirect, Switch, Route, useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "./layouts";
import NotFoundView from "./views/not-found";
import NotAuthorizedView from "./views/not-authorized";
import IconView from "./views/icon";
import BaseView from "./views/app";
import AuthView from "./views/auth";
import LogoutView from "./views/auth/logout";
import { ToastContainer } from "react-toastify";
import { PermissionGuard } from "src/guards";
import LoadDocument from "src/views/app/profile-message/message/documents/load-documents";
import { logout } from "src/reduxs/actions";
import { connect } from "react-redux";
import EchoClient from "src/EchoClient";
import IdleTimer from 'react-idle-timer'

const Routes = (props) => {
  let history = useHistory()

  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );

  useEffect(() => {
    //user logout from the system when role is change by admin
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (currentUser && props.currentUserData) {
      if (
        currentUser?.default_role_id !== props.currentUserData?.defaultRoleId
      ) {
        props.logout(props.history);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentUserData]);

  const handleOnIdle = () => {
    history.push("/logout")
  }

  return (
    <>
      <Switch>
        <Route path="/icon" component={IconView} />
        <Route path="/documents/view" component={LoadDocument} />

        {!isAuthorized ? (
          /*Render auth page when user at `/auth` and not authorized.*/
          <Route>
            <AuthView />
          </Route>
        ) : (
          /*Otherwise redirect to root page (`/`)*/
          <Redirect from="/auth" to="/" />
        )}
        <Route path="/404" component={NotFoundView} />
        <Route path="/403" component={NotAuthorizedView} />
        <Route path="/logout" component={LogoutView} />
        {!isAuthorized ? (
          /*Redirect to `/auth` when user is not authorized*/
          <Redirect to="/auth/login" />
        ) : (
          <Layout>
            <PermissionGuard>
              <EchoClient />
              <BaseView />
              <IdleTimer
                timeout={1000 * 60 * Number(process.env.REACT_APP_IDLE_USER_TIMEOUT)}
                onIdle={handleOnIdle}
                debounce={250}
                crossTab={{
                  emitOnAllTabs: true
                }}
              />
            </PermissionGuard>
          </Layout>
        )}
      </Switch>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        enableMultiContainer
        containerId={"default"}
      />
    </>
  );
};
const mapStateToProps = ({ shared }) => {
  const { currentUserData } = shared;

  return {
    currentUserData,
  };
};

export default connect(mapStateToProps, {
  logout,
})(Routes);
