import React from "react";
import { IconSearch } from "src/components/svg";
import { useTranslation } from "react-i18next";

const SearchField = (props, ref) => {
  const { classes } = props;
  const { t } = useTranslation();
  return (
    <div className={`field-wrap icon-start ${classes?.root || ""}`}>
      <input
        ref={ref}
        type="search"
        name={props?.name}
        value={props.search}
        placeholder={t("COMMON.SEARCH_LABEL")}
        className="field-table"
        onChange={props.onSearch}
      />

      <span className="field-icon left-3 focus:outline-none svg-color-grey">
        <IconSearch />
      </span>
    </div>
  );
};

export default React.forwardRef(SearchField);
