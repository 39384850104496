import Req from "../interceptors/token-interceptor";

const PaidInOutService = (function () {
  function _getAllPaidInOut(param) {
    return Req.get(
      `/api/paidInOut/${param.projectId}?search=${
        param?.search || ""
      }&searchFields=${param?.searchFields || ""}`,
      {
        withCredentials: true,
      }
    );
  }
  function _getCapitalCall(param) {
    return Req.get(
      `/api/getCapitalCall?projectId=${param.projectId}&fromDate=${
        param?.fromDate || ""
      }&toDate=${param?.toDate || ""}&type=${param.type}`
    );
  }
  function _addPaidInOut(data) {
    return Req.post("/api/paidInOut", data);
  }
  function _getPaidInOut(id) {
    return Req.get(`/api/paidInOut/${id}`);
  }
  function _editPaidInOut(data, id) {
    return Req.put(`/api/paidInOut/${id}`, data);
  }
  function _deletePaidInOut(id) {
    return Req.delete(`/api/paidInOut/${id}`);
  }
  function _exportPaidInOut(projectId) {
    return Req.get(`/api/exportPaidInOut/${projectId}`, {
      responseType: "arraybuffer",
    });
  }
  function _getAllPaidInOutFund(param) {
    return Req.get(
      `/api/fundPaidInOut/${param.projectId}?search=${
        param?.search || ""
      }&searchFields=${param?.searchFields || ""}`,
      {
        withCredentials: true,
      }
    );
  }
  function _getCapitalCallFund(param) {
    return Req.get(
      `/api/getCapitalCall?projectId=${param.projectId}&fromDate=${
        param?.fromDate || ""
      }&toDate=${param?.toDate || ""}&type=${param.type}`
    );
  }
  function _addPaidInOutFund(data) {
    return Req.post("/api/fundPaidInOut", data);
  }
  function _getPaidInOutFund(id) {
    return Req.get(`/api/paidInOut/${id}`);
  }
  function _editPaidInOutFund(data, id) {
    return Req.put(`/api/fundPaidInOut/${id}`, data);
  }
  function _deletePaidInOutFund(id) {
    return Req.delete(`/api/fundPaidInOut/${id}`);
  }
  function _exportPaidInOutFund(projectId) {
    return Req.get(`/api/exportPaidInOut/${projectId}`, {
      responseType: "arraybuffer",
    });
  }
  function _setIsChecked(param) {
    return Req.post(`/api/switchProject`, param);
  }
  function _updatePaidInOutFund(data, id) {
    return Req.put(`/api/updateTotalEquity/${id}`, data);
  }

  return {
    getAllPaidInOut: _getAllPaidInOut,
    getCapitalCall: _getCapitalCall,
    addPaidInOut: _addPaidInOut,
    getPaidInOut: _getPaidInOut,
    editPaidInOut: _editPaidInOut,
    deletePaidInOut: _deletePaidInOut,
    exportPaidInOut: _exportPaidInOut,
    getAllPaidInOutFund: _getAllPaidInOutFund,
    getCapitalCallFund: _getCapitalCallFund,
    addPaidInOutFund: _addPaidInOutFund,
    getPaidInOutFund: _getPaidInOutFund,
    editPaidInOutFund: _editPaidInOutFund,
    deletePaidInOutFund: _deletePaidInOutFund,
    exportPaidInOutFund: _exportPaidInOutFund,
    setIsChecked: _setIsChecked,
    updatePaidInOutFund: _updatePaidInOutFund,
  };
})();
export default PaidInOutService;
