import React from "react";
import { useField } from "formik";

const TextareaField = ({
  label,
  classes,
  icon,
  iconPosition,
  rows,
  isRequired,
  loading = false,
  ...props
}) => {
  const [field, meta] = useField(props);

  return loading ? (
    <div className="field-group animate-pulse">
      <label className="w-28 h-6 field-label rounded bg-grey-400"></label>
      <span className="w-full h-[50px] block border-0 rounded bg-grey-400"></span>
    </div>
  ) : (
    <div
      className={`field-group ${classes?.root || ""}
    ${meta.touched && meta.error ? "field-group-error" : ""}`}
    >
      {label && (
        <label htmlFor="" className="field-label">
          {label ? label : null}
          {isRequired && <strong className="text-red-700">*</strong>}
        </label>
      )}

      <div
        className={`field-wrap 
       ${iconPosition === "end" ? "icon-end" : ""} 
       ${iconPosition === "start" ? "icon-start" : ""} `}
      >
        <textarea
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
          className={`field resize-none
          
        ${classes?.input || ""}
        `}
          {...field}
          {...props}
          rows={rows || "3"}
        ></textarea>

        {icon ? (
          <span
            className={`field-icon focus:outline-none ${
              iconPosition === "end" ? "right-5" : "left-5"
            }`}
          >
            {icon}
          </span>
        ) : null}
      </div>

      {meta.touched && meta.error ? (
        <span className="block mt-2 text-xs text-right text-red-700">
          {meta.touched && meta.error ? meta.error : null}
        </span>
      ) : null}
    </div>
  );
};

export default TextareaField;
