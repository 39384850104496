import React from "react";

const IconConfiguration = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 23.055 20.361"
    >
      <g
        id="Group_1872"
        dananame="Group 1872"
        transform="translate(-540.562 -167.989)"
      >
        <g
          id="Group_1857"
          dananame="Group 1857"
          transform="translate(1098.739 91.016)"
        >
          <rect
            id="Rectangle_1588"
            dananame="Rectangle 1588"
            width="3.417"
            height="10.121"
            rx="0.477"
            transform="translate(-557.665 86.702)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.024"
          />
          <path
            id="Path_9231"
            dananame="Path 9231"
            d="M-553.643,88.908l3.478-1.855A1.039,1.039,0,0,1-549.31,87l5.1,1.9a1.04,1.04,0,0,1,.671.861l.1.92"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.024"
          />
          <path
            id="Path_9232"
            dananame="Path 9232"
            d="M-553.643,95.788l8.74-.086a1.4,1.4,0,0,0,.714-.207l8.24-5.968a.755.755,0,0,0,.309-.693l-.181-1.714a.306.306,0,0,0-.453-.234l-8.113,4.531-5.23-.868"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.024"
          />
        </g>
        <path
          id="Path_9234"
          dananame="Path 9234"
          d="M-540.082,82.421v-1.06a.422.422,0,0,0-.422-.422h-.625a.421.421,0,0,1-.389-.262l0-.006a.42.42,0,0,1,.09-.46l.442-.442a.422.422,0,0,0,0-.6l-.751-.75a.422.422,0,0,0-.6,0l-.442.441a.422.422,0,0,1-.46.091l-.005,0a.423.423,0,0,1-.261-.39v-.623a.423.423,0,0,0-.423-.423h-1.062a.422.422,0,0,0-.422.423v.623a.421.421,0,0,1-.262.389l-.006,0a.42.42,0,0,1-.46-.09l-.44-.441a.423.423,0,0,0-.6,0l-.751.75a.422.422,0,0,0,0,.6l.442.442a.42.42,0,0,1,.09.46l0,.006a.421.421,0,0,1-.389.262h-.625a.422.422,0,0,0-.422.422v1.06a.422.422,0,0,0,.422.423h.625a.42.42,0,0,1,.389.261l0,.006a.42.42,0,0,1-.09.46l-.442.442a.423.423,0,0,0,0,.6l.751.75a.423.423,0,0,0,.6,0l.44-.441a.422.422,0,0,1,.46-.091l.006,0a.421.421,0,0,1,.262.389v.625a.422.422,0,0,0,.422.422h1.062a.423.423,0,0,0,.423-.422V85.22a.42.42,0,0,1,.261-.389l.005,0a.42.42,0,0,1,.46.09l.442.442a.422.422,0,0,0,.6,0l.751-.75a.423.423,0,0,0,0-.6l-.442-.442a.42.42,0,0,1-.09-.46l0-.006a.42.42,0,0,1,.389-.261h.625A.422.422,0,0,0-540.082,82.421Zm-2.918-.3a1.475,1.475,0,0,1-.267.638,1.451,1.451,0,0,1-.322.323,1.484,1.484,0,0,1-.64.267,1.741,1.741,0,0,1-.23.016,1.723,1.723,0,0,1-.228-.016,1.484,1.484,0,0,1-.64-.267,1.431,1.431,0,0,1-.322-.323,1.476,1.476,0,0,1-.267-.638,1.588,1.588,0,0,1-.017-.23,1.574,1.574,0,0,1,.017-.229,1.485,1.485,0,0,1,.267-.639,1.406,1.406,0,0,1,.322-.322,1.46,1.46,0,0,1,.64-.267,1.569,1.569,0,0,1,.228-.017,1.586,1.586,0,0,1,.23.017,1.46,1.46,0,0,1,.64.267,1.447,1.447,0,0,1,.322.322,1.484,1.484,0,0,1,.267.639,1.574,1.574,0,0,1,.017.229A1.588,1.588,0,0,1-543,82.121Z"
          transform="translate(1098.739 91.016)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.085"
        />
      </g>
    </svg>
  );
};

export default IconConfiguration;
