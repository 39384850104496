import React, { useState, useEffect } from "react";
import { useField } from "formik";
import DatePicker from "react-datepicker";
import moment from "moment";
import { IconCalendar } from "src/components/svg";

const DateField = ({
  label,
  minDate,
  placeholder,
  showIcon = true,
  icon,
  iconPosition = "end",
  classes,
  isRequired,
  loading = false,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    if (field.value) {
      setSelectedDate(new Date(field.value));
    } else {
      setSelectedDate(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  const handleDateChange = (date) => {
    if (date) {
      setSelectedDate(date);
      helpers.setValue(moment(date).format("YYYY-MM-DD"));
    } else {
      setSelectedDate(null);
      helpers.setValue("");
    }
  };

  return loading ? (
    <div className="field-group animate-pulse">
      <label className="w-28 h-6 field-label rounded bg-grey-400"></label>
      <span className="w-full h-[50px] block border-0 rounded bg-grey-400"></span>
    </div>
  ) : (
    <div
      className={`field-group date-time-picker 
      ${meta.touched && meta.error ? "field-group-error" : ""}
      ${classes?.root || ""}
      `}
    >
      {label ? (
        <label htmlFor="" className="field-label">
          {label}
          {isRequired && <strong className="text-red-700">*</strong>}
        </label>
      ) : null}
      <div
        className={`field-wrap 
       ${iconPosition === "end" ? "icon-end" : ""} 
       ${iconPosition === "start" ? "icon-start" : ""} `}
      >
        <DatePicker
          dateFormat="yyyy-MM-dd"
          showPopperArrow={false}
          popperClassName="date-picker-popover"
          selected={selectedDate}
          isClearable
          onChange={handleDateChange}
          placeholderText={placeholder}
          minDate={minDate ? new Date(minDate) : new Date("1900-01-01")}
          portalId="root-portal"
        />

        {showIcon ? (
          <span
            className={`field-icon focus:outline-none ${
              showIcon && iconPosition === "end" ? "right-5" : "left-5"
            }`}
          >
            {icon || <IconCalendar />}
          </span>
        ) : null}
      </div>

      {meta.touched && meta.error ? (
        <span className="block mt-2 text-xs text-right text-red-700">
          {meta.touched && meta.error ? meta.error : null}
        </span>
      ) : null}
    </div>
  );
};

export default DateField;
