import React from "react";

const IconCheckO = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 67.658 67.649"
    >
      <path
        id="Path_9316"
        dataname="Path 9316"
        d="M-65.49-113.248l6.762,6.762a4.92,4.92,0,0,0,6.957,0l27.351-27.343v0A32.789,32.789,0,0,0-53.206-147.6a32.94,32.94,0,0,0-30.728,30.435A32.826,32.826,0,0,0-51.191-82.011a32.823,32.823,0,0,0,32.824-32.823"
        transform="translate(85.025 148.66)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default IconCheckO;
