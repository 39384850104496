import React from "react";

const IconUndo = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 17.299 13.816">
      <g
        id="Group_2074"
        dataname="Group 2074"
        transform="translate(-1670.138 -298.116)"
      >
        <g id="Group_2075" dataname="Group 2075">
          <path
            id="Path_9289"
            dataname="Path 9289"
            d="M-2931.483-12118.47l-4.672,4.672,5.085,5.085"
            transform="translate(4607.293 12418)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_9290"
            dataname="Path 9290"
            d="M-2936.5-12113.815h11.457c2.233,0,3.482.65,3.482,3.719v3.029"
            transform="translate(4608 12418)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
};

export default IconUndo;
