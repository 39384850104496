import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  forgotPassword,
  resetAuth,
  resendPasswordLink,
} from "../../../reduxs/actions";
import { Formik, Form } from "formik";
import { InputField } from "../../../components/form";
import * as Yup from "yup";
import { withTranslation } from "react-i18next";
import { IconCheckO, IconSpinner } from "../../../components/svg";
import SetLanguage from "src/views/auth/set-lang";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.validationSchema = Yup.object().shape({
      email: Yup.string()
        .email(this.props.t("AUTH.VALIDATION.INVALID_EMAIL"))
        .required(this.props.t("AUTH.VALIDATION.REQUIRED_FIELD")),
    });
    this.props.resetAuth();
  }

  onForgotPassword = (values) => {
    if (!this.props.loading) {
      this.props.forgotPassword(values);
    }
  };

  handleResendButton = (email) => {
    if (!this.props.loading3) {
      this.props.resendPasswordLink(email);
    }
  };

  render() {
    const { t } = this.props;
    let userEmail = localStorage.getItem("useremail");
    if (!userEmail) {
      userEmail = "";
    }

    return (
      <div className="auth-wrapper">
        <img
          className="w-52 block xl:hidden my-8 mx-auto"
          src={require("../../../assets/images/Malling-logo-hv.svg").default}
          alt=""
        />

        <div className="auth-wrapper-aside">
          <img width="200px" height="auto"
            src={require("../../../assets/images/Malling-logo-hv.svg").default}
            alt=""
          />

          <h2>{t("AUTH.FORGOT.PASSWORD")}</h2>

          <p>{t("AUTH.FORGOT.INFO")}</p>

          <footer>© {t("AUTH.GENERAL.FOOTER")}</footer>
        </div>

        <div className="auth-wrapper-content">
          <header>
            <h4>
              {/* {t("AUTH.GENERAL.FINDING_TROUBLE")}{" "} */}
              <a href="/" className="text-primary-500">
                {t("AUTH.GENERAL.SELECT_LANGUAGE")}
              </a>
            </h4>

            <SetLanguage />
          </header>

          {!this.props.loading && this.props.linkSentSuccess ? (
            //Show Success Message If Forget Password Request Success
            <div className="auth-wrapper-content__succes-ui">
              <figure>
                <IconCheckO />
              </figure>
              <h1>{t("AUTH.FORGOT.LINK_SENT")}</h1>
              <h6>
                {t("AUTH.GENERAL.CHECK_INBOX")} {userEmail}
              </h6>
              <button
                className="self-start mt-5 btn-primary md"
                type="button"
                onClick={() => this.handleResendButton(userEmail)}
                disabled={this.props.loading3}
              >
                {t("AUTH.FORGOT.RESEND_LINK")}
                {/* Resend Link */}
                {this.props.loading3 && (
                  <span className="ml-3 -mr-1">
                    <IconSpinner />
                  </span>
                )}
              </button>
            </div>
          ) : (
            //Forgot Password Form
            <>
              <h1>{t("AUTH.FORGOT.TITLE")}</h1>
              <h6>{t("AUTH.FORGOT.DESC")}</h6>

              <Formik
                initialValues={{ email: "" }}
                validationSchema={this.validationSchema}
                onSubmit={this.onForgotPassword}
              >
                {(props) => (
                  <Form className="flex flex-col w-full xl:w-3/5 2xl:w-2/5">
                    <InputField
                      name="email"
                      type="text"
                      label={t("AUTH.INPUT.EMAIL")}
                      placeholder={t("AUTH.INPUT.EMAIL")}
                    />

                    <button
                      className="self-start mt-5 btn-primary md"
                      type="submit"
                      disabled={this.props.loading}
                    >
                      {t("AUTH.GENERAL.SUBMIT_BUTTON")}
                      {this.props.loading && (
                        <span className="ml-3 -mr-1">
                          <IconSpinner />
                        </span>
                      )}
                    </button>
                  </Form>
                )}
              </Formik>
            </>
          )}

          <Link to={"/auth/login"} className="btn-back-login">
            {t("AUTH.FORGOT.RETURN_TO_LOGIN")}
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { success, message, loading, error, loading3, linkSentSuccess } = auth;
  return { success, message, loading, error, loading3, linkSentSuccess };
};
export default connect(mapStateToProps, {
  forgotPassword,
  resetAuth,
  resendPasswordLink,
})(withTranslation()(ForgotPassword));
