import React from "react";

const IconMinus = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 298.667 298.667">
      <g>
        <g>
          <rect y="128" width="298.667" height="42.667" />
        </g>
      </g>
    </svg>
  );
};

export default IconMinus;
