import Req from "../interceptors/token-interceptor";

const RoleService = (function () {
  function _getAllRole() {
    return Req.get(`/api/getAllRoleList?page=1&pageSize=${process.env.REACT_APP_DEFAULT_DATA_LIST_COUNT ? process.env.REACT_APP_DEFAULT_DATA_LIST_COUNT : 100}&sortedBy&orderBy=name`);
  }
  function _getModuleList() {
    return Req.get(`/api/modules`);
  }
  function _getModuleById(id){
    return Req.get(`/api/permissionsByRoleType/${id}`)
  }
  function _getRole(id) {
    return Req.get(`/api/roles/${id}`);
  }
  function _addRole(data) {
    return Req.post("/api/roles", data);
  }
  function _editRole(data, id) {
    return Req.put(`/api/roles/${id}`, data);
  }
  function _deleteRole(id) {
    return Req.delete(`/api/roles/${id}`);
  }
  function _deleteMultipleRole(ids) {
    return Req.post("/api/massDelete", { type: "roles", ids: ids });
  }
  function _searchRole(query) {
    return Req.get(
      `/api/getAllRoleList?orderBy=name&searchData=${query}`
    );
  }
  return {
    getAllRole: _getAllRole,
    getModuleList: _getModuleList,
    getRole: _getRole,
    addRole: _addRole,
    editRole: _editRole,
    deleteRole: _deleteRole,
    deleteMultipleRole: _deleteMultipleRole,
    searchRole: _searchRole,
    getModuleById:_getModuleById
  };
})();
export default RoleService;
