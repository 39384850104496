import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { Breakpoints } from '../LayoutConfig'
import { useTranslation } from 'react-i18next'
import {
  IconChevronDown,
  IconChevronRight,
  IconProject,
} from '../../../components/svg'
import { withResizeDetector } from 'react-resize-detector'
import { isAdminOrEmployee } from 'src/helpers/util'
import { useSelector } from 'react-redux'

const Menu = (props) => {
  const { t } = useTranslation()
  const [currentpath, setCurrentpath] = useState('')
  const [activePath, setActivePath] = useState('')
  const [hover, setHover] = useState(false)
  const role = useSelector((state) => state.auth?.currentRole)
  const handleMenu = () => {
    setHover(false)
    if (window.innerWidth < Breakpoints.xl) {
      props.closeDrawer()
    } else {
    }
  }

  useEffect(() => {
    const currentUrl =
      window.location.pathname.split('/')[1] || window.location.pathname
    setCurrentpath(currentUrl)

    setActivePath(props.item?.path.split('/')[1])
  }, [props])

  useEffect(() => {
    const currentUrl =
      window.location.pathname.split('/')[1] || window.location.pathname

    setCurrentpath(currentUrl)

    setActivePath(props.item?.path.split('/')[1])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location])

  return (
    <li
      onMouseEnter={() =>
        props.drawerCollapse || currentpath !== activePath
          ? setHover(true)
          : null
      }
      onMouseLeave={() => setHover(false)}
      className='cms-menu-item'
    >
      <NavLink
        to={props.item?.path}
        onClick={handleMenu}
        className={`flex items-center px-6 py-3 text-sideNav 
        ${
          currentpath && activePath && currentpath === activePath
            ? 'active'
            : ''
        }
        `}
      >
        <span className='icon'>
          {!isAdminOrEmployee(role) &&
          props.item.translate === 'PERMISSIONS.modules.investorManagement' ? (
            <IconProject />
          ) : (
            props.item.icon
          )}
        </span>

        <span className='text'>
          {!isAdminOrEmployee(role) &&
          props.item.translate === 'PERMISSIONS.modules.investorManagement'
            ? t(`PERMISSIONS.modules.projectManagement`)
            : t(props.item.translate)}
        </span>

        <span
          className={`flex-shrink-0 pl-1 ml-auto -mr-3 svg-size-xs ${
            props.drawerCollapse ? 'hidden' : 'block'
          }`}
        >
          {props.item?.submenu?.length > 0 &&
          currentpath &&
          activePath &&
          currentpath === activePath ? (
            <IconChevronDown />
          ) : props.item?.submenu?.length > 0 ? (
            <IconChevronRight />
          ) : null}
        </span>
      </NavLink>

      {/* === Sub Menu Items Starts Here === */}
      {props.item?.submenu?.length > 0 ? (
        <ul
          className={`cms-submenu-item  ${
            currentpath && activePath && currentpath === activePath
              ? 'block'
              : 'hidden'
          } 
          ${hover ? 'hover-menu' : ''}`}
        >
          {props.item.submenu?.map((val, j) => {
            return (
              <li key={j} onClick={handleMenu}>
                <NavLink to={val.path} className='flex items-center'>
                  <span className='icon svg-size-xs'>{val.icon}</span>
                  <span className='text'>{t(val.translate)}</span>
                </NavLink>
              </li>
            )
          })}
        </ul>
      ) : null}
    </li>
  )
}

export default withResizeDetector(Menu)
