import React, { Component } from 'react'
import { connect } from 'react-redux'
import { sideNavConfig, Breakpoints } from './LayoutConfig'
import { withRouter } from 'react-router-dom'
import { logout, getPermission } from '../../reduxs/actions'
import Topnav from './topnav'
import Sidenav from './sidenav'

import {
  IconConfiguration,
  IconDashboard,
  IconSiteSetting,
  IconUserManagement,
  IconProject,
  IconDocument,
  IconInvestment,
  IconUserO,
  IconRole,
  IconFileGears,
  IconFilter,
  IconUserGroup,
  IconDocuments,
} from 'src/components/svg'
//mport { isThisHour } from "date-fns";
// import { IdleTimeoutManager } from "idle-timer-manager";

class Layout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mobileDrawerOpen: false,
      drawerCollapse: false,
      drawerHover: false,
      menuList: [],
    }
  }

  _onIdle() {
    this.props.logout(this.props.history)
  }

  hanndelMobileDrawerOpenToggle = () => {
    this.setState({ mobileDrawerOpen: !this.state.mobileDrawerOpen })
  }

  hanndelDrawerCollapseToggle = () => {
    this.setState({ drawerCollapse: !this.state.drawerCollapse })
  }

  onHoverDrawer = (hover) => {
    if (
      this.state.drawerCollapse &&
      sideNavConfig.expandOnHover &&
      !sideNavConfig.hoverSubMenu
    ) {
      if (hover === 'enter') {
        this.setState({ drawerHover: true })
      } else if (hover === 'leave') {
        this.setState({ drawerHover: false })
      }
    }
  }

  checkSmallDevices = () => {
    if (window.innerWidth >= Breakpoints.xxl) {
      this.setState({ drawerCollapse: sideNavConfig.collapseState.xxl })
    } else if (window.innerWidth >= Breakpoints.xl) {
      this.setState({ drawerCollapse: sideNavConfig.collapseState.xl })
    } else {
      this.setState({ drawerCollapse: false })
    }
  }

  componentDidMount() {
    this.checkSmallDevices()
    this.props.getPermission(this.props.currentRole?.id)
    // this.manager = new IdleTimeoutManager({
    //   timeout: 600, // 10 min
    //   onExpired: (time) => {
    //     this._onIdle();
    //   },
    // });
  }

  componentWillUnmount() {
    // window.Echo.leaveChannel(
    //   `message_${this.props.currentUserData?.id}_${this.props.currentUserData?.defaultRoleId}`
    // );
    // window.Echo.leaveChannel(`notification_${this.props.currentUserData?.id}`);
    // this.manager.clear();
  }

  matchChild = (section) => {
    if (section?.length > 0) {
      let match = section?.find(function (element) {
        return element.action.find((val) => val.key === 'list')?.value === 1
      })
      if (match?.key) {
        return this.matchPageUrl(match.key)
      } else {
        return '/'
      }
    } else {
      return '/'
    }
  }

  matchPageUrl = (pageName) => {
    switch (pageName) {
      //For Sub Menu
      case 'user':
        return '/user-management/user'
      case 'role':
        return '/user-management/role'
      case 'setting':
        return '/setting'
      case 'configChoice':
        return '/configuration/config-choice'
      case 'configChoiceCategory':
        return '/configuration/config-choice-category'
      case 'project':
        return '/project-management/project'
      case 'investor':
        return '/investor-management/investor'
      case 'document':
        return '/document-management/document'
      case 'boardDocument':
        return '/document-management/board-document'
      default:
        return '/dashboard'
    }
  }

  matchPageIcon = (pageName) => {
    switch (pageName) {
      case 'userManagement':
        return <IconUserManagement />
      case 'user':
        return <IconUserO />
      case 'role':
        return <IconRole />
      case 'projectManagement':
        return <IconProject />
      case 'investorManagement':
        return <IconInvestment />
      case 'documentManagement':
        return <IconDocument />
      case 'document':
        return <IconDocuments />
      case 'boardDocument':
        return <IconUserGroup />
      case 'siteSetting':
        return <IconSiteSetting />
      case 'configuration':
        return <IconConfiguration />
      case 'configChoiceCategory':
        return <IconFileGears />
      case 'configChoice':
        return <IconFilter />
      default:
        return ''
    }
  }

  componentDidUpdate(previousProps) {
    if (previousProps.permission !== this.props.permission) {
      this.constructMenu()
    }
  }

  constructMenu = () => {
    let menuIgniter = []
    menuIgniter.push({
      title: 'Overview',
      root: true,
      icon: <IconDashboard />,
      path: '/dashboard',
      translate: 'MENU.OVERVIEW',
    })

    this.props.permission?.permission?.forEach((module) => {
      if (module?.section?.length === 1) {
        // If There is Single Menu
        const sectionAllowed = module.section?.[0]?.['action'].find(
          (act) => act.key === 'list' && act.value
        )
          ? true
          : false
        if (sectionAllowed) {
          menuIgniter.push({
            title: module.displayName,
            root: true,
            icon: this.matchPageIcon(module.key),
            submenu: [],
            path: this.matchPageUrl(module.section?.[0]?.key),
            translate: 'PERMISSIONS.modules.' + module.key,
          })
        }
      } else {
        // If There is Multiple Submenu and Create Submenu Items
        let preparedMenu = {
          title: module.displayName,
          translate: 'PERMISSIONS.modules.' + module.key,
          root: true,
          icon: this.matchPageIcon(module.key),
          submenu: [],
          path: this.matchChild(module?.section || null),
          permitted: false,
        }
        module.section.forEach((section) => {
          const sectionAllowed = section['action'].find(
            (act) => act.key === 'list' && act.value
          )
            ? true
            : false
          if (!preparedMenu.permitted) {
            preparedMenu['permitted'] = sectionAllowed
          }
          if (sectionAllowed) {
            preparedMenu['submenu'].push({
              title: section.displayName,
              translate: 'PERMISSIONS.sections.' + section.key,
              icon: this.matchPageIcon(section.key),
              path: this.matchPageUrl(section.key),
            })
          }
        })
        if (preparedMenu.permitted) {
          menuIgniter.push(preparedMenu)
        }
      }
    })

    this.setState({ menuList: menuIgniter })
  }

  render() {
    return (
      <div className='flex w-[100%] h-[100%]'>
        <>
          <Sidenav
            toggleSideNav={this.hanndelDrawerCollapseToggle}
            history={this.props.history}
            location={this.props.location}
            drawerCollapse={this.state.drawerCollapse}
            menuList={this.state.menuList}
            openMobileMenu={this.state.mobileDrawerOpen}
            drawerHover={this.onHoverDrawer}
            closeDrawer={this.hanndelMobileDrawerOpenToggle}
          />

          <main
            className={`cms-content-wrapper ${
              this.state.drawerCollapse ? 'collapsed' : 'expanded'
            }`}
          >
            <Topnav
              toggleSideNav={this.hanndelDrawerCollapseToggle}
              toggleMobileSideNav={this.hanndelMobileDrawerOpenToggle}
              drawerCollapse={this.state.drawerCollapse}
            />

            {/* App Pages Here */}
            <div className='container max-w-full mx-auto mt-7 px-4 xl:px-10 pb-8'>
              {this.props.children}
            </div>
          </main>

          {/* Backdrop Html */}
          {this.state.mobileDrawerOpen && window.innerWidth < Breakpoints.xl ? (
            <div
              className='fixed top-0 left-0 z-40 w-full h-full bg-opacity-70 bg-grey-900'
              onClick={() => this.setState({ mobileDrawerOpen: false })}
            ></div>
          ) : null}
        </>
      </div>
    )
  }
}
const mapStateToProps = ({ shared, auth }) => {
  const { permission, currentUserData } = shared
  const { currentRole } = auth
  return {
    permission,
    currentRole,
    currentUserData,
  }
}
export default withRouter(
  connect(mapStateToProps, {
    logout,
    getPermission,
  })(Layout)
)
