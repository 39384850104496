import React from "react";

const IconColoredEquity = () => {
  return (
    <svg
      width="70"
      height="63"
      viewBox="0 0 70 63"
    >
      <g
        id="Group_3002"
        dataname="Group 3002"
        transform="translate(-415 -886)"
      >
        <rect
          id="Rectangle_1683"
          dataname="Rectangle 1683"
          width="70"
          height="63"
          rx="5"
          transform="translate(415 886)"
          fill="#f0ddd2"
        />
        <g
          id="Group_2759"
          dataname="Group 2759"
          transform="translate(644.845 948.365)"
        >
          <g
            id="Group_2755"
            dataname="Group 2755"
            transform="translate(-211.845 -43.365)"
          >
            <path
              id="Path_10097"
              dataname="Path 10097"
              d="M-195.649-19.065a12.359,12.359,0,0,1-3.775.584,12.449,12.449,0,0,1-12.421-12.447h8.581a3.839,3.839,0,0,0,3.84-3.838v-8.6A12.417,12.417,0,0,1-192.87-41.5"
              transform="translate(211.845 43.365)"
              fill="none"
              stroke="#f16241"
              strokeLinecap="round"
              strokeWidth="2"
            />
            <path
              id="Path_10098"
              dataname="Path 10098"
              d="M-199.424-43.365v8.6a3.839,3.839,0,0,1-3.84,3.836h-8.581A12.436,12.436,0,0,1-199.424-43.365Z"
              transform="translate(211.845 43.365)"
              fill="none"
              stroke="#f16241"
              strokeLinecap="round"
              strokeWidth="2"
            />
          </g>
          <g
            id="Group_2758"
            dataname="Group 2758"
            transform="translate(-194.058 -40.668)"
          >
            <g id="Group_2756" dataname="Group 2756">
              <path
                id="Path_10100"
                dataname="Path 10100"
                d="M-176.245-24.651A7.609,7.609,0,0,1-184-17.2a7.609,7.609,0,0,1-7.754-7.454,7.24,7.24,0,0,1,1.683-4.636h0c.385-.366.742-.718,1.069-1.059,2.824-2.937,3.462-5.1.989-9.235a.276.276,0,0,1,.366-.38c1.028.466,2.6.885,3.509-.285a.2.2,0,0,1,.3,0c.914,1.17,2.481.751,3.509.285a.276.276,0,0,1,.366.38c-2.5,4.186-1.817,6.354,1.1,9.351q.382.392.815.8A7.255,7.255,0,0,1-176.245-24.651Z"
                transform="translate(191.753 40.318)"
                fill="none"
                stroke="#f16241"
                strokeLinecap="round"
                strokeWidth="2"
              />
              <line
                id="Line_28"
                dataname="Line 28"
                x2="7.769"
                transform="translate(3.752 5.169)"
                fill="none"
                stroke="#f16241"
                strokeLinecap="round"
                strokeWidth="2"
              />
            </g>
            <g
              id="Group_2757"
              dataname="Group 2757"
              transform="translate(4.87 10.469)"
            >
              <g
                id="Group_2754"
                dataname="Group 2754"
                transform="translate(0 1.091)"
              >
                <path
                  id="Path_10101"
                  dataname="Path 10101"
                  d="M-180.667-25.558a2.341,2.341,0,0,0-2.11-1.657,4.654,4.654,0,0,0-1.034-.027,2.233,2.233,0,0,0-2.245,2.172c0,1.482,1.161,1.728,2.416,1.735.926.005,3.173-.2,3.173,1.732,0,1.665-1.778,1.862-2.31,1.917a3.926,3.926,0,0,1-1.034-.019,2.52,2.52,0,0,1-2.3-1.755"
                  transform="translate(186.236 27.259)"
                  fill="none"
                  stroke="#f16241"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
                <path
                  id="Path_10099"
                  dataname="Path 10099"
                  d="M-186.252-21.52"
                  transform="translate(186.252 26.601)"
                  fill="none"
                  stroke="#f16241"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
              </g>
              <line
                id="Line_29"
                dataname="Line 29"
                y2="0.805"
                transform="translate(2.884 8.686)"
                fill="none"
                stroke="#f16241"
                strokeLinecap="round"
                strokeWidth="2"
              />
              <line
                id="Line_30"
                dataname="Line 30"
                y2="1.092"
                transform="translate(2.884)"
                fill="none"
                stroke="#f16241"
                strokeLinecap="round"
                strokeWidth="2"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconColoredEquity;
