import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import InvestorService from "../../services/investor-service";
import TableDataService from "../../services/table-data-service1";
import ExportDataService from "../../services/export-service";
import saveAs from "file-saver";
import moment from "moment";
import {
  GET_ALL_INVESTOR,
  GET_INVESTOR_LIST,
  ADD_INVESTOR,
  GET_INVESTOR,
  EDIT_INVESTOR,
  DELETE_INVESTOR,
  DELETE_MULTIPLE_INVESTOR,
  EXPORT_INVESTOR,
  GET_ALL_INVESTOR_COMMENT,
  ADD_INVESTOR_COMMENT,
  EDIT_INVESTOR_COMMENT,
  DELETE_INVESTOR_COMMENT,
} from "../actions";
import {
  getAllInvestorSuccess,
  getAllInvestorError,
  getInvestorList,
  getInvestorListSuccess,
  getInvestorListError,
  addInvestorSuccess,
  addInvestorError,
  getInvestorSuccess,
  getInvestorError,
  editInvestorSuccess,
  editInvestorError,
  deleteInvestorSuccess,
  deleteInvestorError,
  deleteMultipleInvestorSuccess,
  deleteMultipleInvestorError,
  exportInvestorSuccess,
  exportInvestorError,
  addInvestorCommentSuccess,
  addInvestorCommentError,
  editInvestorCommentSuccess,
  editInvestorCommentError,
  deleteInvestorCommentSuccess,
  deleteInvestorCommentError,
  getAllInvestorComment,
  getAllInvestorCommentSuccess,
  getAllInvestorCommentError,
} from "./action";
import { toast } from "react-toastify";
import { handleMyErrorMessage } from "../../helpers/util";
import ToastElement from "../../components/toast";

export function* watchGetAllInvestor() {
  yield takeEvery(GET_ALL_INVESTOR, getAllInvestors);
}

const getAllInvestorAsync = async () => {
  return InvestorService.getAllInvestor();
};

function* getAllInvestors() {
  try {
    const response = yield call(getAllInvestorAsync);
    if (response.data.success) {
      yield put(getAllInvestorSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getAllInvestorError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type='error' message={error.response.data.message} />,
    //   { containerId: 'default' }
    // )
    // yield put(getAllInvestorError(error.response.data.message))
    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getAllInvestorError(errorMessage));
    }
  }
}

export function* watchGetInvestorList() {
  yield takeEvery(GET_INVESTOR_LIST, getInvestorListAc);
}

const getInvestorListAsync = async (dbParam) => {
  const currentRole = JSON.parse(localStorage.getItem("currentRole"));
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  if (currentRole) {
    dbParam.defaultRoleId = currentRole?.id;
  } else {
    dbParam.defaultRoleId = currentUser.default_role_id;
  }
  return TableDataService.getAllData("investors", dbParam);
};

function* getInvestorListAc({ payload }) {
  try {
    const response = yield call(getInvestorListAsync, payload.dbParam);
    if (response.data.success) {
      yield put(
        getInvestorListSuccess(response.data.data, response.data.metadata)
      );
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getInvestorListError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type='error' message={error.response.data.message} />,
    //   { containerId: 'default' }
    // )
    // yield put(getInvestorListError(error.response.data.message))
    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getInvestorListError(errorMessage));
    }
  }
}

export function* watchAddInvestor() {
  yield takeEvery(ADD_INVESTOR, addInvestor);
}

const addInvestorAsync = async (data) => {
  return InvestorService.addInvestor(data);
};

function* addInvestor({ payload }) {
  const { history } = payload;
  try {
    const response = yield call(addInvestorAsync, payload.investorData);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        addInvestorSuccess(response.data.success, response.data.message)
      );
      history.push({
        pathname: `/investor-management/investor`,
      });
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(addInvestorError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type='error'
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   { containerId: 'default' }
    // )
    // yield put(addInvestorError(error.response.data.message))

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(addInvestorError(errorMessage));
    }
  }
}

export function* watchGetInvestor() {
  yield takeEvery(GET_INVESTOR, getInvestor);
}

const getInvestorAsync = async (id) => {
  return InvestorService.getInvestor(id);
};

function* getInvestor({ payload }) {
  try {
    const response = yield call(getInvestorAsync, payload.investorId);
    if (response.data.success) {
      yield put(getInvestorSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getInvestorError(response.data.message));
    }
  } catch (error) {
    // if (error.response.status === 404) {
    //   yield put(getInvestorError('404'))
    // } else {
    //   toast.error(
    //     <ToastElement type='error' message={error.response.data.message} />,
    //     { containerId: 'default' }
    //   )
    //   yield put(getInvestorError(error.response.data.message))
    // }

    if (error.response && error.response.status === 404) {
      yield put(getInvestorError("404"));
    } else {
      const errorMessage = handleMyErrorMessage(error);
      if (errorMessage) {
        toast.error(<ToastElement type="error" message={errorMessage} />, {
          containerId: "default",
        });
        yield put(getInvestorError(errorMessage));
      }
    }
  }
}

export function* watchEditInvestor() {
  yield takeEvery(EDIT_INVESTOR, editInvestor);
}

const editInvestorAsync = async (data, id) => {
  return InvestorService.editInvestor(data, id);
};

function* editInvestor({ payload }) {
  const { history } = payload;
  try {
    const response = yield call(
      editInvestorAsync,
      payload.investorData,
      payload.investorId
    );
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        editInvestorSuccess(response.data.success, response.data.message)
      );
      history.push(`/investor-management/investor`);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(editInvestorError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type='error'
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   { containerId: 'default' }
    // )
    // yield put(editInvestorError(error.response.data.message))

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(editInvestorError(errorMessage));
    }
  }
}

export function* watchDeleteInvestor() {
  yield takeEvery(DELETE_INVESTOR, deleteInvestor);
}

const deleteInvestorAsync = async (id) => {
  return InvestorService.deleteInvestor(id);
};

function* deleteInvestor({ payload }) {
  try {
    const response = yield call(deleteInvestorAsync, payload.investorId);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        deleteInvestorSuccess(response.data.success, response.data.message)
      );
      // Fetch updated investor list
      yield put(getInvestorList({}));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(deleteInvestorError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type='error' message={error.response.data.message} />,
    //   { containerId: 'default' }
    // )
    // yield put(deleteInvestorError(error.response.data.message))

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(deleteInvestorError(errorMessage));
    }
  }
}

export function* watchDeleteMultipleInvestor() {
  yield takeEvery(DELETE_MULTIPLE_INVESTOR, deleteMultipleInvestor);
}

const deleteMultipleInvestorAsync = async (ids) => {
  return InvestorService.deleteMultipleInvestor(ids);
};

function* deleteMultipleInvestor({ payload }) {
  try {
    const response = yield call(
      deleteMultipleInvestorAsync,
      payload.investorIds
    );
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        deleteMultipleInvestorSuccess(
          response.data.success,
          response.data.message
        )
      );
      yield put(getInvestorList({}));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(deleteMultipleInvestorError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type='error' message={error.response.data.message} />,
    //   { containerId: 'default' }
    // )
    // yield put(deleteMultipleInvestorError(error.response.data.message))

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(deleteMultipleInvestorError(errorMessage));
    }
  }
}

export function* watchExportInvestor() {
  yield takeEvery(EXPORT_INVESTOR, exportInvestor);
}

const exportInvestorAsync = async (params) => {
  const currentRole = JSON.parse(localStorage.getItem("currentRole"));
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  if (currentRole) {
    params.defaultRoleId = currentRole?.id;
  } else {
    params.defaultRoleId = currentUser.default_role_id;
  }

  if (
    params?.defaultRole === "internalEmployee" ||
    params?.defaultRole === "superAdmin"
  ) {
    delete params.defaultRole;

    return ExportDataService.getAllData("investors/export", params);
  } else {
    delete params.defaultRole;

    return ExportDataService.getAllData("investors/projects/export", params);
  }
};

function* exportInvestor({ payload }) {
  try {
    const response = yield call(exportInvestorAsync, payload.dbParam);
    if (response && response.data) {
      yield put(exportInvestorSuccess(true, ""));
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const fileName =
        "Investors-" + moment().format("YYYY-MMM-DD-HH-mm") + ".xlsx";
      saveAs(blob, fileName);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(exportInvestorError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type='error' message={error.response.data.message} />,
    //   { containerId: 'default' }
    // )
    // yield put(exportInvestorError(error.response.data.message))

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(exportInvestorError(errorMessage));
    }
  }
}

export function* watchGetAllInvestorComment() {
  yield takeEvery(GET_ALL_INVESTOR_COMMENT, getAllInvestorsComment);
}

const getAllInvestorCommentAsync = async (id) => {
  return InvestorService.getInvestorComments(id);
};

function* getAllInvestorsComment({ payload }) {
  try {
    const response = yield call(getAllInvestorCommentAsync, payload.investorId);
    if (response.data.success) {
      yield put(getAllInvestorCommentSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getAllInvestorCommentError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type='error' message={error.response.data.message} />,
    //   { containerId: 'default' }
    // )
    // yield put(getAllInvestorCommentError(error.response.data.message))

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getAllInvestorCommentError(errorMessage));
    }
  }
}

export function* watchAddInvestorComment() {
  yield takeEvery(ADD_INVESTOR_COMMENT, addInvestorComment);
}

const addInvestorCommentAsync = async (data) => {
  return InvestorService.addInvestorComment(data);
};

function* addInvestorComment({ payload }) {
  try {
    const response = yield call(addInvestorCommentAsync, payload.commentData);
    if (response.data.success) {
      const { investorId } = payload.commentData;
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        addInvestorCommentSuccess(response.data.success, response.data.message)
      );
      yield put(getAllInvestorComment(investorId));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(addInvestorCommentError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type='error'
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   { containerId: 'default' }
    // )
    // yield put(addInvestorCommentError(error.response.data.message))

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(addInvestorCommentError(errorMessage));
    }
  }
}

export function* watchEditInvestorComment() {
  yield takeEvery(EDIT_INVESTOR_COMMENT, editInvestorComment);
}

const editInvestorCommentAsync = async (data, id) => {
  return InvestorService.editInvestorComment(data, id);
};

function* editInvestorComment({ payload }) {
  try {
    const response = yield call(
      editInvestorCommentAsync,
      payload.commentData,
      payload.commentId
    );
    if (response.data.success) {
      const { investorId } = payload.commentData;
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        editInvestorCommentSuccess(response.data.success, response.data.message)
      );
      yield put(getAllInvestorComment(investorId));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(editInvestorCommentError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type='error'
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   { containerId: 'default' }
    // )
    // yield put(editInvestorCommentError(error.response.data.message))

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(editInvestorCommentError(errorMessage));
    }
  }
}

export function* watchDeleteInvestorComment() {
  yield takeEvery(DELETE_INVESTOR_COMMENT, deleteInvestorComment);
}

const deleteInvestorCommentAsync = async (id) => {
  return InvestorService.deleteInvestorComment(id);
};

function* deleteInvestorComment({ payload }) {
  try {
    const { id, investorId } = payload.comment;
    const response = yield call(deleteInvestorCommentAsync, id);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        deleteInvestorCommentSuccess(
          response.data.success,
          response.data.message
        )
      );
      yield put(getAllInvestorComment(investorId));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(deleteInvestorCommentError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type='error' message={error.response.data.message} />,
    //   { containerId: 'default' }
    // )
    // yield put(deleteInvestorCommentError(error.response.data.message))

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(deleteInvestorCommentError(errorMessage));
    }
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAllInvestor),
    fork(watchGetInvestorList),
    fork(watchAddInvestor),
    fork(watchGetInvestor),
    fork(watchEditInvestor),
    fork(watchDeleteInvestor),
    fork(watchDeleteMultipleInvestor),
    fork(watchExportInvestor),
    fork(watchAddInvestorComment),
    fork(watchEditInvestorComment),
    fork(watchDeleteInvestorComment),
    fork(watchGetAllInvestorComment),
  ]);
}
