/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { IconCheckO } from "../../../components/svg";
import { Link } from "react-router-dom";
import qs from "query-string";
import AuthService from "../../../services/auth-service";
import ToastElement from "../../../components/toast";
import { toast } from "react-toastify";
import SetLanguage from "src/views/auth/set-lang";
class WelcomeNorwegian extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      credentials: {
        client_id: process.env.REACT_APP_BANKID_CLIENT_ID,
        scope: process.env.REACT_APP_BANKID_SCOPE,
        redirect_uri: process.env.REACT_APP_BANKID_REDIRECT_URI,
        response_type: process.env.REACT_APP_BANKID_RESPONSE_TYPE,
        nonce: process.env.REACT_APP_NONCE,
        state: "verify",
      },
    };
  }

  loginWithBankId = () => {
    this.setState({ isLoading: true });
    AuthService.bandIdAuth()
      .then((res) => {
        const { authorization_endpoint } = res.data;
        if (authorization_endpoint) {
          this.setState({ isLoading: false });
          let redirectUrl = `${authorization_endpoint}?${qs.stringify(
            this.state.credentials
          )}`;
          window.open(redirectUrl, "_self");
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        toast.error(
          <ToastElement
            type="error"
            message={"Something went wrong,please try again"}
          />,
          { containerId: "default", position: "top-right" }
        );
      });
  };

  render() {
    const { t } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="auth-wrapper">
        <img
          className="w-52 block xl:hidden my-8 mx-auto"
          src={require("../../../assets/images/Malling-logo-hv.svg").default}
          alt=""
        />

        <div className="auth-wrapper-aside">
          <img
            src={require("../../../assets/images/Malling-logo-hv.svg").default}
            alt=""
          />

          {/* <h2 className="mt-16 mb-4 2xl:mb-8 text-lg 2xl:text-3xl font-light text-white">
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
          </h2> */}

          <ul>
            <li>{t("AUTH.WELCOME.FINALISE_TEXT_FOR_NORWEIGN")}</li>
            <li>{t("AUTH.WELCOME.AFTER_SUBMIT_TEXT_FOR_NORWEIGN")}</li>
            <li>
              {t("AUTH.WELCOME.QUERY_TEXT")}:{" "}
              <a
                className="font-bold underline"
                href="mailto:havard.solbrakke@malling.no"
              >
                havard.solbrakke@malling.no
              </a>
            </li>
          </ul>

          <footer className="flex justify-between">
            <span>
              © {t("AUTH.INTRO.FOOTER")}
            </span>
            <span>{process.env.REACT_APP_WEB_CLIENT_VERSION}</span>
          </footer>
        </div>

        <div className="auth-wrapper-content">
          <header>
            <SetLanguage />
          </header>

          {!this.props.loading && this.props.success ? (
            //Show Success Message If Bank ID Verified Success
            <div className="auth-wrapper-content__succes-ui">
              <figure>
                <IconCheckO />
              </figure>
              <h1>{t("AUTH.WELCOME.SUCCESS_TITLE")}</h1>
              <h6>{t("AUTH.WELCOME.SUCCESS_MESSAGE")}</h6>

              <Link to={"/auth/login"} className="btn-back-login">
                {t("AUTH.FORGOT.RETURN_TO_LOGIN")}
              </Link>
            </div>
          ) : (
            //Bank ID Button
            <>
              <h1>{t("AUTH.WELCOME.TITLE")}</h1>
              <h6 className="!mb-8">{t("AUTH.WELCOME.SETUP")}</h6>
              <h6>{t("AUTH.WELCOME.BANKID_MESSAGE")}</h6>

              {/* {this.props.error ? (
                  <span className="block mb-2 text-red-700">
                    {this.props.error}
                  </span>
                ) : null} */}

              <div className="inline-flex flex-col">
                <button
                  className="flex items-center mb-4 btn-primary-outlined md"
                  onClick={this.loginWithBankId}
                  disabled={isLoading}
                  style={{ height: 48 }}
                >
                  <img
                    className="w-7 mr-7"
                    src={
                      require("../../../assets/images/bankid-vector-logo.svg")
                        .default
                    }
                    alt=""
                  />
                  {t("AUTH.LOGIN.LOGIN_BANKID")}
                </button>

                <p className="w-60 text-right text-grey-700">
                  {t("AUTH.LOGIN.BANKID_INFO")}
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loading1, loading, error } = auth;
  return { loading1, loading, error };
};
export default connect(
  mapStateToProps,
  {}
)(withTranslation()(WelcomeNorwegian));
