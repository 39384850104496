import React from "react";

const IconHorizKebab = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 512 512">
      <g id="More">
        <path d="m256 192a64 64 0 1 0 64 64 64.072 64.072 0 0 0 -64-64zm0 112a48 48 0 1 1 48-48 48.055 48.055 0 0 1 -48 48z" />
        <path d="m72 192a64 64 0 1 0 64 64 64.072 64.072 0 0 0 -64-64zm0 112a48 48 0 1 1 48-48 48.055 48.055 0 0 1 -48 48z" />
        <path d="m440 192a64 64 0 1 0 64 64 64.072 64.072 0 0 0 -64-64zm0 112a48 48 0 1 1 48-48 48.055 48.055 0 0 1 -48 48z" />
      </g>
    </svg>
  );
};

export default IconHorizKebab;
