import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import MediaService from "../../services/media-service";
import { UPLOAD_MEDIA, DELETE_MEDIA,UPLOAD_MEDIA_PROFILE } from "../actions";
import { handleMyErrorMessage } from '../../helpers/util'
import {
  uploadMediaSuccess,
  uploadMediaError,
  deleteMediaSuccess,
  deleteMediaError,
  uploadMediaProfileSuccess,
  uploadMediaProfileError
} from "./action";

export function* watchUploadMedia() {
  yield takeEvery(UPLOAD_MEDIA, uploadMedia);
}

const uploadMediaAsync = async (file) => {
  return MediaService.upload(file);
};

function* uploadMedia({ payload }) {
  try {
    const response = yield call(uploadMediaAsync, payload.mediaFile);
    if (response.data.success) {
      yield put(uploadMediaSuccess(response.data.data));
    } else {
      yield put(uploadMediaError(response.data.message));
    }
  } catch (error) {
    // yield put(uploadMediaError(error.response.data.message));
    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      yield put(uploadMediaError(errorMessage))
    }
  }
}

export function* watchUploadMediaProfile() {
  yield takeEvery(UPLOAD_MEDIA_PROFILE, uploadMediaProfile);
}

const uploadProfileMediaAsync = async (file) => {
  return MediaService.upload(file);
};

function* uploadMediaProfile({ payload }) {
  try {
    const response = yield call(uploadProfileMediaAsync, payload.mediaFile);
    if (response.data.success) {
      yield put(uploadMediaProfileSuccess(response.data.data));
    } else {
      yield put((response.data.message));
    }
  } catch (error) {
    // yield put(uploadMediaProfileError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      yield put(uploadMediaProfileError(errorMessage))
    }
  }
}

export function* watchDeleteMedia() {
  yield takeEvery(DELETE_MEDIA, deleteMedia);
}

const deleteMediaAsync = async (id) => {
  return MediaService.delete(id);
};

function* deleteMedia({ payload }) {
  try {
    const response = yield call(deleteMediaAsync, payload.mediaId);
    if (response.data.success) {
      yield put(
        deleteMediaSuccess(response.data.success, response.data.message)
      );
    } else {
      yield put(deleteMediaError(response.data.message));
    }
  } catch (error) {
    // yield put(deleteMediaError(error.response.data.message));
    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      yield put(deleteMediaError(errorMessage))
    }
  }
}

export default function* rootSaga() {
  yield all([
     fork(watchUploadMedia),
     fork(watchDeleteMedia),
     fork(watchUploadMediaProfile)
    ]);
}
