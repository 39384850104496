import React, { Component } from "react";
import { connect } from "react-redux";
import { login, resetAuth } from "../../../reduxs/actions";
import { withTranslation } from "react-i18next";
import WelcomeMalling from "./welcome-malling";
import WelcomeNorwegian from "./welcome-norwegian";
import WelcomeInternational from "./welcome-international";
import { Redirect } from "react-router-dom";

class WelcomeScreen extends Component {

  checkVerificationData = (obj) => {
  
    for (var key in obj) {
      if (obj[key] !== null && obj[key] !== "") return true;
    }
    return false;
  };

  getWelcomeScreen = (type) => {
    switch (type) {
      case "internalEmployee":
        return <WelcomeMalling />;
      case "investorNorwegian":
        return <WelcomeNorwegian />;
      case "investorInternational":
        return <WelcomeInternational />;
      default:
        return <WelcomeMalling />;
    }
  };

  render() {

    const { verifyData } = this.props;
    if (!this.checkVerificationData(verifyData)) {
      return <Redirect to="/" />;
    }
    return <>{this.getWelcomeScreen(verifyData.typeId)}</>;
  }
}

const mapStateToProps = ({ auth }) => {
  const { user, loading, error, verifyData } = auth;
  return { user, loading, error, verifyData };
};
export default connect(mapStateToProps, { login, resetAuth })(
  withTranslation()(WelcomeScreen)
);
