import React from "react";

const IconAdd = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 341.4 341.4">
      <g>
        <g>
          <polygon
            points="192,149.4 192,0 149.4,0 149.4,149.4 0,149.4 0,192 149.4,192 149.4,341.4 192,341.4 192,192 341.4,192 
          341.4,149.4 		"
          />
        </g>
      </g>
    </svg>
  );
};

export default IconAdd;
