import Req from "../interceptors/token-interceptor";

const PaidInService = (function () {
  function _getAllPaidIn(param) {
    return Req.get(
      `/api/paidIn/${param.projectId}?search=${
        param?.search || ""
      }&searchFields=${param?.searchFields || ""}`,
      { withCredentials: true }
    );
  }
  function _addPaidIn(data) {
    return Req.post("/api/paidIn", data);
  }
  function _getPaidIn(id) {
    return Req.get(`/api/paidIn/${id}`);
  }
  function _editPaidIn(data, id) {
    return Req.put(`/api/paidIn/${id}`, data);
  }
  function _deletePaidIn(id) {
    return Req.delete(`/api/paidIn/${id}`);
  }
  function _exportPaidIn(projectId) {
    return Req.get(`/api/exportPaidIn/${projectId}`, {
      responseType: "arraybuffer",
    });
  }
  function _getAllPaidInFund(param) {
    return Req.get(
      `/api/fundPaidIn/${param.projectId}?search=${
        param?.search || ""
      }&searchFields=${param?.searchFields || ""}`,
      { withCredentials: true }
    );
  }
  function _addPaidInFund(data) {
    return Req.post("/api/fundPaidIn", data);
  }
  function _getPaidInFund(id) {
    return Req.get(`/api/fundPaidIn/${id}`);
  }
  function _editPaidInFund(data, id) {
    return Req.put(`/api/fundPaidIn/${id}`, data);
  }
  function _deletePaidInFund(id) {
    return Req.delete(`/api/fundPaidIn/${id}`);
  }
  function _exportPaidInFund(projectId) {
    return Req.get(`/api/exportFundPaidIn/${projectId}`, {
      responseType: "arraybuffer",
    });
  }
  function _updatePaidInFund(data, id) {
    return Req.put(`/api/updateProjectPaidInCapital/${id}`, data);
  }
  return {
    getAllPaidIn: _getAllPaidIn,
    addPaidIn: _addPaidIn,
    getPaidIn: _getPaidIn,
    editPaidIn: _editPaidIn,
    deletePaidIn: _deletePaidIn,
    exportPaidIn: _exportPaidIn,
    getAllPaidInFund: _getAllPaidInFund,
    addPaidInFund: _addPaidInFund,
    getPaidInFund: _getPaidInFund,
    editPaidInFund: _editPaidInFund,
    deletePaidInFund: _deletePaidInFund,
    exportPaidInFund: _exportPaidInFund,
    updatePaidInFund: _updatePaidInFund,
  };
})();
export default PaidInService;
