import React from "react";

const IconRole = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 12.769 11.767"
    >
      <g
        id="Group_2077"
        dataname="Group 2077"
        transform="translate(193.813 -363.738)"
      >
        <path
          id="Path_9291"
          dataname="Path 9291"
          d="M-180.418,376.271a2.614,2.614,0,0,0-1.107-1.657,3.65,3.65,0,0,0-2.141-.632,3.6,3.6,0,0,0-2.112.7,2.566,2.566,0,0,0-1.034,1.686"
          transform="translate(-1.116 -1.673)"
          fill="none"
          stroke="#000"
          strokeWidth="1"
        />
        <circle
          id="Ellipse_295"
          dataname="Ellipse 295"
          cx="1.613"
          cy="1.613"
          r="1.613"
          transform="translate(-186.337 366.934)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeWidth="1"
        />
        <path
          id="Rectangle_1642"
          dataname="Rectangle 1642"
          d="M-190,375.005h-.418a2.9,2.9,0,0,1-2.9-2.9v-4.97a2.9,2.9,0,0,1,2.9-2.9h4.97"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_8"
          dataname="Line 8"
          x2="2.684"
          transform="translate(-191.169 368.547)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Line_9"
          dataname="Line 9"
          x2="1.75"
          transform="translate(-191.169 371.36)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export default IconRole;
