import React, { Component } from "react";
import { LayoutSplashScreen } from "../../../configs/core";
import {
  setVerifyData,
  checkUserVerification,
} from "../../../reduxs/auth/action";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import qs from "query-string";
class Verify extends Component {
  constructor(props) {
    super(props);
    const verificationData = qs.parse(this.props.location.search);

    if (verificationData) {
      props.checkUserVerification(verificationData, props.history);
      // localStorage.setItem("verifyData", JSON.stringify(verificationData));
      // this.props.setVerifyData(verificationData);
      // this.props.history.push({
      //   pathname: "/auth/welcome",
      //   state: { type: verificationData.typeId },
      // });
    }
  }
  render() {
    return <LayoutSplashScreen />;
  }
}

export default connect(null, {
  setVerifyData,
  checkUserVerification,
})(withTranslation()(Verify));
