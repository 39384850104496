import Req from '../interceptors/token-interceptor'
import qs from 'query-string'
import { cleanObj } from 'src/helpers/util'

const ProjectService = (function () {
  function _getAllProject(params) {
    const sentParams = { ...cleanObj(params) }
    return Req.get(
      `/api/getAllProjectList?page=1&pageSize=${process.env.REACT_APP_DEFAULT_DATA_LIST_COUNT ? process.env.REACT_APP_DEFAULT_DATA_LIST_COUNT : 100}&sortedBy=asc&orderBy=name${
        qs.stringify(sentParams) && '&' + qs.stringify(sentParams)
      }`,
      { withCredentials: true }
    )
  }
  function _addProject(data) {
    return Req.post('/api/projects', data)
  }
  function _getProject(id) {
    return Req.get(`/api/projects/${id}`)
  }
  function _editProject(data, id) {
    return Req.put(`/api/projects/${id}`, data)
  }
  function _deleteProject(id) {
    return Req.delete(`/api/projects/${id}`)
  }
  function _deleteMultipleProject(ids) {
    return Req.post('/api/massDelete', { type: 'project', ids: ids })
  }
  function _addProperty(data) {
    return Req.post('/api/project/properties', data)
  }
  function _getProperty(id) {
    return Req.get(`/api/project/properties/${id}`)
  }
  function _editProperty(data, id) {
    return Req.put(`/api/project/properties/${id}`, data)
  }
  function _deleteProperty(id) {
    return Req.delete(`/api/project/properties/${id}`)
  }
  function _projectSearch(search) {
    return Req.get(`/api/getAllProjectList?searchData=${search}`)
  }

  //projects by investor id
  function _getProjectsByInvestor(id, params) {
    const sentParams = { ...cleanObj(params) }
    if (Object.keys(sentParams).length > 0) {
      return Req.get(
        `/api/getProjectsByInvestor/${id}?${qs.stringify(sentParams, {
          arrayFormat: 'bracket',
        })}`
      )
    } else {
      return Req.get(`/api/getProjectsByInvestor/${id}`)
    }
  }
  return {
    getAllProject: _getAllProject,
    addProject: _addProject,
    getProject: _getProject,
    editProject: _editProject,
    deleteProject: _deleteProject,
    deleteMultipleProject: _deleteMultipleProject,
    addProperty: _addProperty,
    getProperty: _getProperty,
    editProperty: _editProperty,
    deleteProperty: _deleteProperty,
    getProjectsByInvestor: _getProjectsByInvestor,
    projectSearch: _projectSearch,
  }
})()
export default ProjectService
