import React from "react";

const IconColoredUsers = () => {
  return (
    <svg
      width="70"
      height="63"
      viewBox="0 0 70 63"
    >
      <g
        id="Group_3003"
        dataname="Group 3003"
        transform="translate(-415 -1130)"
      >
        <rect
          id="Rectangle_1685"
          dataname="Rectangle 1685"
          width="70"
          height="63"
          rx="5"
          transform="translate(415 1130)"
          fill="#e5f3fc"
        />
        <g
          id="Group_2764"
          dataname="Group 2764"
          transform="translate(-16.922 7.74)"
        >
          <g
            id="Group_2763"
            dataname="Group 2763"
            transform="translate(449 1138)"
          >
            <path
              id="Path_9291"
              dataname="Path 9291"
              d="M-181.522,17.854a5.8,5.8,0,0,0-2.461-3.684,8.118,8.118,0,0,0-4.76-1.405,8.024,8.024,0,0,0-4.7,1.556,5.717,5.717,0,0,0-2.3,3.749"
              transform="translate(207.993 11.009)"
              fill="none"
              stroke="#199bd3"
              strokeLinecap="round"
              strokeWidth="2"
            />
            <circle
              id="Ellipse_295"
              dataname="Ellipse 295"
              cx="3.586"
              cy="3.586"
              r="3.586"
              transform="translate(15.793 11.824)"
              fill="none"
              stroke="#199bd3"
              strokeLinecap="round"
              strokeWidth="2"
            />
            <path
              id="Path_9291-2"
              dataname="Path 9291"
              d="M-191.412,12.014a8.1,8.1,0,0,0-2.844-.442,7.983,7.983,0,0,0-4.7,1.556,5.7,5.7,0,0,0-2.3,3.749"
              transform="translate(201.25 9.55)"
              fill="none"
              stroke="#199bd3"
              strokeLinecap="round"
              strokeWidth="2"
            />
            <circle
              id="Ellipse_295-2"
              dataname="Ellipse 295"
              cx="3.586"
              cy="3.586"
              r="3.586"
              transform="translate(3.537 9.17)"
              fill="none"
              stroke="#199bd3"
              strokeLinecap="round"
              strokeWidth="2"
            />
            <path
              id="Path_9291-3"
              dataname="Path 9291"
              d="M-177.981,16.876a5.707,5.707,0,0,0-2.3-3.749,7.993,7.993,0,0,0-4.7-1.556,8.086,8.086,0,0,0-2.844.442"
              transform="translate(217.681 9.55)"
              fill="none"
              stroke="#199bd3"
              strokeLinecap="round"
              strokeWidth="2"
            />
            <circle
              id="Ellipse_295-3"
              dataname="Ellipse 295"
              cx="3.586"
              cy="3.586"
              r="3.586"
              transform="translate(28.989 9.17)"
              fill="none"
              stroke="#199bd3"
              strokeLinecap="round"
              strokeWidth="2"
            />
            <circle
              id="Ellipse_295-4"
              dataname="Ellipse 295"
              cx="3.586"
              cy="3.586"
              r="3.586"
              transform="translate(15.062 0)"
              fill="none"
              stroke="#199bd3"
              strokeLinecap="round"
              strokeWidth="2"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconColoredUsers;
