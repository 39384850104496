import React from "react";

const IconWarn = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 28.509 25.091">
      <g dataname="Group 1752" transform="translate(-111 -358.716)">
        <g
          id="Path_9208"
          dataname="Path 9208"
          transform="translate(108.755 355.807)"
          fill="none"
        >
          <path
            d="M 16.49999046325684 4.909091949462891 C 16.32818031311035 4.909091949462891 15.90029907226562 4.957061767578125 15.63846015930176 5.401391983032227 L 4.388460159301758 24.49230194091797 C 4.122539520263672 24.94356155395508 4.29517936706543 25.34464073181152 4.381750106811523 25.49614143371582 C 4.468320846557617 25.64763069152832 4.726219177246094 26 5.25 26 L 27.75 26 C 28.27376937866211 26 28.53166961669922 25.64764022827148 28.61824035644531 25.49614143371582 C 28.70481109619141 25.34464073181152 28.87744903564453 24.94356155395508 28.61153030395508 24.49230194091797 L 17.36153030395508 5.401391983032227 C 17.09968948364258 4.957061767578125 16.67180061340332 4.909091949462891 16.49999046325684 4.909091949462891 M 16.4999942779541 2.909088134765625 C 17.50219345092773 2.909088134765625 18.50439071655273 3.401395797729492 19.08460998535156 4.386011123657227 L 30.33460998535156 23.47692108154297 C 31.51310920715332 25.4768009185791 30.0712890625 28.00000190734863 27.75 28.00000190734863 L 5.25 28.00000190734863 C 2.9287109375 28.00000190734863 1.486879348754883 25.4768009185791 2.665380477905273 23.47692108154297 L 13.91538047790527 4.386011123657227 C 14.4955997467041 3.401395797729492 15.4977970123291 2.909088134765625 16.4999942779541 2.909088134765625 Z"
            stroke="none"
            fill="#000"
          />
        </g>
        <g id="Group_1742" dataname="Group 1742" transform="translate(-1.245)">
          <path
            id="Path_9207"
            dataname="Path 9207"
            d="M-10290.5-10577.347v5.411"
            transform="translate(10417 10945)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeWidth="2"
          />
          <path
            id="Path_9209"
            dataname="Path 9209"
            d="M-10290.5-10577.347v.516"
            transform="translate(10417 10955)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
};

export default IconWarn;
