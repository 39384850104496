import Req from "../interceptors/token-interceptor";
import qs from "query-string";
import { cleanObj } from "src/helpers/util";
const DashboardService = (function () {
  function _getDashboardData(endpoint, params) {
    const sentParams = { ...cleanObj(params) };
    return Req.get(`/api/${endpoint}?${qs.stringify(sentParams)}`);
  }

  function _exportTopPaidInOutProject(roleId, investorId) {
    let endpoint;
    if (investorId) {
      endpoint = `/api/top5PaidInOutProjectList?export=true&defaultRoleId=${roleId}&investorId=${investorId}`;
    } else {
      endpoint = `/api/top5PaidInOutProjectList?export=true&defaultRoleId=${roleId}`;
    }
    return Req.get(endpoint, {
      responseType: "arraybuffer",
    });
  }

  function _getAllNews(params) {
    // const sentParams = { ...cleanObj(params) };
    return Req.get(
      `/api/news_informations?orderBy=${params.orderBy}&page=${params.page}&pageSize=${params.pageSize}&sortedBy=${params.sortBy}`
    );
  }
  function _addNews(data) {
    return Req.post("/api/news_informations", data);
  }

  function _getNews(id) {
    return Req.get(`/api/news_informations/${id}`);
  }

  function _updateNews(data, id) {
    return Req.put(`/api/news_informations/${id}`, data);
  }

  function _deleteNews(id) {
    return Req.delete(`/api/news_informations/${id}`);
  }

  return {
    getDashboardData: _getDashboardData,
    exportTopPaidInOutProject: _exportTopPaidInOutProject,
    getAllNews: _getAllNews,
    addNews: _addNews,
    getNews: _getNews,
    updateNews: _updateNews,
    deleteNews: _deleteNews,
  };
})();
export default DashboardService;
