export const GET_NOTIFICATION_LIST = "GET_NOTIFICATION_LIST";
export const GET_NOTIFICATION_LIST_SUCCESS = "GET_NOTIFICATION_LIST_SUCCESS";
export const GET_NOTIFICATION_LIST_ERROR = "GET_NOTIFICATION_LIST_ERROR";
export const GET_RECENT_NOTIFICATIONS = "GET_RECENT_NOTIFICATIONS";
export const GET_RECENT_NOTIFICATIONS_SUCCESS =
  "GET_RECENT_NOTIFICATIONS_SUCCESS";
export const GET_RECENT_NOTIFICATIONS_ERROR = "GET_RECENT_NOTIFICATIONS_ERROR";
export const MARK_NOTIFICATION_AS_READ = "MARK_NOTIFICATION_AS_READ";
export const MARK_NOTIFICATION_AS_READ_SUCCESS =
  "MARK_NOTIFICATION_AS_READ_SUCCESS";
export const MARK_NOTIFICATION_AS_READ_ERROR =
  "MARK_NOTIFICATION_AS_READ_ERROR";
export const RESET_NOTIFICATION = "RESET_NOTIFICATION";

export const getNotificationList = (dbParam) => ({
  type: GET_NOTIFICATION_LIST,
  payload: { dbParam },
});

export const getNotificationListSuccess = (notificationList, metaData) => ({
  type: GET_NOTIFICATION_LIST_SUCCESS,
  payload: { notificationList, metaData },
});

export const getNotificationListError = (error) => ({
  type: GET_NOTIFICATION_LIST_ERROR,
  payload: error,
});
export const getRecentNotification = (roleId) => ({
  type: GET_RECENT_NOTIFICATIONS,
  payload: { roleId },
});

export const getRecentNotificationSuccess = (notifications) => ({
  type: GET_RECENT_NOTIFICATIONS_SUCCESS,
  payload: { notifications },
});

export const getRecentNotificationError = (error) => ({
  type: GET_RECENT_NOTIFICATIONS_ERROR,
  payload: error,
});

export const markNotificationAsRead = (notificationId) => ({
  type: MARK_NOTIFICATION_AS_READ,
  payload: { notificationId },
});

export const markNotificationAsReadSuccess = (success) => ({
  type: MARK_NOTIFICATION_AS_READ_SUCCESS,
  payload: { success },
});

export const markNotificationAsReadError = (error) => ({
  type: MARK_NOTIFICATION_AS_READ_ERROR,
  payload: error,
});

export const resetNotification = () => ({
  type: RESET_NOTIFICATION,
  payload: {},
});
