import React from "react";

const IconPrint = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 29.394 30.244">
      <g id="Group_5959" data-name="Group 5959" transform="translate(0 0)">
        <path
          id="Path_10752"
          dataname="Path 10752"
          d="M-1888.6-131.354h-1.6v-5.853a1.96,1.96,0,0,0-1.958-1.957h-17.048a1.961,1.961,0,0,0-1.959,1.957v5.853h-2.034a2.4,2.4,0,0,0-2.4,2.4v9.845h4.433v5.609a.619.619,0,0,0,.619.619.619.619,0,0,0,.62-.619v-8.029h-2.913a.619.619,0,0,1-.619-.619.619.619,0,0,1,.619-.619h23.671a.619.619,0,0,1,.619.619h.255l-.256.008a.619.619,0,0,1-.618.611h-1.032v-.01h-1.239v10.661a.72.72,0,0,1-.719.72h-11.925a.619.619,0,0,0-.619.619.619.619,0,0,0,.619.619h11.925a1.96,1.96,0,0,0,1.958-1.959v-8.232h4v-9.845A2.4,2.4,0,0,0-1888.6-131.354Zm-21.323-5.853a.72.72,0,0,1,.72-.719h17.048a.719.719,0,0,1,.719.719v5.853h-18.487Zm18.384,9.436h-3.521a.619.619,0,0,1-.619-.619.619.619,0,0,1,.619-.619h3.521a.619.619,0,0,1,.619.619A.619.619,0,0,1-1891.543-127.772Z"
          transform="translate(1915.599 139.165)"
        />
        <path
          id="Path_10753"
          dataname="Path 10753"
          d="M-1845.128,2.813a.7.7,0,0,0,.7-.7.7.7,0,0,0-.7-.7h-11a.7.7,0,0,0-.7.7.7.7,0,0,0,.7.7Z"
          transform="translate(1864.918 17.944)"
        />
        <path
          id="Path_10754"
          dataname="Path 10754"
          d="M-1843.624,37.925a.619.619,0,0,0-.619-.619h-8.532a.619.619,0,0,0-.62.619.619.619,0,0,0,.62.619h8.532A.619.619,0,0,0-1843.624,37.925Z"
          transform="translate(1861.958 -13.014)"
        />
      </g>
    </svg>
  );
};

export default IconPrint;
