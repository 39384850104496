import { combineReducers } from "redux";
import auth from "./auth/reducer";
import media from "./media/reducer";
import shared from "./shared/reducer";
import user from "./user/reducer";
import role from "./role/reducer";
import configChoice from "./config-choice/reducer";
import configChoiceCategory from "./config-choice-category/reducer";
import project from "./project/reducer";
import investor from "./investor/reducer";
import paidInOut from "./paid-in-out/reducer";
import paidIn from "./paid-in/reducer";
import paidOut from "./paid-out/reducer";
import normalDocument from "./document/normal/reducer";
import boardDocument from "./document/board/reducer";
import message from "./message/reducer";
import notification from "./notification/reducer";
import dashboard from "./dashboard/reducer";
import paidInOutFund from "./paid-in-out-fund/reducer";
import paidInFund from "./paid-in-fund/reducer";
import paidOutFund from "./paid-out-fund/reducer";
const reducers = combineReducers({
  auth,
  media,
  shared,
  user,
  role,
  configChoice,
  configChoiceCategory,
  project,
  investor,
  paidInOut,
  paidIn,
  paidOut,
  normalDocument,
  boardDocument,
  message,
  notification,
  dashboard,
  paidInOutFund,
  paidInFund,
  paidOutFund
});

export default reducers;
