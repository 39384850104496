export const UPLOAD_MEDIA = "UPLOAD_MEDIA";
export const UPLOAD_MEDIA_SUCCESS = "UPLOAD_MEDIA_SUCCESS";
export const UPLOAD_MEDIA_ERROR = "UPLOAD_MEDIA_ERROR";
export const DELETE_MEDIA = "DELETE_MEDIA";
export const DELETE_MEDIA_SUCCESS = "DELETE_MEDIA_SUCCESS";
export const DELETE_MEDIA_ERROR = "DELETE_MEDIA_ERROR";
export const RESET_MEDIA = "RESET_MEDIA";
export const UPLOAD_MEDIA_PROFILE="UPLOAD_MEDIA_PROFILE";
export const UPLOAD_MEDIA_PROFILE_SUCCESS="UPLOAD_MEDIA_PROFILE_SUCCESS";
export const UPLOAD_MEDIA_PROFILE_ERROR="UPLOAD_MEDIA_PROFILE_ERROR";

export const uploadMedia = (mediaFile) => ({
  type: UPLOAD_MEDIA,
  payload: { mediaFile },
});

export const uploadMediaSuccess = (mediaData) => ({
  type: UPLOAD_MEDIA_SUCCESS,
  payload: mediaData,
});

export const uploadMediaError = (error) => ({
  type: UPLOAD_MEDIA_ERROR,
  payload: error,
});

export const uploadMediaProfile=(mediaFile)=>({
  type:UPLOAD_MEDIA_PROFILE,
  payload:{mediaFile}
})

export const uploadMediaProfileSuccess=(mediaData)=>({
  type:UPLOAD_MEDIA_PROFILE_SUCCESS,
  payload:mediaData
})
export const uploadMediaProfileError = (error) => ({
  type: UPLOAD_MEDIA_PROFILE_ERROR,
  payload: error,
});

export const deleteMedia = (mediaId) => ({
  type: DELETE_MEDIA,
  payload: { mediaId },
});

export const deleteMediaSuccess = (success, message) => ({
  type: DELETE_MEDIA_SUCCESS,
  payload: { success, message },
});

export const deleteMediaError = (error) => ({
  type: DELETE_MEDIA_ERROR,
  payload: error,
});

export const resetMedia = () => ({
  type: RESET_MEDIA,
  payload: {},
});
