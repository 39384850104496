import React from "react";

const IconSiteSetting = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 26.271 15.045"
    >
      <g
        id="Group_1851"
        dataname="Group 1851"
        transform="translate(641.046 -84.155)"
      >
        <path
          id="Path_9218"
          dataname="Path 9218"
          d="M-615.318,94.81V93.75a.423.423,0,0,0-.422-.423h-.625a.422.422,0,0,1-.389-.261l0-.006a.42.42,0,0,1,.09-.46l.442-.442a.423.423,0,0,0,0-.6l-.751-.749a.422.422,0,0,0-.6,0l-.442.441a.422.422,0,0,1-.46.091l-.005,0a.42.42,0,0,1-.261-.389v-.624a.422.422,0,0,0-.423-.422h-1.062a.422.422,0,0,0-.422.422v.623a.423.423,0,0,1-.262.39l-.006,0a.422.422,0,0,1-.459-.09l-.441-.441a.422.422,0,0,0-.6,0l-.751.749a.422.422,0,0,0,0,.6l.442.442a.424.424,0,0,1,.09.46l0,.006a.42.42,0,0,1-.389.261h-.624a.423.423,0,0,0-.423.423v1.06a.423.423,0,0,0,.423.423h.624a.42.42,0,0,1,.389.261l0,.006a.424.424,0,0,1-.09.46l-.442.442a.422.422,0,0,0,0,.6l.751.75a.423.423,0,0,0,.6,0l.441-.441a.421.421,0,0,1,.459-.091l.006,0a.421.421,0,0,1,.262.389v.624a.422.422,0,0,0,.422.423h1.062a.423.423,0,0,0,.423-.423v-.625a.42.42,0,0,1,.261-.389l.005,0a.42.42,0,0,1,.46.09l.442.442a.423.423,0,0,0,.6,0l.751-.75a.423.423,0,0,0,0-.6l-.442-.442a.42.42,0,0,1-.09-.46l0-.006a.422.422,0,0,1,.389-.261h.625A.422.422,0,0,0-615.318,94.81Zm-2.917-.3a1.493,1.493,0,0,1-.267.638,1.471,1.471,0,0,1-.323.322,1.468,1.468,0,0,1-.64.268,1.725,1.725,0,0,1-.229.016,1.726,1.726,0,0,1-.229-.016,1.473,1.473,0,0,1-.64-.268,1.448,1.448,0,0,1-.322-.322,1.493,1.493,0,0,1-.267-.638,1.74,1.74,0,0,1-.016-.23,1.752,1.752,0,0,1,.016-.23,1.5,1.5,0,0,1,.267-.638,1.448,1.448,0,0,1,.322-.322,1.46,1.46,0,0,1,.64-.267,1.572,1.572,0,0,1,.229-.017,1.572,1.572,0,0,1,.229.017,1.455,1.455,0,0,1,.64.267,1.471,1.471,0,0,1,.323.322,1.5,1.5,0,0,1,.267.638,1.752,1.752,0,0,1,.016.23A1.74,1.74,0,0,1-618.235,94.51Z"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.085"
        />
        <path
          id="Path_9219"
          dataname="Path 9219"
          d="M-627.563,95.79h-12.025a.86.86,0,0,1-.86-.86V85.61a.86.86,0,0,1,.86-.86h19.34a.86.86,0,0,1,.86.86v1.54"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.19"
        />
        <line
          id="Line_1"
          dataname="Line 1"
          x2="18.418"
          transform="translate(-640.451 88.258)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.19"
        />
        <line
          id="Line_2"
          dataname="Line 2"
          x2="3.706"
          transform="translate(-637.092 92.375)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.19"
        />
      </g>
    </svg>
  );
};

export default IconSiteSetting;
