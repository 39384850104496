import {
  GET_ALL_PROJECT_FOLDER,
  GET_ALL_PROJECT_FOLDER_SUCCESS,
  GET_ALL_PROJECT_FOLDER_ERROR,
  ADD_PROJECT_FOLDER,
  ADD_PROJECT_FOLDER_SUCCESS,
  ADD_PROJECT_FOLDER_ERROR,
  EDIT_PROJECT_FOLDER,
  EDIT_PROJECT_FOLDER_SUCCESS,
  EDIT_PROJECT_FOLDER_ERROR,
  DELETE_PROJECT_FOLDER,
  DELETE_PROJECT_FOLDER_SUCCESS,
  DELETE_PROJECT_FOLDER_ERROR,
  GET_PROJECT_FOLDER_LIST,
  GET_PROJECT_FOLDER_LIST_SUCCESS,
  GET_PROJECT_FOLDER_LIST_ERROR,
  GET_FOLDER_DOCUMENT_LIST,
  GET_FOLDER_DOCUMENT_LIST_SUCCESS,
  GET_FOLDER_DOCUMENT_LIST_ERROR,
  ADD_FOLDER_DOCUMENT,
  ADD_FOLDER_DOCUMENT_SUCCESS,
  ADD_FOLDER_DOCUMENT_ERROR,
  GET_FOLDER_DOCUMENT,
  GET_FOLDER_DOCUMENT_SUCCESS,
  GET_FOLDER_DOCUMENT_ERROR,
  EDIT_FOLDER_DOCUMENT,
  EDIT_FOLDER_DOCUMENT_SUCCESS,
  EDIT_FOLDER_DOCUMENT_ERROR,
  DELETE_FOLDER_DOCUMENT,
  DELETE_FOLDER_DOCUMENT_SUCCESS,
  DELETE_FOLDER_DOCUMENT_ERROR,
  DELETE_MULTIPLE_FOLDER_DOCUMENT,
  DELETE_MULTIPLE_FOLDER_DOCUMENT_SUCCESS,
  DELETE_MULTIPLE_FOLDER_DOCUMENT_ERROR,
  RESET_FOLDER_DOCUMENT,
  GET_PROJECT_FOLDER,
  GET_PROJECT_FOLDER_SUCCESS,
  GET_PROJECT_FOLDER_ERROR,
  DELETE_ROOT_PROJECT,
  DELETE_ROOT_PROJECT_SUCCESS,
  DELETE_ROOT_PROJECT_ERROR,
  DOWNLOAD_BOARD_DOCUMENT,
  DOWNLOAD_BOARD_DOCUMENT_SUCCESS,
  DOWNLOAD_BOARD_DOCUMENT_ERROR,
  VIEW_BOARD_DOCUMENT,
  VIEW_BOARD_DOCUMENT_SUCCESS,
  VIEW_BOARD_DOCUMENT_ERROR,
  CLEAR_PROJECT_LIST,
  INITIAL_PROJECT_LIST,
} from "../../actions";

const INIT_STATE = {
  dbParam: null,
  documents: null,
  documentList: null,
  documentData: null,
  folderData: null,
  documentId: null,
  documentIds: null,
  projects: null,
  folders: null,
  success: false,
  message: null,
  loading: false,
  loading1: false,
  loading3: false,
  error: null,
  metaData: null,
  fetchFolder: false,
  initialProjects: "",
  folderChange: false,
  clearChange: false,
};

const boardDocumentReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_PROJECT_FOLDER:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ALL_PROJECT_FOLDER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: false,
        projects: action.payload,
        error: null,
        initialProjects: state.folderChange
          ? action.payload
          : state?.initialProjects,
        clearChange: state.folderChange,
      };
    case GET_ALL_PROJECT_FOLDER_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        projects: null,
        error: action.payload,
      };
    case ADD_PROJECT_FOLDER:
      return {
        ...state,
        fetchProject: true,
        loading: true,
        error: null,
      };
    case ADD_PROJECT_FOLDER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        fetchFolder: true,
        error: null,
        folderChange: true,
      };
    case ADD_PROJECT_FOLDER_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        fetchFolder: false,
        message: null,
        error: action.payload,
      };

    case GET_PROJECT_FOLDER:
      return {
        ...state,
        loading3: true,
        error: null,
      };
    case GET_PROJECT_FOLDER_SUCCESS:
      return {
        ...state,
        loading3: false,
        folderData: action.payload,
        error: null,
      };
    case GET_PROJECT_FOLDER_ERROR:
      return {
        ...state,
        loading3: false,
        folderData: null,
        error: action.payload,
      };

    case EDIT_PROJECT_FOLDER:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case EDIT_PROJECT_FOLDER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case EDIT_PROJECT_FOLDER_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };

    case DELETE_PROJECT_FOLDER:
      return {
        ...state,
        loading1: true,
        error: null,
      };
    case DELETE_PROJECT_FOLDER_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_PROJECT_FOLDER_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };

    case GET_PROJECT_FOLDER_LIST:
      return {
        ...state,
        loading: true,
        documentId: null,
        documentData: null,
        documentIds: null,
        error: null,
      };
    case GET_PROJECT_FOLDER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        folders: action.payload,
        error: null,
      };
    case GET_PROJECT_FOLDER_LIST_ERROR:
      return {
        ...state,
        loading: false,
        folders: null,
        error: action.payload,
      };

    case GET_FOLDER_DOCUMENT_LIST:
      return {
        ...state,
        // loading: true,
        documentId: null,
        documentData: null,
        documentIds: null,
        error: null,
      };
    case GET_FOLDER_DOCUMENT_LIST_SUCCESS:
      return {
        ...state,
        // loading: false,
        documentList: action.payload.documents,
        metaData: action.payload.metaData,
        error: null,
      };
    case GET_FOLDER_DOCUMENT_LIST_ERROR:
      return {
        ...state,
        // loading: false,
        documentList: null,
        error: action.payload,
      };
    case ADD_FOLDER_DOCUMENT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_FOLDER_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,

        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case ADD_FOLDER_DOCUMENT_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case GET_FOLDER_DOCUMENT:
      return {
        ...state,
        error: null,
      };
    case GET_FOLDER_DOCUMENT_SUCCESS:
      return {
        ...state,
        documentData: action.payload,
        error: null,
      };
    case GET_FOLDER_DOCUMENT_ERROR:
      return {
        ...state,
        documentData: null,
        error: action.payload,
      };
    case EDIT_FOLDER_DOCUMENT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case EDIT_FOLDER_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case EDIT_FOLDER_DOCUMENT_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DELETE_FOLDER_DOCUMENT:
      return {
        ...state,
        loading1: true,
        error: null,
      };
    case DELETE_FOLDER_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_FOLDER_DOCUMENT_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DELETE_MULTIPLE_FOLDER_DOCUMENT:
      return {
        ...state,
        loading1: true,
        error: null,
      };
    case DELETE_MULTIPLE_FOLDER_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DELETE_MULTIPLE_FOLDER_DOCUMENT_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case RESET_FOLDER_DOCUMENT:
      return {
        ...state,
        loading: false,
        loading1: false,
        success: false,
        message: null,
        error: null,
        documentData: null,
        folderData: null,
        fetchFolder: false,
      };
    case DELETE_ROOT_PROJECT:
      return {
        ...state,
        loading1: true,
        error: null,
      };
    case DELETE_ROOT_PROJECT_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        folderChange: true,
        error: null,
      };
    case DELETE_ROOT_PROJECT_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DOWNLOAD_BOARD_DOCUMENT:
      return {
        ...state,
        loading1: true,
        error: null,
      };
    case DOWNLOAD_BOARD_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case DOWNLOAD_BOARD_DOCUMENT_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case VIEW_BOARD_DOCUMENT:
      return {
        ...state,
        error: null,
      };
    case VIEW_BOARD_DOCUMENT_SUCCESS:
      return {
        ...state,
        documentData: action.payload,
        error: null,
      };
    case VIEW_BOARD_DOCUMENT_ERROR:
      return {
        ...state,
        documentData: null,
        error: action.payload,
      };

    case CLEAR_PROJECT_LIST:
      return {
        ...state,
        folderChange: false,
      };
    case INITIAL_PROJECT_LIST:
      return {
        ...state,
        folderChange: true,
      };

    default:
      return { ...state };
  }
};
export default boardDocumentReducer;
