import React, { useState, useEffect } from "react";
import { useField } from "formik";
import { Switch } from "@headlessui/react";
import { useTranslation } from "react-i18next";

const StatusToggle = ({
  label,
  labelPos,
  options,
  switchText,
  classes,
  handleFilter,
  ...props
}) => {
  const { i18n } = useTranslation();
  const [field, meta, handler] = useField(props);
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = (value) => {
    setIsChecked(value);

    if (value) {
      handler.setValue(
        options?.find(
          (element) =>
            element.configChoice === "active" ||
            element.configChoice === "yes" ||
            element.configChoice === "Active" ||
            element.configChoice === "notifyViaEmail"
        )?.id || ""
      );
    } else {
      handler.setValue(
        options?.find(
          (element) =>
            element.configChoice === "inactive" ||
            element.configChoice === "Inactive" ||
            element.configChoice === "no" ||
            element.configChoice === "noNotifyViaEmail"
        )?.id || ""
      );
    }
    if (handleFilter) {
      if (value) {
        handleFilter(
          options?.find(
            (element) =>
              element.configChoice === "active" ||
              element.configChoice === "yes" ||
              element.configChoice === "Active" ||
              element.configChoice === "notifyViaEmail"
          )?.id || ""
        );
      } else {
        handleFilter(
          options?.find(
            (element) =>
              element.configChoice === "inactive" ||
              element.configChoice === "Inactive" ||
              element.configChoice === "no" ||
              element.configChoice === "noNotifyViaEmail"
          )?.id || ""
        );
      }
    }
  };

  useEffect(() => {
    if (
      field.value ===
      options?.find(
        (element) =>
          element.configChoice === "active" ||
          element.configChoice === "Active" ||
          element.configChoice === "yes" ||
          element.configChoice === "notifyViaEmail"
      )?.id
    ) {
      setIsChecked(true);
    } else if (field.value === true) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value, options]);

  return (
    <div className={`flex items-center ${classes.root}`}>
      <label
        className={`text-gray-400 
        ${labelPos === "end" ? "order-1 ml-4" : "mr-4"} 
        ${classes.label}
        `}
      >
        {label ? label : null}
      </label>

      <Switch
        type="button"
        {...field}
        checked={isChecked}
        onChange={handleChange}
        className={`switch ${isChecked ? "checked" : ""} ${classes.switch}`}
      >
        <span className="sr-only">{label ? label : null}</span>
        <span className={`switch__handle`}>
          {isChecked && (
            <svg width="7.731" height="7.731" viewBox="0 0 7.731 7.731">
              <defs>
                <clipPath id="clip-path">
                  <path
                    d="M5.835.415A1.073,1.073,0,0,1,7.3.2a.991.991,0,0,1,.227,1.416L4.062,6.185a.644.644,0,0,1-.962.074L.306,3.553a.989.989,0,0,1,0-1.433,1.075,1.075,0,0,1,1.483,0L3.378,3.658Z"
                    transform="translate(0 0)"
                  />
                </clipPath>
              </defs>
              <rect width="7.731" height="7.731" fill="none" />
              <g transform="translate(0 0.966)">
                <path
                  d="M5.835.415A1.073,1.073,0,0,1,7.3.2a.991.991,0,0,1,.227,1.416L4.062,6.185a.644.644,0,0,1-.962.074L.306,3.553a.989.989,0,0,1,0-1.433,1.075,1.075,0,0,1,1.483,0L3.378,3.658Z"
                  transform="translate(0 0)"
                />
              </g>
            </svg>
          )}
        </span>
      </Switch>
      {switchText && (
        <span className="ml-2 text-sm">
          {isChecked
            ? options?.find(
                (element) =>
                  element.configChoice === "active" ||
                  element.configChoice === "yes"
              )?.displayName?.[i18n.language]
            : options?.find(
                (element) =>
                  element.configChoice === "inactive" ||
                  element.configChoice === "no"
              )?.displayName?.[i18n.language]}
        </span>
      )}
      {meta.touched && meta.error ? null : null}
    </div>
  );
};

export default StatusToggle;
