import React from "react";

const IconChevronDown = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22">
      <path
        fill="none"
        stroke="#000000"
        strokeWidth="1.5215"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="15.2154"
        d="M20.2,6.6l-8.8,8.8L2.5,6.6"
      />
    </svg>
  );
};

export default IconChevronDown;
