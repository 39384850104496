import React from "react";

const IconPhoneCode = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 59.873 67.896"
    >
      <g
        id="Group_1976"
        dataname="Group 1976"
        transform="translate(-64.908 105.296)"
      >
        <g
          id="Group_1964"
          dataname="Group 1964"
          transform="translate(64.908 -105.296)"
        >
          <path
            id="Path_9304"
            dataname="Path 9304"
            d="M100.611-37.4H72.951a8.053,8.053,0,0,1-8.043-8.043V-97.253a8.053,8.053,0,0,1,8.043-8.043h27.66a8.053,8.053,0,0,1,8.043,8.043v13.519h-2.306V-97.253a5.744,5.744,0,0,0-5.737-5.738H72.951a5.745,5.745,0,0,0-5.737,5.738v51.809a5.745,5.745,0,0,0,5.737,5.738h27.66a5.744,5.744,0,0,0,5.737-5.738V-63.5h2.306v18.059A8.053,8.053,0,0,1,100.611-37.4Z"
            transform="translate(-64.908 105.296)"
          />
        </g>
        <g
          id="Group_1965"
          dataname="Group 1965"
          transform="translate(66.061 -51.619)"
        >
          <rect
            id="Rectangle_1595"
            dataname="Rectangle 1595"
            width="41.441"
            height="2.306"
          />
        </g>
        <g
          id="Group_1966"
          dataname="Group 1966"
          transform="translate(66.061 -92.953)"
        >
          <rect
            id="Rectangle_1596"
            dataname="Rectangle 1596"
            width="41.441"
            height="2.306"
          />
        </g>
        <g
          id="Group_1967"
          dataname="Group 1967"
          transform="translate(82.764 -99.54)"
        >
          <path
            id="Path_9305"
            dataname="Path 9305"
            d="M94.369-95.9h-6.3a1.153,1.153,0,0,1-1.153-1.153A1.153,1.153,0,0,1,88.068-98.2h6.3a1.153,1.153,0,0,1,1.152,1.153A1.152,1.152,0,0,1,94.369-95.9Z"
            transform="translate(-86.915 98.202)"
          />
        </g>
        <g
          id="Group_1968"
          dataname="Group 1968"
          transform="translate(84.491 -45.621)"
        >
          <circle
            id="Ellipse_285"
            dataname="Ellipse 285"
            cx="1.7"
            cy="1.7"
            r="1.7"
          />
        </g>
        <g
          id="Group_1969"
          dataname="Group 1969"
          transform="translate(77.037 -84.129)"
        >
          <path
            id="Path_9306"
            dataname="Path 9306"
            d="M117.447-57.43H90.011A10.166,10.166,0,0,1,79.856-67.584v-1.471A10.166,10.166,0,0,1,90.011-79.209h27.436A10.166,10.166,0,0,1,127.6-69.055v1.471A10.165,10.165,0,0,1,117.447-57.43ZM90.011-76.9a7.857,7.857,0,0,0-7.849,7.848v1.471a7.857,7.857,0,0,0,7.849,7.849h27.436a7.857,7.857,0,0,0,7.848-7.849v-1.471a7.856,7.856,0,0,0-7.848-7.848Z"
            transform="translate(-79.856 79.209)"
          />
        </g>
        <g
          id="Group_1971"
          dataname="Group 1971"
          transform="translate(88.781 -76.575)"
        >
          <g id="Group_1970" dataname="Group 1970" transform="translate(0 0)">
            <path
              id="Path_9307"
              dataname="Path 9307"
              d="M100.764-66.993l-2.907.524,2.1.557a2.915,2.915,0,0,1,.4.127.881.881,0,0,1,.3.23.581.581,0,0,1,.151.4q0,.747-1.08,1.176l-2.05-2.271.715,2.986a1.6,1.6,0,0,1-.953.413,1.039,1.039,0,0,1-.651-.174.59.59,0,0,1-.222-.493,2.43,2.43,0,0,1,.143-.747l.683-2-1.97,2.287a2.224,2.224,0,0,1-.755-.461.911.911,0,0,1-.325-.683.592.592,0,0,1,.247-.54,2.6,2.6,0,0,1,.738-.286l1.891-.524-2.828-.524a2.514,2.514,0,0,1-.064-.612,1.294,1.294,0,0,1,.2-.682.638.638,0,0,1,.579-.326.682.682,0,0,1,.429.136q.174.135.445.389l1.445,1.382-.7-2.78a1.6,1.6,0,0,1,.953-.413,1.041,1.041,0,0,1,.652.174.6.6,0,0,1,.222.5,2.726,2.726,0,0,1-.206.881l-.62,1.652L99.143-68.1a1.4,1.4,0,0,1,.9-.509.664.664,0,0,1,.587.295,1.19,1.19,0,0,1,.2.682A2.716,2.716,0,0,1,100.764-66.993Z"
              transform="translate(-94.33 69.899)"
            />
          </g>
        </g>
        <g
          id="Group_1973"
          dataname="Group 1973"
          transform="translate(98.799 -76.575)"
        >
          <g id="Group_1972" dataname="Group 1972" transform="translate(0 0)">
            <path
              id="Path_9308"
              dataname="Path 9308"
              d="M113.11-66.993l-2.907.524,2.1.557a2.915,2.915,0,0,1,.4.127.881.881,0,0,1,.3.23.581.581,0,0,1,.15.4q0,.747-1.08,1.176l-2.049-2.271.715,2.986a1.6,1.6,0,0,1-.953.413,1.041,1.041,0,0,1-.652-.174.592.592,0,0,1-.222-.493,2.407,2.407,0,0,1,.143-.747l.682-2-1.969,2.287a2.216,2.216,0,0,1-.755-.461.908.908,0,0,1-.325-.683.591.591,0,0,1,.246-.54,2.6,2.6,0,0,1,.738-.286l1.891-.524-2.828-.524a2.518,2.518,0,0,1-.063-.612,1.293,1.293,0,0,1,.2-.682.639.639,0,0,1,.58-.326.678.678,0,0,1,.428.136c.117.09.265.22.445.389l1.445,1.382-.7-2.78a1.6,1.6,0,0,1,.953-.413,1.043,1.043,0,0,1,.652.174.6.6,0,0,1,.222.5,2.731,2.731,0,0,1-.207.881l-.62,1.652,1.414-1.413a1.4,1.4,0,0,1,.9-.509.665.665,0,0,1,.588.295,1.19,1.19,0,0,1,.2.682A2.76,2.76,0,0,1,113.11-66.993Z"
              transform="translate(-106.676 69.899)"
            />
          </g>
        </g>
        <g
          id="Group_1975"
          dataname="Group 1975"
          transform="translate(108.44 -76.575)"
        >
          <g id="Group_1974" dataname="Group 1974" transform="translate(0 0)">
            <path
              id="Path_9309"
              dataname="Path 9309"
              d="M124.992-66.993l-2.907.524,2.1.557a2.915,2.915,0,0,1,.4.127.881.881,0,0,1,.3.23.581.581,0,0,1,.151.4q0,.747-1.08,1.176l-2.05-2.271.715,2.986a1.6,1.6,0,0,1-.953.413,1.039,1.039,0,0,1-.651-.174.59.59,0,0,1-.222-.493,2.43,2.43,0,0,1,.143-.747l.683-2-1.97,2.287a2.224,2.224,0,0,1-.755-.461.911.911,0,0,1-.325-.683.59.59,0,0,1,.247-.54,2.594,2.594,0,0,1,.738-.286l1.891-.524-2.829-.524a2.559,2.559,0,0,1-.063-.612,1.293,1.293,0,0,1,.2-.682.64.64,0,0,1,.58-.326.682.682,0,0,1,.429.136q.174.135.445.389l1.446,1.382-.7-2.78a1.6,1.6,0,0,1,.953-.413,1.041,1.041,0,0,1,.652.174.6.6,0,0,1,.222.5,2.732,2.732,0,0,1-.207.881l-.619,1.652,1.413-1.413a1.4,1.4,0,0,1,.9-.509.664.664,0,0,1,.588.295,1.19,1.19,0,0,1,.2.682A2.716,2.716,0,0,1,124.992-66.993Z"
              transform="translate(-118.558 69.899)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconPhoneCode;
