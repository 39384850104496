import React from "react";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

import Card from "src/components/card";
import { IconAnimLogo, IconDownload } from "src/components/svg";

const Header = (state) => {
  return (
    <header className="flex justify-end items-center px-10 py-3 rounded-t-lg bg-primary-600">
      <a
        href={state.currentDocument.uri}
        download
        target="_blank"
        rel="noreferrer"
        className="btn-icon svg-size-xs svg-color-white"
      >
        <IconDownload />
      </a>
      {/* <button
        className="btn-icon svg-size-xs svg-color-white"
        type="button"
      >
        <IconPrint />
      </button> */}
    </header>
  );
};

const DocumentViewer = (props) => {
  const { data, loading = true } = props;
  const docs = [
    {
      uri: data ? data.url : require("src/assets/images/doc-logo.pdf").default,
      fileType: data?.mimeType,
    },
  ];

  return (
    <Card classes="mt-5 px-0 py-0 relative">
      {loading ? (
        <div className="w-full h-full absolute left-0 top-0 flex justify-center pt-28 bg-grey-200 z-10 svg-size-logo">
          <IconAnimLogo />
        </div>
      ) : null}

      {!loading && data && (
        <DocViewer
          documents={docs}
          pluginRenderers={DocViewerRenderers}
          theme={{
            primary: "#b5a47e",
            tertiary: "#ECE8E1",
          }}
          config={{
            header: {
              overrideComponent: Header,
            },
          }}
        />
      )}
    </Card>
  );
};
export default DocumentViewer;
