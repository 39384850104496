import React from "react";
import { useTranslation } from "react-i18next";
import en from "src/assets/images/en.svg";
import no from "src/assets/images/no.svg";

export default function SetLanguage() {
  const { i18n } = useTranslation();

  const setLocale = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <button type="button" onClick={() => setLocale("en")}>
        <img src={en} alt="" className="img-fluid mr-2" />
      </button>
      <button type="button" onClick={() => setLocale("no")}>
        <img src={no} alt="" className="img-fluid mr-2" />
      </button>
    </>
  );
}
