import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import DocumentService from "../../../services/document-service";
import TableDataService from "../../../services/table-data-service1";
import { convertToSlug } from "src/helpers/util";
import { saveAs } from "file-saver";
import {
  GET_ALL_DOCUMENT,
  GET_RECENT_DOCUMENT,
  GET_DOCUMENT_LIST,
  ADD_DOCUMENT,
  GET_DOCUMENT,
  EDIT_DOCUMENT,
  DELETE_DOCUMENT,
  DELETE_MULTIPLE_DOCUMENT,
  DOWNLOAD_DOCUMENT,
  GET_DOCUMENT_FILES_BY_DOCID,
} from "../../actions";
import {
  getRecentDocument,
  getAllDocumentSuccess,
  getAllDocumentError,
  getRecentDocumentSuccess,
  getRecentDocumentError,
  getDocumentList,
  getDocumentListSuccess,
  getDocumentListError,
  addDocumentSuccess,
  addDocumentError,
  getDocumentSuccess,
  getDocumentError,
  editDocumentSuccess,
  editDocumentError,
  deleteDocumentSuccess,
  deleteDocumentError,
  deleteMultipleDocumentSuccess,
  deleteMultipleDocumentError,
  downloadDocumentSuccess,
  downloadDocumentError,
  resetDocument,
  getFilesByDocIdSuccess,
  getFilesByDocIdError,
} from "./action";
import { toast } from "react-toastify";
import { handleMyErrorMessage } from "../../../helpers/util";
import ToastElement from "../../../components/toast";
import moment from "moment";

export function* watchGetAllDocument() {
  yield takeEvery(GET_ALL_DOCUMENT, getAllDocuments);
}

const getAllDocumentAsync = async () => {
  return DocumentService.getAllDocument();
};

function* getAllDocuments() {
  try {
    const response = yield call(getAllDocumentAsync);
    if (response.data.success) {
      yield put(getAllDocumentSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getAllDocumentError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getAllDocumentError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getAllDocumentError(errorMessage));
    }
  }
}

//Quick access documents
export function* watchGetRecentDocument() {
  yield takeEvery(GET_RECENT_DOCUMENT, getRecentDocuments);
}

const getRecentDocumentAsync = async (payload) => {
  const currentRole = JSON.parse(localStorage.getItem("currentRole"));
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  if (currentRole) {
    return DocumentService.getRecentDocument(currentRole?.id);
  } else {
    return DocumentService.getRecentDocument(currentUser.default_role_id);
  }
};

function* getRecentDocuments() {
  try {
    const response = yield call(getRecentDocumentAsync);
    if (response.data.success) {
      yield put(getRecentDocumentSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getRecentDocumentError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getRecentDocumentError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getRecentDocumentError(errorMessage));
    }
  }
}

export function* watchGetDocumentList() {
  yield takeEvery(GET_DOCUMENT_LIST, getDocumentListAc);
}

const getDocumentListAsync = async (dbParam) => {
  const currentRole = JSON.parse(localStorage.getItem("currentRole"));
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  if (currentRole) {
    dbParam.defaultRoleId = currentRole?.id;
  } else {
    dbParam.defaultRoleId = currentUser.default_role_id;
  }
  return TableDataService.getAllData("documents", dbParam);
};

function* getDocumentListAc({ payload }) {
  try {
    const response = yield call(getDocumentListAsync, payload.dbParam);
    if (response.data.success) {
      yield put(
        getDocumentListSuccess(response.data.data, response.data.metadata)
      );
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getDocumentListError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getDocumentListError(error.response.data.message));
    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getDocumentListError(errorMessage));
    }
  }
}

export function* watchAddDocument() {
  yield takeEvery(ADD_DOCUMENT, addDocument);
}

const addDocumentAsync = async (data) => {
  return DocumentService.addDocument(data);
};

function* addDocument({ payload }) {
  try {
    const response = yield call(addDocumentAsync, payload.documentData);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        addDocumentSuccess(response.data.success, response.data.message)
      );
      yield put(getDocumentList({}));
      yield put(getRecentDocument());
      yield put(resetDocument());
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(addDocumentError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type="error"
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   { containerId: "default" }
    // );
    // yield put(addDocumentError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(addDocumentError(errorMessage));
    }
  }
}

export function* watchGetDocument() {
  yield takeEvery(GET_DOCUMENT, getDocument);
}

const getDocumentAsync = async (id) => {
  return DocumentService.getDocument(id);
};

function* getDocument({ payload }) {
  try {
    const response = yield call(getDocumentAsync, payload.documentId);
    if (response.data.success) {
      yield put(getDocumentSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getDocumentError(response.data.message));
    }
  } catch (error) {
    // if (error.response.status === 404) {
    //   yield put(getDocumentError("404"));
    // } else {
    //   toast.error(
    //     <ToastElement type="error" message={error.response.data.message} />,
    //     { containerId: "default" }
    //   );
    //   yield put(getDocumentError(error.response.data.message));
    // }

    if (error.response && error.response.status === 404) {
      yield put(getDocumentError("404"));
    } else {
      const errorMessage = handleMyErrorMessage(error);
      if (errorMessage) {
        toast.error(<ToastElement type="error" message={errorMessage} />, {
          containerId: "default",
        });
        yield put(getDocumentError(errorMessage));
      }
    }
  }
}

export function* watchEditDocument() {
  yield takeEvery(EDIT_DOCUMENT, editDocument);
}

const editDocumentAsync = async (data, id) => {
  return DocumentService.editDocument(data, id);
};

function* editDocument({ payload }) {
  try {
    const response = yield call(
      editDocumentAsync,
      payload.documentData,
      payload.documentId
    );
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        editDocumentSuccess(response.data.success, response.data.message)
      );
      yield put(getRecentDocument());
      yield put(getDocumentList({}));
      yield put(resetDocument());
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(editDocumentError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type="error"
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   { containerId: "default" }
    // );
    // yield put(editDocumentError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(editDocumentError(errorMessage));
    }
  }
}

export function* watchDeleteDocument() {
  yield takeEvery(DELETE_DOCUMENT, deleteDocument);
}

const deleteDocumentAsync = async (id) => {
  return DocumentService.deleteDocument(id);
};

function* deleteDocument({ payload }) {
  try {
    const response = yield call(deleteDocumentAsync, payload.documentId);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        deleteDocumentSuccess(response.data.success, response.data.message)
      );
      yield put(getDocumentList({}));
      yield put(getRecentDocument());
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(deleteDocumentError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(deleteDocumentError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(deleteDocumentError(errorMessage));
    }
  }
}

export function* watchDeleteMultipleDocument() {
  yield takeEvery(DELETE_MULTIPLE_DOCUMENT, deleteMultipleDocument);
}

const deleteMultipleDocumentAsync = async (ids) => {
  return DocumentService.deleteMultipleDocument(ids);
};

function* deleteMultipleDocument({ payload }) {
  try {
    const response = yield call(
      deleteMultipleDocumentAsync,
      payload.documentIds
    );
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        deleteMultipleDocumentSuccess(
          response.data.success,
          response.data.message
        )
      );
      yield put(getDocumentList({}));
      yield put(getRecentDocument());
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(deleteMultipleDocumentError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(deleteMultipleDocumentError(error.response.data.message));
    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(deleteDocumentError(errorMessage));
    }
  }
}

export function* watchDownloadDocument() {
  yield takeEvery(DOWNLOAD_DOCUMENT, downloadDocument);
}

const downloadDocumentAsync = async (id) => {
  return DocumentService.downloadNormalDocument(id);
};

function* downloadDocument({ payload }) {
  try {
    const response = yield call(downloadDocumentAsync, payload.documentId);
    if (response && response.data) {
      yield put(downloadDocumentSuccess(true, ""));
      const blob = new Blob([response.data], {
        type: "application/zip",
      });

      const fileName =
        convertToSlug(payload.projectName) +
        "-" +
        moment().format("YYYY-MMM-DD-HH-mm");
      saveAs(blob, `${fileName}.zip`);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(downloadDocumentError(response.data.message));
    }
  } catch (error) {
    // let message = "";
    // if (error.response.status === 500) {
    //   message = error.response.statusText;
    // }
    // toast.error(<ToastElement type="error" message={message} />, {
    //   containerId: "default",
    // });
    // yield put(downloadDocumentError(message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(downloadDocumentError(errorMessage));
    }
  }
}

export function* watchGetDocumentFilesById() {
  yield takeEvery(GET_DOCUMENT_FILES_BY_DOCID, getDocumentFilesById);
}

const getDocumentFilesByIdAsync = async (id) => {
  return DocumentService.getDocumentFilesById(id);
};

function* getDocumentFilesById({ payload }) {
  try {
    const response = yield call(getDocumentFilesByIdAsync, payload.documentId);
    if (response.data.success) {
      yield put(getFilesByDocIdSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getFilesByDocIdError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getFilesByDocIdError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getFilesByDocIdError(errorMessage));
    }
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAllDocument),
    fork(watchGetDocumentList),
    fork(watchAddDocument),
    fork(watchGetDocument),
    fork(watchEditDocument),
    fork(watchDeleteDocument),
    fork(watchDeleteMultipleDocument),
    fork(watchGetRecentDocument),
    fork(watchDownloadDocument),
    fork(watchGetDocumentFilesById),
  ]);
}
