import { Component, createRef } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { withTranslation } from "react-i18next";
import PhoneField from "src/components/form/phone-field";
import { IconPhone, IconSpinner } from "src/components/svg";
import { connect } from "react-redux";
import { login } from "src/reduxs/auth/action";
import { withRouter } from "react-router-dom";
import { enterPressListener, GetLocationDetails } from "src/helpers/util";
// import axios from "axios";

class LoginInternational extends Component {
  constructor(props) {
    super(props);

    this.validationSchema = Yup.object().shape({
      phone: Yup.number().required(
        this.props.t("AUTH.VALIDATION.REQUIRED_FIELD")
      )
      .test("min5Digit", this.props.t("AUTH.VALIDATION.MIN_LENGTH", {
              name: this.props.t("AUTH.LOGIN.PHONE"),
              min: 5,
            }), 
            val => val && val.toString().length >= 5
          )
          .test("max13Digit", this.props.t("AUTH.VALIDATION.MAX_LENGTH", {
              name: this.props.t("AUTH.LOGIN.PHONE"),
              max: 13,
            }), 
            val => val && val.toString().length <= 13
          )
          .typeError(this.props.t("AUTH.VALIDATION.PHONE.PHONE_TYPE_ERROR"))
          .integer(this.props.t("AUTH.VALIDATION.PHONE.PHONE_INTEGER")),
    });
    this.state = {
      countryId: "",
      isdCode: "",
      isdCodeRequiredErrorDisplay: false,
      selectedCountry: null,
    };
    this.setCountryId = this.setCountryId.bind(this);
    this.setIsdCode = this.setIsdCode.bind(this);
    this.formRef = createRef();
  }
  setCountryId(id) {
    this.setState({ countryId: id });
  }
  setIsdCode(code) {
    this.setState({ isdCode: code });
  }

  onLogin = (values) => {
    const isdCode = this.state.isdCode;
    if (isdCode) {
      this.setState({ isdCodeRequiredErrorDisplay: false });
      const finalValues = {
        countryId: this.state.countryId,
        isdCode,
        ...values,
      };
      if (!this.props.loading) {
        this.props.login(finalValues, this.props.history);
      }
    } else {
      this.setState({ isdCodeRequiredErrorDisplay: true });
    }
  };

  submitForm(e) {
    if (e.key === "Enter") {
      this.formRef?.current?.handleSubmit();
    }
  }
  componentDidMount() {
    enterPressListener(this.submitForm);
    this.setCountryIsd();
  }
  componentWillUnmount(p) {
    enterPressListener(this.submitForm);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.countryList !== this.props.countryList) {
      this.setCountryIsd();
    }
  }

  setCountryIsd = async () => {
    const currentLocation = JSON.parse(localStorage.getItem("selectedCountry"));
    if (currentLocation) {
      this.setState({
        selectedCountry: currentLocation,
      });
    } else {
      const locationResponse = await GetLocationDetails()
      if(locationResponse && locationResponse.success && this.props?.countryList){
        const country = this.props?.countryList?.find(
          (c) => c.code === locationResponse.data.countryCode
        );
        if (country) {
          const countryInfo = {
            value: country.id,
            label: country.isdCode,
            isdCode: country.isdCode,
          };
          this.setState({
            selectedCountry: countryInfo,
          });
          localStorage.setItem("selectedCountry", JSON.stringify(countryInfo));
        }
      }
      // const { data } = await axios.get("https://geolocation-db.com/json/");
      // if (data && this.props?.countryList) {
      //   const country = this.props?.countryList?.find(
      //     (c) => c.code === data.country_code
      //   );
      //   if (country) {
      //     const countryInfo = {
      //       value: country.id,
      //       label: country.isdCode,
      //       isdCode: country.isdCode,
      //     };
      //     this.setState({
      //       selectedCountry: countryInfo,
      //     });
      //     localStorage.setItem("selectedCountry", JSON.stringify(countryInfo));
      //   }
      // }
    }
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <Formik
          innerRef={this.formRef}
          initialValues={{
            phone: "",
            type: "investorInternational",
          }}
          validationSchema={this.validationSchema}
          onSubmit={this.onLogin}
        >
          {(props) => (
            <Form
              className={`w-full xl:w-3/5 2xl:w-2/5 animate__animated animate__faster 
            ${this.props.classes?.root || ""}`}
            >
              {this.props ? (
                <>
                  <PhoneField
                    name="phone"
                    label={t("AUTH.LOGIN.PHONE")}
                    placeholder={t("AUTH.LOGIN.PHONE_PLACEHOLDER")}
                    icon={<IconPhone />}
                    iconPosition="end"
                    setCountryId={this.setCountryId}
                    setIsdCode={this.setIsdCode}
                    selectedcountry={this.state.selectedCountry}
                  />
                  {this.state.isdCodeRequiredErrorDisplay && (
                    <span className="block mb-2 text-xs text-left text-red-700">
                      {this.props.t("AUTH.VALIDATION.REQUIRED_FIELD")}
                    </span>
                  )}
                </>
              ) : null}

              <button
                className="mt-5 btn-primary md"
                type="submit"
                disabled={this.props.loading}
              >
                {t("AUTH.LOGIN.BUTTON")}

                {this.props.loading && (
                  <span className="ml-3 -mr-1">
                    <IconSpinner />
                  </span>
                )}
              </button>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  error: state.auth.error,
  countryList: state.shared.countryList,
});

export default withRouter(
  connect(mapStateToProps, { login })(withTranslation()(LoginInternational))
);
