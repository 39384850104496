import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import PaidInService from "../../services/paid-in-service";
import {
  GET_ALL_PAIDIN_FUND,
  ADD_PAIDIN_FUND,
  GET_PAIDIN,
  EDIT_PAIDIN_FUND,
  DELETE_PAIDIN_FUND,
  EXPORT_PAIDIN_FUND,
  UPDATE_PAID_IN,
} from "../actions";
import {
  getAllPaidInFundSuccess,
  getAllPaidInFundError,
  addPaidInFundSuccess,
  addPaidInFundError,
  getPaidInSuccess,
  getPaidInError,
  editPaidInFundSuccess,
  editPaidInFundError,
  deletePaidInFundSuccess,
  deletePaidInFundError,
  exportPaidInFundSuccess,
  exportPaidInFundError,
  updatePaidInError,
  updatePaidInSuccess,
} from "./action";
import {
  getAllPaidInFund,
  getpaidOutInvestorListFund,
} from "src/reduxs/actions";
import { toast } from "react-toastify";
import { handleMyErrorMessage } from "../../helpers/util";
import ToastElement from "../../components/toast";
import saveAs from "file-saver";
import moment from "moment";

export function* watchGetAllPaidInFund() {
  yield takeEvery(GET_ALL_PAIDIN_FUND, getAllPaidInFundAc);
}

const getAllPaidInAsync = async (param) => {
  return PaidInService.getAllPaidInFund(param);
};

function* getAllPaidInFundAc({ payload }) {
  try {
    const response = yield call(getAllPaidInAsync, payload.param);
    if (response.data.success) {
      yield put(
        getAllPaidInFundSuccess(
          response.data.success,
          response.data.data,
          response.data?.meta?.totalPaidInAmount
        )
      );
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getAllPaidInFundError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getAllPaidInFundError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getAllPaidInFundError(errorMessage));
    }
  }
}

export function* watchAddPaidInFund() {
  yield takeEvery(ADD_PAIDIN_FUND, addPaidInFund);
}

const addPaidInAsync = async (data) => {
  return PaidInService.addPaidInFund(data.paidInData);
};

function* addPaidInFund({ payload }) {
  try {
    const response = yield call(addPaidInAsync, payload);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        addPaidInFundSuccess(response.data.success, response.data.message)
      );

      // Fetch updated list
      yield put(
        getAllPaidInFund({
          projectId: payload.projectId,
        })
      );

      yield put(getpaidOutInvestorListFund(payload.projectId));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(addPaidInFundError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type="error"
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   { containerId: "default" }
    // );
    // yield put(addPaidInFundError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(addPaidInFundError(errorMessage));
    }
  }
}

export function* watchGetPaidIn() {
  yield takeEvery(GET_PAIDIN, getPaidInAc);
}

const getPaidInAsync = async (id) => {
  return PaidInService.getPaidIn(id);
};

function* getPaidInAc({ payload }) {
  try {
    const response = yield call(getPaidInAsync, payload.paidInId);
    if (response.data.success) {
      yield put(getPaidInSuccess(response.data.data));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(getPaidInError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(getPaidInError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(getPaidInError(errorMessage));
    }
  }
}

export function* watchEditPaidInFund() {
  yield takeEvery(EDIT_PAIDIN_FUND, editPaidInFund);
}

const editPaidInAsync = async (data, id) => {
  return PaidInService.editPaidInFund(data, id);
};

function* editPaidInFund({ payload }) {
  try {
    const response = yield call(
      editPaidInAsync,
      payload.paidInData,
      payload.paidInId
    );
    if (response.data.success) {
      yield put(
        editPaidInFundSuccess(response.data.success, response.data.message)
      );
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        getAllPaidInFund({
          projectId: payload.projectId,
        })
      );

      if (payload.paidInData?.investorId) {
        yield put(getpaidOutInvestorListFund(payload.projectId));
      }
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(editPaidInFundError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type="error"
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   { containerId: "default" }
    // );
    // yield put(editPaidInFundError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(editPaidInFundError(errorMessage));
    }
  }
}

export function* watchDeletePaidInFund() {
  yield takeEvery(DELETE_PAIDIN_FUND, deletePaidInFund);
}

const deletePaidInAsync = async (id) => {
  return PaidInService.deletePaidInFund(id);
};

function* deletePaidInFund({ payload }) {
  try {
    const response = yield call(deletePaidInAsync, payload.paidInId);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        deletePaidInFundSuccess(response.data.success, response.data.message)
      );
      // Fetch updated list
      yield put(getpaidOutInvestorListFund(payload.projectId));

      yield put(
        getAllPaidInFund({
          projectId: payload.projectId,
        })
      );
    } else {
      toast.error(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(deletePaidInFundError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(deletePaidInFundError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(deletePaidInFundError(errorMessage));
    }
  }
}

export function* watchExportPaidInFund() {
  yield takeEvery(EXPORT_PAIDIN_FUND, exportPaidIn);
}

const exportPaidInAsync = async (projectId) => {
  return PaidInService.exportPaidInFund(projectId);
};

function* exportPaidIn({ payload }) {
  try {
    const response = yield call(exportPaidInAsync, payload.projectId);
    if (response && response.data) {
      yield put(exportPaidInFundSuccess(true, ""));
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const fileName =
        "PaidInFund-" + moment().format("YYYY-MMM-DD-HH-mm") + ".xlsx";
      saveAs(blob, fileName);
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(exportPaidInFundError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement type="error" message={error.response.data.message} />,
    //   { containerId: "default" }
    // );
    // yield put(exportPaidInFundError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(exportPaidInFundError(errorMessage));
    }
  }
}

export function* watchUpdatePaidIn() {
  yield takeEvery(UPDATE_PAID_IN, updatePaidIn);
}

const updatePaidInAsync = async (data, projectId) => {
  return PaidInService.updatePaidInFund(data, projectId);
};

function* updatePaidIn({ payload }) {
  try {
    const response = yield call(
      updatePaidInAsync,
      payload.data,
      payload.projectId
    );
    if (response.data.success) {
      yield put(
        updatePaidInSuccess(response.data.success, response.data.message)
      );
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(
        getAllPaidInFund({
          projectId: payload.projectId,
        })
      );
      // yield put(
      //   getAllPaidInOut({
      //     projectId: payload.projectId,
      //     eachPropertyFund: payload?.eachPropertyFund,
      //   })
      // );
      // if (payload.paidInData?.investorId) {
      //   yield put(getpaidOutInvestorList(payload.projectId));
      // }
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default" }
      );
      yield put(updatePaidInError(response.data.message));
    }
  } catch (error) {
    // toast.error(
    //   <ToastElement
    //     type="error"
    //     message={parseMessage(
    //       error.response.data.error
    //         ? error.response.data.error
    //         : error.response.data.message
    //     )}
    //   />,
    //   { containerId: "default" }
    // );
    // yield put(updatePaidInError(error.response.data.message));

    const errorMessage = handleMyErrorMessage(error);
    if (errorMessage) {
      toast.error(<ToastElement type="error" message={errorMessage} />, {
        containerId: "default",
      });
      yield put(updatePaidInError(errorMessage));
    }
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAllPaidInFund),
    fork(watchAddPaidInFund),
    fork(watchGetPaidIn),
    fork(watchEditPaidInFund),
    fork(watchDeletePaidInFund),
    fork(watchExportPaidInFund),
    fork(watchUpdatePaidIn),
  ]);
}
