import React, { Component } from "react";
import { connect } from "react-redux";
import { login, resetAuth } from "src/reduxs/actions";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import { withTranslation } from "react-i18next";
import { InputField, InputPasswordField } from "src/components/form";
import { IconSpinner } from "src/components/svg";
import { enterPressListener } from "src/helpers/util";

class LoginMalling extends Component {
  constructor(props) {
    super(props);
    // Return Url
    this.returnUrl = this.props.location?.state?.returnUrl || null;
    // Success/error message
    this.responseMsg = this.props.location?.state?.responseMsg || "";
    this.validationSchema = Yup.object().shape({
      email: Yup.string()
        .email(this.props.t("AUTH.VALIDATION.INVALID_EMAIL"))
        .required(this.props.t("AUTH.VALIDATION.REQUIRED_FIELD")),
      password: Yup.string().required(
        this.props.t("AUTH.VALIDATION.REQUIRED_FIELD")
      ),
    });
    this.props.resetAuth();
    this.formRef = React.createRef();
    this.submitForm = this.submitForm.bind(this);
  }

  onLogin = (values) => {
    if (!this.props.loading) {
      this.props.login(values, this.props.history);
    }
  };

  submitForm(e) {
    if (e.key === "Enter") {
      this.formRef?.current?.handleSubmit();
    }
  }
  componentDidMount() {
    enterPressListener(this.submitForm);
  }
  componentWillUnmount() {
    enterPressListener(this.submitForm);
  }

  render() {
    const { t } = this.props;
    const remember = JSON.parse(localStorage.getItem("credentials"));
    return (
      <Formik
        innerRef={this.formRef}
        initialValues={{
          email: remember && remember.email ? remember.email : "",
          password: remember && remember.password ? remember.password : "",
          remember: remember && remember.remember ? 1 : 0,
          type: "mallingEmployee",
          returnUrl: this.returnUrl,
        }}
        validationSchema={this.validationSchema}
        onSubmit={this.onLogin}
      >
        {(props) => (
          <Form
            className={`w-full xl:w-3/5 2xl:w-2/5 animate__animated animate__faster ${
              this.props.classes?.root || ""
            }`}
          >
            <InputField
              name="email"
              placeholder="Enter Email"
              type="email"
              label={t("AUTH.INPUT.EMAIL")}
            />
            <div className="relative">
              <Link
                to={`/auth/forgot-password`}
                className="absolute top-0 right-0 text-xs text-primary-500 z-[1]"
              >
                {t("AUTH.GENERAL.FORGOT_BUTTON")}
              </Link>

              <InputPasswordField
                autoComplete="off"
                name="password"
                placeholder="Enter Password"
                label={t("AUTH.INPUT.PASSWORD")}
              />
            </div>

            <label className="flex items-center mb-10 text-gray-500">
              <input
                type="checkbox"
                className="mr-3 rounded border-primary-400 bg-primary-200 focus:ring-0 focus:ring-offset-0 text-primary-400 form-checkbox"
                checked={props.values.remember === 1}
                onChange={(event) =>
                  props.setFieldValue("remember", event.target.checked ? 1 : 0)
                }
              />
              {t("AUTH.GENERAL.REMEMBER_ME")}
            </label>

            <button
              className="btn-primary md"
              type="submit"
              disabled={this.props.loading}
              form={props?.formId}
            >
              {t("AUTH.LOGIN.BUTTON")}
              {this.props.loading && (
                <span className="ml-3 -mr-1">
                  <IconSpinner />
                </span>
              )}
            </button>
          </Form>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { user, loading, error } = auth;
  return { user, loading, error };
};
export default withRouter(
  connect(mapStateToProps, { login, resetAuth })(
    withTranslation()(LoginMalling)
  )
);
