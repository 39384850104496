import Req from "../interceptors/token-interceptor";
import AuthReq from "../interceptors/auth-interceptor";

const AuthService = (function () {
  function _bandIdAuth() {
    // return Req.get(
    //   "https://auth.current.bankid.no/auth/realms/current/.well-known/openid-configuration"
    // );
    return Req.get(`${process.env.REACT_APP_BANKID_AUTHORITY}`);
  }

  function _verifyUser(token) {
    return AuthReq.get(`/api/users/verify/${token}`);
  }

  function _verifyNewUser(data) {
    return AuthReq.post(`/api/user/verify`, data);
  }

  function _login(data) {
    return AuthReq.post("/api/login", data, { withCredentials: true });
  }

  function _verifyOtp(data) {
    return AuthReq.post(`/api/otp/verify`, data);
    // return AuthReq.post(`/api/otp/verify`, { otp: data.otp, isVerify: data.isVerify, phone: data.phone, countryId: data.countryId });
  }

  function _resendOtp(data) {
    return AuthReq.post(`/api/otp/resend`, data);
  }

  function _initialChangePassword(data) {
    return AuthReq.post("/api/initialChangePassword", data);
  }

  function _forgotPassword(data) {
    return AuthReq.post("/api/forgotPassword", data);
  }

  function _verifyResetToken(token) {
    return AuthReq.get(`/api/verify/${token}`);
  }

  function _resetPassword(data) {
    return AuthReq.post("/api/resetPassword", data);
  }

  function _verifyEmail(token) {
    return AuthReq.get(`/api/users/verifyEmail/${token}`);
  }

  function _logout() {
    return Req.get("/api/logout");
  }

  function _resendInvitationLink(id, type = "resend") {
    let endpoint = `/api/sendActivationLink`;
    if (type === "resend") {
      endpoint = `/api/sendActivationLink`;
    } else {
      endpoint = `/api/sendRoleAndEmailChangeLink`;
    }
    return AuthReq.post(endpoint, { userId: id });
  }

  function _resendForgotPasswordLink(email) {
    return AuthReq.post("/api/forgotPassword", { email });
  }

  function _checkUserVerification(code) {
    return Req.get(`/api/checkUserVerification?token=${code}`);
  }

  return {
    verifyUser: _verifyUser,
    verifyNewUser: _verifyNewUser,
    login: _login,
    verifyOtp: _verifyOtp,
    resendOtp: _resendOtp,
    initialChangePassword: _initialChangePassword,
    forgotPassword: _forgotPassword,
    verifyResetToken: _verifyResetToken,
    resetPassword: _resetPassword,
    verifyEmail: _verifyEmail,
    logout: _logout,
    bandIdAuth: _bandIdAuth,
    resendInvitationLink: _resendInvitationLink,
    resendForgotPasswordLink: _resendForgotPasswordLink,
    checkUserVerification: _checkUserVerification,
  };
})();
export default AuthService;
