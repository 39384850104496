import Req from "../interceptors/token-interceptor";

const MessageService = (function () {
  function _draftMessage(messageId = null, data) {
    if (messageId) {
      return Req.put(`/api/messages/${messageId}`, data);
    } else {
      return Req.post("/api/messages", data);
    }
  }
  function _sendMessage(data) {
    return Req.post("/api/messages", data);
  }
  function _replyMessage() {
    return Req.post(`/api/`);
  }

  function _getDocumentsByProject(id) {
    return Req.get(`/api/getDocumentList/${id}`);
  }
  function _getMessage(id, roleId) {
    return Req.get(`/api/messages/${id}?defaultRoleId=${roleId}`);
  }
  function _deleteMessage(ids) {
    return Req.delete(`/api/messages`);
  }
  function _deleteMultipleMessage(ids, type, defaultRoleId) {
    return Req.post("/api/messageDelete", { type, ids, defaultRoleId });
  }

  function _getMallingContactPerson() {
    return Req.get("/api/internalEmployeeList");
  }
  function _getUnreadInboxMessage(id) {
    return Req.get(`/api/inboxMessageCount?defaultRoleId=${id}`);
  }
  //seen inbox message
  function _markMessageSeen(messageId, data) {
    return Req.put(`/api/updateMessageStatus/${messageId}`, data);
  }

  //seen documents by investor
  function _markDocumentSeen(messageId, data) {
    return Req.put(`/api/updateDocumentReadStatus/${messageId}`, data);
  }

  function _downloadMessageDocument(id) {
    return Req.get(`/api/messageDocument/${id}?download=true`, {
      responseType: "arraybuffer",
    });
  }

  function _getMessageDocuments(messageId, investorId) {
    return Req.get(
      `/api/showDocumentList?messageId=${messageId}&receiverId=${investorId}`
    );
  }

  // function _getProjectByMessageType(type) {
  //   return Req.get(`/api/projectList?type=${type}`);
  // }

  return {
    draftMessage: _draftMessage,
    sendMessage: _sendMessage,
    replyMessage: _replyMessage,
    deleteMessage: _deleteMessage,
    getDocumentsByProject: _getDocumentsByProject,
    getMessage: _getMessage,
    deleteMultipleMessage: _deleteMultipleMessage,
    getMallingContactPerson: _getMallingContactPerson,
    getUnreadInboxMessage: _getUnreadInboxMessage,
    markMessageSeen: _markMessageSeen,
    downloadMessageDocument: _downloadMessageDocument,
    getMessageDocuments: _getMessageDocuments,
    markDocumentSeen: _markDocumentSeen,
    // getProjectByMessageType: _getProjectByMessageType,
  };
})();
export default MessageService;
