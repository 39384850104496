import React, { useEffect, useState } from "react";
import usePlacesAutocomplete, {
  // getGeocode,
  // getLatLng,
  getDetails
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import { useField } from "formik";
import { IconContact } from "src/components/svg";

const GoogleAutoCompleteField = ({
  label,
  classes,
  placeholder,
  callback,
  isRequired,
  ...props
}) => {
  const [field, meta, handler] = useField(props);
  const {
    ready,
    value,
    suggestions: { status, data },

    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 200,
  });
  const [customAddressSuggestion, setCustomAddressSuggestion] = useState('')

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
    setCustomAddressSuggestion('');
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
    setCustomAddressSuggestion(e.target.value)
  };

  const handleSelect =
    ({ description, place_id, isCustom }) =>
    () => {
      handler.setValue(description);
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false);
      clearSuggestions();
      setCustomAddressSuggestion('');

      if(!isCustom){
        const geometryParam = {
          placeId : place_id,
          fields: ['geometry']
        }

        // Get place detail
        getDetails(geometryParam)
        .then((details) => {
          let lat = details?.geometry?.location?.lat() || '';
          let lng = details?.geometry?.location?.lng() || '';
          if(callback){
            callback(lat, lng, description);
          }
        })
        .catch((error) => {});

        // Get latitude and longitude via utility functions (was not used because geocoding API costs extra)
        // getGeocode({ address: description })
        //   .then((results) => getLatLng(results[0]))
        //   .then(({ lat, lng }) => {
        //     if (callback) {
        //       callback(lat, lng, description);
        //     }
        //   })
        //   .catch((error) => {});
      }else{
        if(callback){
            callback('', '', description);
          }
      }

      
    };

  useEffect(() => {
    field.value ? setValue(field.value, false) : setValue("", false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  const renderSuggestions = () => {
    let customSuggestion = customAddressSuggestion ? [<li
          className="react-select__option flex items-start cursor-pointer children:flex-shrink-0"
          key={`google-custom-address-${customAddressSuggestion.split(' ').join('-')}`}
          onClick={handleSelect({description: customAddressSuggestion.replace(/\b\w/g , function(m){ return m.toUpperCase(); } ), isCustom: true})}
        >
          <IconContact />
          <span className="ml-5 -mt-1.5 text-base">
            {/* {customAddressSuggestion.substring(0, 24)+'...'} */}
            {customAddressSuggestion.replace(/\b\w/g , function(m){ return m.toUpperCase(); } )}
            <small className="block text-xs text-grey-700">
              Custom Address
            </small>
          </span>
        </li>] : []

        

    let googleSuggestion = data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          className="react-select__option flex items-start cursor-pointer children:flex-shrink-0"
          key={place_id}
          onClick={handleSelect(suggestion)}
        >
          <IconContact />
          <span className="ml-5 -mt-1.5 text-base">
            {main_text}
            <small className="block text-xs text-grey-700">
              {secondary_text}
            </small>
          </span>
        </li>
      );
    });

      return [...customSuggestion, ...googleSuggestion]
    }

  return (
    <div
      ref={ref}
      className={`field-group ${
        meta.touched && meta.error ? "field-group-error" : ""
      }  ${classes?.root || ""}`}
    >
      {label && (
        <label htmlFor="" className="field-label">
          {label ? label : null}
          {isRequired && <strong className="text-red-700">*</strong>}
        </label>
      )}
      <div className={`field-wrap`}>
        <input
          {...field}
          {...props}
          className={`field ${classes?.input || ""}`}
          value={value}
          onChange={handleInput}
          disabled={!ready}
          placeholder={placeholder}
        />
        {/* We can use the "status" to decide whether we should display the dropdown or not */}
        {/* {status === "OK" && (
          <div className="react-select__menu w-full absolute top-[3.125rem] left-0 bg-white z-10 overflow-auto">
            <ul className="react-select__menu-list">{renderSuggestions()}</ul>
          </div>
        )} */}
        { (status === "OK" || customAddressSuggestion) && (<div className="react-select__menu w-full absolute top-[3.125rem] left-0 bg-white z-10 overflow-auto">
            <ul className="react-select__menu-list">{renderSuggestions()}</ul>
          </div>)
        }
      </div>

      {meta.touched && meta.error ? (
        <span className="block mt-2 text-xs text-right text-red-700">
          {meta.touched && meta.error ? meta.error : null}
        </span>
      ) : null}
    </div>
  );
};

export default GoogleAutoCompleteField;
