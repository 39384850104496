import React from "react";

const IconChat = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 23.205 17.606"
    >
      <g
        id="Group_3177"
        dataname="Group 3177"
        transform="translate(-376.5 -1171.5)"
      >
        <g
          id="Group_3048"
          dataname="Group 3048"
          transform="translate(377 1172)"
        >
          <path
            id="Path_10291"
            dataname="Path 10291"
            d="M-1295.141,530.669v9.886a.864.864,0,0,1-.864.864h-9.214l-2.2,1.924a.163.163,0,0,1-.268-.151l.322-1.772h-1.9a.864.864,0,0,1-.864-.864v-9.883a.864.864,0,0,1,.864-.864H-1296A.861.861,0,0,1-1295.141,530.669Z"
            transform="translate(1310.129 -529.808)"
            fill="none"
            stroke="#000"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <path
            id="Path_10292"
            dataname="Path 10292"
            d="M-1284.246,549.766v1.613a.864.864,0,0,0,.865.864h9.214l2.2,1.924a.163.163,0,0,0,.268-.151l-.322-1.772h1.9a.865.865,0,0,0,.865-.864V541.5a.865.865,0,0,0-.865-.864h-6.252"
            transform="translate(1291.462 -537.614)"
            fill="none"
            stroke="#000"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <circle
            id="Ellipse_315"
            dataname="Ellipse 315"
            cx="0.615"
            cy="0.615"
            r="0.615"
            transform="translate(2.591 4.993)"
            fill="none"
            stroke="#000"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <circle
            id="Ellipse_316"
            dataname="Ellipse 316"
            cx="0.615"
            cy="0.615"
            r="0.615"
            transform="translate(6.879 4.993)"
            fill="none"
            stroke="#000"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <circle
            id="Ellipse_317"
            dataname="Ellipse 317"
            cx="0.615"
            cy="0.615"
            r="0.615"
            transform="translate(11.167 4.993)"
            fill="none"
            stroke="#000"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  );
};

export default IconChat;
