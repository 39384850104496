import React from "react";

const IconCaretDown = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 292.4 164.5">
      <g>
        <path
          d="M286.9,5.4C283.3,1.8,279,0,274.1,0H18.3C13.3,0,9,1.8,5.4,5.4C1.8,9,0,13.3,0,18.3c0,4.9,1.8,9.2,5.4,12.8L133.3,159
        c3.6,3.6,7.9,5.4,12.9,5.4s9.2-1.8,12.8-5.4L286.9,31.1c3.6-3.6,5.4-7.9,5.4-12.8C292.4,13.3,290.5,9,286.9,5.4z"
        />
      </g>
    </svg>
  );
};

export default IconCaretDown;
