import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import AuthService from "../../services/auth-service";

import {
  VERIFY_USER,
  VERIFY_NEW_USER,
  LOGIN,
  VERIFY_OTP,
  RESEND_OTP,
  INITIAL_CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  VERIFY_RESET_TOKEN,
  RESET_PASSWORD,
  VERIFY_EMAIL,
  LOGOUT,
  RESEND_INVITATION_LINK,
  RESEND_FORGOT_PASSWORD_LINK,
  CHECK_USER_VERIFICATION,
} from "../actions";
import {
  verifyUserSuccess,
  verifyUserError,
  loginSuccess,
  loginError,
  verifyOtpSuccess,
  verifyOtpError,
  resendOtpSuccess,
  resendOtpError,
  initialChangePasswordSuccess,
  initialChangePasswordError,
  forgotPasswordSuccess,
  forgotPasswordError,
  verifyResetTokenSuccess,
  verifyResetTokenError,
  resetPasswordSuccess,
  resetPasswordError,
  verifyEmailSuccess,
  verifyEmailError,
  logoutSuccess,
  logoutError,
  clearVerifyData,
  clearCurrentRole,
  resendInvitationLinkSuccess,
  resendInvitationLinkError,
  resendPasswordLinkSuccess,
  resendPasswordLinkError,
  setVerifyData,
} from "./action";
import { clearSelectedInvestor } from "src/reduxs/user/action";
import {
  getPermission,
  getCurrentUser,
  // getNotificationList,
} from "../shared/action";
import { parseMessage, handleMyErrorMessage } from "../../helpers/util";
import { toast } from "react-toastify";
import ToastElement from "../../components/toast";

export function* watchVerifyUser() {
  yield takeEvery(VERIFY_USER, verifyUser);
}

const verifyUserAsync = async (token) => {
  return AuthService.verifyUser(token);
};

function* verifyUser({ payload }) {
  try {
    const response = yield call(verifyUserAsync, payload.token);
    if (response.data.success) {
      yield put(
        verifyUserSuccess(response.data.success, response.data.message)
      );
      payload.history.push({
        pathname: "/auth/login",
        state: { responseMsg: response.data.message },
      });
    } else {
      payload.history.push({
        pathname: "/auth/login",
        state: { responseMsg: response.data.message },
      });
      yield put(verifyUserError(response.data.message));
    }
  } catch (error) {
    payload.history.push({
      pathname: "/auth/login",
      state: {
        responseMsg: parseMessage(
          error.response.data.error
            ? error.response.data.error
            : error.response.data.message
        ),
      },
    });
    yield put(
      verifyUserError(
        parseMessage(
          error.response.data.error
            ? error.response.data.error
            : error.response.data.message
        )
      )
    );
  }
}

export function* watchVerifyNewUser() {
  yield takeEvery(VERIFY_NEW_USER, verifyNewUser);
}

const verifyNewUserAsync = async (data) => {
  const verificationData = JSON.parse(localStorage.getItem("verifyData"));
  const { typeId } = verificationData;
  let newData = {
    typeId: verificationData.typeId,
    code: verificationData.code,
  };
  if (typeId === "investorInternational") {
    newData = {
      ...newData,
      phone: data.phone,
      countryId: data.countryId,
    };
  } else if (typeId === "investorNorwegian") {
    newData = {
      ...newData,
      token: data.token,
    };
  } else {
    newData = {
      ...newData,
      password: data.password,
      passwordConfirmation: data.passwordConfirmation,
    };
  }

  return AuthService.verifyNewUser(newData);
};

function* verifyNewUser({ payload }) {
  const verificationData = JSON.parse(localStorage.getItem("verifyData"));
  const { typeId } = verificationData;
  try {
    const response = yield call(verifyNewUserAsync, payload.data);
    if (response.data.success) {
      yield put(
        verifyUserSuccess(
          response.data.success,
          response.data.message,
          response.data.data
        )
      );
      if (typeId === "investorInternational") {
        toast.success(
          <ToastElement type="success" message={response.data.message} />,
          { containerId: "default", position: "top-right" }
        );

        payload.history.push({
          pathname: "/auth/otp",
          state: {
            responseMsg: response.data.message,
            type: typeId,
            isVerify: true,
          },
        });
      } else {
        toast.success(
          <ToastElement type="success" message={response.data.message} />,
          { containerId: "default", position: "top-right" }
        );
        localStorage.setItem("currentUser", JSON.stringify(response.data.data));
        localStorage.removeItem("verifyData");
        yield put(clearVerifyData());
        yield put(getCurrentUser(response?.data?.data?.id));
        payload.history.push({
          pathname: "/dashboard",
          state: { loginData: response.data.data },
        });
      }
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "top-right" }
      );
      payload.history.push({
        pathname: "/auth/welcome",
        state: { responseMsg: response.data.message, type: typeId },
      });
      yield put(verifyUserError(response.data.message));
    }
  } catch (error) {
    const errMessage = error.response.data.error
      ? error.response.data.error
      : error.response.data.message;

    toast.error(<ToastElement type="error" message={errMessage} />, {
      containerId: "default",
      position: "top-right",
    });

    payload.history.push({
      pathname: "/auth/welcome",
      state: {
        responseMsg: parseMessage(errMessage),
        type: typeId,
      },
    });
    yield put(
      verifyUserError(
        parseMessage(
          error.response.data.error
            ? error.response.data.error
            : error.response.data.message
        )
      )
    );
  }
}

export function* watchLogin() {
  yield takeEvery(LOGIN, login);
}

const loginAsync = async (data) => {
  return AuthService.login(data);
};

function* login({ payload }) {
  try {
    const response = yield call(loginAsync, payload.loginData);

    if (
      payload.loginData.type === "investorInternational" &&
      response.data.success
    ) {
      localStorage.setItem("loginData", JSON.stringify(payload.loginData));
      payload.history.push({
        pathname: "/auth/otp",
        state: { isVerify: false },
      });
    } else if (
      payload.loginData.type === "mallingEmployee" &&
      response.data.success
    ) {
      localStorage.setItem("currentUser", JSON.stringify(response.data.data));
      if (payload.loginData.remember === 1) {
        const creds = {
          email: payload.loginData.email,
          password: payload.loginData.password,
          remember: payload.loginData.remember === 1,
        };
        localStorage.setItem("credentials", JSON.stringify(creds));
      } else {
        localStorage.removeItem("credentials");
      }
      yield put(
        loginSuccess(
          response.data.success,
          response.data.message,
          response.data.data
        )
      );
      yield put(getCurrentUser(response?.data?.data?.id));
      payload.history.push({
        pathname: "/dashboard",
        state: { loginData: payload.loginData },
      });
    } else if (
      payload.loginData.type === "investorNorwegian" &&
      response.data.success
    ) {
      localStorage.setItem("currentUser", JSON.stringify(response.data.data));
      yield put(
        loginSuccess(
          response.data.success,
          response.data.message,
          response.data.data
        )
      );
      yield put(getCurrentUser(response?.data?.data?.id));
      payload.history.push({
        pathname: "/dashboard",
        state: { loginData: payload.loginData },
      });
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "top-right" }
      );

      yield put(loginError(response.data.message));
      if (payload.loginData.type === "investorNorwegian") {
        payload.history.push({
          pathname: "/auth/login",
          state: { type: payload.loginData.type },
        });
      }
    }
  } catch (error) {
    const errMessage = error.response.data.error
      ? error.response.data.error
      : error.response.data.message;

    toast.error(<ToastElement type="error" message={errMessage} />, {
      containerId: "default",
      position: "top-right",
    });
    yield put(loginError(parseMessage(errMessage)));

    if (payload.loginData.type === "investorNorwegian") {
      payload.history.push({
        pathname: "/auth/login",
        state: { type: payload.loginData.type },
      });
    }
  }
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (data) => {
  return AuthService.forgotPassword(data);
};

function* forgotPassword({ payload }) {
  try {
    const response = yield call(
      forgotPasswordAsync,
      payload.forgotPasswordData
    );
    if (response.data.success) {
      localStorage.setItem("useremail", payload.forgotPasswordData.email);
      yield put(
        forgotPasswordSuccess(response.data.success, response.data.message)
      );
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "top-right" }
      );
      yield put(forgotPasswordError(response.data.message));
    }
  } catch (error) {
    const errMessage = error.response.data.error
      ? error.response.data.error
      : error.response.data.message;

    toast.error(<ToastElement type="error" message={errMessage} />, {
      containerId: "default",
      position: "top-right",
    });
    yield put(forgotPasswordError(parseMessage(errMessage)));
  }
}

export function* watchVerifyResetToken() {
  yield takeEvery(VERIFY_RESET_TOKEN, verifyResetToken);
}

const verifyResetTokenAsync = async (token) => {
  return AuthService.verifyResetToken(token);
};

function* verifyResetToken({ payload }) {
  try {
    const response = yield call(verifyResetTokenAsync, payload.token);
    if (response.data.success) {
      yield put(
        verifyResetTokenSuccess(response.data.success, response.data.message)
      );
    } else {
      payload.history.push({
        pathname: "/auth/login",
        state: { responseMsg: response.data.message },
      });
      yield put(verifyResetTokenError(response.data.message));
    }
  } catch (error) {
    payload.history.push({
      pathname: "/auth/login",
      state: {
        responseMsg: parseMessage(
          error.response.data.error
            ? error.response.data.error
            : error.response.data.message
        ),
      },
    });
    yield put(
      verifyResetTokenError(
        parseMessage(
          error.response.data.error
            ? error.response.data.error
            : error.response.data.message
        )
      )
    );
  }
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (data) => {
  return AuthService.resetPassword(data);
};

function* resetPassword({ payload }) {
  try {
    const response = yield call(resetPasswordAsync, payload.resetPasswordData);
    if (response.data.success) {
      localStorage.removeItem("useremail");
      yield put(
        resetPasswordSuccess(response.data.success, response.data.message)
      );
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "top-right" }
      );

      payload.history.push({
        pathname: "/auth/login",
        state: { responseMsg: response.data.message },
      });
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "top-right" }
      );
      yield put(resetPasswordError(response.data.message));
    }
  } catch (error) {
    const errMessage = error.response.data.error
      ? error.response.data.error
      : error.response.data.message;

    toast.error(<ToastElement type="error" message={errMessage} />, {
      containerId: "default",
      position: "top-right",
    });
    yield put(resetPasswordError(parseMessage(errMessage)));
  }
}

export function* watchInitialChangePassword() {
  yield takeEvery(INITIAL_CHANGE_PASSWORD, initialChangePassword);
}

const initialChangePasswordAsync = async (data) => {
  return AuthService.initialChangePassword(data);
};

function* initialChangePassword({ payload }) {
  try {
    const response = yield call(
      initialChangePasswordAsync,
      payload.initialChangePasswordData
    );
    if (response.data.success) {
      localStorage.setItem("currentUser", JSON.stringify(response.data.data));
      localStorage.setItem("token", response.data.data.token);
      yield put(initialChangePasswordSuccess(response.data.data));
      // Fetch permission/user
      yield put(getPermission());
      yield put(getCurrentUser());
      // yield put(getNotificationList());
      payload.history.push("/");
    } else {
      yield put(initialChangePasswordError(response.data.message));
    }
  } catch (error) {
    yield put(
      initialChangePasswordError(
        parseMessage(
          error.response.data.error
            ? error.response.data.error
            : error.response.data.message
        )
      )
    );
  }
}

export function* watchVerifyOtp() {
  yield takeEvery(VERIFY_OTP, verifyOtp);
}

const verifyOtpAsync = async (data) => {
  return AuthService.verifyOtp(data);
};

function* verifyOtp({ payload }) {
  try {
    const response = yield call(verifyOtpAsync, payload.otpData);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "top-right" }
      );

      localStorage.setItem("currentUser", JSON.stringify(response.data.data));
      localStorage.removeItem("verifyData");
      localStorage.removeItem("loginData");
      yield put(verifyOtpSuccess(response.data.data));
      yield put(
        loginSuccess(
          response.data.success,
          response.data.message,
          response.data.data
        )
      );

      yield put(clearVerifyData());
      yield put(getCurrentUser(response?.data?.data?.id));
      payload.history.push({
        pathname: "/dashboard",
        state: { loginData: response.data.data },
      });
      yield put(getCurrentUser(response?.data?.data?.id));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "top-right" }
      );
      yield put(verifyOtpError(response.data.message));
    }
  } catch (error) {
    const errMessage = error.response.data.error
      ? error.response.data.error
      : error.response.data.message;

    toast.error(<ToastElement type="error" message={errMessage} />, {
      containerId: "default",
      position: "top-right",
    });
    yield put(verifyOtpError(parseMessage(errMessage)));
  }
}

export function* watchResendOtp() {
  yield takeEvery(RESEND_OTP, resendOtp);
}

const resendOtpAsync = async (data) => {
  return AuthService.resendOtp(data);
};

function* resendOtp({ payload }) {
  try {
    const response = yield call(resendOtpAsync, payload.loginData);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "top-right" }
      );
      yield put(resendOtpSuccess(response.data.success, response.data.message));
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "top-right" }
      );
      yield put(resendOtpError(response.data.message));
    }
  } catch (error) {
    const errMessage = error.response.data.error
      ? error.response.data.error
      : error.response.data.message;
    toast.error(<ToastElement type="error" message={errMessage} />, {
      containerId: "default",
      position: "top-right",
    });

    yield put(resendOtpError(parseMessage(errMessage)));
  }
}

export function* watchVerifyEmail() {
  yield takeEvery(VERIFY_EMAIL, verifyEmail);
}

const verifyEmailAsync = async (token) => {
  return AuthService.verifyEmail(token);
};

function* verifyEmail({ payload }) {
  try {
    const response = yield call(verifyEmailAsync, payload.token);
    if (response.data.success) {
      yield put(
        verifyEmailSuccess(response.data.success, response.data.message)
      );
      payload.history.push({
        pathname: "/auth/login",
        state: { responseMsg: response.data.message },
      });
    } else {
      payload.history.push({
        pathname: "/auth/login",
        state: { responseMsg: response.data.message },
      });
      yield put(verifyEmailError(response.data.message));
    }
  } catch (error) {
    payload.history.push({
      pathname: "/auth/login",
      state: {
        responseMsg: parseMessage(
          error.response.data.error
            ? error.response.data.error
            : error.response.data.message
        ),
      },
    });
    yield put(
      verifyEmailError(
        parseMessage(
          error.response.data.error
            ? error.response.data.error
            : error.response.data.message
        )
      )
    );
  }
}

export function* watchLogout() {
  yield takeEvery(LOGOUT, logout);
}

const logoutAsync = async () => {
  return AuthService.logout();
};

function* logout({ payload }) {
  try {
    const response = yield call(logoutAsync);
    if (response.data.success) {
      localStorage.removeItem("currentUser");
      localStorage.removeItem("currentRole");
      localStorage.removeItem("verifyData");
      localStorage.removeItem("boardDocumentData");
      localStorage.removeItem("rootProjectFolders");
      localStorage.removeItem("selectedInvestor");
      yield put(logoutSuccess(response.data.success, response.data.message));
      yield put(clearCurrentRole());
      yield put(clearSelectedInvestor());
      payload.history.push("/auth/login");
    } else {
      localStorage.removeItem("currentUser");
      localStorage.removeItem("currentRole");
      yield put(logoutError(response.data.message));
      payload.history.push("/auth/login");
    }
  } catch (error) {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("currentRole");
    // yield put(logoutError(error.response.data.message ? error.response.data.message : error));
    const errorMessage = handleMyErrorMessage(error)
    if(errorMessage){
      yield put(logoutError(errorMessage));
    }
    
    if (error.response && error.response.status !== 401) {
      payload.history.push("/auth/login");
    }
  }
}

export function* watchResendInvitationLink() {
  yield takeEvery(RESEND_INVITATION_LINK, resendInvitationLinkAc);
}

const resendInvitationLinkAsync = async (id, type) => {
  return AuthService.resendInvitationLink(id, type);
};

function* resendInvitationLinkAc({ payload }) {
  try {
    const response = yield call(
      resendInvitationLinkAsync,
      payload.userId,
      payload.type
    );
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "top-right" }
      );
      yield put(
        resendInvitationLinkSuccess(
          response.data.success,
          response.data.message
        )
      );
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "top-right" }
      );
      yield put(resendInvitationLinkError(response.data.message));
    }
  } catch (error) {
    const errMessage = error.response.data.error
      ? error.response.data.error
      : error.response.data.message;
    toast.error(<ToastElement type="error" message={errMessage} />, {
      containerId: "default",
      position: "top-right",
    });

    yield put(resendInvitationLinkError(parseMessage(errMessage)));
  }
}

export function* watchResendPasswordLink() {
  yield takeEvery(RESEND_FORGOT_PASSWORD_LINK, resendPasswordLinkAc);
}

const resendPasswordLinkAsync = async (email) => {
  return AuthService.resendForgotPasswordLink(email);
};

function* resendPasswordLinkAc({ payload }) {
  try {
    const response = yield call(resendPasswordLinkAsync, payload.email);
    if (response.data.success) {
      toast.success(
        <ToastElement type="success" message={response.data.message} />,
        { containerId: "default", position: "top-right" }
      );
      yield put(
        resendPasswordLinkSuccess(response.data.success, response.data.message)
      );
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "top-right" }
      );
      yield put(resendPasswordLinkError(response.data.message));
    }
  } catch (error) {
    const errMessage = error.response.data.error
      ? error.response.data.error
      : error.response.data.message;
    toast.error(<ToastElement type="error" message={errMessage} />, {
      containerId: "default",
      position: "top-right",
    });

    yield put(resendPasswordLinkError(parseMessage(errMessage)));
  }
}

export function* watchCheckUserVerification() {
  yield takeEvery(CHECK_USER_VERIFICATION, checkUserVerificationAc);
}

const checkUserVerificationAsync = async (code) => {
  return AuthService.checkUserVerification(code);
};

function* checkUserVerificationAc({ payload }) {
  const typeId = payload.verificationData?.typeId;
  let type = "malling";
  if (typeId === "internalEmployee") {
    type = "malling";
  } else if (typeId === "investorInternational") {
    type = "international";
  } else {
    type = "norwegian";
  }
  try {
    const response = yield call(
      checkUserVerificationAsync,
      payload.verificationData?.code
    );

    if (response.data.success) {
      //if user is not already verified
      if (response.data.data) {
        yield put(clearVerifyData());
        yield put(setVerifyData(payload.verificationData));
        localStorage.setItem(
          "verifyData",
          JSON.stringify(payload.verificationData)
        );
        payload.history.push({
          pathname: "/auth/welcome",
          state: { type: payload.verificationData.typeId },
        });
      } else {
        toast.error(
          <ToastElement type="error" message={response.data.message} />,
          { containerId: "default", position: "top-right" }
        );
        yield put(clearVerifyData());
        payload.history.push({
          pathname: "/auth/login",
          state: { type },
        });
      }
    } else {
      toast.error(
        <ToastElement type="error" message={response.data.message} />,
        { containerId: "default", position: "top-right" }
      );
      payload.history.push({
        pathname: "/auth/login",
        state: { type },
      });
    }
  } catch (error) {
    const errMessage = error.response.data.error
      ? error.response.data.error
      : error.response.data.message;
    toast.error(<ToastElement type="error" message={errMessage} />, {
      containerId: "default",
      position: "top-right",
    });
    payload.history.push({
      pathname: "/auth/login",
      state: { type },
    });
  }
}
export default function* rootSaga() {
  yield all([
    fork(watchVerifyUser),
    fork(watchVerifyNewUser),
    fork(watchLogin),
    fork(watchVerifyOtp),
    fork(watchResendOtp),
    fork(watchInitialChangePassword),
    fork(watchForgotPassword),
    fork(watchVerifyResetToken),
    fork(watchResetPassword),
    fork(watchVerifyEmail),
    fork(watchLogout),
    fork(watchResendInvitationLink),
    fork(watchResendPasswordLink),
    fork(watchCheckUserVerification),
  ]);
}
