import React from "react";

const IconPencilLine = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 14.876 16.558">
      <g
        id="Group_2703"
        dataname="Group 2703"
        transform="translate(333.006 -1417.295)"
      >
        <g id="Group_2702" dataname="Group 2702">
          <path
            id="Path_10042"
            dataname="Path 10042"
            d="M-330.961,1431.46h0l.026-.006h0l2.628-.657.048-.012a2.455,2.455,0,0,0,.621-.215,2.416,2.416,0,0,0,.5-.422l.036-.035,7.194-7.194h0l.027-.027a9.132,9.132,0,0,0,.775-.835,1.583,1.583,0,0,0,.373-.977,1.58,1.58,0,0,0-.373-.976,9.132,9.132,0,0,0-.775-.835l-.027-.027-.172-.172-.027-.026a9.038,9.038,0,0,0-.835-.776,1.581,1.581,0,0,0-.976-.373,1.584,1.584,0,0,0-.977.373,9.284,9.284,0,0,0-.835.775l-.027.027-7.194,7.194-.035.036a2.421,2.421,0,0,0-.422.5,2.454,2.454,0,0,0-.215.621l-.012.048-.663,2.654-.008.03a3.521,3.521,0,0,0-.1.476.848.848,0,0,0,.226.706.847.847,0,0,0,.706.226,3.513,3.513,0,0,0,.476-.1Z"
            fill="none"
            stroke="#000"
            strokeWidth="1.2"
          />
          <path
            id="Path_10043"
            dataname="Path 10043"
            d="M-324.415,1419.58l4,4"
            fill="none"
            stroke="#000"
            strokeWidth="1.2"
          />
        </g>
        <line
          id="Line_22"
          dataname="Line 22"
          x2="13.676"
          transform="translate(-332.405 1433.373)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.96"
        />
      </g>
    </svg>
  );
};

export default IconPencilLine;
