import React from "react";

const IconDocument = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 8.52 8.52"
    >
      <g
        id="Group_2010"
        dataname="Group 2010"
        transform="translate(-2.5 -2.5)"
      >
        <path
          id="Path_9261"
          dataname="Path 9261"
          d="M3,4.671c0-.788,0-1.182.245-1.426S3.883,3,4.671,3H8.849c.788,0,1.182,0,1.426.245s.245.639.245,1.426V8.849c0,.788,0,1.182-.245,1.426s-.639.245-1.426.245H4.671c-.788,0-1.182,0-1.426-.245S3,9.636,3,8.849Z"
          fill="none"
          stroke="#000"
          strokeWidth="0.7"
        />
        <path
          id="Path_9262"
          dataname="Path 9262"
          d="M3,7H4.253a1.8,1.8,0,0,1,.791.088c.176.088.3.252.545.58L5.841,8c.246.328.369.492.545.58a1.8,1.8,0,0,0,.791.088H9.266A2.084,2.084,0,0,0,10,8.607a.836.836,0,0,0,.452-.452,2.084,2.084,0,0,0,.064-.737h0"
          transform="translate(0 -2.329)"
          fill="none"
          stroke="#000"
          strokeWidth="0.7"
        />
        <path
          id="Path_9263"
          dataname="Path 9263"
          d="M7,16h3.342"
          transform="translate(-2.329 -7.569)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeWidth="0.7"
        />
      </g>
    </svg>
  );
};

export default IconDocument;
