import React from "react";

const IconChevronRightDouble = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22">
      <path
        fill="none"
        stroke="#000000"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="15.2154"
        d="M9.3,2.2l8.8,8.8l-8.8,8.8"
      />
      <path
        fill="none"
        stroke="#000000"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="15.2154"
        d="M2.9,2.2l8.8,8.8l-8.8,8.8"
      />
    </svg>
  );
};

export default IconChevronRightDouble;
