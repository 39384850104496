import {
  SET_PAIDINOUT,
  GET_ALL_PAIDINOUT,
  GET_ALL_PAIDINOUT_SUCCESS,
  GET_ALL_PAIDINOUT_ERROR,
  GET_CAPITAL_CALL_CREDIT,
  GET_CAPITAL_CALL_CREDIT_SUCCESS,
  GET_CAPITAL_CALL_CREDIT_ERROR,
  GET_CAPITAL_CALL_DEBIT,
  GET_CAPITAL_CALL_DEBIT_SUCCESS,
  GET_CAPITAL_CALL_DEBIT_ERROR,
  ADD_PAIDINOUT,
  ADD_PAIDINOUT_SUCCESS,
  ADD_PAIDINOUT_ERROR,
  GET_PAIDINOUT,
  GET_PAIDINOUT_SUCCESS,
  SET_EACH_PROPERTYFUND,
  GET_PAIDINOUT_ERROR,
  EDIT_PAIDINOUT,
  EDIT_PAIDINOUT_SUCCESS,
  EDIT_PAIDINOUT_ERROR,
  DELETE_PAIDINOUT,
  DELETE_PAIDINOUT_SUCCESS,
  DELETE_PAIDINOUT_ERROR,
  EXPORT_PAIDINOUT,
  EXPORT_PAIDINOUT_SUCCESS,
  EXPORT_PAIDINOUT_ERROR,
  RESET_PAIDINOUT,
  SET_CHECKED,
  SET_CHECKED_SUCCESS,
  SET_CHECKED_ERROR,
} from "../actions";

const INIT_STATE = {
  rowIndex: null,
  columnId: null,
  value: null,
  param: null,
  capitalParam: null,
  paidInOutList: null,
  capitalCall: null,
  paidInOutData: null,
  paidInOutId: null,
  projectId: null,
  success: false,
  success1: false,
  message: null,
  loading: false,
  loading1: false,
  loading2: false,
  loading3: false,
  error: null,
  paidInCapitalCall: null,
  paidOutCapitalCall: null,
  eachPropertyFund: false,
  checkedLoading: false,
};

function _setPaidInOutList(paidInOutList, rowIndex, columnId, value) {
  paidInOutList?.data?.project?.forEach(function (item, index) {
    if (index === rowIndex) this[index][columnId] = value;
  }, paidInOutList?.data?.project);
  return paidInOutList;
}

const paidInOutReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_PAIDINOUT:
      return {
        ...state,
        paidInOutList: _setPaidInOutList(
          state.paidInOutList,
          action.payload.rowIndex,
          action.payload.columnId,
          action.payload.value
        ),
      };
    case GET_ALL_PAIDINOUT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ALL_PAIDINOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        paidInOutList: action.payload.paidInOutList,
        error: null,
      };
    case GET_ALL_PAIDINOUT_ERROR:
      return {
        ...state,
        loading: false,
        paidInOutList: null,
        error: action.payload,
      };
    case GET_CAPITAL_CALL_CREDIT:
      return {
        ...state,
        error: null,
      };
    case GET_CAPITAL_CALL_CREDIT_SUCCESS:
      return {
        ...state,
        paidInCapitalCall: action.payload,
        error: null,
      };
    case GET_CAPITAL_CALL_CREDIT_ERROR:
      return {
        ...state,
        paidInCapitalCall: null,
        error: action.payload,
      };
    case GET_CAPITAL_CALL_DEBIT:
      return {
        ...state,
        error: null,
      };
    case GET_CAPITAL_CALL_DEBIT_SUCCESS:
      return {
        ...state,
        paidOutCapitalCall: action.payload,
        error: null,
      };
    case GET_CAPITAL_CALL_DEBIT_ERROR:
      return {
        ...state,
        paidOutCapitalCall: null,
        error: action.payload,
      };
    case ADD_PAIDINOUT:
      return { ...state, loading1: true, error: null };
    case ADD_PAIDINOUT_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case ADD_PAIDINOUT_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case GET_PAIDINOUT:
      return { ...state, error: null };
    case GET_PAIDINOUT_SUCCESS:
      return {
        ...state,
        paidInOutData: action.payload,
        error: null,
      };
    case GET_PAIDINOUT_ERROR:
      return {
        ...state,
        paidInOutData: null,
        error: action.payload,
      };
    case EDIT_PAIDINOUT:
      return { ...state, loading1: true, error: null };
    case EDIT_PAIDINOUT_SUCCESS:
      return {
        ...state,
        loading1: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case EDIT_PAIDINOUT_ERROR:
      return {
        ...state,
        loading1: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case DELETE_PAIDINOUT:
      return { ...state, loading2: true, error: null };
    case DELETE_PAIDINOUT_SUCCESS:
      return {
        ...state,
        loading2: false,
        success1: action.payload.success1,
        success2: action.payload.success1,
        message: action.payload.message,
        error: null,
      };
    case DELETE_PAIDINOUT_ERROR:
      return {
        ...state,
        loading2: false,
        success1: false,
        message: null,
        error: action.payload,
      };
    case EXPORT_PAIDINOUT:
      return { ...state, loading3: true, error: null };
    case EXPORT_PAIDINOUT_SUCCESS:
      return {
        ...state,
        loading3: false,
        success: action.payload.success,
        message: action.payload.message,
        error: null,
      };
    case EXPORT_PAIDINOUT_ERROR:
      return {
        ...state,
        loading3: false,
        success: false,
        message: null,
        error: action.payload,
      };
    case RESET_PAIDINOUT:
      return {
        ...state,
        loading: false,
        loading1: false,
        loading2: false,
        loading3: false,
        success: false,
        success1: false,
        message: null,
        error: null,
      };
    case SET_CHECKED:
      return {
        ...state,
        checkedLoading: true,
      };

    case SET_CHECKED_SUCCESS:
      return {
        ...state,
        checkedLoading: false,
        eachPropertyFund: action.payload.eachPropertyFund,
      };
    case SET_CHECKED_ERROR:
      return {
        ...state,
        checkedLoading: false,
        error: action.payload.error,
      };

    case SET_EACH_PROPERTYFUND:
      return {
        ...state,
        eachPropertyFund: action.payload,
      };
    default:
      return { ...state };
  }
};
export default paidInOutReducer;
