import React, { Component } from "react";
import * as Svg from "../../components/svg";
class Icon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listIcon: [],
    };
  }
  componentDidMount() {
    for (const [iconComponentName, IconComponent] of Object.entries(Svg)) {
      this.setState((prevState) => ({
        listIcon: [
          ...prevState.listIcon,
          { iconName: iconComponentName, iconComp: IconComponent },
        ],
      }));
    }
  }

  render() {
    return (
      <div
        style={{
          maxWidth: "100%",
          width: 1536,
          marginRight: "auto",
          marginLeft: "auto",
        }}
      >
        <h1
          style={{
            marginTop: 30,
            marginBottom: 50,
            fontSize: 50,
            fontWeight: 700,
            textAlign: "center",
          }}
        >
          Icons Used In This Project
        </h1>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {this.state.listIcon.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  maxWidth: "100%",
                  width: 200,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginBottom: 20,
                  padding: 15,
                }}
              >
                <figure className="svg-size-compact text-center">
                  {React.createElement(item.iconComp, {})}
                </figure>
                <h5 style={{ fontSize: 18, marginTop: 20, fontWeight: 500 }}>
                  {item.iconName}
                </h5>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
export default Icon;
