import React, { Component } from "react";
import { withTranslation } from "react-i18next";
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: false, isChunkIssue: false };
  }

  componentDidCatch(error) {
    if (error.name ==='ChunkLoadError') {
      this.setState({ error: true, isChunkIssue: true });
    } else {
      this.setState({ error: true });
    }
    //send error message to site admin here
  }

  render() {
    const { t } = this.props;
    if (this.state.error && this.state.isChunkIssue) {
      return (
        <div className="w-screen h-screen flex flex-1 items-center justify-center">
        <div className="px-12 py-12 bg-white rounded-lg shadow-card w-1/4 justify-center">
          <h4 className='text-lg mb-4'><strong>{t("ERROR.BOUNDARY_TITLE")}</strong></h4>
          <p className='text-gray-500 mb-4'>{t("ERROR.BOUNDARY_MESSAGE")}</p>
          <button type="button" className='mt-4 py-2 px-8 rounded-md bg-primary-500 text-white' onClick={() => window.location.reload()}>
            {t("COMMON.BUTTON.RELOAD_PAGE")}
          </button>
        </div>
        </div>
      );
    }else if (this.state.error) {
      return (
        <div className="w-screen h-screen flex flex-1 items-center justify-center">
        <div className="px-12 py-12 bg-white rounded-lg shadow-card w-1/4 justify-center">
          <h4 className='text-lg mb-4'><strong>{t("ERROR.BOUNDARY_TITLE")}</strong></h4>
          <p className='text-gray-500'>{t("ERROR.BOUNDARY_MESSAGE")}</p>
        </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
