import React from "react";
const IconNews = (props) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 24.082 24.966"
    >
      <g
        id="Group_3488"
        data-name="Group 3488"
        transform="translate(-189.775 1613.044)"
      >
        <path
          id="Path_10572"
          data-name="Path 10572"
          d="M211.164-1588.578H191.908a1.633,1.633,0,0,1-1.633-1.633v-20.7a1.633,1.633,0,0,1,1.633-1.633h15.432a1.633,1.633,0,0,1,1.633,1.633v1.767"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="bevel"
          strokeWidth="1"
        />
        <path
          id="Path_10573"
          data-name="Path 10573"
          d="M582.9-1533.347v3.722a2.193,2.193,0,0,1-2.193,2.193,2.192,2.192,0,0,1-2.193-2.193V-1548.3h2.807a1.578,1.578,0,0,1,1.578,1.578v1.329"
          transform="translate(-369.543 -61.146)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="bevel"
          strokeWidth="1"
        />
        <line
          id="Line_171"
          data-name="Line 171"
          y2="3.971"
          transform="translate(213.357 -1600.861)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="bevel"
          strokeWidth="1"
        />
        <rect
          id="Rectangle_1883"
          data-name="Rectangle 1883"
          width="13.292"
          height="1.972"
          transform="translate(192.893 -1609.62)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="bevel"
          strokeWidth="1"
        />
        <rect
          id="Rectangle_1884"
          data-name="Rectangle 1884"
          width="6.935"
          height="6.289"
          transform="translate(192.893 -1605.575)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="bevel"
          strokeWidth="1"
        />
        <line
          id="Line_172"
          data-name="Line 172"
          x2="13.292"
          transform="translate(192.893 -1597.451)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="bevel"
          strokeWidth="1"
        />
        <line
          id="Line_173"
          data-name="Line 173"
          x2="9.552"
          transform="translate(192.893 -1595.377)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="bevel"
          strokeWidth="1"
        />
        <line
          id="Line_174"
          data-name="Line 174"
          x2="5.269"
          transform="translate(192.893 -1593.303)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="bevel"
          strokeWidth="1"
        />
        <line
          id="Line_175"
          data-name="Line 175"
          x2="5.269"
          transform="translate(192.893 -1590.737)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="bevel"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};
export default IconNews;
