import React, { useState, useEffect } from "react";
import { useField } from "formik";
//import { useTranslation } from "react-i18next";
import { Switch } from "@headlessui/react";
//import { useFormikContext } from "formik";
const SwitchToggle = ({
  label,
  labelClass,
  labelPos,
  options,
  callback,
  switchText,
  classes,
  loading = false,
  ...props
}) => {
  //const { i18n } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const [field, meta, handler] = useField(props);
  const [isChecked, setChecked] = useState(false);

  const handleChange = (val) => {
    setChecked(val);
    handler.setValue(val === true ? 1 : 0);
  };

  useEffect(() => {
    if (field.value === true || field.value === 1) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [field.value, isChecked]);

  return loading ? (
    <div className="field-group animate-pulse">
      <label className="w-1/2 h-6 field-label rounded bg-grey-400"></label>
    </div>
  ) : (
    <div className={`flex items-center ${classes.root || ""}`}>
      <label
        className={`text-gray-400 
        ${labelPos === "end" ? "order-1 ml-4" : "mr-4"} 
        ${classes.label || ""}
        `}
      >
        {label ? label : null}
      </label>

      <Switch
        {...field}
        type="button"
        disabled={props.disabled}
        checked={isChecked}
        onChange={handleChange}
        className={`switch ${isChecked ? "checked" : ""} 
        ${classes?.switch || ""}`}
      >
        <span className="sr-only">{label ? label : null}</span>
        <span className={`switch__handle`}>
          {isChecked && (
            <svg width="7.731" height="7.731" viewBox="0 0 7.731 7.731">
              <defs>
                <clipPath id="clip-path">
                  <path
                    d="M5.835.415A1.073,1.073,0,0,1,7.3.2a.991.991,0,0,1,.227,1.416L4.062,6.185a.644.644,0,0,1-.962.074L.306,3.553a.989.989,0,0,1,0-1.433,1.075,1.075,0,0,1,1.483,0L3.378,3.658Z"
                    transform="translate(0 0)"
                  />
                </clipPath>
              </defs>
              <rect width="7.731" height="7.731" fill="none" />
              <g transform="translate(0 0.966)">
                <path
                  d="M5.835.415A1.073,1.073,0,0,1,7.3.2a.991.991,0,0,1,.227,1.416L4.062,6.185a.644.644,0,0,1-.962.074L.306,3.553a.989.989,0,0,1,0-1.433,1.075,1.075,0,0,1,1.483,0L3.378,3.658Z"
                  transform="translate(0 0)"
                />
              </g>
            </svg>
          )}
        </span>
      </Switch>

      {switchText && (
        <span className="ml-2 text-sm">{isChecked ? "Yes" : "No"}</span>
      )}
    </div>
  );
};

export default SwitchToggle;
