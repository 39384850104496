import Req from "../interceptors/token-interceptor";
import qs from "query-string";
import { cleanObj } from "src/helpers/util";

const SharedService = (function () {
  function _getCurrentUser(id) {
    return Req.get(`/api/users/${id}/?isProfile=false`, {
      withCredentials: true,
    });
  }
  function _getCurrentUserProfile(id) {
    return Req.get(`/api/users/${id}/?isProfile=true`, {
      withCredentials: true,
    });
  }
  function _getPermission(id) {
    return Req.post(
      `/api/user/permission`,
      { defaultRoleId: id },
      { withCredentials: true }
    );
  }
  function _getCountryList() {
    return Req.get("/api/countries");
  }
  function _getConfigChoice(category) {
    return Req.get(`/api/configChoice/${category}`);
  }
  function _getMultipleConfigChoice(data) {
    return Req.post(`/api/configChoice`, { categories: data });
  }
  function _getInvestorsByProject(id, params) {
    const sentParams = { ...cleanObj(params) };
    if (Object.keys(sentParams).length > 0) {
      return Req.get(
        `/api/getInvestorList/${id}?${qs.stringify(sentParams, {
          arrayFormat: "bracket",
        })}`
      );
    } else {
      return Req.get(`/api/getInvestorList/${id}`);
    }
  }

  return {
    getCurrentUser: _getCurrentUser,
    getPermission: _getPermission,
    getCountryList: _getCountryList,
    getConfigChoice: _getConfigChoice,
    getMultipleConfigChoice: _getMultipleConfigChoice,
    getInvestorsByProject: _getInvestorsByProject,
    getCurrentUserProfile: _getCurrentUserProfile,
  };
})();
export default SharedService;
