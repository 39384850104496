import React from "react";

const IconMailForward = () => {
  return (
    <svg
      width="14.321"
      height="6.69"
      viewBox="0 0 14.321 6.69"
    >
      <g
        id="Group_3561"
        dataname="Group 3561"
        transform="translate(0.315 0.34)"
      >
        <path
          id="Path_10581"
          dataname="Path 10581"
          d="M329.33,392.36v1.681a.614.614,0,0,0,.674.53h8.832v1.372a.318.318,0,0,0,.35.274.405.405,0,0,0,.247-.08l3.487-2.743a.235.235,0,0,0,0-.388h0l-3.487-2.743a.419.419,0,0,0-.494,0,.247.247,0,0,0-.1.192v1.38L330,391.83A.614.614,0,0,0,329.33,392.36Z"
          transform="translate(-329.33 -390.182)"
          fill="none"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

export default IconMailForward;
