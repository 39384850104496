import React from "react";

const IconColoredBank = () => {
  return (
    <svg
      width="70"
      height="63"
      viewBox="0 0 70 63"
    >
      <g
        id="Group_3001"
        dataname="Group 3001"
        transform="translate(-415 -642)"
      >
        <rect
          id="Rectangle_1675"
          dataname="Rectangle 1675"
          width="70"
          height="63"
          rx="5"
          transform="translate(415 642)"
          fill="#e5f4e3"
        />
        <g id="bank" transform="translate(433.667 656.445)">
          <g id="Group_2712" dataname="Group 2712" transform="translate(0 0)">
            <g id="Group_2711" dataname="Group 2711" transform="translate(0)">
              <path
                id="Path_10058"
                dataname="Path 10058"
                d="M32.393,27.45h-1.1V26.9a1.647,1.647,0,0,0-1.1-1.546V14.173a1.647,1.647,0,0,0,1.1-1.546v-.549a1.647,1.647,0,1,0,0-3.294H30.9L16.759.081a.549.549,0,0,0-.575,0L2.042,8.784H1.648a1.647,1.647,0,1,0,0,3.294v.549a1.647,1.647,0,0,0,1.1,1.546V25.355a1.647,1.647,0,0,0-1.1,1.546v.549H.55A.549.549,0,0,0,0,28v4.392a.549.549,0,0,0,.549.549H32.393a.549.549,0,0,0,.549-.549V28A.549.549,0,0,0,32.393,27.45ZM30.2,12.627a.549.549,0,0,1-.549.549h-2.2a.549.549,0,0,1-.549-.549v-.549H30.2Zm-.549,13.725a.549.549,0,0,1,.549.549v.549H26.9V26.9a.549.549,0,0,1,.549-.549ZM28,25.254V14.274h1.1v10.98ZM1.648,10.98a.549.549,0,0,1,0-1.1H5.491v-1.1H4.137L16.471,1.193,28.806,8.784H8.785v1.1h22.51a.549.549,0,1,1,0,1.1Zm10.98,14.375V14.173a1.647,1.647,0,0,0,1.1-1.546v-.549h5.49v.549a1.647,1.647,0,0,0,1.1,1.546V25.355a1.647,1.647,0,0,0-1.1,1.546v.549h-5.49V26.9A1.647,1.647,0,0,0,12.628,25.355Zm-.549,1a.549.549,0,0,1,.549.549v.549H9.334V26.9a.549.549,0,0,1,.549-.549Zm-1.647-1.1V14.274h1.1v10.98Zm1.647-12.078h-2.2a.549.549,0,0,1-.549-.549v-.549h3.294v.549A.549.549,0,0,1,12.079,13.176ZM20.314,26.9a.549.549,0,0,1,.549-.549h2.2a.549.549,0,0,1,.549.549v.549H20.314Zm1.1-1.647V14.274h1.1v10.98ZM23.06,13.176h-2.2a.549.549,0,0,1-.549-.549v-.549h3.294v.549A.549.549,0,0,1,23.06,13.176Zm-19.764,0a.549.549,0,0,1-.549-.549v-.549H6.04v.549a.549.549,0,0,1-.549.549Zm1.647,1.1v10.98h-1.1V14.274h1.1ZM2.746,26.9a.549.549,0,0,1,.549-.549h2.2a.549.549,0,0,1,.549.549v.549H2.746Zm4.392,4.941H1.1V28.548H7.138Zm-1.1-6.487V14.173a1.647,1.647,0,0,0,1.1-1.546v-.549h1.1v.549a1.647,1.647,0,0,0,1.1,1.546V25.355a1.647,1.647,0,0,0-1.1,1.546v.549h-1.1V26.9A1.647,1.647,0,0,0,6.04,25.355Zm18.666,6.487H8.236v-1.1H21.412v-1.1H8.236v-1.1h16.47Zm-1.1-6.487V14.173a1.647,1.647,0,0,0,1.1-1.546v-.549h1.1v.549a1.647,1.647,0,0,0,1.1,1.546V25.355A1.647,1.647,0,0,0,25.8,26.9v.549h-1.1V26.9A1.647,1.647,0,0,0,23.609,25.355Zm8.235,6.487H25.8V28.548h6.039Z"
                transform="translate(-0.001 0.001)"
                fill="#2f8d5e"
              />
            </g>
          </g>
          <g
            id="Group_2714"
            dataname="Group 2714"
            transform="translate(23.336 29.827)"
          >
            <g
              id="Group_2713"
              dataname="Group 2713"
              transform="translate(0 0)"
            >
              <rect
                id="Rectangle_1670"
                dataname="Rectangle 1670"
                height="1.556"
                transform="translate(0 0)"
                fill="#2f8d5e"
              />
            </g>
          </g>
          <g
            id="Group_2716"
            dataname="Group 2716"
            transform="translate(6.225 9.605)"
          >
            <g
              id="Group_2715"
              dataname="Group 2715"
              transform="translate(0 0)"
            >
              <rect
                id="Rectangle_1671"
                dataname="Rectangle 1671"
                width="1.556"
                transform="translate(0)"
                fill="#2f8d5e"
              />
            </g>
          </g>
          <g
            id="Group_2718"
            dataname="Group 2718"
            transform="translate(14.274 3.294)"
          >
            <g id="Group_2717" dataname="Group 2717">
              <path
                id="Path_10059"
                dataname="Path 10059"
                d="M210.2,48a2.2,2.2,0,1,0,2.2,2.2A2.2,2.2,0,0,0,210.2,48Zm0,3.294a1.1,1.1,0,1,1,1.1-1.1A1.1,1.1,0,0,1,210.2,51.3Z"
                transform="translate(-208.001 -48.002)"
                fill="#2f8d5e"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconColoredBank;
