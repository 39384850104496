import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "src/configs/core";

const DashboardView = lazy(() => import("./dashboard"));
const NotificationView = lazy(() => import("./notification"));
const ProfileMessageView = lazy(() => import("./profile-message"));
const UserManagementView = lazy(() => import("./user-management"));
const ConfigurationView = lazy(() => import("./configuration"));
const ProjectManagementView = lazy(() => import("./project-management"));
const InvestorManagement = lazy(() => import("./investor-management"));
const DocumentManagement = lazy(() => import("./document-management"));

export default function Base() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }

        <ContentRoute
          path="/dashboard"
          render={(props) => <DashboardView {...props} />}
        />

        <ContentRoute
          path="/notification"
          render={(props) => <NotificationView {...props} />}
        />

        <ContentRoute
          path="/profile-message"
          render={(props) => <ProfileMessageView {...props} />}
        />

        <Route
          path="/user-management"
          render={(props) => <UserManagementView {...props} />}
        />

        <Route path="/configuration" component={ConfigurationView} />

        <Route
          path="/project-management"
          render={(props) => <ProjectManagementView {...props} />}
        />

        <Route
          path="/investor-management"
          render={(props) => <InvestorManagement {...props} />}
        />

        <Route
          path="/document-management"
          render={(props) => <DocumentManagement {...props} />}
        />

        <Redirect to="/404" />
      </Switch>
    </Suspense>
  );
}
