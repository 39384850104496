import React from "react";

const IconHandBuilding = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 14.063 18.005">
      <g
        id="Group_3155"
        dataname="Group 3155"
        transform="translate(-655.834 -367.565)"
      >
        <g id="Group_2009" dataname="Group 2009">
          <g id="Group_2006" dataname="Group 2006">
            <path
              id="Path_9257"
              dataname="Path 9257"
              d="M658.546,380.668l1.5-1.274a1.528,1.528,0,0,1,1-.368h1.32a1.468,1.468,0,0,1,.632.143l.094.045a1.462,1.462,0,0,0,.63.143h1.692a.7.7,0,0,1,.7.7h0v.18"
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
            <path
              id="Path_9258"
              dataname="Path 9258"
              d="M663.394,380.732h2.226l2.888-2.475a.568.568,0,0,1,.8.059c.012.013.022.027.033.041l.057.08a.54.54,0,0,1-.031.663l-2.9,3.362a1.1,1.1,0,0,1-.829.381h-4.232a.424.424,0,0,0-.266.092l-.2.156"
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
            <rect
              id="Rectangle_1612"
              dataname="Rectangle 1612"
              width="2.151"
              height="4.393"
              rx="0.628"
              transform="matrix(0.853, -0.522, 0.522, 0.853, 656.35, 381.307)"
              fill="none"
              stroke="#000"
              strokeLinejoin="round"
              strokeWidth="0.75"
            />
          </g>
        </g>
        <g id="Group_3154" dataname="Group 3154">
          <rect
            id="Rectangle_1791"
            dataname="Rectangle 1791"
            width="0.915"
            height="0.915"
            transform="translate(663.169 369.709)"
          />
          <rect
            id="Rectangle_1792"
            dataname="Rectangle 1792"
            width="0.915"
            height="0.915"
            transform="translate(664.709 369.709)"
          />
          <rect
            id="Rectangle_1793"
            dataname="Rectangle 1793"
            width="0.915"
            height="0.915"
            transform="translate(663.169 371.801)"
          />
          <rect
            id="Rectangle_1794"
            dataname="Rectangle 1794"
            width="0.915"
            height="0.915"
            transform="translate(664.709 371.801)"
          />
          <rect
            id="Rectangle_1795"
            dataname="Rectangle 1795"
            width="0.915"
            height="0.915"
            transform="translate(663.169 373.714)"
          />
          <path
            id="Rectangle_1796"
            dataname="Rectangle 1796"
            d="M662.243,377.262V367.94h4.183v9.322h-4.183"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.75"
          />
          <rect
            id="Rectangle_1797"
            dataname="Rectangle 1797"
            width="0.601"
            height="0.914"
            transform="translate(661.419 373.072)"
          />
          <rect
            id="Rectangle_1798"
            dataname="Rectangle 1798"
            width="0.601"
            height="0.914"
            transform="translate(661.419 375.477)"
          />
          <rect
            id="Rectangle_1799"
            dataname="Rectangle 1799"
            width="0.915"
            height="0.915"
            transform="translate(664.709 373.714)"
          />
          <line
            id="Line_161"
            dataname="Line 161"
            y1="0.837"
            transform="translate(663.571 376.425)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.75"
          />
          <line
            id="Line_162"
            dataname="Line 162"
            y1="0.837"
            transform="translate(664.909 376.425)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.75"
          />
          <path
            id="Path_10331"
            dataname="Path 10331"
            d="M660.393,377.162v-5.22a.326.326,0,0,1,.326-.326h1.213"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeWidth="0.861"
          />
          <line
            id="Line_163"
            dataname="Line 163"
            x2="8.265"
            transform="translate(659.437 377.262)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.75"
          />
          <line
            id="Line_164"
            dataname="Line 164"
            x2="2"
            transform="translate(663.22 375.997)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.75"
          />
        </g>
      </g>
    </svg>
  );
};

export default IconHandBuilding;
