import React from "react";

const IconFilter = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 18 16">
      <g id="Group_2869" dataname="Group 2869" transform="translate(-3 -4)">
        <path
          id="Path_10176"
          dataname="Path 10176"
          d="M11,8h9"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeWidth="2"
        />
        <path
          id="Path_10177"
          dataname="Path 10177"
          d="M4,16H14"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeWidth="2"
        />
        <circle
          id="Ellipse_308"
          dataname="Ellipse 308"
          cx="3"
          cy="3"
          r="3"
          transform="translate(10 5) rotate(90)"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeWidth="2"
        />
        <circle
          id="Ellipse_309"
          dataname="Ellipse 309"
          cx="3"
          cy="3"
          r="3"
          transform="translate(20 13) rotate(90)"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export default IconFilter;
