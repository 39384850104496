export const GET_DASHBOARD_STUFF_COUNT = "GET_DASHBOARD_STUFF_COUNT";
export const GET_DASHBOARD_STUFF_COUNT_SUCCESS =
  "GET_DASHBOARD_STUFF_COUNT_SUCCESS";
export const GET_DASHBOARD_STUFF_COUNT_ERROR =
  "GET_DASHBOARD_STUFF_COUNT_ERROR";

export const GET_DASHBOARD_PAID_IN_PROJECT_LIST =
  "GET_DASHBOARD_PAID_IN_PROJECT_LIST";
export const GET_DASHBOARD_PAID_IN_PROJECT_LIST_SUCCESS =
  "GET_DASHBOARD_PAID_IN_PROJECT_LIST_SUCCESS";
export const GET_DASHBOARD_PAID_IN_PROJECT_LIST_ERROR =
  "GET_DASHBOARD_PAID_IN_PROJECT_LIST_ERROR";

export const GET_DASHBOARD_PAID_OUT_PROJECT_LIST =
  "GET_DASHBOARD_PAID_OUT_PROJECT_LIST";
export const GET_DASHBOARD_PAID_OUT_PROJECT_LIST_SUCCESS =
  "GET_DASHBOARD_PAID_OUT_PROJECT_LIST_SUCCESS";
export const GET_DASHBOARD_PAID_OUT_PROJECT_LIST_ERROR =
  "GET_DASHBOARD_PAID_OUT_PROJECT_LIST_ERROR";

export const GET_TOP_PAID_IN_OUT_PROJECT_LIST =
  "GET_TOP_PAID_IN_OUT_PROJECT_LIST";
export const GET_TOP_PAID_IN_OUT_PROJECT_LIST_SUCCESS =
  "GET_TOP_PAID_IN_OUT_PROJECT_LIST_SUCCESS";
export const GET_TOP_PAID_IN_OUT_PROJECT_LIST_ERROR =
  "GET_TOP_PAID_IN_OUT_PROJECT_LIST_ERROR";

export const EXPORT_TOP_PAID_IN_OUT_PROJECT = "EXPORT_TOP_PAID_IN_OUT_PROJECT";
export const EXPORT_TOP_PAID_IN_OUT_PROJECT_SUCCESS =
  "EXPORT_TOP_PAID_IN_OUT_PROJECT_SUCCESS";
export const EXPORT_TOP_PAID_IN_OUT_PROJECT_ERROR =
  "EXPORT_TOP_PAID_IN_OUT_PROJECT_ERROR";

export const GET_TOP_RENT_PROJECT = "GET_TOP_RENT_PROJECT";
export const GET_TOP_RENT_PROJECT_SUCCESS = "GET_TOP_RENT_PROJECT_SUCCESS";
export const GET_TOP_RENT_PROJECT_ERROR = "GET_TOP_RENT_PROJECT_ERROR";

export const GET_SUM_PAID_IN_OUT_PROJECT = "GET_SUM_PAID_IN_OUT_PROJECT";
export const GET_SUM_PAID_IN_OUT_PROJECT_SUCCESS =
  "GET_SUM_PAID_IN_OUT_PROJECT_SUCCESS";
export const GET_SUM_PAID_IN_OUT_PROJECT_ERROR =
  "GET_SUM_PAID_IN_OUT_PROJECT_ERROR";

export const GET_LARGEST_INVESTOR_PER_PROJECT =
  "GET_LARGEST_INVESTOR_PER_PROJECT";
export const GET_LARGEST_INVESTOR_PER_PROJECT_SUCCESS =
  "GET_LARGEST_INVESTOR_PER_PROJECT_SUCCESS";
export const GET_LARGEST_INVESTOR_PER_PROJECT_ERROR =
  "GET_LARGEST_INVESTOR_PER_PROJECT_ERROR";

export const GET_TOTAL_DEBT_PER_PROJECT = "GET_TOTAL_DEBT_PER_PROJECT";
export const GET_TOTAL_DEBT_PER_PROJECT_SUCCESS =
  "GET_TOTAL_DEBT_PER_PROJECT_SUCCESS";
export const GET_TOTAL_DEBT_PER_PROJECT_ERROR =
  "GET_TOTAL_DEBT_PER_PROJECT_ERROR";
export const GET_SQM_PROJECT = "GET_SQM_PROJECT";
export const GET_SQM_PROJECT_SUCCESS = "GET_SQM_PROJECT_SUCCESS";
export const GET_SQM_PROJECT_ERROR = "GET_SQM_PROJECT_ERROR";

export const GET_ALL_NEWS = "GET_ALL_NEWS";
export const GET_ALL_NEWS_SUCCESS = "GET_ALL_NEWS_SUCCESS";
export const GET_ALL_NEWS_ERROR = "GET_ALL_NEWS_ERROR";

export const GET_NEWS = "GET_NEWS";
export const GET_NEWS_SUCCESS = "GET_NEWS_SUCCESS";
export const GET_NEWS_ERROR = "GET_NEWS_ERROR";

export const ADD_NEWS = "ADD_NEWS";
export const ADD_NEWS_SUCCESS = "ADD_NEWS_SUCCESS";
export const ADD_NEWS_ERROR = "ADD_NEWS_ERROR";

export const UPDATE_NEWS = "UPDATE_NEWS";
export const UPDATE_NEWS_SUCCESS = "UPDATE_NEWS_SUCCESS";
export const UPDATE_NEWS_ERROR = "UPDATE_NEWS_ERROR";

export const DELETE_NEWS = "DELETE_NEWS";
export const DELETE_NEWS_SUCCESS = "DELETE_NEWS_SUCCESS";
export const DELETE_NEWS_ERROR = "DELETE_NEWS_ERROR";
export const RESET_DASHBOARD = "RESET_DASHBOARD";

export const getDashboardStuffCount = (params) => ({
  type: GET_DASHBOARD_STUFF_COUNT,
  payload: { params },
});

export const getDashboardStuffCountSuccess = (dashboardStuff) => ({
  type: GET_DASHBOARD_STUFF_COUNT_SUCCESS,
  payload: dashboardStuff,
});

export const getDashboardStuffCountError = (error) => ({
  type: GET_DASHBOARD_STUFF_COUNT_ERROR,
  payload: error,
});

export const getDashboardPaidInProjectList = (params) => ({
  type: GET_DASHBOARD_PAID_IN_PROJECT_LIST,
  payload: { params },
});

export const getDashboardPaidInProjectSuccess = (projects) => ({
  type: GET_DASHBOARD_PAID_IN_PROJECT_LIST_SUCCESS,
  payload: projects,
});

export const getDashboardPaidInProjectError = (error) => ({
  type: GET_DASHBOARD_PAID_IN_PROJECT_LIST_ERROR,
  payload: error,
});

export const getDashboardPaidOutProjectList = (params) => ({
  type: GET_DASHBOARD_PAID_OUT_PROJECT_LIST,
  payload: { params },
});

export const getDashboardPaidOutProjectSuccess = (projects) => ({
  type: GET_DASHBOARD_PAID_OUT_PROJECT_LIST_SUCCESS,
  payload: projects,
});

export const getDashboardPaidOutProjectError = (error) => ({
  type: GET_DASHBOARD_PAID_OUT_PROJECT_LIST_ERROR,
  payload: error,
});

export const getTopPaidInOutProjectList = (params) => ({
  type: GET_TOP_PAID_IN_OUT_PROJECT_LIST,
  payload: { params },
});

export const getTopPaidInOutProjectSuccess = (projects) => ({
  type: GET_TOP_PAID_IN_OUT_PROJECT_LIST_SUCCESS,
  payload: projects,
});

export const getTopPaidInOutProjectError = (error) => ({
  type: GET_TOP_PAID_IN_OUT_PROJECT_LIST_ERROR,
  payload: error,
});

export const exportTopPaidInOutProject = (
  defaultRoleId,
  investorId = null
) => ({
  type: EXPORT_TOP_PAID_IN_OUT_PROJECT,
  payload: { defaultRoleId, investorId },
});

export const exportTopPaidInOutProjectSuccess = (success, message) => ({
  type: EXPORT_TOP_PAID_IN_OUT_PROJECT_SUCCESS,
  payload: { success, message },
});

export const exportTopPaidInOutProjectError = (error) => ({
  type: EXPORT_TOP_PAID_IN_OUT_PROJECT_ERROR,
  payload: error,
});

export const getTopRentProject = (params) => ({
  type: GET_TOP_RENT_PROJECT,
  payload: { params },
});

export const getTopRentProjectSuccess = (projects) => ({
  type: GET_TOP_RENT_PROJECT_SUCCESS,
  payload: projects,
});

export const getTopRentProjectError = (error) => ({
  type: GET_TOP_RENT_PROJECT_ERROR,
  payload: error,
});

export const getSumPaidInOutProject = (params) => ({
  type: GET_SUM_PAID_IN_OUT_PROJECT,
  payload: { params },
});

export const getSumPaidInOutProjectSuccess = (projects) => ({
  type: GET_SUM_PAID_IN_OUT_PROJECT_SUCCESS,
  payload: projects,
});

export const getSumPaidInOutProjectError = (error) => ({
  type: GET_SUM_PAID_IN_OUT_PROJECT_ERROR,
  payload: error,
});

export const getLargestInvestorPerProject = (params) => ({
  type: GET_LARGEST_INVESTOR_PER_PROJECT,
  payload: { params },
});

export const getLargestInvestorPerProjectSuccess = (investors) => ({
  type: GET_LARGEST_INVESTOR_PER_PROJECT_SUCCESS,
  payload: investors,
});

export const getLargestInvestorPerProjectError = (error) => ({
  type: GET_LARGEST_INVESTOR_PER_PROJECT_ERROR,
  payload: error,
});

export const getTotalDebtPerProject = (params) => ({
  type: GET_TOTAL_DEBT_PER_PROJECT,
  payload: { params },
});

export const getTotalDebtPerProjectSuccess = (projects) => ({
  type: GET_TOTAL_DEBT_PER_PROJECT_SUCCESS,
  payload: projects,
});

export const getTotalDebtPerProjectError = (error) => ({
  type: GET_TOTAL_DEBT_PER_PROJECT_ERROR,
  payload: error,
});

export const getSqmProject = (params) => ({
  type: GET_SQM_PROJECT,
  payload: { params },
});

export const getSqmProjectSuccess = (projects) => ({
  type: GET_SQM_PROJECT_SUCCESS,
  payload: projects,
});

export const getSqmProjectError = (error) => ({
  type: GET_SQM_PROJECT_ERROR,
  payload: error,
});

export const getAllNews = (params) => ({
  type: GET_ALL_NEWS,
  payload: { params },
});

export const getAllNewsSuccess = (news, metaData) => ({
  type: GET_ALL_NEWS_SUCCESS,
  payload: { news, metaData },
});

export const getAllNewsError = (error) => ({
  type: GET_ALL_NEWS_ERROR,
  payload: error,
});

export const getNews = (id) => ({
  type: GET_NEWS,
  payload: { id },
});

export const getNewsSuccess = (newsData) => ({
  type: GET_NEWS_SUCCESS,
  payload: newsData,
});

export const getNewsError = (error) => ({
  type: GET_NEWS_ERROR,
  payload: error,
});

export const addNews = (newsData) => ({
  type: ADD_NEWS,
  payload: { newsData },
});

export const addNewsSuccess = (success, message) => ({
  type: ADD_NEWS_SUCCESS,
  payload: { success, message },
});

export const addNewsError = (error) => ({
  type: ADD_NEWS_ERROR,
  payload: error,
});

export const updateNews = (newsData, id) => ({
  type: UPDATE_NEWS,
  payload: { newsData, id },
});

export const updateNewsSuccess = (success, message) => ({
  type: UPDATE_NEWS_SUCCESS,
  payload: { success, message },
});

export const updateNewsError = (error) => ({
  type: UPDATE_NEWS_ERROR,
  payload: error,
});

export const deleteNews = (id) => ({
  type: DELETE_NEWS,
  payload: { id },
});

export const deleteNewsSuccess = (success, message) => ({
  type: DELETE_NEWS_SUCCESS,
  payload: { success, message },
});

export const deleteNewsError = (error) => ({
  type: DELETE_NEWS_ERROR,
  payload: error,
});

export const resetDashboard = () => ({
  type: RESET_DASHBOARD,
  payload: {},
});
