import React from "react";

const IconInvestment = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 14.067 13.157"
    >
      <g
        id="Group_2009"
        dataname="Group 2009"
        transform="translate(-627.8 29.341)"
      >
        <g
          id="Group_2006"
          dataname="Group 2006"
          transform="translate(628.316 -23.633)"
        >
          <path
            id="Path_9257"
            dataname="Path 9257"
            d="M630.994-19.716l1.5-1.274a1.533,1.533,0,0,1,1-.368h1.32a1.461,1.461,0,0,1,.632.143l.094.045a1.47,1.47,0,0,0,.63.143h1.692a.7.7,0,0,1,.7.7v.18"
            transform="translate(-628.798 22.264)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.75"
          />
          <path
            id="Path_9258"
            dataname="Path 9258"
            d="M636.368-19.85h2.226l2.888-2.475a.569.569,0,0,1,.835.1l.057.08a.538.538,0,0,1-.031.663l-2.9,3.362a1.093,1.093,0,0,1-.829.381h-4.232a.43.43,0,0,0-.266.092l-.2.156"
            transform="translate(-629.324 22.462)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.75"
          />
          <rect
            id="Rectangle_1612"
            dataname="Rectangle 1612"
            width="2.151"
            height="4.393"
            rx="0.628"
            transform="matrix(0.853, -0.522, 0.522, 0.853, 0, 3.187)"
            fill="none"
            stroke="#000"
            strokeLinejoin="round"
            strokeWidth="0.75"
          />
        </g>
        <g
          id="Group_2007"
          dataname="Group 2007"
          transform="translate(634.874 -28.966)"
        >
          <path
            id="Path_9259"
            dataname="Path 9259"
            d="M640.2-25.329a1.945,1.945,0,0,1-1.945,1.945,1.946,1.946,0,0,1-1.945-1.945,2.2,2.2,0,0,1,1.295-1.983l-.507-1.437.653-.217.479.557.478-.557.653.217-.5,1.419A2.192,2.192,0,0,1,640.2-25.329Z"
            transform="translate(-636.313 28.966)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.75"
          />
          <line
            id="Line_7"
            dataname="Line 7"
            x2="0.648"
            transform="translate(1.919 1.627)"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.75"
          />
        </g>
        <g
          id="Group_2008"
          dataname="Group 2008"
          transform="translate(636.211 -26.474)"
        >
          <path
            id="Path_9260"
            dataname="Path 9260"
            d="M639.255-24.547a.522.522,0,0,1-.508.505v.244h-.289v-.247a.543.543,0,0,1-.514-.537l.342,0a.3.3,0,0,0,.338.268c.16,0,.279-.07.279-.2s-.132-.165-.4-.218-.517-.14-.517-.471a.5.5,0,0,1,.477-.484v-.247h.289v.249a.509.509,0,0,1,.475.5h-.342a.265.265,0,0,0-.291-.233c-.149,0-.256.063-.256.189s.128.161.4.212S639.256-24.875,639.255-24.547Z"
            transform="translate(-637.944 25.927)"
          />
        </g>
      </g>
    </svg>
  );
};

export default IconInvestment;
