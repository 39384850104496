import React, { Component } from "react";
import notFound from "../assets/images/404.svg";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

class NotFound extends Component {
  render() {
    const { t } = this.props;
    return (
      <main className="w-full h-full flex flex-col justify-center items-center">
        <img src={notFound} alt="" />
        <h4 className="mt-24 mb-5 text-4xl">{t("ERROR.PAGE_NOT_FOUND")}</h4>
        <h4 className="mb-10 text-lg text-gray-400">{t("ERROR.NOT_FOUND")}</h4>
        <Link
          to="/"
          className="flex items-center justify-center py-2 px-6 rounded-md bg-primary-500 text-white"
        >
          {t("AUTH.GENERAL.BACK_BUTTON")}
        </Link>
      </main>
    );
  }
}
export default withTranslation()(NotFound);
